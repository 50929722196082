export const shortenStr = (str, maxLength, nLeft, nRright) => {
  if (str.length > maxLength) {
    return (
      str.substr(0, nLeft) +
      '...' +
      str.substr(str.length - nRright, str.length)
    );
  }
  return str;
};
