import React from 'react';

// constants
import {
  MANAGE_RECIPIENTS_MODES,
  RECIPIENT_ACTIONS,
} from '../../../../constraints';
import appRoutes from '../../../../app-routes';

// hooks
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useHistory } from 'react-router-dom';
import { useUpdateIdentityFile } from '../../hooks/useUpdateIdentityFile';
import { useDownloadIdentityFile } from '../../hooks/useDownloadIdentityFile';

// components
import { FormattedMessage } from 'react-intl';
import { SuccessModal } from '../../../../components/SuccessModal';
import { RecipientsFileForm } from './RecipientsFileForm';
import Modal from '../../../../ui/Modal';

const RecipientManager = ({
  managerMode = MANAGE_RECIPIENTS_MODES.MAIN,
  onClose,
}) => {
  const history = useHistory();
  const { isDiploma } = useConfigContext();

  const successMessageId = isDiploma
    ? 'Successfully added student list'
    : 'Successfully added recipient list';

  const {
    mutate: downloadIdentityFile,
    isLoading: isDownloadingRecipientsList,
  } = useDownloadIdentityFile();

  const handleDownloadIdentityFile = () => {
    downloadIdentityFile();
  };

  const handleUpdateSuccess = () => {
    history.push(
      appRoutes.RECIPIENT_MANAGER.replace(
        ':mode',
        MANAGE_RECIPIENTS_MODES.SUCCESS,
      ),
    );
  };

  const {
    mutate: updateRecipients,
    isError,
    error: errorData,
    reset,
  } = useUpdateIdentityFile({
    onSuccess: handleUpdateSuccess,
  });

  const onReset = () => {
    reset();
  };

  return (
    <>
      <Modal isOpen toggle={onClose} onClose={onClose} closeIconEnabled>
        <RecipientsFileForm
          backBtnDisabled
          onSubmit={updateRecipients}
          mode={RECIPIENT_ACTIONS.UPDATE_LIST}
          onRecipientListDownload={handleDownloadIdentityFile}
          isDownloadingRecipientsList={isDownloadingRecipientsList}
          isError={isError}
          errorData={errorData?.response?.data}
          onReset={onReset}
          onClose={onClose}
        />
      </Modal>
      <SuccessModal
        isOpen={managerMode === MANAGE_RECIPIENTS_MODES.SUCCESS}
        onClose={onClose}
        toggle={onClose}
        closeIconEnabled
      >
        <FormattedMessage
          id={successMessageId}
          defaultMessage={successMessageId}
        />
      </SuccessModal>
    </>
  );
};

export default RecipientManager;
