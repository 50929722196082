import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import Error from './components/Error';
import Warning from './components/Warning';
import HelpInfo from './components/HelpInfo';
import RegularInput from './components/RegularInput';

const FormControlWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;

  &.has-error {
    margin-bottom: 0;
  }
`;

const FormControl = (props) => {
  const {
    touched,
    error,
    warning,
    helpInfo,
    floatLabel,
    expandError,
    className,
  } = props;

  return (
    <FormControlWrapper
      className={classNames({
        [className]: className,
        'has-error': touched && error && expandError,
      })}
    >
      <RegularInput {...props} floatLabel={floatLabel} />
      {helpInfo && <HelpInfo helpInfo={helpInfo} />}
      {touched && error && <Error error={error} expandError={expandError} />}
      {warning && <Warning warning={warning} />}
    </FormControlWrapper>
  );
};

FormControl.propTypes = {
  active: PropTypes.bool,
  touched: PropTypes.bool,
  dirty: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  warning: PropTypes.string,
  helpInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.string,
  children: PropTypes.any,
  floatLabel: PropTypes.bool,
  className: PropTypes.string,
  expandError: PropTypes.bool,
};

FormControl.defaultProps = {
  active: false,
  touched: false,
  dirty: false,
  expandError: false,
};

export default FormControl;
