import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import styled, { css } from 'styled-components';

import animationData from './animationData.json';
import animationData2 from './animationData2.json';
import animationData3 from './animationData3.json';

const StyledBlockchainVisualWrapper = styled.div`
  ${({ fixed }) =>
    fixed &&
    css`
      @media (min-width: 1850px) {
        max-width: 400px;
      }

      @media (min-width: 1300px) {
        margin-top: -40px;
      }
    `}
`;

const BlockchainVisual = ({ secondary, size, remoteSignAnimation, fixed }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (secondary) {
    defaultOptions.animationData = animationData2;
  } else if (remoteSignAnimation) {
    defaultOptions.animationData = animationData3;
  } else {
    defaultOptions.animationData = animationData;
  }

  return (
    <StyledBlockchainVisualWrapper className="blockchain-visual" fixed={fixed}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </StyledBlockchainVisualWrapper>
  );
};

BlockchainVisual.propTypes = {
  secondary: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fixed: PropTypes.bool,
};

BlockchainVisual.defaultProps = {
  secondary: false,
  size: 400,
  fixed: false,
};

export default BlockchainVisual;
