import React from 'react';
import { useQueryClient } from 'react-query';
import * as Styled from './styled';
import { Utils } from 'billon-ui';
import { useCategoryUpdate } from '../../hooks/useCategoryUpdate';
import { QueryKeys } from '../../../../queryKeys';
import CustomToolTip from '../../../../ui/CustomToolTip';
import { FormattedMessage } from 'react-intl';

const { Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

export const ActiveSwitch = ({
  categoryId,
  isActive,
  parentId,
  isInProgress,
  isSwitchingAvailable,
}) => {
  const queryClient = useQueryClient();

  const { mutate: updateCategory, isLoading } = useCategoryUpdate({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.CATEGORY_LIST]);
    },
  });

  const handleSwitch = () => {
    updateCategory({
      id: categoryId,
      data: {
        isActive: !isActive,
      },
    });
  };

  if (isInProgress || isLoading) {
    return <Loader width={50} />;
  }

  if (isSwitchingAvailable) {
    return (
      <Styled.SwitchField
        isSwitchingAvailable={isSwitchingAvailable}
        value={categoryId}
        className={'formatted-switch'}
        input={{
          checked: isActive,
          onChange: handleSwitch,
        }}
        slim
        meta={{
          dirty: true,
          submitting: isInProgress || isLoading,
        }}
      />
    );
  }

  return (
    <CustomToolTip
      id={`IsParentActiveToolTip-${parentId}-${categoryId}`}
      trigger={
        <Styled.SwitchField
          isSwitchingAvailable={isSwitchingAvailable}
          className={'formatted-switch'}
          input={{
            checked: isActive,
          }}
          slim
          meta={{
            dirty: true,
            submitting: isInProgress || isLoading,
          }}
        />
      }
      delay={false}
      placement="right"
      minWidth={true}
    >
      <FormattedMessage
        id="Parent category must be active"
        defaultMessage="Parent category must be active"
      />
    </CustomToolTip>
  );
};
