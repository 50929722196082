import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Form as FormModule } from 'billon-ui';

const { CheckboxField } = FormModule;

const TheWatcher = ({
  control,
  watch,
  onAppendRecipients,
  unRegisterRecipient,
  record,
}) => {
  const watcher = watch(`recipients[${record.uniqueId}]`);

  const prevCheckedRef = useRef();

  useEffect(() => {
    if (watcher) {
      onAppendRecipients(record);
    } else if (prevCheckedRef.current) {
      unRegisterRecipient(record);
    }

    prevCheckedRef.current = watcher;
  }, [watcher]);

  return (
    <Controller
      name={`recipients[${record.uniqueId}]`}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <CheckboxField
            input={{
              ...field,
              checked: field.value,
            }}
            meta={{
              touched: fieldState.invalid,
              error: fieldState.error,
            }}
          />
        );
      }}
    />
  );
};

export const chooseRecipientFormatter = (
  value,
  record,
  control,
  onAppendRecipients,
  unRegisterRecipient,
  watch,
) => {
  return (
    <TheWatcher
      control={control}
      record={record}
      watch={watch}
      onAppendRecipients={onAppendRecipients}
      unRegisterRecipient={unRegisterRecipient}
    />
  );
};
