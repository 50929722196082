import React from 'react';
import { Grid, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Styled from './styled';
import appRoutes from '../../../../app-routes';
import { CopiedInfo } from '../../../../components';
import { RECIPIENT_STATUSES } from '../../../../constraints';

const { Row, Col } = Grid;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const RecipientTableDetails = ({ record }) => {
  const history = useHistory();

  const handleShowDetails = () => {
    history.push(appRoutes.GDPR_RECIPIENT.replace(':id', record.id));
  };

  return (
    <Row noGutters margin={0} lg={12}>
      <Styled.CopiedInfoColumn lg={6}>
        <CopiedInfo
          label="Internal ID"
          value={record.id}
          emphasisedValue
          valuePlacedBetween
        />
      </Styled.CopiedInfoColumn>
      <Col lg={6}>
        <Styled.FlexEndRow lg={12}>
          <Styled.ActionButtonColumn md={4}>
            <Button
              outline
              onClick={handleShowDetails}
              block
              disabled={record?.status === RECIPIENT_STATUSES.FORGETTING}
            >
              <FormattedMessage id="See details" defaultMessage="See details" />
            </Button>
          </Styled.ActionButtonColumn>
        </Styled.FlexEndRow>
      </Col>
    </Row>
  );
};

export default RecipientTableDetails;
