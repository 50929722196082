import React from 'react';

// helpers
import { boundLengthFormatterLong } from '../../../../../../formatters';

// components
import { NavButton } from './styled';

const HorizontalTemplateGroupList = ({
  activeNotificationGroupId,
  notificationGroups,
  handleNotificationGroupClick,
}) => {
  return (
    <>
      {notificationGroups?.rows.map(({ id, name, disabled }) => (
        <NavButton
          isSelected={id === activeNotificationGroupId}
          disabled={disabled}
          onClick={() => handleNotificationGroupClick(id)}
        >
          {boundLengthFormatterLong(name)}
        </NavButton>
      ))}
    </>
  );
};

export default HorizontalTemplateGroupList;
