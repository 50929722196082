import React from 'react';
import { useIntl } from 'react-intl';
import { useExcelDownloder } from 'react-xls';
import { ExcelDownloderLink } from '../styled';
import { removeUndefinedValues } from '../../../../../../../helpers/removeUndefinedValues';

const ErrorExporter = ({
  name,
  nonUniqueErrors,
  validationErrors,
  children,
}) => {
  const { formatMessage } = useIntl();
  const { ExcelDownloder, Type } = useExcelDownloder();

  const fileName = formatMessage({ id: name });

  const nonUniqueErrorRows = [
    [
      formatMessage({ id: 'source system' }),
      formatMessage({ id: 'identifier' }),
      formatMessage({ id: 'email' }),
      formatMessage({ id: 'line' }),
    ],
    ...nonUniqueErrors?.map((singleError) => {
      const { lines, invalidData } = singleError || {};
      const { sourceSystem, email, id } = invalidData || {};
      return [sourceSystem, id, email, lines?.toString()];
    }),
  ];

  const validationErrorRows = [
    [
      formatMessage({ id: 'line' }),
      formatMessage({ id: 'identifier' }),
      formatMessage({ id: 'email' }),
      formatMessage({ id: 'phone number' }),
      formatMessage({ id: 'error type' }),
    ],
    ...validationErrors?.map((singleError) => {
      const { line, errorInfo, invalidData } = singleError || {};
      const { email, id, phoneNumber } = invalidData || {};
      return [line, id, email, phoneNumber, JSON.stringify(errorInfo)];
    }),
  ];

  const data = removeUndefinedValues({
    [formatMessage({ id: 'Data duplication errors' })]:
      nonUniqueErrors?.length > 0 ? nonUniqueErrorRows : undefined,
    [formatMessage({ id: 'Other errors' })]:
      validationErrors?.length > 0 ? validationErrorRows : undefined,
  });

  return (
    <ExcelDownloder data={data} filename={fileName} type={Type.Link}>
      <ExcelDownloderLink>{children}</ExcelDownloderLink>
    </ExcelDownloder>
  );
};

export default ErrorExporter;
