import React from 'react';
import { FormattedMessage } from 'react-intl';

// hooks
import { useConfigContext } from '../../Config/hooks/useConfig';

// constants
import {
  IDENTITY_DOCUMENT_TYPE,
  MAP_IDENTITY_DOCUMENT_TYPE,
  MAP_TAURON_IDENTITY_DOCUMENT_TYPE,
  MAP_TAURON_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL,
} from '../../../constraints';

export const useIdentityDocumentType = () => {
  const { isTauron, isDiploma } = useConfigContext();

  const MAP_IDENTITY_DOCUMENT_TYPE_BASE = isTauron
    ? { ...MAP_IDENTITY_DOCUMENT_TYPE, ...MAP_TAURON_IDENTITY_DOCUMENT_TYPE }
    : MAP_IDENTITY_DOCUMENT_TYPE;

  const MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE = isTauron
    ? {
        ...MAP_IDENTITY_DOCUMENT_TYPE,
        ...MAP_TAURON_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL,
      }
    : MAP_IDENTITY_DOCUMENT_TYPE;

  const defaultIdentityDocumentTypeKey = IDENTITY_DOCUMENT_TYPE.PESEL;

  const defaultIdentityDocumentTypeOption = {
    label: (
      <FormattedMessage
        id={MAP_IDENTITY_DOCUMENT_TYPE_BASE[defaultIdentityDocumentTypeKey]}
      />
    ),
    value: defaultIdentityDocumentTypeKey,
  };

  const identityDocumentTypeList = isTauron
    ? [
        IDENTITY_DOCUMENT_TYPE.PESEL,
        IDENTITY_DOCUMENT_TYPE.NIP,
        IDENTITY_DOCUMENT_TYPE.TEMPORARY_ID,
        IDENTITY_DOCUMENT_TYPE.FOREIGN_ID,
        IDENTITY_DOCUMENT_TYPE.OTHER,
      ]
    : [IDENTITY_DOCUMENT_TYPE.PESEL, IDENTITY_DOCUMENT_TYPE.OTHER];

  const identityDocumentTypeOptions = identityDocumentTypeList.map(
    (option) => ({
      label: <FormattedMessage id={MAP_IDENTITY_DOCUMENT_TYPE_BASE[option]} />,
      value: option,
    }),
  );

  const getIdentityDocumentTypeText = (documentType) => {
    if (isDiploma) {
      return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.INDEX_NUMBER;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.PESEL) {
      return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.PESEL;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.NIP) {
      return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.NIP;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.FOREIGN_ID) {
      return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.FOREIGN_ID;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.TEMPORARY_ID) {
      return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.TEMPORARY_ID;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.OTHER) {
      return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.OTHER;
    }
    return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.UNASSIGNED;
  };

  const getIdentityDocumentTypeBaseText = (documentType) => {
    if (isDiploma) {
      return MAP_IDENTITY_DOCUMENT_TYPE_BASE.INDEX_NUMBER;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.PESEL) {
      return MAP_IDENTITY_DOCUMENT_TYPE_BASE.PESEL;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.NIP) {
      return MAP_IDENTITY_DOCUMENT_TYPE_BASE.NIP;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.FOREIGN_ID) {
      return MAP_IDENTITY_DOCUMENT_TYPE_BASE.FOREIGN_ID;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.TEMPORARY_ID) {
      return MAP_IDENTITY_DOCUMENT_TYPE_BASE.TEMPORARY_ID;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.NIP) {
      return MAP_IDENTITY_DOCUMENT_TYPE_BASE.NIP;
    }
    if (documentType === IDENTITY_DOCUMENT_TYPE.OTHER) {
      return MAP_IDENTITY_DOCUMENT_TYPE_BASE.OTHER;
    }
    return MAP_IDENTITY_DOCUMENT_TYPE_BASE.UNASSIGNED;
  };

  const getIdentityDocumentNumberFieldLabelId = (documentTypeOption) => {
    if (isDiploma)
      return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.INDEX_NUMBER;
    if (!documentTypeOption)
      return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE.UNASSIGNED;
    return MAP_EXTERNAL_IDENTITY_DOCUMENT_TYPE_LABEL_BASE[
      documentTypeOption?.value || documentTypeOption
    ];
  };

  const identityDocumentTypeFormatter = (documentType) => {
    const documentTypeMessageId = getIdentityDocumentTypeBaseText(documentType);
    return (
      <FormattedMessage
        id={documentTypeMessageId}
        defaultMessage={documentTypeMessageId}
      />
    );
  };

  const getExternalIdText = () => {
    if (isDiploma) {
      return MAP_IDENTITY_DOCUMENT_TYPE_BASE.INDEX_NUMBER;
    }
    return MAP_IDENTITY_DOCUMENT_TYPE_BASE.ID;
  };

  return {
    identityDocumentTypeOptions,
    defaultIdentityDocumentTypeOption,
    getIdentityDocumentTypeText,
    getIdentityDocumentNumberFieldLabelId,
    getExternalIdText,
    identityDocumentTypeFormatter,
  };
};
