import { Utils, Grid } from 'billon-ui';
import styled from 'styled-components';

const { Text } = Utils;
const { Row, Col } = Grid;

export const MarginZeroRow = styled(Row)`
  margin: 0;
  margin-left: ${({ contentIndentEnabled }) =>
    contentIndentEnabled ? '12.5px' : 0};
`;

export const DetailsCol = styled(Col)`
  padding-left: 0px;
`;

export const DetailsHeaderRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
`;

export const HeaderTagColumn = styled(Col)`
  align-items: left;
  margin: 0;
  padding-left: 0px;
`;

export const HeaderText = styled(Text)`
  font-size: 18px;
  font-weight: 700;
`;

export const DetailRow = styled(Row)`
  margin: 0;
  color: ${({ warning, theme }) => warning && theme.palette.error};
`;

export const DetailTag = styled(Text)`
  margin: 0 10px 0 0;
  font-size: 13px;
  font-weight: 700;
`;

export const DetailValue = styled(Text)`
  font-size: 13px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
`;
