import apiRoutes from 'js/api-routes';
import { QueryKeys } from 'js/queryKeys';
import { useQueryWithLogout } from 'js/hooks/useQueryWithLogout';
import { getRequest } from 'js/helpers/apiClient';

const useMeRequest = (options, token) => {
  return useQueryWithLogout(
    [QueryKeys.ME],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.ME,
        headers: {
          Authorization: token ? token : sessionStorage.getItem('token'),
        },
      });
      return data;
    },
    options,
  );
};

export default useMeRequest;
