import React, { useState } from 'react';

// utils
import { categoryStatusFormatter } from '../../../../formatters';
import { CATEGORY_STATUSES, UserRoles } from '../../../../constraints';

// hooks
import { useConfigContext } from '../../../Config/hooks/useConfig';
import useFilters from '../../../../hooks/useFilters';
import { useCategoryList } from '../../hooks/useCategoryList';

// components
import { ProtectedComponent } from '../../../Auth/components';
import DocumentNavigator from '../DocumentNavigator';
import { AddCategoryCell } from '../AddCategoryCell';
import { ExpandButton } from '../ExpandButton';
import { ActiveSwitch } from '../ActiveSwitch';
import * as Styled from './styled';

export const CategoryList = ({
  parentId,
  parent: parentData,
  depth = 0,
  onCreate,
  isProceeding,
}) => {
  const { filters, navigateWithSearchQuery } = useFilters();

  const { customer } = useConfigContext();

  const [openCategories, setOpenCategories] = useState([]);
  const { data, isLoading } = useCategoryList({
    filters: {
      parentId,
      ...filters.filters,
    },
  });

  const [isBranchProceeding, setIsBranchProceeding] = useState(false);

  const onProceed = () => {
    setIsBranchProceeding(true);
  };

  const onProceedFinish = () => {
    setIsBranchProceeding(false);
  };

  const onNavigateToDocuments = (categoryId, documentType) => {
    navigateWithSearchQuery(
      {
        filters: {
          categoryId,
          documentTypeList: documentType,
        },
      },
      '/documents',
    );
  };

  const isCategoryOpen = (categoryId) =>
    openCategories.some((cat) => cat === categoryId);

  const toggleOpenCategory = (categoryId) => {
    const alreadyOpened = isCategoryOpen(categoryId);

    if (alreadyOpened) {
      setOpenCategories(openCategories.filter((cat) => cat !== categoryId));
    } else {
      setOpenCategories([categoryId, ...openCategories]);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {(data?.rows || []).map((category) => {
        return (
          <div key={category.id}>
            <Styled.Row bold={!parentId}>
              {Array.from(Array(depth)).map(() => (
                <Styled.FirstCell />
              ))}
              <Styled.FirstCell>
                {category.hasChildren && (
                  <ExpandButton
                    isOpen={isCategoryOpen(category.id)}
                    onClick={() => toggleOpenCategory(category.id)}
                  />
                )}
              </Styled.FirstCell>
              <Styled.NameCell>
                <AddCategoryCell
                  id={category.id}
                  name={category.name}
                  status={category.status}
                  isActive={category.isActive}
                  onClick={() => onCreate(category)}
                />
              </Styled.NameCell>
              <Styled.Cell>
                {categoryStatusFormatter(category.status)}
              </Styled.Cell>
              <Styled.Cell>
                <ProtectedComponent
                  roles={[
                    UserRoles.AGENT,
                    UserRoles.BUSINESS_ADMIN,
                    UserRoles.ADMIN,
                  ]}
                  render={false}
                >
                  <ActiveSwitch
                    isActive={category.isActive}
                    categoryId={category.id}
                    parentId={parentId}
                    isInProgress={
                      category.status === CATEGORY_STATUSES.IN_PROGRESS
                    }
                    isParentActive={!parentData ? true : parentData?.isActive}
                    isSwitchingAvailable={
                      !isProceeding && !parentData
                        ? true
                        : parentData.isActive &&
                          parentData.status === CATEGORY_STATUSES.SUCCESS
                    }
                    onProceed={onProceed}
                    onProceedFinish={onProceedFinish}
                  />
                </ProtectedComponent>
              </Styled.Cell>
              <Styled.Cell>{category.noOfDocsInPath}</Styled.Cell>
              <Styled.Cell>
                <DocumentNavigator
                  customer={customer}
                  onNavigate={(documentType) => {
                    onNavigateToDocuments(category.id, documentType);
                  }}
                />
              </Styled.Cell>
            </Styled.Row>
            {isCategoryOpen(category.id) && (
              <CategoryList
                parentId={category.id}
                parent={category}
                depth={depth + 1}
                onCreate={onCreate}
                isProceeding={isBranchProceeding}
              />
            )}
          </div>
        );
      })}
    </>
  );
};
