import * as yup from 'yup';
import { useIntl } from 'react-intl';
import {
  VALIDATION_ERRORS,
  MAP_VALIDATION_USER_ERRORS,
} from '../../../../constraints';
import { Utils } from 'billon-ui';
import { validateEmptyPhoneNumber } from '../../../../helpers/validateEmptyPhoneNumber';
import phone from 'phone';

const { validators } = Utils;

export const useSchema = ({ isPhoneNumberRequired }) => {
  const { formatMessage } = useIntl();

  return yup.object({
    username: yup
      .string()
      .test(
        'whitespaces',
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_USERNAME,
          defaultMessage:
            MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_USERNAME,
        }),
        (value) => (value ? value?.trim()?.length > 0 : true),
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_USERNAME,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_USERNAME,
        }),
      ),
    firstName: yup
      .string()
      .test(
        'whitespaces',
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_FIRST_NAME,
          defaultMessage:
            MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_FIRST_NAME,
        }),
        (value) => (value ? value?.trim()?.length > 0 : true),
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
        }),
      ),
    lastName: yup
      .string()
      .test(
        'whitespaces',
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_LAST_NAME,
          defaultMessage:
            MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_LAST_NAME,
        }),
        (value) => (value ? value?.trim()?.length > 0 : true),
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
        }),
      ),
    phoneNumber: yup
      .string()
      .test(
        'emptyPhoneNumber',
        formatMessage({
          id: VALIDATION_ERRORS.PHONE_INVALID,
          defaultMessage: VALIDATION_ERRORS.PHONE_INVALID,
        }),
        (v) => {
          const isEmpty = validateEmptyPhoneNumber(v);
          if (isEmpty) {
            return true;
          } else {
            return phone(v)?.length === 2;
          }
        },
      )
      .when('isPhoneNumberRequired', {
        is: isPhoneNumberRequired,
        then: yup
          .string()
          .test(
            'whitespaces',
            formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_PHONE_NUMBER,
              defaultMessage:
                MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_PHONE_NUMBER,
            }),
            (value) => (value ? value?.trim()?.length > 0 : true),
          )
          .required(
            formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
            }),
          ),
      }),

    email: yup
      .string()
      .test(
        'whitespaces',
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_EMAIL,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.WHITESPACE_FORBIDDEN_EMAIL,
        }),
        (value) => (value ? value?.trim()?.length > 0 : true),
      )
      .test(
        'email',
        formatMessage({
          id: VALIDATION_ERRORS.EMAIL_INVALID,
          defaultMessage: VALIDATION_ERRORS.EMAIL_INVALID,
        }),
        (value) => {
          const trimmedValue = value?.trim();
          if (trimmedValue?.length === 0) {
            return true;
          }
          return !validators.emailValidator(trimmedValue);
        },
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_EMAIL,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_EMAIL,
        }),
      ),
  });
};
