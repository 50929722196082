import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import {
  RECIPIENT_UPLOAD_ERROR_VALUES,
  RECIPIENT_FILE_ERRORS_TYPE,
} from '../../../../../../../constraints';
import {
  RECIPIENT_ERROR_CODES,
  RECIPIENT_VALIDATION_PURPOSE,
} from '../../../../../../../constants';

// hooks
import { useConfigContext } from '../../../../../../Config/hooks/useConfig';

// helpers
import { getValidationErrorTypes } from '../helpers/getValidationErrorTypes';
import { geFileErrorTypes } from '../helpers/geFileErrorTypes';

// Components
import CustomToolTip from '../../../../../../../ui/CustomToolTip';
import ReasonsForFailuresColumn from './ReasonsForFailuresColumn';
import RowsToUpdateColumn from './RowsToUpdateColumn';
import * as Styled from '../styled';
import { Grid, Utils } from 'billon-ui';

const { Row, Col } = Grid;
const { Text, Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const FileErrorSummary = ({
  errorData = {},
  mode,
  validationPurpose = RECIPIENT_VALIDATION_PURPOSE.PUBLISH,
  handleResetMutation,
  handleIgnoreErrors,
  handleExit,
  cancelMessageId = 'Cancel',
}) => {
  const { isDiploma } = useConfigContext();

  const {
    errorInfo,
    statusCode,
    errors,
    validationErrors,
    nonUniqueErrors,
    success,
    total,
  } = errorData;

  const foundMissingDuplicated = errorData?.errors?.filter(({ type }) =>
    [
      RECIPIENT_FILE_ERRORS_TYPE.MISSING,
      RECIPIENT_FILE_ERRORS_TYPE.DUPLICATED,
    ].includes(type),
  );

  const recipientsLoadEMPTY = errorInfo && errorInfo['RECIPIENT'] === 'EMPTY';
  const isSuccessZero = success === 0;

  const fileErrorTypes = geFileErrorTypes(errors);
  const nonUniqueErrorTypes = getValidationErrorTypes(nonUniqueErrors);
  const validationErrorTypes = getValidationErrorTypes(validationErrors);

  const areReasonsForFailuresDisplayed =
    nonUniqueErrorTypes?.filter((e) =>
      [RECIPIENT_UPLOAD_ERROR_VALUES.NON_UNIQUE].includes(e),
    )?.length > 0 ||
    validationErrorTypes?.filter((e) =>
      [
        RECIPIENT_UPLOAD_ERROR_VALUES.EMPTY,
        RECIPIENT_UPLOAD_ERROR_VALUES.DIFFERENT,
        RECIPIENT_UPLOAD_ERROR_VALUES.INVALID,
        RECIPIENT_UPLOAD_ERROR_VALUES.WHITESPACE_FORBIDDEN,
      ].includes(e),
    )?.length > 0 ||
    fileErrorTypes?.filter((e) =>
      [
        RECIPIENT_UPLOAD_ERROR_VALUES.MISSING,
        RECIPIENT_UPLOAD_ERROR_VALUES.DUPLICATED,
      ].includes(e),
    )?.length > 0 ||
    statusCode === RECIPIENT_ERROR_CODES.INTERNAL_SERVER_ERROR;

  const areRowsToUpdateDisplayed = [
    ...nonUniqueErrorTypes,
    ...validationErrorTypes,
    ...fileErrorTypes,
  ].includes(RECIPIENT_UPLOAD_ERROR_VALUES.NEEDS_UPDATE);

  const isRecipientAmountExceeded =
    statusCode === RECIPIENT_ERROR_CODES.RECIPIENTS_AMOUNT_IN_FILE_EXCEEDED;

  return (
    <div>
      <Styled.FormHeader>
        <FormattedMessage id="Result" defaultMessage="Result" />
        {statusCode === RECIPIENT_ERROR_CODES.FILE_ERROR && (
          <>
            {': '}
            <Styled.SpanDanger>
              <FormattedMessage
                id="File uploading error"
                defaultMessage="File uploading error"
              />
            </Styled.SpanDanger>
          </>
        )}
        {isRecipientAmountExceeded && (
          <>
            {': '}
            <Styled.SpanDanger>
              <FormattedMessage
                id="Recipients amount in file exceeded"
                defaultMessage="Recipients amount in file exceeded"
              />
            </Styled.SpanDanger>
          </>
        )}
      </Styled.FormHeader>

      {statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR && (
        <Text>
          <FormattedMessage
            id="Correctly uploaded2"
            defaultMessage="Correctly uploaded2"
          />
          &nbsp;
          {recipientsLoadEMPTY
            ? 0
            : success &&
              total && (
                <>
                  {success} <FormattedMessage id="of" defaultMessage="of" />{' '}
                  {total}
                </>
              )}
          &nbsp;
          {isDiploma ? (
            <FormattedMessage id="students" defaultMessage="students" />
          ) : (
            <FormattedMessage id="recipients" defaultMessage="recipients" />
          )}
          .
        </Text>
      )}

      <Row>
        {areReasonsForFailuresDisplayed && (
          <ReasonsForFailuresColumn
            statusCode={statusCode}
            mode={mode}
            validationErrors={validationErrors}
            errors={errors}
            isNeighbourDisplayed={areRowsToUpdateDisplayed}
            nonUniqueErrors={nonUniqueErrors}
          />
        )}

        {areRowsToUpdateDisplayed && (
          <RowsToUpdateColumn
            statusCode={statusCode}
            mode={mode}
            validationErrors={validationErrors}
            isNeighbourDisplayed={areReasonsForFailuresDisplayed}
          />
        )}
      </Row>

      <Styled.CenterRow>
        {handleResetMutation && (
          <Col md={4} sm={4} xs={4}>
            <Button block type="button" outline onClick={handleResetMutation}>
              <FormattedMessage id="Fix errors" defaultMessage="Fix errors" />
            </Button>
          </Col>
        )}

        {handleIgnoreErrors &&
          !isSuccessZero &&
          !foundMissingDuplicated &&
          !isRecipientAmountExceeded && (
            <Col md={4} sm={4} xs={4}>
              <CustomToolTip
                id="IgnoreTooltip"
                trigger={
                  <Button block type="button" onClick={handleIgnoreErrors}>
                    {areReasonsForFailuresDisplayed ? (
                      <FormattedMessage
                        id="Ignore errors"
                        defaultMessage="Ignore errors"
                      />
                    ) : (
                      <FormattedMessage
                        id="Confirm2"
                        defaultMessage="Confirm2"
                      />
                    )}
                  </Button>
                }
                delay={false}
                placement="bottom-start"
              >
                {areReasonsForFailuresDisplayed ? (
                  validationPurpose === RECIPIENT_VALIDATION_PURPOSE.PUBLISH ? (
                    <FormattedMessage
                      id="Publish document without notifying incorrectly uploaded recipients"
                      defaultMessage="Publish document without notifying incorrectly uploaded recipients"
                    />
                  ) : (
                    <FormattedMessage
                      id="Recipients with incorrect data will be omitted from further steps in the process"
                      defaultMessage="Recipients with incorrect data will be omitted from further steps in the process"
                    />
                  )
                ) : isDiploma ? (
                  <FormattedMessage
                    id="Students data will be updated"
                    defaultMessage="Students data will be updated"
                  />
                ) : (
                  <FormattedMessage
                    id="Recipients data will be updated"
                    defaultMessage="Recipients data will be updated"
                  />
                )}
              </CustomToolTip>
            </Col>
          )}

        {handleExit &&
          (isSuccessZero ||
            foundMissingDuplicated ||
            recipientsLoadEMPTY ||
            isRecipientAmountExceeded) && (
            <Col md={4} sm={4} xs={4}>
              <Button block type="button" onClick={handleExit}>
                <FormattedMessage
                  id={cancelMessageId}
                  defaultMessage={cancelMessageId}
                />
              </Button>
            </Col>
          )}
      </Styled.CenterRow>
    </div>
  );
};

export default FileErrorSummary;
