import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { RECIPIENT_FILTER_FIELD_NAMES } from '../../constraints';

// hooks
import { useIdentityDocumentType } from '../../modules/Recipients/hooks/useIdentityDocumentType';

// Components
import * as Styled from '../SelectCategoryField/styled';
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const SelectIdentityDocumentTypeField = ({
  name = RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_TYPE,
  disabled,
  labelMessageId = 'Document type',
  isDefaultLabelEnabled = true,
}) => {
  const { identityDocumentTypeOptions } = useIdentityDocumentType();
  return (
    <>
      {isDefaultLabelEnabled && (
        <Text as="h6" fontSize="12px" fontWeight="500">
          <FormattedMessage
            id={labelMessageId}
            defaultMessage={labelMessageId}
          />
        </Text>
      )}
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          options={identityDocumentTypeOptions}
          disabled={disabled}
        />
      </Styled.FormGroup>
    </>
  );
};
