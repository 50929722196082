import { Grid, Utils } from 'billon-ui';
import styled from 'styled-components';

const { Col, Row } = Grid;
const { Text } = Utils;

export const FlexEndRow = styled(Row)`
  justify-content: flex-end;
`;

export const CopiedInfoColumn = styled(Col)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const ActionButtonColumn = styled(Col)`
  margin-bottom: 0.3rem;
`;

export const NoDataLabel = styled(Text)`
  pointer-events: none;
  opacity: 0.4;
`;
