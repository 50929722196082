import React, { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

// constants
import {
  NOTIFICATION_MODULE_MENU_LABEL,
  NOTIFICATION_TEMPLATE_FIELD_NAMES,
  NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS,
  NOTIFICATION_TEMPLATES,
  NOTIFICATION_PREVIEW_TYPES,
} from '../../../../../../../../constraints';

// hooks
import { useAddPrivateNotificationGroupContent } from '../../../../../../hooks/privateNotifications/useAddPrivateNotificationGroupContent';
import { usePatchPrivateSingleNotificationGroupContent } from '../../../../../../hooks/privateNotifications/usePatchPrivateSingleNotificationGroupContent';
import { useSchema } from './useSchema';
import { useTenantList } from '../../../../../../../Tenants/hooks/useTenantList';

// helpers
import { parseCreateContentData } from '../../../../../../helpers/parseCreateContentData';

// Components
import TemplatePreview from '../../../TemplatePreview';
import { HorizontalTenantsList } from '../../../GroupContent/components/HorizontalTenantsList';
import { FormFooter } from '../../../../../../../../components/FormFooter';
import { ControlledTextAreaField } from '../../../../../../../../components/ControlledTextAreaField';
import { ControlledCalendarField } from '../../../../../../../../components/ControlledCalendarField/ControlledCalendarField';
import FieldTitle from '../../../CreateTemplateForm/components/FieldTitle';
import { Form as FormModule, Grid, Utils } from 'billon-ui';

const { FormGroup } = FormModule;
const { Row, Col } = Grid;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const CreateContentForm = ({
  submitButtonText,
  handleSuccess,
  handleError,
  groupId,
  contentData,
  isUpdateMode = false,
}) => {
  const { formatMessage } = useIntl();
  const schema = useSchema();

  const { data: tenants, isLoading: areTenantsLoading } = useTenantList({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const firstTenantId = tenants?.length > 0 && tenants[0]?.id;

  const [activeTenantId, setActiveTenantId] = useState(firstTenantId);

  const handleActiveTenantIdChange = (id) => {
    setActiveTenantId(id);
  };

  const minDate = new Date(Date.now() + 1 * 60000).setSeconds(0, 0);
  const startDate = new Date(Date.now() + 10 * 60000).setSeconds(0, 0);

  const { emailTemplate, smsTemplate, id: contentId } = contentData || {};

  const formMethods = useForm({
    defaultValues: {
      [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE]:
        emailTemplate ?? '',
      [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_SMS_TEMPLATE]:
        smsTemplate ?? '',
      [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_VALID_SINCE]: startDate,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, resetField, watch } = formMethods;

  const emailValue = watch(
    NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE,
  );
  const smsValue = watch(
    NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_SMS_TEMPLATE,
  );

  const { mutate: createContent, isLoading: isCreating } =
    useAddPrivateNotificationGroupContent(groupId, {
      onSuccess: () => {
        handleSuccess();
      },
      onError: ({ response }) => {
        const { statusCode } = response?.data;
        handleError(statusCode);
      },
    });

  const { mutate: updateContent, isLoading: isUpdating } =
    usePatchPrivateSingleNotificationGroupContent(contentId, {
      onSuccess: (data) => {
        const { id } = data || {};
        handleSuccess(id);
      },
      onError: ({ response }) => {
        const { statusCode } = response?.data;
        handleError(statusCode);
      },
    });

  const handleFormSubmit = (values) => {
    if (!!isUpdateMode) {
      updateContent(parseCreateContentData(values));
    } else {
      createContent({
        data: parseCreateContentData(values),
      });
    }
  };

  const emailNumberOfRows = useMemo(() => {
    const length = emailValue?.length;
    if (length < 800) {
      return 12;
    }
    return Math.floor(length * (3 / 195));
  }, [emailValue]);

  if (areTenantsLoading) {
    return (
      <PageLoader
        message={
          <FormattedMessage
            id="Templates are loading..."
            defaultMessage="Templates are loading..."
          />
        }
      />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row
          xl={12}
          style={{ justifyContent: 'center' }}
          padding={'0 16px 0 16px'}
        >
          <Col xl={4}>
            <FormGroup>
              <FieldTitle as="h2" marginBottom="24px">
                <FormattedMessage
                  id={
                    NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_EMAIL_CONTENT_FIELD
                  }
                  defaultMessage={
                    NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_EMAIL_CONTENT_FIELD
                  }
                />
              </FieldTitle>
              <ControlledTextAreaField
                name={NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE}
                maxLength={null}
                placeholder={formatMessage({
                  id: NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS.CONTENT_EMAIL_TEMPLATE,
                  defaultMessage:
                    NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS.CONTENT_EMAIL_TEMPLATE,
                })}
                rows={emailNumberOfRows}
              />
            </FormGroup>
          </Col>
          <Col xl={8}>
            {tenants?.length > 0 && (
              <Row>
                <HorizontalTenantsList
                  small
                  tenants={tenants}
                  activeTenantId={activeTenantId}
                  handleActiveTenantIdChange={handleActiveTenantIdChange}
                />
              </Row>
            )}
            {tenants?.length > 0 &&
              tenants.map(({ id }) => (
                <Row md={12}>
                  <TemplatePreview
                    tenantId={id}
                    templateType={NOTIFICATION_TEMPLATES.PRIVATE_PUBLISHED}
                    previewType={NOTIFICATION_PREVIEW_TYPES.EMAIL}
                    template={emailValue}
                    activeTenantId={activeTenantId}
                  />
                </Row>
              ))}
          </Col>
        </Row>
        <br />
        <Row
          xl={12}
          style={{ justifyContent: 'center' }}
          padding={'0 16px 0 16px'}
        >
          <Col xl={4}>
            <FormGroup>
              <FieldTitle as="h2" marginBottom="24px">
                <FormattedMessage
                  id={
                    NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_SMS_CONTENT_FIELD
                  }
                  defaultMessage={
                    NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_SMS_CONTENT_FIELD
                  }
                />
              </FieldTitle>
              <ControlledTextAreaField
                name={NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_SMS_TEMPLATE}
                maxLength={null}
                placeholder={formatMessage({
                  id: NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS.CONTENT_SMS_TEMPLATE,
                  defaultMessage:
                    NOTIFICATION_TEMPLATE_FIELD_PLACEHOLDERS.CONTENT_SMS_TEMPLATE,
                })}
                rows={5}
              />
            </FormGroup>
          </Col>
          <Col xl={8}>
            {tenants?.length > 0 && (
              <Row>
                <HorizontalTenantsList
                  small
                  tenants={tenants}
                  activeTenantId={activeTenantId}
                  handleActiveTenantIdChange={handleActiveTenantIdChange}
                />
              </Row>
            )}
            {tenants?.length > 0 &&
              tenants.map(({ id }) => (
                <Row md={12}>
                  <TemplatePreview
                    tenantId={id}
                    templateType={NOTIFICATION_TEMPLATES.PRIVATE_PUBLISHED}
                    previewType={NOTIFICATION_PREVIEW_TYPES.SMS}
                    template={smsValue}
                    activeTenantId={activeTenantId}
                  />
                </Row>
              ))}
          </Col>
        </Row>
        <br />
        <Row
          xl={12}
          style={{ justifyContent: 'center' }}
          padding={'0 16px 0 16px'}
        >
          <Col xl={6}>
            <FormGroup>
              <FieldTitle as="h2">
                <FormattedMessage
                  id={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_VALID_SINCE}
                  defaultMessage={
                    NOTIFICATION_MODULE_MENU_LABEL.CONTENT_VALID_SINCE
                  }
                />
              </FieldTitle>
              <ControlledCalendarField
                name={NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_VALID_SINCE}
                enableTime={true}
                minDate={minDate}
                displayTime={true}
                showMonths={2}
                onClear={(name) => resetField(name)}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormFooter
          backBtnDisabled
          isLoading={isCreating || isUpdating}
          submitButtonText={submitButtonText}
        />
      </form>
    </FormProvider>
  );
};

export default CreateContentForm;
