import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Utils } from 'billon-ui';

const { PageLoader } = Utils.Loader;

const ProtectedApplication = ({
  isAuthenticated,
  children,
  isVerifying,
  unauthorizedRoutes,
}) => {
  if (isAuthenticated) {
    if (isVerifying) {
      return (
        <PageLoader
          message={
            <FormattedMessage
              id="Token verification. Please wait..."
              defaultMessage="Token verification. Please wait..."
            />
          }
        />
      );
    }

    return children;
  }

  return unauthorizedRoutes;
};

ProtectedApplication.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  isVerifying: PropTypes.bool,
  unauthorizedRoutes: PropTypes.node.isRequired,
};

export default ProtectedApplication;
