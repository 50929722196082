import styled from 'styled-components';

import { ReactComponent as Save } from '../../../../../../../../ui/Icons/save.svg';
import { ReactComponent as Close } from '../../../../../../../../ui/Icons/close.svg';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule, Button: ButtonModule } = Utils;
const { Loader } = LoaderModule;
const { Button } = ButtonModule;

export const LoadingIcon = styled(Loader)`
  align-self: center;
`;

export const ActionButton = styled(Button)`
  svg path {
    fill: ${(props) => props.theme.bodyColor} !important;
  }
  svg:hover path {
    fill: ${(props) => props.theme.palette.primary} !important;
  }
  cursor: pointer;
  margin-left: 1rem;
`;

export const ActiveActionButton = styled(ActionButton)`
  svg path {
    fill: ${(props) => props.theme.palette.primary} !important;
  }
  svg:hover path {
    fill: ${(props) => props.theme.palette.primaryLightest} !important;
  }
  padding: 0;
  margin-top: 12px;
  background-color: transparent !important;
  fill: none;
  color: red;
`;

export const SaveIcon = styled(Save)`
  path {
    fill: ${(props) => props.theme.palette.primary} !important;
  }
  width: 16px;
  height: auto;
  margin-top: 24px;
`;

export const CloseIcon = styled(Close)`
  path {
    fill: ${(props) => props.theme.bodyColor} !important;
  }
  :hover path {
    fill: ${(props) => props.theme.palette.primaryLightest} !important;
  }
  padding-top: 5px;
  width: 32px;
  height: auto;
  cursor: pointer;
  margin-top: 24px;
`;
