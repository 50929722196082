import { useIntl } from 'react-intl';
import {
  RECIPIENT_STATUSES,
  MAP_RECIPIENT_STATUSES,
} from '../../../../../constraints';

export const useRecipientStatusOptions = () => {
  const { formatMessage } = useIntl();

  const statusKeys = [
    RECIPIENT_STATUSES.SHELL,
    RECIPIENT_STATUSES.PUBLISHING,
    RECIPIENT_STATUSES.PUBLISHED,
    RECIPIENT_STATUSES.SUSPENDED,
    RECIPIENT_STATUSES.ERROR,
    RECIPIENT_STATUSES.UPDATING,
    RECIPIENT_STATUSES.FORGETTING,
  ];

  const statusOptions = statusKeys.map((key) => {
    const msgId = MAP_RECIPIENT_STATUSES[key];
    return {
      label: formatMessage({
        id: msgId,
        defaultMessage: msgId,
      }),
      value: key,
    };
  });

  return { statusOptions };
};
