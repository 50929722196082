import React, { createContext, useContext } from 'react';

import config from '../../../config';
import { CUSTOMER } from '../../../constraints';
import { notificationTheme } from '../constants/notificationTheme';

const ConfigContext = createContext(false);

export const useConfigContext = () => useContext(ConfigContext);

export const ConfigContextProvider = ({ children }) => {
  const {
    customer,
    enablePublic,
    enablePrivate,
    enableSigning,
    enableActiveDelivery,
    enablePublicationDelay,
    enableReports,
    enableSharedSecret,
    enableNotificationManager,
    isSharedSecretOptional,
  } = config;

  const isDiploma = customer === CUSTOMER.DIPLOMA;
  const isTauron = customer === CUSTOMER.TAURON;
  const isDefault = customer === CUSTOMER.DEFAULT;

  const publicationFormConfig = {
    enableRetention: !isTauron && !isDiploma,
    isValidUntilRequired: isDefault,
    displayTime: !isTauron && !isDiploma,
    enableTenants: isTauron,
  };

  const usersConfig = {
    enableAdministrativePasswordChange: isTauron,
    enableTwoStepPassword: !isTauron,
    isPasswordInfoNested: !isDiploma,
  };

  const recipientsConfig = {
    enableIdentityDocumentTypeSelection: !isDiploma,
    enableSharedSecret: enableSharedSecret,
    isSharedSecretOptional: enableSharedSecret && isSharedSecretOptional,
    isSharedSecretRequired: enableSharedSecret && !isSharedSecretOptional,
    isFullName: isTauron,
    enableDocumentNumberBlur: isDefault,
    enablePpeNumbers: isTauron,
    isPpeNumberOptional: isTauron,
    enablePayerNumbers: isTauron,
    isPayerNumberOptional: isTauron,
  };

  const notificationsConfig = {
    enablePrivateTemplateDefault: isTauron || isDiploma,
    enablePublicDocumentType: isTauron,
  };

  return (
    <ConfigContext.Provider
      value={{
        customer,
        enablePublic,
        enablePrivate,
        enableSigning,
        enableActiveDelivery,
        enablePublicationDelay,
        enableReports,
        enableNotificationManager,
        config,
        isDiploma,
        isTauron,
        isDefault,
        publicationFormConfig,
        usersConfig,
        notificationTheme,
        recipientsConfig,
        notificationsConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
