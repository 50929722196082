import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { USER_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useIsActiveOptions } from '../../hooks/useIsActiveOptions';

// Components
import { ControlledSelectField } from '../../../../../../components/ControlledSelectField/ControlledSelectField';
import { FieldLabel } from '../../../../../../components/Form';

const SelectIsActiveField = ({
  onClear,
  isClearable,
  name = USER_FILTER_FIELD_NAMES.IS_ACTIVE,
}) => {
  const { isActiveOptions } = useIsActiveOptions();
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Is active" defaultMessage="Is active" />
      </FieldLabel>
      <ControlledSelectField
        name={name}
        options={isActiveOptions}
        onClear={onClear}
        isClearable={isClearable}
      />
    </>
  );
};

export default SelectIsActiveField;
