import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_TYPE } from '../../../../../../../../constraints';

// hooks
import useDocumentType from '../../../../../../hooks/useDocumentType';

// Components
import NotificationManagerButton from '../../../NotificationManager/components/NotificationManagerButton';
import * as Styled from './styled';
import { Grid } from 'billon-ui';

const { Col } = Grid;

const GroupHeaderManageButton = ({ titleMessageId }) => {
  const { documentType } = useDocumentType();
  return (
    <Col md={12}>
      <Styled.CenteredVericallyRow md={5}>
        <Col md={3} style={{ minWidth: '210px' }}>
          <Styled.SingleTemplateHeader>
            <FormattedMessage
              id={titleMessageId}
              defaultMessage={titleMessageId}
            />
          </Styled.SingleTemplateHeader>
        </Col>
        {documentType === DOCUMENT_TYPE.PRIVATE && (
          <NotificationManagerButton />
        )}
      </Styled.CenteredVericallyRow>
    </Col>
  );
};

export default GroupHeaderManageButton;
