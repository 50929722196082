import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { requestLogout } from '../ui/Authentication/actions';

export const useMutationWithLogout = (fn, options) => {
  const dispatch = useDispatch();

  return useMutation(fn,
    {
      ...options,
      onError: (error) => {
        if(error?.response?.status === 401 || error?.response?.status === 403) {
          dispatch(requestLogout());
        }

        options && options.onError && options.onError(error);
      }
    }
  );
}
