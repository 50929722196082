import React from 'react';
import PropTypes from 'prop-types';

const HelpInfo = ({ helpInfo }) => (
  <label htmlFor={helpInfo} className="help-info">
    {helpInfo}
  </label>
);

HelpInfo.propTypes = {
  helpInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default HelpInfo;
