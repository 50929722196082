import * as yup from 'yup';
import { useIntl } from 'react-intl';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useSharedSecretValueSchema } from './useSharedSecretValueSchema';

// constants
import {
  VALIDATION_ERRORS,
  MAP_VALIDATION_USER_ERRORS,
  IDENTITY_DOCUMENT_TYPE,
  RECIPIENT_FILTER_FIELD_NAMES,
  SHARED_SECRET_FIELD_NAMES,
} from '../../../../../../constraints';

// utils
import { validatePESEL, validateNIP } from '../../validators';
import { Utils } from 'billon-ui';
const { validators } = Utils;

export const useSchema = () => {
  const { isDiploma, recipientsConfig } = useConfigContext();
  const { isFullName } = recipientsConfig || {};
  const sharedSecretValueSchema = useSharedSecretValueSchema();
  const { formatMessage } = useIntl();
  const genericYupObject = {
    [RECIPIENT_FILTER_FIELD_NAMES.PAYER_NUMBER]: yup.string().max(
      255,
      formatMessage(
        {
          id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
          defaultMessage: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
        },
        {
          length: 50,
        },
      ),
    ),
    [RECIPIENT_FILTER_FIELD_NAMES.PPE_NUMBER]: yup.string().max(
      255,
      formatMessage(
        {
          id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
          defaultMessage: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
        },
        {
          length: 50,
        },
      ),
    ),
    [RECIPIENT_FILTER_FIELD_NAMES.PHONE_NUMBER]: yup
      .string()
      .test(
        RECIPIENT_FILTER_FIELD_NAMES.PHONE_NUMBER,
        formatMessage({
          id: VALIDATION_ERRORS.PHONE_INVALID,
          defaultMessage: VALIDATION_ERRORS.PHONE_INVALID,
        }),
        (v) => !validators.phoneVerification(v),
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
        }),
      ),
    [RECIPIENT_FILTER_FIELD_NAMES.EMAIL]: yup
      .string()
      .test(
        RECIPIENT_FILTER_FIELD_NAMES.EMAIL,
        formatMessage({
          id: VALIDATION_ERRORS.EMAIL_INVALID,
          defaultMessage: VALIDATION_ERRORS.EMAIL_INVALID,
        }),
        (v) => !validators.emailValidator(v),
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_EMAIL,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_EMAIL,
        }),
      ),
    [RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_NUMBER]: yup
      .string()
      .test(
        RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_NUMBER,
        function () {
          if (isDiploma) {
            return formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.INVALID_INDEX,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.INVALID_INDEX,
            });
          }
          const documentType = this?.parent?.documentType;
          if (documentType?.value === IDENTITY_DOCUMENT_TYPE.OTHER) {
            return formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.INVALID_NATIONAL_ID,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.INVALID_NATIONAL_ID,
            });
          }
          return formatMessage({
            id: MAP_VALIDATION_USER_ERRORS.INVALID_DOCUMENT_NUMBER,
            defaultMessage: MAP_VALIDATION_USER_ERRORS.INVALID_DOCUMENT_NUMBER,
          });
        },
        function (documentNumber) {
          const documentType = this?.parent?.documentType;
          const documentTypeValue = documentType?.value;
          if (
            isDiploma ||
            !documentType ||
            documentTypeValue === IDENTITY_DOCUMENT_TYPE.OTHER
          ) {
            return true;
          }
          if (documentTypeValue === IDENTITY_DOCUMENT_TYPE.NIP) {
            return validateNIP(documentNumber);
          }
          if (documentTypeValue === IDENTITY_DOCUMENT_TYPE.PESEL) {
            return validatePESEL(documentNumber);
          }
          return true;
        },
      )
      .required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_DOCUMENT_NUMBER,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_DOCUMENT_NUMBER,
        }),
      ),
    [SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_VALUE]: sharedSecretValueSchema,
  };
  return yup.object(
    isFullName
      ? {
          [RECIPIENT_FILTER_FIELD_NAMES.NAME]: yup.string().required(
            formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.EMPTY_NAME,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_NAME,
            }),
          ),
          ...genericYupObject,
        }
      : {
          [RECIPIENT_FILTER_FIELD_NAMES.FIRST_NAME]: yup.string().required(
            formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
            }),
          ),
          [RECIPIENT_FILTER_FIELD_NAMES.LAST_NAME]: yup.string().required(
            formatMessage({
              id: MAP_VALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
              defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
            }),
          ),
          ...genericYupObject,
        },
  );
};
