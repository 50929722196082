export const parseCreateTemplateData = ({
  name,
  emailTemplate,
  smsTemplate,
}) => ({
  name,
  content: {
    emailTemplate,
    smsTemplate,
  },
});
