import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Layout, Utils } from 'billon-ui';
import { DocumentErrorExporter } from '../DocumentErrorExporter';
import { mapERRORS, UNKNOWN_ERRORS } from '../../constraints';
import * as Styled from './styled';
import PropTypes from 'prop-types';

const { Content: ContentModule } = Layout;
const { ErrorContent } = ContentModule;

const { Text } = Utils;

const ExportableErrorContent = ({
  headerTitleId,
  statusCode,
  jobId,
  title,
  deliveredLabel,
  exportDisabled,
}) => {
  const intl = useIntl();

  const chosenStatusCOde = statusCode
    ? statusCode
    : UNKNOWN_ERRORS.UNKNOWN_ERROR;

  const label = deliveredLabel ? deliveredLabel : mapERRORS[chosenStatusCOde];
  const message = label !== '' && intl.formatMessage({ id: label });

  return (
    <ErrorContent>
      <FormattedMessage id={headerTitleId} defaultMessage={headerTitleId} />
      <Text textAlign={'center'} margin={'15px 0 15px 0'}>
        {message}
        {'. '}
        {!exportDisabled && (
          <DocumentErrorExporter label={label} jobId={jobId} title={title}>
            <Styled.UnderlinedSpan>
              <FormattedMessage
                id="Export the error message"
                defaultMessage="Export the error message"
              />
            </Styled.UnderlinedSpan>
          </DocumentErrorExporter>
        )}
      </Text>
    </ErrorContent>
  );
};

ExportableErrorContent.propTypes = {
  deliveredLabel: PropTypes.string,
  exportDisabled: PropTypes.bool,
};

export default ExportableErrorContent;
