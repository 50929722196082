/* global sessionStorage */
import { Utils } from 'billon-ui';
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import {
  requestSettings,
  receiveSettingsSuccess,
  receiveSettingsFailure,
  receiveFailed,
  requestSmsCode,
  receiveSmsCodeSuccess,
  receiveSmsCodeFailure,
  requestUnauthorizedSmsCodeByToken,
  receiveUnauthorizedSmsCodeSuccess,
  receiveUnauthorizedSmsCodeFailure,
  requestMe,
  receiveMeSuccess,
  receiveMeFailure,
  requestValidatePassword,
  receiveValidatePasswordSuccess,
  receiveValidatePasswordFailure,
  requestModifyPasswordByToken,
  receiveModifyPasswordSuccess,
  receiveModifyPasswordFailure,
} from './actions';
import { receiveForbidden } from 'js/ui/Authentication/actions';
import routes from '../../api-routes';

const { helpers } = Utils;
const { request } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  METHOD_POST,
  METHOD_PATCH,
} = request;

const settingsEpic = (actions$) =>
  actions$.ofType(requestSettings.getType()).mergeMap((action) =>
    ajax({
      url: routes.SETTINGS,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: action.payload.token
          ? action.payload.token
          : sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveSettingsSuccess(response))
      .catch((err) =>
        action.payload.token
          ? Observable.of(receiveFailed(err))
          : Observable.of(receiveSettingsFailure(err)),
      ),
  );

const receiveSettingsFailureEpic = (actions$) =>
  actions$.ofType(receiveSettingsFailure.getType()).map(receiveForbidden);

const smsCodeEpic = (actions$) =>
  actions$.ofType(requestSmsCode.getType()).mergeMap(() =>
    ajax({
      url: routes.SMS_CODE,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveSmsCodeSuccess(response))
      .takeUntil(actions$.ofType(requestSmsCode.getType()))
      .catch((err) => Observable.of(receiveSmsCodeFailure(err))),
  );

const receiveSmsCodeSuccessEpic = (actions$) =>
  actions$.ofType(receiveSmsCodeSuccess.getType()).map(requestSettings);

const receiveSmsCodeFailureEpic = (actions$) =>
  actions$.ofType(receiveSmsCodeFailure.getType()).map(receiveForbidden);

const meEpic = (actions$) =>
  actions$.ofType(requestMe.getType()).mergeMap(() =>
    ajax({
      url: routes.ME,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveMeSuccess(response))
      .catch((err) => Observable.of(receiveMeFailure(err))),
  );

const validatePasswordEpic = (actions$) =>
  actions$.ofType(requestValidatePassword.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const token = action.payload.token;
    return ajax({
      url: routes.VALIDATE_PASSWORD,
      body: JSON.stringify(action.payload.values),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: token,
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) resolve(response);
        return receiveValidatePasswordSuccess(response);
      })
      .catch(({ response }) => {
        if (reject) {
          reject(response);
        }
        return Observable.of(receiveValidatePasswordFailure(response));
      });
  });

const unauthorizedSmsCodeByTokenEpic = (actions$) =>
  actions$
    .ofType(requestUnauthorizedSmsCodeByToken.getType())
    .mergeMap((action) => {
      const { resolve, reject } = action.payload.meta;
      const { password } = action.payload.values;
      const token = action.payload.token;

      return ajax({
        url: routes.SMS_CODE,
        headers: {
          [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
          Authorization: token,
        },
        method: METHOD_GET,
      })
        .map(({ response }) => {
          if (resolve) resolve(response);
          return receiveUnauthorizedSmsCodeSuccess(password);
        })
        .catch(({ response }) => {
          if (reject) {
            reject(response);
          }
          return Observable.of(receiveUnauthorizedSmsCodeFailure(response));
        });
    });

const modifyPasswordByTokenEpic = (actions$) =>
  actions$.ofType(requestModifyPasswordByToken.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const token = action.payload.token;

    return ajax({
      url: routes.MODIFY_PASSWORD,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: token,
      },
      body: JSON.stringify(action.payload.values),
      method: METHOD_PATCH,
    })
      .map(({ response }) => {
        if (resolve) resolve(response);
        return receiveModifyPasswordSuccess(response);
      })
      .catch(({ response }) => {
        if (reject) {
          reject(response);
        }
        return Observable.of(receiveModifyPasswordFailure(response));
      });
  });

export default [
  settingsEpic,
  receiveSettingsFailureEpic,
  smsCodeEpic,
  receiveSmsCodeSuccessEpic,
  receiveSmsCodeFailureEpic,
  meEpic,
  validatePasswordEpic,
  unauthorizedSmsCodeByTokenEpic,
  modifyPasswordByTokenEpic,
];
