import { useIntl } from 'react-intl';
import {
  DOCUMENTS_OPTIONS,
  MAP_DOCUMENTS_OPTIONS,
} from '../../../../../../../constraints';

export const useDocumentTypeOptions = () => {
  const { formatMessage } = useIntl();

  const documentTypeOptions = [
    DOCUMENTS_OPTIONS.ALL,
    DOCUMENTS_OPTIONS.PRIVATE,
    DOCUMENTS_OPTIONS.PUBLIC,
    DOCUMENTS_OPTIONS.REPORT,
  ].map((status) => ({
    label: formatMessage({
      id: MAP_DOCUMENTS_OPTIONS[status],
    }),
    value: status,
  }));

  return { documentTypeOptions };
};
