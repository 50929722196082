import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import classNames from 'classnames';
import * as Styled from './styled';
import { useSettingsContext } from '../../modules/Settings/hooks/useSettings';
import { SETTINGS_CONSTANTS } from '../../constraints';

const PoweredBy = ({ fixed, className, theme }) => {
  const { getSettingValueByKey } = useSettingsContext();
  const publisherName = getSettingValueByKey(SETTINGS_CONSTANTS.PUBLISHER_NAME);

  return (
    <Styled.PB
      className={classNames({
        fixed,
        [className]: !!className,
      })}
    >
      Powered by {publisherName}
      <Styled.VersionSpan>
        v. {process.env.REACT_APP_VERSION}
      </Styled.VersionSpan>
    </Styled.PB>
  );
};

PoweredBy.propTypes = {
  fixed: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.object,
};

PoweredBy.defaultProps = {
  fixed: false,
};

export default withTheme(PoweredBy);
