import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { Utils, Grid } from 'billon-ui';
const { Button: ButtonModule } = Utils;
const { Row } = Grid;

export const CenterRow = styled(Row)`
  margin: 0;
  align-items: center;
  padding: 2rem 0 1rem;
  flex-direction: row;
  justify-content: center;
`;

export const BackButton = styled(ButtonModule.BackButton)`
  ${down('lg')} {
    width: 100%;
    margin: 0.5rem 0;
  }
  text-align: ${({ nextBtnDisabled }) => (nextBtnDisabled ? 'center' : 'left')};
  width: ${({ nextBtnDisabled }) => (nextBtnDisabled ? '100%' : 'auto')};
`;
