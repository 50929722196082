import styled from 'styled-components';
import { Utils } from 'billon-ui';
import { ReactComponent as Lock } from '../../../../../../ui/Icons/lock.svg';
import { ReactComponent as Error } from '../../../../../../ui/Icons/exclamation.svg';

const { Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

export const LoadingIcon = styled(Loader)`
  align-self: center;
`;

export const ActionButton = styled.span`
  svg path {
    fill: ${(props) => props.theme.palette.grayLighter} !important;
  }

  svg:hover path {
    fill: black !important;
  }
`;

export const LockIcon = styled(Lock)`
  path {
    fill: ${(props) => props.theme.palette.grayLighter} !important;
  }
  width: 16px;
  height: auto;
  align-self: center;
`;

export const ErrorIcon = styled(Error)`
  path {
    fill: ${(props) => props.theme.palette.error} !important;
  }
  width: 16px;
  height: auto;
  align-self: center;
`;
