import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Utils, Grid } from 'billon-ui';

import * as Styled from './styled';

const { Icon } = Utils;
const { Button: ButtonModule } = Utils;
const { Col } = Grid;
const { ButtonLoader } = ButtonModule;

const StatusInfoModal = ({
  title,
  children,
  isOpen,
  toggle,
  isSubmitting,
  onSubmit,
  onOpened,
  onClosed,
  className,
  backdrop,
  titleUnderline,
}) => (
  <Styled.Modal
    isOpen={isOpen}
    toggle={toggle}
    onOpened={onOpened}
    onClosed={onClosed}
    className={classNames('modal-lg', className)}
    centered
    fade={false}
    backdrop={backdrop}
  >
    <Styled.CloseIconButton onClick={toggle}>
      <Icon name="times" />
    </Styled.CloseIconButton>
    <Styled.ModalBody>
      <Styled.ModalHeader titleUnderline={titleUnderline}>
        {children}
      </Styled.ModalHeader>
    </Styled.ModalBody>
    <Styled.ModalFooter>
      <Col>
        <ButtonLoader size="lg" block />
      </Col>
    </Styled.ModalFooter>
  </Styled.Modal>
);

StatusInfoModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  titleUnderline: PropTypes.bool,
};

StatusInfoModal.defaultProps = {
  isOpen: false,
  backdrop: 'static',
  titleUnderline: false,
  isSubmitting: false,
};

export default StatusInfoModal;
