import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SearchBarField from '../../../../../../ui/SearchBarField';
import * as Styled from './styled';

const SearchCategoryName = () => {
  const { formatMessage } = useIntl();

  return (
    <Styled.Wrapper>
      <Styled.Col>
        <Styled.Text>
          <FormattedMessage
            id="Search for Category"
            defaultMessage="Search for Category"
          />
        </Styled.Text>
        <SearchBarField
          name="name"
          className="search-bar"
          classic
          placeholder={formatMessage({
            id: 'Category name',
            defaultMessage: 'Category name',
          })}
        />
      </Styled.Col>
    </Styled.Wrapper>
  );
};

export default SearchCategoryName;
