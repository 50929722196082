import { Grid } from 'billon-ui';
import styled from 'styled-components';

const { Col, Row } = Grid;

export const FlexEndRow = styled(Row)`
  justify-content: flex-end;
`;

export const CopiedInfoColumn = styled(Col)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const ActionButtonColumn = styled(Col)`
  margin-bottom: 0.3rem;
`;
