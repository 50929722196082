import React from 'react';
import { FormattedMessage } from 'react-intl';

// hooks
import { usePrivateNotificationGroupContents } from '../../../../hooks/privateNotifications/usePrivateNotificationGroupContents';

// constants
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../../../constraints';

// components
import {
  ContentHistoryHeader,
  ContentHistoryRow,
  SetNewContentButton,
} from './components';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const NotificationManager = ({ notificationType, groupId }) => {
  const { data: groupContents, isFetching: areGroupContentsLoading } =
    usePrivateNotificationGroupContents(null, groupId, {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  if (areGroupContentsLoading) {
    return (
      <PageLoader
        message={
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CONTENTS_LOADING}
            defaultMessage={
              NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CONTENTS_LOADING
            }
          />
        }
      />
    );
  }

  return (
    <>
      <SetNewContentButton />
      <ContentHistoryHeader />
      {groupContents?.count > 0 &&
        groupContents?.rows.map(({ id }) => (
          <ContentHistoryRow
            notificationType={notificationType}
            contentId={id}
          />
        ))}
    </>
  );
};

export default NotificationManager;
