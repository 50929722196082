import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

// hooks
import { useSchema } from './hooks/useSchema/useSchema';
import { useRecipientCreateFormDefaultValues } from './hooks/useRecipientFormDefaultValues';

// utils
import {
  MAP_VALIDATION_USER_ERRORS_NAMES,
  MAP_VALIDATION_USER_ERRORS,
} from '../../../../constraints';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';
import { parseRecipientData } from '../../helpers/formFieldParsers/parseRecipientData';

// components
import { DataTab, SubmitButton } from './components';
import * as Styled from './styled';
import { Grid } from 'billon-ui';

const { Col } = Grid;

const CreateForm = ({
  onSubmit,
  isSaving,
  initialValues,
  errors: backendErrors,
  submitMessage,
}) => {
  const { computedDefaultValues } =
    useRecipientCreateFormDefaultValues(initialValues);
  const schema = useSchema();
  const formMethods = useForm({
    defaultValues: computedDefaultValues,
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = formMethods;

  const handleFormSubmit = (data) => {
    onSubmit(removeUndefinedValues(parseRecipientData(data)));
  };

  useEffect(() => {
    if (!!backendErrors) {
      Object.entries(backendErrors).forEach(([key, value]) => {
        setError(MAP_VALIDATION_USER_ERRORS_NAMES[key], {
          type: 'response',
          message: MAP_VALIDATION_USER_ERRORS[`${value}_${key}`],
        });
      });
    }
  }, [backendErrors]);

  return (
    <FormProvider {...formMethods}>
      <Col md={{ size: 8, offset: 2 }}>
        <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
          <DataTab />
          <SubmitButton
            isLoading={isSaving || isSubmitting}
            submitMessage={submitMessage}
          />
        </Styled.Form>
      </Col>
    </FormProvider>
  );
};

CreateForm.propTypes = {
  onSubmit: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  errors: PropTypes.object,
  submitMessage: PropTypes.object,
};

export default CreateForm;
