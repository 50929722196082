import { useMemo } from 'react';

import { useConfigContext } from '../../Config/hooks/useConfig';
import useFilters from '../../../hooks/useFilters';

import { DOCUMENT_TYPE } from '../../../constraints';

const useDocumentType = () => {
  const {
    isDiploma,
    notificationsConfig: { enablePrivateTemplateDefault },
  } = useConfigContext();
  const { filters } = useFilters();

  const defaultDocumentType = useMemo(() => {
    if (isDiploma || enablePrivateTemplateDefault) {
      return DOCUMENT_TYPE.PRIVATE;
    } else {
      return DOCUMENT_TYPE.PUBLIC;
    }
  }, [filters]);

  const documentType = useMemo(() => {
    const predefinedDocumentTypeList = filters?.filters?.documentTypeList;
    if (predefinedDocumentTypeList) {
      return predefinedDocumentTypeList;
    } else {
      return defaultDocumentType;
    }
  }, [filters, defaultDocumentType]);

  return { isDiploma, documentType };
};

export default useDocumentType;
