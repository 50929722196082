import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

// constants
import appRoutes from '../../../../app-routes';
import { QueryKeys } from '../../../../queryKeys';
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../constraints';

// hooks
import useFilters from '../../../../hooks/useFilters';

// Components
import Modal from '../../components/Modal';
import { ErrorModal } from '../../../../components/ErrorModal';
import { SuccessModal } from '../../../../components/SuccessModal';
import { CreateTemplateForm } from '../../components/Templates/components';

import { Layout, Utils } from 'billon-ui';
const { Content: ContentModule } = Layout;
const { ErrorContent } = ContentModule;
const { Text } = Utils;

const CreateTemplatePage = () => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const { filters, navigateWithSearchQuery } = useFilters();

  const [createModalOpened, setCreateModalOpened] = useState(true);
  const [errorModalOpened, setErrorModalOpened] = useState(false);
  const [successModalOpened, setSuccessModalOpened] = useState(false);

  const [errorContent, setErrorContent] = useState();

  const handleCreateModalClose = () => {
    setCreateModalOpened(false);
    navigateWithSearchQuery(null, appRoutes.NOTIFICATION_TEMPLATES);
  };
  const handleSuccess = (id) => {
    setSuccessModalOpened(true);
    queryClient.invalidateQueries([
      QueryKeys.PRIVATE_NOTIFICATION_TEMPLATE_GROUPS,
    ]);
    if (!!id) {
      navigateWithSearchQuery({ filters: { groupId: id } });
    }
  };
  const handleError = (errorContent) => {
    setErrorContent(errorContent);
    setErrorModalOpened(true);
  };
  const handleErrorModalClose = () => {
    setErrorContent();
    setErrorModalOpened(false);
    queryClient.invalidateQueries([
      QueryKeys.PRIVATE_NOTIFICATION_TEMPLATE_GROUPS,
    ]);
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpened(false);
    handleCreateModalClose();
  };

  return (
    <>
      <Modal
        isOpen={createModalOpened}
        toggle={handleCreateModalClose}
        onClose={handleCreateModalClose}
        title={formatMessage({
          id: NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_MODAL,
          defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_MODAL,
        })}
      >
        <CreateTemplateForm
          handleSuccess={handleSuccess}
          handleError={handleError}
        />
      </Modal>
      <ErrorModal
        isOpen={errorModalOpened}
        toggle={handleErrorModalClose}
        onClose={handleErrorModalClose}
        closeIconEnabled={false}
      >
        <ErrorContent>
          <Text as="h3">
            <FormattedMessage
              id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_ERROR}
              defaultMessage={
                NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_ERROR
              }
            />
          </Text>
          <Text as="h4">{errorContent}</Text>
        </ErrorContent>
      </ErrorModal>
      <SuccessModal
        isOpen={successModalOpened}
        toggle={handleSuccessModalClose}
        onClose={handleSuccessModalClose}
        closeIconEnabled={false}
      >
        <FormattedMessage
          id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_SUCCESS}
          defaultMessage={
            NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CREATE_SUCCESS
          }
        />
      </SuccessModal>
    </>
  );
};

export default CreateTemplatePage;
