import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const ExecuteButton = ({
  handleTrigger,
  disabled,
  outline,
  messageId,
  children,
}) => {
  return (
    <Styled.Button
      onClick={handleTrigger}
      disabled={disabled}
      outline={outline}
    >
      <FormattedMessage id={messageId} defaultMessage={messageId} />
      {children}
    </Styled.Button>
  );
};

export default ExecuteButton;
