import styled from 'styled-components';
import { Modal as ModalModule } from 'billon-ui';
import { Grid, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { BackButton: BillonBackButton } = ButtonModule;
const { ModalFooter: BillonModalFooter } = ModalModule;
const { Col, Row } = Grid;

export const ModalFooter = styled(BillonModalFooter)`
  padding-left: 0;
  padding-right: 0;
`;

export const BackButton = styled(BillonBackButton)`
  padding: 0;
`;

export const ZeroCol = styled(Col)`
  padding-left: 11.2px;
`;

export const ActionRow = styled(Row)`
  width: 100%;
`;
