import apiRoutes from '../../../api-routes';
import { postRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';
import { DOCUMENT_TYPE } from '../../../constraints';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';
import { getRequest } from '../../../helpers/apiClient';
import { QueryKeys } from '../../../queryKeys';

const transformFromFileParams = (params) => {
  const { documentFile, contactDetails, ...documentInfo } = params;
  const formData = new FormData();

  if (documentFile) {
    formData.append('documentFile', documentFile[0]);
  }

  if (contactDetails) {
    formData.append('contactDetails', contactDetails[0]);
  }

  const newBlob = new Blob([JSON.stringify(documentInfo)], {
    type: 'application/json',
  });

  formData.append('documentInfo', newBlob);

  return formData;
};

export const usePublishDocumentAsync = (type, options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: publishedDocumentData } = await postRequest({
      url:
        type === DOCUMENT_TYPE.PUBLIC
          ? apiRoutes.DOCUMENT_PUBLIC_PUBLISH_ASYNC
          : apiRoutes.DOCUMENT_PRIVATE_PUBLISH_ASYNC,
      data: transformFromFileParams(data),
      headers: {
        Authorization: sessionStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
    });

    return publishedDocumentData;
  }, options);

export const usePublishDocumentAsyncWithStatusCheck = (id, options) => {
  return useQueryWithLogout(
    [QueryKeys.PUBLISH_DOCUMENT_ASYNC_STATUS, id],
    async () => {
      const { data: publishedDocumentData } = await getRequest({
        url: apiRoutes.DOCUMENT_PUBLISH_ASYNC_STATUS.replace('{id}', id),
        headers: {
          Authorization: sessionStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
        },
      });

      return publishedDocumentData;
    },
    options,
  );
};
