import styled from 'styled-components';
import { Form as FormModule } from 'billon-ui';

export const SwitchField = styled(FormModule.SwitchField)`
  .slider {
    border-color: ${(props) => props.disabled && 'c9d4de'} !important;

    &:before {
      background-color: ${(props) => props.disabled && 'c9d4de'} !important;
    }
  }

  padding-top: ${(props) => !props.isSwitchingAvailable && '3px'} !important;
`;
