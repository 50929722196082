import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, Utils, Modal as ModalModule, Layout } from 'billon-ui';
import Modal from '../../../../ui/Modal';
import * as Styled from './styled';

const { ModalFooter } = ModalModule;
const { Col } = Grid;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Content: ContentModule } = Layout;
const { ErrorContent } = ContentModule;

const SendSmsCodeFailureModal = (props) => {
  return (
    <Modal {...props} closeIconEnabled={false}>
      <Styled.Content>
        <Styled.Title>
          <FormattedMessage
            id={'Error while sending sms message'}
            defaultMessage={'Error while sending sms message'}
          />
        </Styled.Title>
        <ErrorContent height="20rem" />
        {props.children}
      </Styled.Content>
      <ModalFooter>
        <Col md={8}>
          <Button size="lg" block onClick={props.onClose}>
            <FormattedMessage id="Close" defaultMessage="Close" />
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default SendSmsCodeFailureModal;
