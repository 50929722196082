import React from 'react';
import * as Styled from './styled';
import { Table as TableModule, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { DOCUMENTS_OPTIONS } from '../../../../../../../../constraints';

const { Table, TableColumn } = TableModule;
const { Pagination } = Utils;

const ReportHistoryTable = ({
  historyDocumentType,
  customReport,
  pagination,
  onPaginationChange,
}) => {
  const numberOfPages = Math.ceil(customReport.count / pagination.limit);
  const currentPage = pagination.page;

  const handleHistoryPageChange = (page) => {
    onPaginationChange(page);
  };

  return (
    <Styled.HistoryTableWrapper>
      {historyDocumentType === DOCUMENTS_OPTIONS.ALL && (
        <Table tableData={customReport.rows}>
          <TableColumn fieldName="reportName">
            <FormattedMessage id="Date" defaultMessage="Date" />
          </TableColumn>

          <TableColumn fieldName="allDocumentsPublications">
            <FormattedMessage
              id="Published documents number"
              defaultMessage="Published documents number"
            />
          </TableColumn>
          <TableColumn fieldName="allDocumentsSize">
            <FormattedMessage
              id="Published documents size [MB]"
              defaultMessage="Published documents size [MB]"
            />
          </TableColumn>
          <TableColumn fieldName="documentSystemSize">
            <FormattedMessage
              id="Published documents size in the system [MB]"
              defaultMessage="Published documents size in the system [MB]"
            />
          </TableColumn>
          <TableColumn fieldName="averagePublicationTimePrivate">
            <FormattedMessage
              id="Average publish time [s]"
              defaultMessage="Average publish time [s]"
            />
          </TableColumn>
        </Table>
      )}
      {historyDocumentType === DOCUMENTS_OPTIONS.PRIVATE && (
        <Table tableData={customReport.rows}>
          <TableColumn fieldName="reportName">
            <FormattedMessage id="Date" defaultMessage="Date" />
          </TableColumn>
          <TableColumn fieldName="privateDocumentsPublications">
            <FormattedMessage
              id="Published documents number"
              defaultMessage="Published documents number"
            />
          </TableColumn>
          <TableColumn fieldName="privateDocumentsSize">
            <FormattedMessage
              id="Published documents size [MB]"
              defaultMessage="Published documents size [MB]"
            />
          </TableColumn>
          <TableColumn fieldName="averagePublicationTimePrivate">
            <FormattedMessage
              id="Average publish time [s]"
              defaultMessage="Average publish time [s]"
            />
          </TableColumn>
        </Table>
      )}
      {historyDocumentType === DOCUMENTS_OPTIONS.PUBLIC && (
        <Table tableData={customReport.rows}>
          <TableColumn fieldName="reportName">
            <FormattedMessage id="Date" defaultMessage="Date" />
          </TableColumn>
          <TableColumn fieldName="publicDocumentsPublications">
            <FormattedMessage
              id="Published documents number"
              defaultMessage="Published documents number"
            />
          </TableColumn>
          <TableColumn fieldName="publicDocumentsSize">
            <FormattedMessage
              id="Published documents size [MB]"
              defaultMessage="Published documents size [MB]"
            />
          </TableColumn>
          <TableColumn fieldName="averagePublicationTimePublic">
            <FormattedMessage
              id="Average publish time [s]"
              defaultMessage="Average publish time [s]"
            />
          </TableColumn>
        </Table>
      )}
      <Pagination
        onClick={handleHistoryPageChange}
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
      />
    </Styled.HistoryTableWrapper>
  );
};

export default ReportHistoryTable;
