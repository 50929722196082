import React from 'react';
import { MAP_ACTIVE_DELIVERY } from '../../../constraints';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';

import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 160px;
`;

export const activeDeliveryFormatter = (value, record) => {
  const { activeDelivery } = record || {};
  const { type } = activeDelivery || {};

  if (!type) {
    return '';
  }

  const messageId = capitalizeFirstLetter(MAP_ACTIVE_DELIVERY[type]);

  if (!messageId) {
    return '';
  }

  return (
    <Wrapper>
      <FormattedMessage id={messageId} defaultMessage={messageId} />
    </Wrapper>
  );
};
