import { parseDateRange } from '../../../helpers/parseDateRange';

const parseTextField = (v) => {
  if (!v || v?.length === 0) return '';
  else return v.toLowerCase();
};

export const parseFilters = ({
  createdAt,
  createdBy,
  firstName,
  id,
  isActive,
  lastName,
  role,
  username,
  phoneNumber,
  email,
  status,
}) => ({
  createdAt: parseDateRange(createdAt),
  createdBy,
  firstName: parseTextField(firstName),
  id: parseTextField(id),
  phoneNumber: parseTextField(phoneNumber),
  isActive: isActive !== null ? isActive.value : null,
  lastName: parseTextField(lastName),
  role: role && role.value,
  status: status && status.value,
  email: parseTextField(email),
  username: parseTextField(username),
});
