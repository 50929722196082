import * as yup from 'yup';
import { useIntl } from 'react-intl';

import {
  VALIDATION_ERRORS,
  NOTIFICATION_FIELD_ERRORS,
  NOTIFICATION_CONTENT_TAGS,
} from '../../../../constraints';

export const usePrivateEmailTitleSchema = ({ validationActive, fieldName }) => {
  const { formatMessage } = useIntl();

  return yup.object(
    validationActive
      ? {
          [fieldName]: yup
            .string()
            .test(
              fieldName + 'emailUserInputTagPresence',
              formatMessage(
                {
                  id: NOTIFICATION_FIELD_ERRORS.FORBIDDEN_TAG,
                  defaultMessage: NOTIFICATION_FIELD_ERRORS.FORBIDDEN_TAG,
                },
                { TAG: NOTIFICATION_CONTENT_TAGS.USER_INPUT },
              ),
              (value) =>
                value
                  ? !value?.includes(NOTIFICATION_CONTENT_TAGS.USER_INPUT)
                  : true,
            )
            .test(
              fieldName + 'emailTitleEmpty',
              formatMessage({
                id: NOTIFICATION_FIELD_ERRORS.TITLE_EMPTY,
                defaultMessage: NOTIFICATION_FIELD_ERRORS.TITLE_EMPTY,
              }),
              (value) =>
                value ? value?.replaceAll(' ', '')?.length > 0 : true,
            )
            .max(
              255,
              formatMessage(
                {
                  id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
                  defaultMessage:
                    VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
                },
                {
                  length: 255,
                },
              ),
            )
            .required(VALIDATION_ERRORS.REQUIRED),
        }
      : {},
  );
};
