import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useSettingsContext } from '../../Settings/hooks/useSettings';
import { useTenantList } from './useTenantList';
import { useConfigContext } from '../../Config/hooks/useConfig';
import { SETTINGS_CONSTANTS, USER_STATUSES } from '../../../constraints';
import {
  tenantFormatter,
  tenantHeaderMessageIdFormatter,
} from '../formatters/tenantFormatter';
import { useAuthenticatedContext } from '../../Auth/hooks/useAuthenticated';

const TenantsContext = createContext(false);

export const useTenantsContext = () => useContext(TenantsContext);

export const TenantsProvider = ({ children }) => {
  const { customer } = useConfigContext();

  const { status } = useAuthenticatedContext();

  const isEnabled = useMemo(() => {
    if (status) {
      return ![USER_STATUSES.PENDING, USER_STATUSES.PASSWORD_EXPIRED].includes(
        status,
      );
    }

    return false;
  }, [status]);

  const {
    data: tenants,
    isFetching: areTenantsFetching,
    isFetched: areTenantsFetched,
    isError,
  } = useTenantList({
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isEnabled,
  });

  const { getSettingValueByKey } = useSettingsContext();

  const areTenantsAvailable = useMemo(
    () => !isError && tenants?.length > 0,
    [tenants, isError],
  );

  const getTenantNameByValue = useCallback(
    (tenant) => {
      if (!areTenantsAvailable) {
        return getSettingValueByKey(SETTINGS_CONSTANTS.PUBLISHING_INSTITUTION);
      }
      return tenantFormatter(customer)(tenant);
    },
    [areTenantsAvailable, getSettingValueByKey, customer],
  );

  const tenantHeaderMessageId = useMemo(
    () => tenantHeaderMessageIdFormatter(customer),
    [customer],
  );

  const contextValue = useMemo(
    () => ({
      areTenantsFetching,
      areTenantsFetched,
      tenants,
      areTenantsAvailable,
      tenantHeaderMessageId,
      getTenantNameByValue,
    }),
    [
      areTenantsFetching,
      areTenantsFetched,
      tenants,
      areTenantsAvailable,
      tenantHeaderMessageId,
      getTenantNameByValue,
    ],
  );

  return (
    <TenantsContext.Provider value={contextValue}>
      {children}
    </TenantsContext.Provider>
  );
};
