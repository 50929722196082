import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// constants
import {
  RECIPIENT_FILTER_FIELD_NAMES,
  MAP_RECIPIENT_PLACEHOLDERS,
  MAP_RECIPIENT_FIELD_NAMES,
} from '../../../../../../constraints';

// components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';

const PpeNumbersField = () => {
  const { recipientsConfig } = useConfigContext();
  const { isPpeNumberOptional } = recipientsConfig || {};
  const { watch } = useFormContext();
  const ppeNumbers = watch(RECIPIENT_FILTER_FIELD_NAMES.PPE_NUMBERS);
  return (
    <ControlledTextField
      name={RECIPIENT_FILTER_FIELD_NAMES.PPE_NUMBER}
      label={
        <>
          <FormattedMessage
            id={MAP_RECIPIENT_FIELD_NAMES.PPE_NUMBERS}
            defaultMessage={MAP_RECIPIENT_FIELD_NAMES.PPE_NUMBERS}
          />
          {ppeNumbers?.length > 0 && (
            <>
              <br />
              <i>{ppeNumbers?.join(', ')}</i>
            </>
          )}
        </>
      }
      placeholder={MAP_RECIPIENT_PLACEHOLDERS.PPE_NUMBERS}
      required={!isPpeNumberOptional}
    />
  );
};

export default PpeNumbersField;
