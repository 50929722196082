import moment from 'moment';

const parseValidSince = (validSince) => {
  let formatValue = validSince;

  if (new Date(validSince) < Date.now()) {
    formatValue = new Date(Date.now() + 1 * 60000).setSeconds(0, 0);
  }

  if (!isNaN(parseInt(formatValue))) {
    formatValue = parseInt(formatValue);
  }

  return moment(formatValue).format('YYYY-MM-DDTHH:mm:ssZ');
};

export const parseCreateContentData = ({
  emailTemplate,
  smsTemplate,
  validSince,
}) => ({
  emailTemplate,
  smsTemplate,
  validSince: parseValidSince(validSince),
});
