import base from './base';

import { default as tauronUatTdm } from './configs/tauron/tauron.uat.tdm';
import { default as tauronTst } from './configs/tauron/tauron.tst';
import { default as tauronPreprod } from './configs/tauron/tauron.preprod';
import { default as tauronProd } from './configs/tauron/tauron.prod';
import { default as mvp2Demo } from './configs/tauron/mvp2.demo';

import { default as bdsDiploma } from './configs/diploma/diploma.bds';
import { default as diplomaUatTDM } from './configs/diploma/diploma.uat.tdm';
import { default as diplomademo } from './configs/diploma/diploma.demo';

import { default as remotesignatureuat } from './configs/remoteSsignature/rs.uat';
import { default as remotesignaturedemo } from './configs/remoteSsignature/rs.demo';
import { default as sandbox } from './configs/remoteSsignature/sandbox';

import { default as dev } from './configs/dev';
import { default as tergo } from './configs/clients/tergo';

let configType = 'dev';

if (!!process.env.REACT_APP_CONFIG) configType = process.env.REACT_APP_CONFIG;
if (configType == 'dev') {
  console.log('USING DEV CONFIG!');
}

const customerConfig = {
  dev: dev,
  tauronUatTdm: tauronUatTdm,
  tauronTst: tauronTst,
  tauronPreprod: tauronPreprod,
  tauronProd: tauronProd,
  mvp2Demo: mvp2Demo,
  bdsDiploma: bdsDiploma,
  diplomaUatTDM: diplomaUatTDM,
  SANDBOX: sandbox,
  remotesignaturedemo: remotesignaturedemo,
  diplomademo: diplomademo,
  remotesignatureuat: remotesignatureuat,
  tergo: tergo,
}[configType];

const config = {
  ...base,
  ...customerConfig,
};

export default config;
