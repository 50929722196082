import styled from 'styled-components';
import { Grid, Utils, Modal as ModalModule } from 'billon-ui';
import { down } from 'styled-breakpoints';

const { Text } = Utils;
const { Button: ButtonModule } = Utils;
const { BackButton: BillonBackButton, Button } = ButtonModule;
const { ModalFooter: BillonModalFooter } = ModalModule;
const { Col } = Grid;

export const SummaryContainer = styled.div`
  border: 1px dashed #c9d4de;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const IconButtonWrapper = styled.div`
  padding: 0.5rem;
`;

export const Title = styled(Text)`
  font-size: 2.5rem;
  margin-bottom: 4rem;
  font-weight: ${(props) => props.theme.fontWeightBold};

  ${down('lg')} {
    font-size: 1.9rem;
  }

  ${down('sm')} {
    font-size: 1.75rem;
  }
`;

export const BackButton = styled(BillonBackButton)`
  padding: 0;
`;

export const ModalBodyWrapper = styled.div`
  padding-bottom: 30px;
`;

export const ModalFooter = styled(BillonModalFooter)`
  padding-left: 0;
  padding-right: 0;
`;

export const ZeroCol = styled(Col)`
  padding-left: 11.2px;
`;

export const EditPanel = styled.div`
  border: 1px solid #c9d4de;
  border-radius: 8px;
  padding: 4rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const EditButton = styled(Button)`
  padding-left: 4rem;
  padding-right: 4rem;
`;
