import * as yup from 'yup';
import { useIntl } from 'react-intl';

import { VALIDATION_ERRORS } from '../../../../constraints';

export const useSchema = () => {
  const { formatMessage } = useIntl();

  const requiredMessage = formatMessage({
    id: VALIDATION_ERRORS.REQUIRED,
    defaultMessage: VALIDATION_ERRORS.REQUIRED,
  });

  return yup.object({
    username: yup.string().required(requiredMessage),
    password: yup.string().required(requiredMessage),
  });
};
