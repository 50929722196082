import React from 'react';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Icon } = Utils;

export const ExpandButton = ({ onClick, isOpen }) => (
  <Styled.ButtonWrapper onClick={onClick} type="button">
    <Icon name={isOpen ? 'chevron-down' : 'chevron-up'} color="#c9d4de" />
  </Styled.ButtonWrapper>
);
