import React from 'react';
import * as Styled from './styled';
import { Language } from 'billon-ui';
import { countryToLanguage, languageToCountry } from '../../constraints';
import PoweredBy from '../../ui/PoweredBy';

const { ChangeLanguage } = Language;

export const UnauthorizedLayout = ({ children }) => {
  return (
    <Styled.LayoutWrapper>
      <ChangeLanguage
        countryToLanguage={countryToLanguage}
        languageToCountry={languageToCountry}
      />
      <Styled.ContentRow>{children}</Styled.ContentRow>
      <PoweredBy fixed />
    </Styled.LayoutWrapper>
  );
};
