import React from 'react';
import styled from 'styled-components';
import { Utils } from 'billon-ui';

const FieldLabel = ({ children }) => (
  <Utils.Text
    as="label"
    fontSize={'12px'}
    fontWeight={700}
    margin={'0 0 5px 0'}
  >
    {children}
  </Utils.Text>
);

export default FieldLabel;
