import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../../../../ui/CustomToolTip';
import * as Styled from './styled';
import { useIntl } from 'react-intl';

const HeaderToolTip = () => {
  const { formatMessage } = useIntl();
  return (
    <CustomToolTip
      id="CustomToolTip-resend-notification-header-1"
      trigger={<Styled.HeaderIcon name="info-circle" />}
    >
      <Styled.ToolTipContentWrapper>
        <FormattedMessage
          id="A notification will be sent to selected recipients who have been previously included in the list"
          defaultMessage="A notification will be sent to selected recipients who have been previously included in the list"
        />
        {'. '}
        {formatMessage({
          id: 'If you want to add a new recipient, first use the {optionName} option',
        }).replace(
          '{optionName}',
          `"${formatMessage({
            id: 'Update recipient list',
          })}"`,
        )}
        {'.'}
      </Styled.ToolTipContentWrapper>
    </CustomToolTip>
  );
};

export default HeaderToolTip;
