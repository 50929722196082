import styled from 'styled-components';
import { Utils } from 'billon-ui';
const { Icon } = Utils;

export const ToolTipContentWrapper = styled.p`
  padding: 16px;
  margin: 0;
`;

export const HeaderIcon = styled(Icon)`
  color: grey;
`;
