import { PUBLICATION_FIELD_NAMES } from '../../../../../../constraints';
import { defaulSigningOrder } from '../../../../../../components/SelectSigningOrderField/SelectSigningOrderField';
import { defaultSigningType } from '../../../../../../components/SelectSigningTypeField/SelectSigningTypeField';
import { defaulActiveDelivery } from '../../../../../../components/SelectActiveDeliveryField/SelectActiveDeliveryField';
import { defaultPublicDocumentType } from '../../../../../../components/SelectPublicDocumentTypeField/SelectPublicDocumentTypeField';
import { getIdentityValue } from './helpers/getIdentityValue';

export const computeDefaultValues = (
  document,
  user,
  isPublicDocumentTypeEnabled,
) => ({
  [PUBLICATION_FIELD_NAMES.PUBLISHED_BY]: `${user.firstName} ${user.lastName}`,
  [PUBLICATION_FIELD_NAMES.TITLE]: document?.title || '',
  [PUBLICATION_FIELD_NAMES.CATEGORY]: document?.category
    ? {
        value: document?.category.id || document?.category.value,
        label: document?.category.name || document?.category.label,
        notificationTemplate: document?.category?.notificationTemplate,
      }
    : '',
  [PUBLICATION_FIELD_NAMES.VALID_SINCE]: document?.validSince,
  [PUBLICATION_FIELD_NAMES.VALID_UNTIL]: document?.validUntil,
  [PUBLICATION_FIELD_NAMES.PUBLIC_DOCUMENT_TYPE]: isPublicDocumentTypeEnabled
    ? document?.publicDocumentType
      ? {
          value:
            document?.publicDocumentType.id ||
            document?.publicDocumentType.value,
          label:
            document?.publicDocumentType.name ||
            document?.publicDocumentType.label,
        }
      : defaultPublicDocumentType
    : null,
  [PUBLICATION_FIELD_NAMES.VERSION_NAME]: document?.versionName || '',
  [PUBLICATION_FIELD_NAMES.RETAIN_UNTIL]: document?.retainUntil || '',
  [PUBLICATION_FIELD_NAMES.DOCUMENT_FILE]: document?.documentFile || [],
  [PUBLICATION_FIELD_NAMES.SIGNING_ORDER]: document?.signingOrder
    ? {
        value: document?.signingOrder.id || document?.signingOrder.value,
        label: document?.signingOrder.name || document?.signingOrder.label,
      }
    : defaulSigningOrder,
  [PUBLICATION_FIELD_NAMES.SIGNING_TYPE]: document?.signingType
    ? {
        value: document?.signingType.id || document?.signingType.value,
        label: document?.signingType.name || document?.signingType.label,
      }
    : defaultSigningType,
  [PUBLICATION_FIELD_NAMES.ACTIVE_DELIVERY_TYPE]: document?.activeDeliveryType
    ? {
        value:
          document?.activeDeliveryType?.id ||
          document?.activeDeliveryType?.value,
        label:
          document?.activeDeliveryType?.name ||
          document?.activeDeliveryType?.label,
      }
    : defaulActiveDelivery,
  [PUBLICATION_FIELD_NAMES.IDENTITY]: getIdentityValue(document?.identity),
});
