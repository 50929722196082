import React from 'react';
import { ReactComponent as TrashIcon } from '../../../../../../js/ui/Icons/trash.svg';
import IconButton from '../components/IconButton/IconButton';

export const unSelectRecipientFormatter = (
  value,
  record,
  onRemoveRecipient,
) => {
  const handleRemoveClick = () => {
    onRemoveRecipient(record);
  };

  return (
    <IconButton onClick={handleRemoveClick}>
      <TrashIcon />
    </IconButton>
  );
};
