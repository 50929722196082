import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

// hooks
import { useResendDocumentNotification } from '../../hooks/useResendDocumentNotification';

// helpers
import { parseResendNotificationData } from '../../helpers/parseResendNotificationData';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';

// constants
import {
  NOTIFICATION_TEMPLATE_TYPE,
  RESEND_NOTIFICATION_MODES,
  mapDOCUMENT_ACTIONS_ERROR,
} from '../../../../constraints';

// Components
import {
  SearchFromList,
  SearchFromListSummary,
  SelectRecipientsGroup,
  SelectResendMode,
  EnterNotificationContent,
} from './components';
import { SuccessModal } from '../../../../components/SuccessModal';
import { ErrorModal } from '../../../../components/ErrorModal';
import { ExportableErrorContent } from '../../../../components/ExportableErrorContent';

const ResendNotification = ({
  document,
  resendMode,
  handleClose,
  isLoading,
}) => {
  const history = useHistory();

  const [storedRecipients, setStoredRecipients] = useState([]);
  const [latelyRemovedRecipient, setLatelyRemovedRecipient] = useState({});

  const { tenant, category } = document || {};
  const tenantId = tenant?.id;
  const categoryId = category?.id;
  const defaultValues = { ...document, tenantId, categoryId };

  const handleAppendRecipients = (recipient) => {
    if (
      !storedRecipients.find((record) => record.uniqueId === recipient.uniqueId)
    ) {
      setStoredRecipients([...storedRecipients, recipient]);
    }
  };

  const handleRemoveRecipient = (recipient) => {
    setStoredRecipients([
      ...storedRecipients.filter(
        ({ uniqueId }) => uniqueId !== recipient.uniqueId,
      ),
    ]);
    setLatelyRemovedRecipient(recipient);
  };

  const [formData, setFormData] = useState({});
  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const onSelectResendMode = () => {
    setFormData({});
    setStoredRecipients([]);
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SELECT_TYPE}`,
    );
  };

  const onSelectGroup = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SELECT_GROUP}`,
    );
  };

  const onSearchList = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SEARCH_LIST}`,
    );
  };

  const onSummaryList = (subFormData) => {
    handleAppendFormData(subFormData);
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SUMMARY_LIST}`,
    );
  };

  const onSelectEditContent = (subFormData) => {
    handleAppendFormData(subFormData);
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.EDIT_TYPE_VIEW}`,
    );
  };

  const onSelectEditContentAfterSelectGroup = (subFormData) => {
    if (
      !!{ ...formData, ...subFormData }?.groups?.find((v) => v.checked === true)
    ) {
      onSelectEditContent(subFormData);
    }
  };

  const onDistinguishableBack = () => {
    history.goBack();
  };

  const onResendSuccess = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SUCCESS}`,
    );
  };

  const onResendFailure = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.FAILURE}`,
    );
  };

  const {
    mutate: resendNotification,
    error,
    isLoading: isProcessing,
  } = useResendDocumentNotification({
    id: document.documentBlockchainAddress,
    documentType: document.documentType,
    onSuccess: onResendSuccess,
    onFailure: onResendFailure,
  });

  const onSubmitForm = (subFormData) => {
    handleAppendFormData(subFormData);
    resendNotification({
      data: removeUndefinedValues(
        parseResendNotificationData(
          { ...formData, ...subFormData },
          storedRecipients,
        ),
      ),
    });
  };

  const areStoredRecipientsEmpty = isEmpty(storedRecipients);

  const statusCode = error?.response?.data?.statusCode;

  const notificationTemplateType = NOTIFICATION_TEMPLATE_TYPE.PUBLIC_PUBLISHED;

  return (
    <>
      <SelectResendMode
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SELECT_TYPE}
        isLoading={false}
        onClose={handleClose}
        onSelectGroup={onSelectGroup}
        onSearchList={onSearchList}
      />
      <SelectRecipientsGroup
        jobId={document.jobId}
        resendMode={resendMode}
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SELECT_GROUP}
        isLoading={false}
        onClose={handleClose}
        onBack={onSelectResendMode}
        onNext={onSelectEditContentAfterSelectGroup}
        documentBlockchainAddress={document.documentBlockchainAddress}
      />
      <SearchFromList
        jobId={document.jobId}
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SEARCH_LIST}
        isLoading={false}
        onClose={handleClose}
        onBack={onSelectResendMode}
        onNext={onSummaryList}
        documentBlockchainAddress={document.documentBlockchainAddress}
        resendMode={resendMode}
        onAppendRecipients={handleAppendRecipients}
        unRegisterRecipient={handleRemoveRecipient}
        defaultValues={formData}
        latelyRemovedRecipient={latelyRemovedRecipient}
        areStoredRecipientsEmpty={areStoredRecipientsEmpty}
      />
      <SearchFromListSummary
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SUMMARY_LIST}
        isLoading={false}
        onClose={handleClose}
        onBack={onSearchList}
        onNext={onSelectEditContent}
        documentBlockchainAddress={document.documentBlockchainAddress}
        resendMode={resendMode}
        storedRecipients={storedRecipients}
        onRemoveRecipient={handleRemoveRecipient}
        areStoredRecipientsEmpty={areStoredRecipientsEmpty}
      />
      <EnterNotificationContent
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.EDIT_TYPE_VIEW}
        isLoading={isProcessing}
        onClose={handleClose}
        onBack={onDistinguishableBack}
        onNext={onSubmitForm}
        documentBlockchainAddress={document.documentBlockchainAddress}
        documentType={notificationTemplateType}
        defaultValues={defaultValues}
      />
      <SuccessModal
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SUCCESS}
        onClose={handleClose}
        toggle={handleClose}
        closeIconEnabled={true}
      >
        <FormattedMessage
          id="Notifications already resent"
          defaultMessage="Notifications already resent"
        />
      </SuccessModal>
      <ErrorModal
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.FAILURE}
        toggle={handleClose}
        onClose={handleClose}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={mapDOCUMENT_ACTIONS_ERROR.RENOTIFY}
          statusCode={statusCode}
          jobId={document.jobId}
          title={document.title}
        />
      </ErrorModal>
    </>
  );
};

export default ResendNotification;
