import React from 'react';

export const documentListIdentityFormatter = (value, record) => {
  const { identity } = record || {};
  const { firstName, lastName } = identity || {};

  if (identity) {
    return <div>{`${firstName} ${lastName}`}</div>;
  }

  return '---';
};

export const documentListIdentityNameFormatter = (value, record) => {
  const { identity } = record || {};
  const { name } = identity || {};

  return name || '---';
};
