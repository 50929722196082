import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const CopyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;

  &:hover,
  &:focus {
    outline: none;
  }
`;

export const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${({ theme }) => theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -0.5rem;
  left: 50%;
  margin-left: 25px;
  transition: ${({ theme }) => theme.transitionBase};
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: none;
`;

export const CopyHeader = styled(Text)`
  margin: 0;
  font-size: ${({ theme, scale }) => scale === 'section' && '18px'};
  font-weight: ${({ theme, emphasisedHeader, scale }) =>
    emphasisedHeader
      ? scale === 'section'
        ? theme.fontWeightBold
        : theme.fontWeightSemiBold
      : theme.fontWeightBase};
`;

export const ValueLabel = styled.div`
  word-wrap: break-word;
  font-weight: ${({ theme, emphasisedValue }) =>
    emphasisedValue ? theme.fontWeightSemiBold : theme.fontWeightBase};
`;

export const ValueSpan = styled.span`
  word-wrap: break-word;
  font-weight: ${({ theme, emphasisedValue }) =>
    emphasisedValue ? theme.fontWeightSemiBold : theme.fontWeightBase};
`;
