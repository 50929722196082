import styled from 'styled-components';
import { Grid, Utils } from 'billon-ui';

const { Row, Col } = Grid;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const CenteredVericallyRow = styled(Row)`
  align-items: center;
  margin-bottom: 16px;
`;

export const SpacedBetweenCenteredVericallyRow = styled(CenteredVericallyRow)`
  justify-content: space-between;
`;

export const ButtonCol = styled(Col)`
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const HeaderButton = styled(Button)`
  align-self: flex-end;
  @media (min-width: 900px) {
    padding: 0.5rem 3.5rem;
  }
`;
