import React from 'react';
import * as Styled from '../CategoryList/styled';
import { CategoryList } from '../CategoryList/CategoryList';
import { FormattedMessage } from 'react-intl';

export const CategoriesContainer = ({ onCreate }) => {
  return (
    <Styled.Container>
      <Styled.FirstRow>
        <Styled.FirstCell />
        <Styled.NameCell bold>
          <FormattedMessage id="Category name" defaultMessage="Category name" />
        </Styled.NameCell>
        <Styled.Cell bold>
          <FormattedMessage id="Status" defaultMessage="Status" />
        </Styled.Cell>
        <Styled.Cell bold>
          <FormattedMessage id="Active" defaultMessage="Active" />
        </Styled.Cell>
        <Styled.Cell bold>
          <FormattedMessage
            id="Documents count"
            defaultMessage="Documents count"
          />
        </Styled.Cell>
        <Styled.Cell bold>
          <FormattedMessage
            id="Show documents"
            defaultMessage="Show documents"
          />
        </Styled.Cell>
      </Styled.FirstRow>
      <CategoryList onCreate={onCreate} />
    </Styled.Container>
  );
};
