import React from 'react';
import { NotificationRecipientToolTip } from '../components';
import { notificationStatusFormatter } from './';
import {
  NOTIFICATION_HISTORY_STATUSES,
  NOTIFICATION_HISTORY_ERRORS_STATUSES,
  MAP_NOTIFICATION_HISTORY_ERRORS_STATUSES,
} from '../../../../../constraints';

export const notificationReasonWithToolTipFormatter = (value, record) => {
  const errorCheck =
    NOTIFICATION_HISTORY_ERRORS_STATUSES[value] ||
    !NOTIFICATION_HISTORY_STATUSES[value];
  const displayedValue = errorCheck
    ? NOTIFICATION_HISTORY_STATUSES.ERROR
    : value;
  const label = notificationStatusFormatter(displayedValue);

  if (errorCheck) {
    const content = MAP_NOTIFICATION_HISTORY_ERRORS_STATUSES[value];
    return (
      <NotificationRecipientToolTip
        content={content}
        record={record}
        label={label}
      />
    );
  } else return label;
};
