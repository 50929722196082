import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout as LayoutModule } from 'billon-ui';
import { PageMenu } from './components';

const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { ContentContainer } = ContentModule;

const PageLayout = ({ children }) => {
  return (
    <ContentContainer fluid>
      <Content fluid>
        <PageHeader classic>
          <FormattedMessage id="Select report" defaultMessage="Select report" />
        </PageHeader>
        <PageMenu />
        {children}
      </Content>
    </ContentContainer>
  );
};

export default PageLayout;
