import React from 'react';
import DocumentStatusTooltip from '../components/DocumentStatusTooltip/DocumentStatusTooltip';
import StatusWithIcon from '../../../ui/StatusWithIcon';
import {
  documentPublicationCkkERRORS,
  MAP_DOCUMENT_STATUSES,
  mapERRORS,
  MAP_SIGNING_STATUSES,
  SIGNING_STATUS,
  DOCUMENT_STATUS,
  MAP_SIGNING_ERRORS,
  SIGNING_ORDER,
  DOCUMENT_TYPE,
} from '../../../constraints';
import generateId from '../../../utils/generateId';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../components/CustomToolTip';
import { Utils } from 'billon-ui';
import { ToolTipContentWrapper } from '../../../components/CustomToolTip/styled';

const { Icon } = Utils;

export const documentStatusFormatter =
  (enableSigning, iconDisabled, isDetailView) => (rawValue, record) => {
    const {
      signingOrder,
      signingStatus,
      status: documentStatus,
      documentType,
    } = record || {};

    const isDocumentPrivate = documentType === DOCUMENT_TYPE.PRIVATE;
    const isSigningMappingEnabled = isDocumentPrivate && enableSigning;
    const value = isSigningMappingEnabled ? signingStatus : documentStatus;

    const STATUS_MAPPING_BASE = {
      ...MAP_DOCUMENT_STATUSES,
      ...((isSigningMappingEnabled && MAP_SIGNING_STATUSES) || {}),
    };

    const ERROR_MAPPING_BASE = {
      ...mapERRORS,
      ...((isSigningMappingEnabled && MAP_SIGNING_ERRORS) || {}),
    };

    const isSigningDeprecated =
      [SIGNING_ORDER.SENDER_FIRST, SIGNING_ORDER.RECEIVER_ONLY].includes(
        signingOrder,
      ) &&
      [
        SIGNING_STATUS.PUBLISHING_OK,
        SIGNING_STATUS.REJECTED,
        SIGNING_STATUS.WAITING_FOR_RECEIVER,
      ].includes(signingStatus) &&
      documentStatus === DOCUMENT_STATUS.DEPRECATED;

    if (isSigningDeprecated) {
      const signingInfoLabelId = `You are displaying an deprecated version of the document. ${
        signingStatus === SIGNING_STATUS.PUBLISHING_OK
          ? 'The document has already been signed by the Recipient. '
          : ''
      }${
        signingStatus === SIGNING_STATUS.REJECTED
          ? 'The document has already been rejected by the Recipient. '
          : ''
      }See the most recent document version.`;

      return (
        <>
          <FormattedMessage
            id="Submitted for signature"
            defaultMessage="Submitted for signature"
          />{' '}
          <div>
            <FormattedMessage
              id="[previous version]"
              defaultMessage="[previous version]"
            />{' '}
            <CustomToolTip
              id={generateId(record)}
              trigger={<Icon name={'info-circle'} color={'grey'} />}
            >
              <ToolTipContentWrapper>
                <FormattedMessage
                  id={signingInfoLabelId}
                  defaultMessage={signingInfoLabelId}
                />
              </ToolTipContentWrapper>
            </CustomToolTip>
          </div>
        </>
      );
    }

    const chosenValue =
      isDetailView && documentStatus === DOCUMENT_STATUS.DEPRECATED
        ? DOCUMENT_STATUS.DEPRECATED
        : value;

    const label =
      STATUS_MAPPING_BASE[chosenValue] ?? MAP_DOCUMENT_STATUSES.UNKNOWN;
    const formattedLabel = (
      <FormattedMessage id={label} defaultMessage={label} />
    );

    const { jobId, title, ckkStatus } = record || {};
    const ckkStatusMapped = ERROR_MAPPING_BASE[ckkStatus];
    const errorContentId = documentPublicationCkkERRORS[ckkStatus]
      ? ckkStatusMapped
      : ERROR_MAPPING_BASE[chosenValue];

    if (errorContentId && !iconDisabled) {
      return (
        <StatusWithIcon label={formattedLabel}>
          <DocumentStatusTooltip
            id={generateId(record)}
            label={errorContentId}
            jobId={jobId}
            title={title}
            delay={true}
          />
        </StatusWithIcon>
      );
    }

    return formattedLabel;
  };
