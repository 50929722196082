import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Layout, Grid, Utils } from 'billon-ui';
import Modal from '../../../../../../ui/Modal';
import * as Styled from './styled';
import { ModalBody } from '../../../../../../ui/CustomConfirmModal/styled';
import SelectedRecipientsTable from '../SelectedRecipientsTable';
const { Col, Row } = Grid;
const { Button: ButtonModule, Loader: LoaderModule } = Utils;
const { Button } = ButtonModule;
const { Content: ContentModule } = Layout;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;

const ModalHeaderPanel = () => (
  <Row>
    <Styled.TitleContainer md={6}>
      <Styled.ModalTitle>
        <FormattedMessage
          id="Chosen recipients"
          defaultMessage="Chosen recipients"
        />
      </Styled.ModalTitle>
    </Styled.TitleContainer>
  </Row>
);

const SearchFromListSummary = ({
  isOpen,
  isLoading,
  onClose,
  onBack,
  onNext,
  onRemoveRecipient,
  storedRecipients,
  areStoredRecipientsEmpty,
}) => {
  const formMethods = useForm();

  const handleFormSubmit = (values) => {};

  const { handleSubmit } = formMethods;

  if (isLoading) {
    return (
      <Modal
        isOpen={true}
        closeIconEnabled={true}
        onClose={onClose}
        toggle={onClose}
      >
        <Content fluid>
          <PageLoader />
        </Content>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
      closePadding
    >
      <ModalHeaderPanel />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalBody>
            <SelectedRecipientsTable
              data={storedRecipients}
              onRemoveRecipient={onRemoveRecipient}
            />
          </ModalBody>
          <Styled.ModalFooter>
            <Styled.ZeroCol md={3}>
              <Styled.BackButton secondary onClick={onBack}>
                <FormattedMessage id="back" defaultMessage="back" />
              </Styled.BackButton>
            </Styled.ZeroCol>
            <Col md={6}>
              <Button
                size="lg"
                block
                onClick={onNext}
                disabled={areStoredRecipientsEmpty}
              >
                <FormattedMessage id="Next" defaultMessage="Close" />
              </Button>
            </Col>
            <Col md={3}></Col>
          </Styled.ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SearchFromListSummary;
