import React from 'react';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// constants
import { RECIPIENT_UPLOAD_ERROR_VALUES } from '../../../../../../constraints';
import { RECIPIENT_ERROR_CODES } from '../../../../../../constants';

// helpers
import { getValidationErrorTypes } from '../helpers/getValidationErrorTypes';
import { geFileErrorTypes } from '../helpers/geFileErrorTypes';

// Components
import { FormattedMessage } from 'react-intl';
import * as Styled from '../styled';
import ReasonsForFailuresColumn from './ReasonsForFailuresColumn';
import { Grid, Utils } from 'billon-ui';

const { Row } = Grid;
const { Text } = Utils;

const FileErrorSummary = ({ errorData, mode }) => {
  const { isDiploma } = useConfigContext();

  const { errorInfo, statusCode, errors, validationErrors, success, total } =
    errorData || {};

  const fileErrorTypes = geFileErrorTypes(errors);
  const nonUniqueErrorTypes = getValidationErrorTypes(validationErrors);
  const validationErrorTypes = getValidationErrorTypes(validationErrors);

  const recipientsLoadEMPTY = errorInfo && errorInfo['RECIPIENT'] === 'EMPTY';

  const areReasonsForFailuresDisplayed =
    fileErrorTypes?.filter((e) =>
      [
        RECIPIENT_UPLOAD_ERROR_VALUES.MISSING,
        RECIPIENT_UPLOAD_ERROR_VALUES.DUPLICATED,
      ].includes(e),
    )?.length > 0 ||
    nonUniqueErrorTypes?.filter((e) =>
      [RECIPIENT_UPLOAD_ERROR_VALUES.NON_UNIQUE].includes(e),
    )?.length > 0 ||
    validationErrorTypes?.filter((e) =>
      [
        RECIPIENT_UPLOAD_ERROR_VALUES.EMPTY,
        RECIPIENT_UPLOAD_ERROR_VALUES.DIFFERENT,
        RECIPIENT_UPLOAD_ERROR_VALUES.INVALID,
        RECIPIENT_UPLOAD_ERROR_VALUES.WHITESPACE_FORBIDDEN,
      ].includes(e),
    )?.length > 0;

  return (
    <div>
      <Styled.FormHeader>
        <FormattedMessage id="Result" defaultMessage="Result" />
        {statusCode === RECIPIENT_ERROR_CODES.FILE_ERROR && (
          <Styled.SpanDanger>
            {': '}
            <FormattedMessage
              id="File uploading error"
              defaultMessage="File uploading error"
            />
          </Styled.SpanDanger>
        )}
      </Styled.FormHeader>

      {statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR && (
        <Text>
          <FormattedMessage
            id="Correctly uploaded2"
            defaultMessage="Correctly uploaded2"
          />
          &nbsp;
          {recipientsLoadEMPTY ? (
            0
          ) : success && total ? (
            <>
              {success} <FormattedMessage id="of" defaultMessage="of" /> {total}
            </>
          ) : (
            0
          )}
          &nbsp;
          {isDiploma ? (
            <FormattedMessage id="students" defaultMessage="students" />
          ) : (
            <FormattedMessage id="recipients" defaultMessage="recipients" />
          )}
          .
        </Text>
      )}

      <Row>
        {areReasonsForFailuresDisplayed && (
          <ReasonsForFailuresColumn
            statusCode={statusCode}
            mode={mode}
            validationErrors={validationErrors}
            errors={errors}
            isNeighbourDisplayed={false}
          />
        )}
      </Row>
    </div>
  );
};

export default FileErrorSummary;
