import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule } from 'billon-ui';

const { FormGroup, PhoneNumberField } = FormModule;

export const ControlledPhoneNumberField = ({
  name,
  disabled,
  label,
  placeholder,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <PhoneNumberField
            input={field}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            disabled={disabled}
            placeholder={placeholder}
            label={label}
          />
        )}
      />
    </FormGroup>
  );
};
