import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import * as Styled from './styled';
import { Grid } from 'billon-ui';
import { momentInTimezoneFormatter } from '../../../../formatters';
import {
  PUBLICATION_MODES,
  DOCUMENT_STATUS,
  SIGNING_ORDER,
  SIGNING_STATUS,
} from '../../../../constraints';

const { Col } = Grid;

export const SigningStepper = ({
  signingStatus,
  signingOrder,
  status: documentStatus,
  publicationDate,
  publicationMode,
  nextPublicationMode,
  previousPublicationDate,
  nextPublicationDate,
  previousPublicationMode,
}) => {
  const { formatMessage } = useIntl();

  const isIncident =
    documentStatus === DOCUMENT_STATUS.DEPRECATED &&
    [previousPublicationMode, publicationMode, nextPublicationMode].includes(
      PUBLICATION_MODES.INCIDENT,
    );

  const willBeApproved =
    signingStatus === SIGNING_STATUS.WAITING_FOR_RECEIVER &&
    documentStatus === DOCUMENT_STATUS.CURRENT;

  const isRejected =
    signingStatus === SIGNING_STATUS.REJECTED &&
    (documentStatus === DOCUMENT_STATUS.CURRENT ||
      documentStatus === DOCUMENT_STATUS.DEPRECATED);

  const isApproved =
    signingStatus === SIGNING_STATUS.PUBLISHING_OK &&
    [SIGNING_ORDER.RECEIVER_ONLY, SIGNING_ORDER.SENDER_FIRST].includes(
      signingOrder,
    );

  const isDeprecated = documentStatus === DOCUMENT_STATUS.DEPRECATED;

  const stepADate = useMemo(() => {
    if ((!willBeApproved || isApproved || isRejected) && !isDeprecated) {
      return momentInTimezoneFormatter(
        previousPublicationDate || nextPublicationDate,
      );
    }
    return momentInTimezoneFormatter(publicationDate);
  }, [
    willBeApproved,
    isApproved,
    isRejected,
    isDeprecated,
    publicationDate,
    nextPublicationDate,
    previousPublicationDate,
  ]);

  const stepA = {
    name: formatMessage({
      id: 'Submitted for signature',
    }),
    subName: <br />,
    date: stepADate,
    active: true,
    failed: isIncident,
    extraWarning: isIncident && (
      <>
        <div>
          {formatMessage({
            id: 'NOTE: INCIDENT has been published.',
          })}
        </div>
        <div>
          {formatMessage({
            id: 'See the most recent document.',
          })}
        </div>
      </>
    ),
  };

  const { stepBName, stepBSubName } = useMemo(() => {
    if (willBeApproved || isApproved) {
      return {
        stepBName: formatMessage({
          id: 'Approved',
        }),
        stepBSubName: formatMessage({
          id: 'Document signed',
        }),
      };
    }
    if (isRejected) {
      return {
        stepBName: formatMessage({
          id: 'Document rejected by the recipient',
        }),
        stepBSubName: <br />,
      };
    }
    return { stepBName: <br />, stepBSubName: <br /> };
  }, [willBeApproved, isApproved, isRejected, formatMessage]);

  const stepBDate = useMemo(() => {
    if (isDeprecated) {
      return momentInTimezoneFormatter(
        previousPublicationDate || nextPublicationDate,
      );
    }
    if (isApproved || isRejected) {
      return momentInTimezoneFormatter(publicationDate);
    }
    return null;
  }, [
    isApproved,
    isRejected,
    isDeprecated,
    publicationDate,
    nextPublicationDate,
  ]);

  const stepB = {
    name: stepBName,
    subName: stepBSubName,
    date: stepBDate,
    active: isApproved || isRejected,
    failed: isIncident || isRejected,
    extraWarning: null,
  };

  const steps = [stepA, stepB];

  return (
    <div>
      <h3>
        <FormattedMessage id="Signing steps" defaultMessage="Signing steps" />
      </h3>
      <Styled.StepperRowWrapper>
        <Col md={6}>
          <div id="stepper1" class="bs-stepper">
            <div class="bs-stepper-header">
              {steps.map(
                ({ name, date, active, failed, extraWarning, subName }, id) => (
                  <>
                    <Styled.Step class="step">
                      <Styled.CenterWrapper>
                        <span class="step-trigger.disabled">
                          <Styled.StepFailedColorWrapper
                            failed={failed}
                            active={active}
                          >
                            <span class="bs-stepper-circle">{id + 1}</span>
                          </Styled.StepFailedColorWrapper>
                        </span>
                      </Styled.CenterWrapper>
                      <Styled.NameWrapper active={active}>
                        <Styled.CenterWrapper>{name}</Styled.CenterWrapper>
                        <Styled.CenterWrapper>{subName}</Styled.CenterWrapper>
                      </Styled.NameWrapper>
                      {active && date && (
                        <Styled.CenterWrapper>
                          <FormattedMessage id="Date" defaultMessage="Date" />
                          {':'}&nbsp;
                          {date}
                        </Styled.CenterWrapper>
                      )}
                      {extraWarning && (
                        <Styled.CenterWrapper>
                          <Styled.WarningColorWrapper>
                            {extraWarning}
                          </Styled.WarningColorWrapper>
                        </Styled.CenterWrapper>
                      )}
                    </Styled.Step>
                    {id + 1 < steps?.length && (
                      <Styled.LineWrapper className="line"></Styled.LineWrapper>
                    )}
                  </>
                ),
              )}
            </div>
          </div>
        </Col>
      </Styled.StepperRowWrapper>
    </div>
  );
};
