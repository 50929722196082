import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../constraints';

// hooks
import { useReportStatuses } from '../hooks/useReportStatuses';

// Components
import { ControlledSelectField } from '../../../../../components/ControlledSelectField/ControlledSelectField';
import { FieldLabel } from '../../../../../components/Form';

const SelectReportStatusField = ({
  onClear,
  isClearable,
  name = DOCUMENT_FILTER_FIELD_NAMES.DOCUMENT_STATUS_LIST,
}) => {
  const { statusOptions: reportStatusOptions } = useReportStatuses();
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Status" defaultMessage="Status" />
      </FieldLabel>
      <ControlledSelectField
        name={name}
        options={reportStatusOptions}
        onClear={onClear}
        isClearable={isClearable}
      />
    </>
  );
};

export default SelectReportStatusField;
