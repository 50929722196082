import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Icon } = Utils;

export const FancyRadioField = ({
  input,
  name,
  disabled,
  label,
  onClick,
  children,
  meta: { error, touched },
  className,
}) => (
  <Styled.FancyRadio
    className={classNames({
      checked: input.checked,
      [className]: !!className,
    })}
  >
    <Styled.CheckedBox className="checked-box">
      <Icon name="check-circle" />
    </Styled.CheckedBox>
    <Styled.FancyControlInput
      {...input}
      type="radio"
      name={name}
      disabled={disabled}
      onClick={onClick && !disabled ? () => onClick(input.value) : () => {}}
    />
    <Styled.FancyBox className="fancy-box">
      <Styled.FancyHeader>{label}</Styled.FancyHeader>
      {children}
    </Styled.FancyBox>
    {touched && error && (
      <Styled.Error>
        <FormattedMessage id={error} defaultMessage={error} />
      </Styled.Error>
    )}
  </Styled.FancyRadio>
);

FancyRadioField.propTypes = {
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  meta: PropTypes.object.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
};
