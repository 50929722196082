import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import {
  ACTIVE_DELIVERY,
  MAP_ACTIVE_DELIVERY,
  DOCUMENT_FILTER_FIELD_NAMES,
} from '../../constraints';

// Components
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { DeliveryControlledSelectField } from '../ControlledSelectField/DeliveryControlledSelectField';
import { ActiveDeliveryToolTip } from './ActiveDeliveryToolTip';
import * as Styled from '../SelectCategoryField/styled';

import { Utils } from 'billon-ui';

const { Text } = Utils;

const defaulActiveDeliveryKey = ACTIVE_DELIVERY.NONE;

export const defaulActiveDelivery = {
  label: <FormattedMessage id={MAP_ACTIVE_DELIVERY[defaulActiveDeliveryKey]} />,
  value: defaulActiveDeliveryKey,
};

const activeDeliveryList = [
  ACTIVE_DELIVERY.NONE,
  ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY,
  ACTIVE_DELIVERY.CONFIRMATION_OF_RECEIPT,
  ACTIVE_DELIVERY.CONFIRMATION_OF_ACKNOWLEDGMENT,
];

export const activeDeliveryOptions = activeDeliveryList.map((option) => ({
  label: <FormattedMessage id={MAP_ACTIVE_DELIVERY[option]} />,
  value: option,
}));

export const SelectActiveDeliveryField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.ACTIVE_DELIVERY_TYPE,
  deliveredControl,
  disabled,
  onClear,
  isClearable,
}) => {
  return (
    <>
      <Text as="h6" fontSize="12px" fontWeight="600">
        <FormattedMessage id="e-Delivery" defaultMessage="e-Delivery" />{' '}
        <ActiveDeliveryToolTip />
      </Text>
      <Styled.FormGroup>
        {deliveredControl ? (
          <DeliveryControlledSelectField
            name={name}
            options={activeDeliveryOptions}
            disabled={disabled}
            control={deliveredControl}
            onClear={onClear}
            isClearable={isClearable}
          />
        ) : (
          <ControlledSelectField
            name={name}
            options={activeDeliveryOptions}
            disabled={disabled}
            onClear={onClear}
            isClearable={isClearable}
          />
        )}
      </Styled.FormGroup>
    </>
  );
};
