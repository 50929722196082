import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from '../SelectCategoryField/styled';
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { Utils } from 'billon-ui';
import { SIGNING_TYPE, MAP_SIGNING_TYPE } from '../../constraints';

const { Text } = Utils;

const defaulSigningTypeKey = SIGNING_TYPE.STANDARD;

export const defaultSigningType = {
  label: <FormattedMessage id={MAP_SIGNING_TYPE[defaulSigningTypeKey]} />,
  value: defaulSigningTypeKey,
};

const signingTypeList = [
  SIGNING_TYPE.STANDARD,
  SIGNING_TYPE.ADVANCED,
  SIGNING_TYPE.QUALIFIED,
];

export const signingTypeOptions = signingTypeList.map((option) => ({
  label: <FormattedMessage id={MAP_SIGNING_TYPE[option]} />,
  value: option,
  disabled: option !== SIGNING_TYPE.STANDARD,
}));

export const SelectSigningTypeField = ({ name = 'signingType', disabled }) => {
  return (
    <>
      <Text as="h6" fontSize="12px" fontWeight="600">
        <FormattedMessage id="Signing type" defaultMessage="Signing type" />
      </Text>
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          options={signingTypeOptions}
          disabled={disabled}
        />
      </Styled.FormGroup>
    </>
  );
};
