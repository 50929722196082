import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// Components
import { ControlledCalendarField } from '../../../../../../components/ControlledCalendarField/ControlledCalendarField';
import { FieldLabel } from '../../../../../../components/Form';

const CalendarValidUntilField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.VALID_UNTIL_DATE_RANGE,
  onClear,
}) => {
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Valid until" defaultMessage="Valid until" />
      </FieldLabel>
      <ControlledCalendarField
        name={name}
        onClear={onClear}
        enableTime={false}
        showMonths={2}
        range
      />
    </>
  );
};

export default CalendarValidUntilField;
