import React from 'react';
import { Form as FormModule, Utils } from 'billon-ui';
import { useUpdateUser } from '../../hooks/useUpdateUser';

const { Loader } = Utils.Loader;
const { SwitchField } = FormModule;

const IsActiveSwitch = ({ value, row, onSuccess }) => {
  const { mutate: editUser, isLoading } = useUpdateUser({
    onSuccess: onSuccess,
  });

  if (isLoading) {
    return <Loader width={40} />;
  } else {
    return (
      <SwitchField
        input={{
          value,
          name: `isActive[${row.id}]`,
          checked: value,
          onClick: () => {
            editUser({
              id: row.id,
              data: {
                isActive: !row.isActive,
              },
            });
          },
        }}
        slim
        type="checkbox"
        meta={{}}
      />
    );
  }
};

export default IsActiveSwitch;
