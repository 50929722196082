import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form as FormModule, Grid } from 'billon-ui';
import { Controller } from 'react-hook-form';
import * as Styled from './styled';
import { CUSTOMER } from 'js/constraints';

const { FormGroup, TextField, PhoneNumberField, SwitchField } = FormModule;
const { Row, Col } = Grid;

const DataTab = ({
  initialValues,
  customer,
  control,
  errors,
  isActiveWatch,
  setValue,
}) => (
  <>
    <Row>
      <Col md={6}>
        <FormGroup>
          <Controller
            name="username"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  input={field}
                  meta={{
                    dirty: fieldState.isDirty,
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  label={<FormattedMessage id="Login" defaultMessage="Login" />}
                  placeholder="testp1"
                  disabled={initialValues.id}
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  input={field}
                  meta={{
                    dirty: fieldState.isDirty,
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  label={
                    <FormattedMessage
                      id="First name"
                      defaultMessage="First name"
                    />
                  }
                  placeholder="Jan"
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  input={field}
                  meta={{
                    dirty: fieldState.isDirty,
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  label={
                    <FormattedMessage
                      id="Last name"
                      defaultMessage="Last name"
                    />
                  }
                  placeholder="Kowalski"
                />
              );
            }}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <PhoneNumberField
                  input={field}
                  meta={{
                    dirty: fieldState.isDirty,
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  label={
                    <FormattedMessage
                      id="Phone number"
                      defaultMessage="Phone number"
                    />
                  }
                />
              );
            }}
          />
        </FormGroup>

        <FormGroup>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  input={field}
                  meta={{
                    dirty: fieldState.isDirty,
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  label={<FormattedMessage id="Email" defaultMessage="Email" />}
                  placeholder="jan.kowalski@billongroup.com"
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="department"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  input={field}
                  meta={{
                    dirty: fieldState.isDirty,
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  label={
                    <FormattedMessage
                      id="Department"
                      defaultMessage="Department"
                    />
                  }
                  placeholder="Accounting"
                />
              );
            }}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <Styled.FieldFlexRow>
            <Styled.FC>
              <Controller
                name="isActive"
                control={control}
                render={({ field, fieldState }) => {
                  if (field.value === false && customer !== CUSTOMER.TAURON) {
                    setValue('notify', false);
                  }
                  return (
                    <SwitchField
                      input={{
                        ...field,
                        checked: field.value,
                      }}
                      meta={{
                        dirty: fieldState.isDirty,
                        touched: fieldState.invalid,
                        error: errors?.[field.name]?.message,
                      }}
                      type="checkbox"
                    />
                  );
                }}
              />
            </Styled.FC>
            <Styled.FC2>
              <FormattedMessage id="Inactive" defaultMessage="Inactive" />
              {' / '}
              <FormattedMessage id="active2" defaultMessage="active2" />
            </Styled.FC2>
          </Styled.FieldFlexRow>
        </FormGroup>

        {!initialValues?.id && (
          <>
            <FormGroup>
              <Styled.FieldFlexRow>
                <Styled.FC>
                  <Controller
                    name="isRobot"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <SwitchField
                          input={{
                            ...field,
                            checked: field.value,
                          }}
                          meta={{
                            dirty: fieldState.isDirty,
                            touched: fieldState.invalid,
                            error: errors?.[field.name]?.message,
                          }}
                          type="checkbox"
                        />
                      );
                    }}
                  />
                </Styled.FC>
                <Styled.FC2>
                  <FormattedMessage
                    id="Account with Bot functionality"
                    defaultMessage="Account with Bot functionality"
                  />
                </Styled.FC2>
              </Styled.FieldFlexRow>
            </FormGroup>

            {customer !== CUSTOMER.TAURON && (
              <FormGroup>
                <Styled.FieldFlexRow>
                  <Styled.FC>
                    <Controller
                      name="notify"
                      control={control}
                      render={({ field, fieldState }) => {
                        if (!isActiveWatch) {
                          return (
                            <SwitchField
                              input={{
                                ...field,
                                checked: false,
                                value: false,
                              }}
                              meta={{
                                dirty: fieldState.isDirty,
                                touched: fieldState.invalid,
                                error: errors?.[field.name]?.message,
                              }}
                              type="checkbox"
                            />
                          );
                        }
                        return (
                          <SwitchField
                            input={{
                              ...field,
                              checked: field.value,
                            }}
                            meta={{
                              dirty: fieldState.isDirty,
                              touched: fieldState.invalid,
                              error: errors?.[field.name]?.message,
                            }}
                            type="checkbox"
                          />
                        );
                      }}
                    />
                  </Styled.FC>
                  <Styled.FC2>
                    <FormattedMessage
                      id="Send invitation to set password"
                      defaultMessage="Send invitation to set password"
                    />
                  </Styled.FC2>
                </Styled.FieldFlexRow>
              </FormGroup>
            )}
          </>
        )}
      </Col>
    </Row>
  </>
);

DataTab.propTypes = {
  initialValues: PropTypes.object,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  getValues: PropTypes.func.isRequired,
};

export default DataTab;
