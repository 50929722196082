import React from 'react';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import MeDetails from '../../components/MeDetails';
import useMeRequest from '../../../Settings/hooks/useMeRequest';
import ContentContainerGrey from '../../../../ui/ContentContainerGrey';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const MePage = () => {
  const { data: me, isLoading } = useMeRequest();

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <ContentContainerGrey>
      <MeDetails me={me} />
    </ContentContainerGrey>
  );
};

export default MePage;
