import apiRoutes from '../../../api-routes';
import { postRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

export const useCreateUser = (options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: createData } = await postRequest({
      url: apiRoutes.USER,
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return createData;
  }, options);
