import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { VALIDATION_ERRORS } from '../../../../constraints';
import { Utils } from 'billon-ui';

const { validators } = Utils;

export const useSchema = () => {
  const { formatMessage } = useIntl();

  const requiredMessage = formatMessage({
    id: VALIDATION_ERRORS.REQUIRED,
    defaultMessage: VALIDATION_ERRORS.REQUIRED,
  });

  return yup.object({
    email: yup
      .string()
      .test(
        'email',
        formatMessage({
          id: VALIDATION_ERRORS.EMAIL_INVALID,
          defaultMessage: VALIDATION_ERRORS.EMAIL_INVALID,
        }),
        (v) => !validators.emailValidator(v),
      )
      .required(requiredMessage),
  });
};
