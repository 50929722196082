import styled from 'styled-components';

export const IconWrapper = styled.span`
  &:hover {
    cursor: pointer;
  }
  svg path {
    fill: ${(props) => props.theme.palette.grayLighter} !important;
  }
  svg:hover path {
    fill: black !important;
  }
`;
