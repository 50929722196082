import { patchRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../api-routes';

export const useUpdateIdentity = (options) =>
  useMutationWithLogout(async ({ id, data }) => {
    const { data: createData } = await patchRequest({
      url: apiRoutes.SINGLE_RECIPIENT.replace('{id}', id),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return createData;
  }, options);
