import React from 'react';

import SelectDocumentTypeOptions from '../SelectDocumentTypeOptions';
import { DOCUMENT_TYPE } from '../../../../../../constraints';

const PageMenu = () => {
  const options = [
    {
      labelId: 'Public documents',
      value: DOCUMENT_TYPE.PUBLIC,
    },
    { labelId: 'Private documents', value: DOCUMENT_TYPE.PRIVATE },
  ];

  return (
    <>
      <SelectDocumentTypeOptions options={options} />
      <br />
    </>
  );
};

export default PageMenu;
