import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Utils } from 'billon-ui';

import * as Styled from './styled';

const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Col } = Grid;

export const FormFooter = ({
  onBack,
  isLoading,
  submitButtonText,
  backBtnDisabled,
  onNext,
  submitMessageId = 'Next',
  disabled,
  nextBtnDisabled,
}) => (
  <Styled.CenterRow>
    {!backBtnDisabled && (
      <Col md={12} xl={3}>
        <Styled.BackButton
          secondary
          onClick={onBack}
          nextBtnDisabled={nextBtnDisabled}
        >
          <FormattedMessage id="back" defaultMessage="back" />
        </Styled.BackButton>
      </Col>
    )}
    {!nextBtnDisabled && (
      <Col xl={6}>
        {!isLoading ? (
          <Button
            disabled={disabled}
            type="submit"
            size="lg"
            block
            onClick={onNext ? onNext : () => {}}
          >
            {submitButtonText ? (
              <FormattedMessage
                id={submitButtonText}
                defaultMessage={submitButtonText}
              />
            ) : (
              <FormattedMessage
                id={submitMessageId}
                defaultMessage={submitMessageId}
              />
            )}
          </Button>
        ) : (
          <ButtonLoader size="lg" block />
        )}
      </Col>
    )}
  </Styled.CenterRow>
);
