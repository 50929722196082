import React from 'react';
import { retainOptionsList } from '../../constraints';
import { retainOptionToString } from '../../formatters';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Styled from '../SelectCategoryField/styled';
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const SelectRetentionField = ({ name = 'retainUntil', disabled }) => {
  const { formatMessage } = useIntl();

  const retainOptionsFormatted = retainOptionsList.map((option) => ({
    label: formatMessage({
      id: retainOptionToString(option),
      defaultMessage: retainOptionToString(option),
    }),
    value: option,
  }));

  return (
    <>
      <Text as="h6" fontSize="12px" fontWeight="600">
        <FormattedMessage id="Retention date" defaultMessage="Retention date" />
      </Text>
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          options={retainOptionsFormatted}
          disabled={disabled}
        />
      </Styled.FormGroup>
    </>
  );
};
