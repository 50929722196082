// templates
import tauronTemplate from '../assets/recipient-template-tauron.xlsx';
import diplomaTemplate from '../assets/recipient-template-diploma.xlsx';
import mvpTemplate from '../assets/recipient-template-mvp.xlsx';

import { CUSTOMER } from '../../../../../constraints';

export const getCustomerProperties = (customer) => {
  switch (customer) {
    case CUSTOMER.DIPLOMA:
      return {
        manageTitleId: 'Manage students',
        subtitleMessageId:
          'To add or download a student list, select one of the options below',
        downloadTemplateMessageId: 'the template with student list',
        templateFileName: 'Szablon listy odbiorców - dyplomy.xlsx',
        templateHref: diplomaTemplate,
      };
    case CUSTOMER.TAURON:
      return {
        manageTitleId: 'Manage recipients',
        subtitleMessageId:
          'To add or download a recipient list, select one of the options below',
        downloadTemplateMessageId: 'the template with recipient list',
        templateFileName: 'Szablon listy odbiorców.xlsx',
        templateHref: tauronTemplate,
      };
    default:
      return {
        manageTitleId: 'Manage recipients',
        subtitleMessageId:
          'To add or download a recipient list, select one of the options below',
        downloadTemplateMessageId: 'the template with recipient list',
        templateFileName: 'Szablon listy odbiorców - mvp.xlsx',
        templateHref: mvpTemplate,
      };
  }
};
