import * as yup from 'yup';
import { useIntl } from 'react-intl';

import {
  NOTIFICATION_TEMPLATE_FIELD_NAMES,
  VALIDATION_ERRORS,
  NOTIFICATION_FIELD_ERRORS,
  NOTIFICATION_CONTENT_TAGS,
} from '../../../../constraints';

export const usePrivateContentSchema = ({ name }) => {
  const { formatMessage } = useIntl();

  return yup.object(
    name === NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE
      ? {
          [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE]: yup
            .string()
            .test(
              'emailLinkTagPresence',
              formatMessage(
                {
                  id: NOTIFICATION_FIELD_ERRORS.REQUIRED_TAG,
                  defaultMessage: NOTIFICATION_FIELD_ERRORS.REQUIRED_TAG,
                },
                { TAG: NOTIFICATION_CONTENT_TAGS.LINK },
              ),
              (value) =>
                value ? value?.includes(NOTIFICATION_CONTENT_TAGS.LINK) : true,
            )
            .required(VALIDATION_ERRORS.REQUIRED),
        }
      : {
          [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_SMS_TEMPLATE]: yup
            .string()
            .test(
              'smsEmpty',
              formatMessage({
                id: NOTIFICATION_FIELD_ERRORS.SMS_EMPTY,
                defaultMessage: NOTIFICATION_FIELD_ERRORS.SMS_EMPTY,
              }),
              (value) =>
                value ? value?.replaceAll(' ', '')?.length > 0 : true,
            )
            .test(
              'smsMaxLength',
              formatMessage(
                {
                  id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
                  defaultMessage:
                    VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
                },
                {
                  length: 255,
                },
              ),
              (value) => (value ? value?.length <= 255 : true),
            )
            .required(VALIDATION_ERRORS.REQUIRED),
        },
  );
};
