import React from 'react';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';
import { MAP_VALIDATION_ERROR_TYPES } from '../../../../../../constraints';
import WithValueMessage from '../../../../../../helpers/WithValueMessage';
import * as Styled from './styled';

const ValidationInfo = ({ settingsKey, requirement, isErrorActive }) => {
  const { getSettingValueByKey } = useSettingsContext();
  const settingsRecord = getSettingValueByKey(settingsKey);
  if (!settingsRecord) return null;
  const message = MAP_VALIDATION_ERROR_TYPES[requirement];
  return (
    settingsRecord && (
      <Styled.ErrorLabel isErrorActive={isErrorActive}>
        <WithValueMessage messageId={message} name="n" value={settingsRecord} />
      </Styled.ErrorLabel>
    )
  );
};

export default ValidationInfo;
