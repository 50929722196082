import React from 'react';
import { FEEDBACK_ACTIONS } from '../../../../constraints';

// hooks
import { useSettingsContext } from '../../../Settings/hooks/useSettings';

// Components

import { ExportableErrorContent } from '../../../../components/ExportableErrorContent';
import SingleCustomCard from '../../components/SingleCustomCard/SingleCustomCard';
import TwoStepPasswordSet from '../../components/TwoStepPasswordSet';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const SetFirstPasswordPage = () => {
  const { isLoading, isTokenExpired } = useSettingsContext();

  if (isTokenExpired) {
    return (
      <SingleCustomCard>
        <ExportableErrorContent
          exportDisabled
          headerTitleId={'Your password setting link has expired'}
          statusCode={FEEDBACK_ACTIONS.LINK_EXPIRED}
        />
      </SingleCustomCard>
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return <TwoStepPasswordSet />;
};

export default SetFirstPasswordPage;
