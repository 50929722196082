import { QueryKeys } from 'js/queryKeys';
import apiRoutes from 'js/api-routes';
import { useQueryWithLogout } from 'js/hooks/useQueryWithLogout';
import { getRequest } from 'js/helpers/apiClient';

export const useCurrentUser = (options) =>
  useQueryWithLogout(
    [QueryKeys.ME],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.ME,
      });
      return data;
    },
    options,
  );
