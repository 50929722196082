import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

const FieldTitle = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  font-size: ${(props) => props.theme.fontSizeBase};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export default FieldTitle;
