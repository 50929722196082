import React from 'react';
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';

// Formatters
import {
  boundLengthFormatterLong,
  momentFormatterWithoutTime,
  momentInTimezoneFormatter,
} from '../../../../../../formatters';
import { blurSingleFormatter } from '../../../../../../formatters';
import { boundLengthFormatter } from '../../../../../../formatters';
import { documentStatusFormatter } from '../../../../../Documents/formatters/documentStatusFormatter';
import { activeDeliveryFormatter } from '../../../../../Documents/formatters/activeDeliveryFormatter';

// Constants
import {
  DOCUMENT_ORDER_BY,
  ORDER_METHOD,
  CUSTOMER,
  IDENTITY_DOCUMENT_TYPE,
  DOCUMENT_STATUS,
} from '../../../../../../constraints';
import { QueryKeys } from '../../../../../../queryKeys';

// helpers
import { getIdentityPlaceHolderId } from '../../../../../Recipients/helpers/getIdentityPlaceHolderId';

// Hooks
import { useDocumentList } from '../../../../../Documents/hooks';
import useFilters from '../../../../../../hooks/useFilters';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useRefresh } from '../../../../../../hooks/useRefresh';

// Components
import * as Styled from './styled';
import { FilterForm } from '../FilterForm';
import { PreviewDownloadButton } from '../../../../../Documents/components/PreviewDownloadButton';
import { RefreshButton } from '../../../../../../components/RefreshButton/RefreshButton';
import { ReportDownloadBtn } from '../ReportDownloadBtn';
import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination } = Utils;
const { PageHeader } = LayoutModule;
const { Loader } = LoaderModule;

export const DocumentTable = () => {
  const {
    isDiploma,
    customer,
    enableSigning,
    enableActiveDelivery,
    recipientsConfig,
  } = useConfigContext();
  const { enableDocumentNumberBlur } = recipientsConfig || {};

  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    filters: {
      documentStatusList: enableSigning
        ? [
            DOCUMENT_STATUS.NOT_EXIST,
            DOCUMENT_STATUS.UPLOADING,
            DOCUMENT_STATUS.SIGNING,
            DOCUMENT_STATUS.CURRENT,
            DOCUMENT_STATUS.PREPARED_TO_SIGN,
          ]
        : undefined,
    },
  };
  const { filters, navigateWithSearchQuery } = useFilters();

  const fullFilters = merge({}, defaultFilters, filters);

  const { data, isFetching: isDocumentDataFetching } =
    useDocumentList(fullFilters);

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const onSort = (column, isDescending) => {
    navigateWithSearchQuery({
      sort: {
        sortBy: DOCUMENT_ORDER_BY[column],
        order: isDescending ? ORDER_METHOD.ASC : ORDER_METHOD.DESC,
      },
    });
  };

  const isAscendingOrder = (column) => {
    if (filters?.sort?.sortBy === column) {
      return filters?.sort?.order === ORDER_METHOD.ASC;
    } else {
      return true;
    }
  };

  const { isRefreshDisabled, handleRefresh } = useRefresh(
    QueryKeys.DOCUMENT_LIST,
  );

  const documentIdentityText = getIdentityPlaceHolderId(isDiploma, customer);

  return (
    <>
      <FilterForm defaultFilters={defaultFilters} />
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={<ReportDownloadBtn fullFilters={fullFilters} />}
          >
            <FormattedMessage
              id="Generated report"
              defaultMessage="Generated report"
            />

            <RefreshButton
              handleRefresh={handleRefresh}
              isRefreshDisabled={isRefreshDisabled}
            />
          </PageHeader>
        )}
      </FormattedMessage>

      <Styled.TableCard>
        {isDocumentDataFetching ? (
          <Loader width={36} />
        ) : (
          <>
            <Table tableData={data.rows} sortMethod={onSort} responsive>
              <TableColumn
                fieldName="publicationDate"
                formatter={momentInTimezoneFormatter}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.publicationDate)}
              >
                <FormattedMessage
                  id="Publication date"
                  defaultMessage="Publication date"
                />
              </TableColumn>
              <TableColumn
                fieldName="title"
                sortable
                formatter={boundLengthFormatterLong}
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.title)}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma number' : 'Title'}
                  defaultMessage={isDiploma ? 'Diploma number' : 'Title'}
                />
              </TableColumn>
              <TableColumn
                fieldName="validSince"
                formatter={
                  customer === CUSTOMER.TAURON
                    ? momentFormatterWithoutTime
                    : momentInTimezoneFormatter
                }
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.validSince)}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma issuance date' : 'Valid since'}
                  defaultMessage={
                    isDiploma ? 'Diploma issuance date' : 'Valid since'
                  }
                />
              </TableColumn>
              <TableColumn
                fieldName="validUntil"
                formatter={
                  customer === CUSTOMER.TAURON
                    ? momentFormatterWithoutTime
                    : momentInTimezoneFormatter
                }
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.validUntil)}
              >
                <FormattedMessage
                  id="Valid until"
                  defaultMessage="Valid until"
                />
              </TableColumn>
              <TableColumn
                fieldName="category"
                formatter={(value) => value?.name}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.category)}
              >
                <FormattedMessage id="Category" defaultMessage="Category" />
              </TableColumn>
              <TableColumn
                fieldName="identity_surname"
                formatter={(value, record) =>
                  record?.identity &&
                  `${record.identity?.firstName} ${record.identity?.lastName}`
                }
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.identity_surname)}
              >
                <FormattedMessage
                  id="Published for"
                  defaultMessage="Published for"
                />
              </TableColumn>

              <TableColumn
                sortable
                asc={isAscendingOrder(
                  DOCUMENT_ORDER_BY.identity_documentNumber,
                )}
                fieldName="identity_documentNumber"
                formatter={(value, record) =>
                  blurSingleFormatter(
                    boundLengthFormatter(record?.identity?.documentNumber, 20),
                    enableDocumentNumberBlur &&
                      record?.identity?.documentType ===
                        IDENTITY_DOCUMENT_TYPE.PESEL,
                  )
                }
              >
                <FormattedMessage
                  id={documentIdentityText}
                  defaultMessage={documentIdentityText}
                />
              </TableColumn>

              <TableColumn
                fieldName="publishedBy"
                formatter={(value, record) =>
                  record?.identity && `${value?.firstName} ${value?.lastName}`
                }
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.identity_surname)}
              >
                <FormattedMessage
                  id="Administrator"
                  defaultMessage="Administrator"
                />
              </TableColumn>

              {enableActiveDelivery && (
                <TableColumn
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.activeDelivery)}
                  fieldName="activeDelivery"
                  formatter={activeDeliveryFormatter}
                >
                  <FormattedMessage
                    id="e-Delivery"
                    defaultMessage="e-Delivery"
                  />
                </TableColumn>
              )}

              {enableSigning ? (
                <TableColumn
                  fieldName="signingStatus"
                  formatter={documentStatusFormatter(enableSigning)}
                >
                  <FormattedMessage id="Status" defaultMessage="Status" />
                </TableColumn>
              ) : (
                <TableColumn
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.status)}
                  fieldName="status"
                  formatter={documentStatusFormatter(enableSigning)}
                >
                  <FormattedMessage id="Status" defaultMessage="Status" />
                </TableColumn>
              )}
              <TableColumn
                className="text-center fixed-width"
                fieldName="Preview"
                formatter={(value, record) => (
                  <PreviewDownloadButton record={record} isPreview />
                )}
              >
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </TableColumn>
              <TableColumn
                className="text-center fixed-width"
                fieldName="Download"
                formatter={(value, record) => (
                  <PreviewDownloadButton record={record} />
                )}
              >
                <FormattedMessage id="Download" defaultMessage="Download" />
              </TableColumn>
            </Table>
            <Pagination
              onClick={handlePageChange}
              numberOfPages={Math.ceil(
                data.count / fullFilters.pagination.limit,
              )}
              currentPage={Number(fullFilters.pagination.page)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
            />
          </>
        )}
      </Styled.TableCard>
      <br />
      <br />
    </>
  );
};
