import styled from 'styled-components';

export const Input = styled.input`
  padding: 0;
  width: 100%;
  margin: 0;
`;

export const TrimmedField = styled.div`
  margin-top: 24px !important;
`;
