import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfigContext } from '../../modules/Config/hooks/useConfig';
import { getCustomerProperties } from './getCustomerProperties';

// Components
import * as Styled from './styled';
import { StretchedDropzonePlaceholder } from '../DropzonePlaceholder/DropzonePlaceholder';
import SourceSystemsExporter from './SourceSystemsExporter';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule, Utils } from 'billon-ui';

const { UploadField } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const UploadRecipientsField = ({
  name = 'file',
  disabled,
  extraActionsDisabled,
  maxSize = 2000,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { formatMessage } = useIntl();

  const { customer } = useConfigContext();
  const customerProperties = getCustomerProperties(customer);

  const handleDownloadTemplateClick = (e) => {
    e.stopPropagation();
  };

  const handleShowSourceSystemsClick = (e) => {
    e.stopPropagation();
  };

  const limitInMB = (maxSize / 1000).toFixed(2);

  const maxSizeDescription = formatMessage({
    id: 'supported files: .xlsx .xls and .csv max. 5 MB',
  }).replace('5', limitInMB);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <UploadField
            input={field}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            disabled={disabled}
            placeholder={() => (
              <>
                <StretchedDropzonePlaceholder>
                  {!extraActionsDisabled && (
                    <Styled.ActionPanel>
                      <SourceSystemsExporter
                        onClick={handleShowSourceSystemsClick}
                      >
                        <FormattedMessage
                          id="Download source systems list"
                          defaultMessage="Download source systems list"
                        />
                      </SourceSystemsExporter>
                      <Styled.ActionAnchor
                        href={customerProperties.templateHref}
                        target="_blank"
                        download={customerProperties.templateFileName}
                        onClick={handleDownloadTemplateClick}
                      >
                        <FormattedMessage
                          id="Download template"
                          defaultMessage="Download template"
                        />
                      </Styled.ActionAnchor>
                    </Styled.ActionPanel>
                  )}

                  <div>
                    <FormattedMessage
                      id={customerProperties.dragDropMessageId}
                      defaultMessage={customerProperties.dragDropMessageId}
                    />
                  </div>
                  <div>
                    <FormattedMessage id="or" defaultMessage="or" />
                  </div>
                  <Button outline type="button">
                    <FormattedMessage
                      id="Select from disk"
                      defaultMessage="Select from disk"
                    />
                  </Button>
                  <div>
                    {'('}
                    {maxSizeDescription}
                    {')'}
                  </div>
                </StretchedDropzonePlaceholder>
              </>
            )}
          />
        )}
      />
    </>
  );
};
