import { useMemo } from 'react';
import { useConfigContext } from '../../Config/hooks/useConfig';
import { DOCUMENT_TYPE } from '../../../constraints';
import useFilters from '../../../hooks/useFilters';

const useDocumentType = () => {
  const { isDiploma } = useConfigContext();
  const { filters } = useFilters();

  const documentType = useMemo(() => {
    const predefinedDocumentTypeList = filters?.filters?.documentTypeList;
    if (predefinedDocumentTypeList) {
      return predefinedDocumentTypeList;
    } else {
      return DOCUMENT_TYPE.PRIVATE;
    }
  }, [filters]);

  return { isDiploma, documentType };
};

export default useDocumentType;
