import { postRequest } from '../../../../helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../../api-routes';

export const useAddPrivateNotificationGroupContent = (groupId, options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: createData } = await postRequest({
      url: apiRoutes.PRIVATE_NOTIFICATION_GROUP_CONTENTS.replace(
        '{groupId}',
        groupId,
      ),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return createData;
  }, options);
