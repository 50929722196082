import React, { useEffect } from 'react';
import * as Styled from './styled';
import { useTicker } from '../../../../hooks/useTicker';

const CancelPublicationCounter = ({
  publicationStartDate,
  publicationDelayMinutes,
  closingAction,
}) => {
  const { end, days, hours, minutes, seconds } = useTicker(
    publicationStartDate,
    publicationDelayMinutes,
  );

  useEffect(() => {
    if (end && closingAction) {
      closingAction();
    }
  }, [end, closingAction]);

  return (
    <Styled.RedText>
      {end
        ? '00:00:00'
        : `${days ? days + ' d ' : ''}${hours < 10 ? '0' : ''}${hours}:${
            minutes < 10 ? '0' : ''
          }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
    </Styled.RedText>
  );
};

export default CancelPublicationCounter;
