import { combineEpics } from 'redux-observable';
import { TechnicalBreak } from 'billon-ui';

import { epics as AuthenticationEpics } from './ui/Authentication';

import { epics as SettingsEpics } from './modules/Settings';

const { epics: TechnicalBreakEpics } = TechnicalBreak;

export default combineEpics(
  ...[
    ...TechnicalBreakEpics,
    ...SettingsEpics,
    ...AuthenticationEpics,
  ],
);
