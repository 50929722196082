import React from 'react';
import * as Styled from './styled';
import { Grid } from 'billon-ui';

const { Row } = Grid;

const ModalHeaderPanel = ({ children }) => (
  <Row>
    <Styled.TitleContainer md={6}>
      <Styled.ModalTitle>{children}</Styled.ModalTitle>
    </Styled.TitleContainer>
  </Row>
);

export default ModalHeaderPanel;
