import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomConfirmModal from '../../../../../../ui/CustomConfirmModal';
import * as Styled from './styled';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

const ConfirmActionModal = ({
  onSubmit,
  onClose,
  isOpen,
  isSubmitting,
  currentSuspensionActionState,
  recipientName,
  recipientDocumentNumber,
}) => {
  const { isDiploma } = useConfigContext();

  const titleMessageId = currentSuspensionActionState
    ? 'Suspend data processing'
    : 'Resume data processing';
  const descriptionMessageId = currentSuspensionActionState
    ? 'Confirm suspension for'
    : 'Confirm resume for';
  const documentNumberMessageId = isDiploma ? 'Index number' : 'PESEL';

  return (
    <CustomConfirmModal
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      isOpen={isOpen}
      toggle={onClose}
      title={
        <FormattedMessage id={titleMessageId} defaultMessage={titleMessageId} />
      }
    >
      <Styled.ModalContent>
        <Styled.ContentRow>
          <FormattedMessage
            id={descriptionMessageId}
            defaultMessage={descriptionMessageId}
          />
          {': '}
        </Styled.ContentRow>
        <Styled.ContentRow>
          <Styled.Tag>
            <FormattedMessage
              id="Name and surname"
              defaultMessage="Name and surname"
            />
            {': '}
          </Styled.Tag>
          {recipientName}
        </Styled.ContentRow>
        <Styled.ContentRow>
          <Styled.Tag>
            <FormattedMessage
              id={documentNumberMessageId}
              defaultMessage={documentNumberMessageId}
            />
            {': '}
          </Styled.Tag>
          {recipientDocumentNumber}
        </Styled.ContentRow>
      </Styled.ModalContent>
    </CustomConfirmModal>
  );
};

export default ConfirmActionModal;
