import { useMemo } from 'react';

// hooks
import { useConfigContext } from '../../Config/hooks/useConfig';
import useNotificationTemplateByPublicDocumentType from '../../Notifications/hooks/publicNotifications/useNotificationTemplateByPublicDocumentType';
import { useTemplatesByCategoryData } from '../../Notifications/hooks/privateNotifications/useTemplatesByCategoryData';

export const useNotificationContents = ({
  categoryId,
  publicDocumentType,
  categoryNotificationTemplate,
}) => {
  const { enableNotificationManager } = useConfigContext();

  const {
    data: publicDocumentTemplate,
    isLoading: isPublicDocumentTemplateLoading,
    error: publicDocumentTemplateError,
  } = useNotificationTemplateByPublicDocumentType({
    publicDocumentType,
    disabled: !publicDocumentType,
  });

  const {
    data: privateDocumentTemplate,
    isLoading: isPrivateDocumentTemplateLoading,
    error: privateDocumentTemplateError,
  } = useTemplatesByCategoryData({
    categoryNotificationTemplate,
    categoryId,
    enabled:
      (!!categoryNotificationTemplate || !!categoryId) &&
      enableNotificationManager &&
      !publicDocumentType,
  });

  const isLoading = useMemo(
    () => isPublicDocumentTemplateLoading || isPrivateDocumentTemplateLoading,
    [isPublicDocumentTemplateLoading, isPrivateDocumentTemplateLoading],
  );

  const error = useMemo(() => {
    if (!!publicDocumentTemplateError) {
      return publicDocumentTemplateError;
    }
    if (!!privateDocumentTemplateError) {
      return privateDocumentTemplateError;
    }
    return;
  }, [publicDocumentTemplateError, privateDocumentTemplateError]);

  const getPublicDocumentReturnValue = (publicDocumentTemplate) => {
    const { emailTemplate, emailTitle, smsTemplate } =
      publicDocumentTemplate || {};
    return {
      emailInput: emailTemplate,
      smsInput: smsTemplate,
      subjectInput: emailTitle,
    };
  };

  const inputs = useMemo(() => {
    if (!!privateDocumentTemplate) {
      return privateDocumentTemplate;
    }
    if (!!publicDocumentTemplate) {
      return getPublicDocumentReturnValue(publicDocumentTemplate);
    }
    return { emailInput: '', smsInput: '', subjectInput: '' };
  }, [publicDocumentTemplate, privateDocumentTemplate]);

  return {
    inputs,
    isLoading,
    error,
  };
};
