import { QueryKeys } from '../../../../queryKeys';
import { getRequest } from '../../../../helpers/apiClient';
import apiRoutes from '../../../../api-routes';
import { useQueryWithLogout } from '../../../../hooks/useQueryWithLogout';

export const usePrivateNotificationTemplateGroups = (params, options) => {
  return useQueryWithLogout(
    [QueryKeys.PRIVATE_NOTIFICATION_TEMPLATE_GROUPS, params],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.PRIVATE_NOTIFICATION_TEMPLATE_GROUPS,
        params,
      });

      return data;
    },
    options,
  );
};
