import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useIdentityDocumentType } from '../../../../hooks/useIdentityDocumentType';

// constants
import {
  RECIPIENT_FILTER_FIELD_NAMES,
  MAP_RECIPIENT_PLACEHOLDERS,
} from '../../../../../../constraints';

// Components
import { SelectIdentityDocumentTypeField } from '../../../../../../components/SelectIdentityDocumentTypeField/SelectIdentityDocumentTypeField';
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { Form as FormModule } from 'billon-ui';

const { FormGroup } = FormModule;

const SelectDocumentNumberField = () => {
  const { recipientsConfig } = useConfigContext();
  const { enableIdentityDocumentTypeSelection } = recipientsConfig || {};
  const { getIdentityDocumentNumberFieldLabelId } = useIdentityDocumentType();
  const { watch } = useFormContext();
  const documentTypeWatcher = watch(RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_TYPE);

  const documentNumberLabelId = useMemo(
    () => getIdentityDocumentNumberFieldLabelId(documentTypeWatcher),
    [documentTypeWatcher],
  );

  return (
    <>
      {enableIdentityDocumentTypeSelection && (
        <FormGroup>
          <SelectIdentityDocumentTypeField />
        </FormGroup>
      )}
      <FormGroup>
        <ControlledTextField
          name={RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_NUMBER}
          label={
            <FormattedMessage
              id={documentNumberLabelId}
              defaultMessage={documentNumberLabelId}
            />
          }
          placeholder={MAP_RECIPIENT_PLACEHOLDERS.PESEL}
        />
      </FormGroup>
    </>
  );
};

export default SelectDocumentNumberField;
