import styled from 'styled-components';

import { ReactComponent as Edit } from '../../../../../../../../ui/Icons/pencil.svg';
import { ReactComponent as Save } from '../../../../../../../../ui/Icons/save.svg';
import { ReactComponent as Close } from '../../../../../../../../ui/Icons/close.svg';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

export const LoadingIcon = styled(Loader)`
  align-self: center;
`;

export const ActionButton = styled.span`
  svg path {
    fill: ${(props) => props.theme.bodyColor} !important;
  }
  svg:hover path {
    fill: ${(props) => props.theme.palette.primary} !important;
  }
  cursor: pointer;
  margin-left: 1rem;
`;

export const ActiveActionButton = styled(ActionButton)`
  svg path {
    fill: ${(props) => props.theme.palette.primary} !important;
  }
  svg:hover path {
    fill: ${(props) => props.theme.palette.primaryLightest} !important;
  }
`;

export const EditIcon = styled(Edit)`
  path {
    fill: ${(props) => props.theme.bodyColor} !important;
  }
  width: 24px;
  height: auto;
  align-self: center;
  margin-bottom: 8px;
  margin-left: 8px;
`;

export const CloseIcon = styled(Close)`
  path {
    fill: ${(props) => props.theme.bodyColor} !important;
  }
  :hover path {
    fill: ${(props) => props.theme.palette.primaryLightest} !important;
  }
  width: 32px;
  height: auto;
  align-self: center;
  cursor: pointer;
`;
