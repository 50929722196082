import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

// constants
import {
  DOCUMENT_FILTER_FIELD_NAMES,
  MAP_IDENTITY_DOCUMENT_TYPE,
} from '../../../../../../../../constraints';

// hooks
import { useConfigContext } from '../../../../../../../Config/hooks/useConfig';
import { useIdentitiesOptions } from '../../../../../../../../hooks/useIdentitiesOptions';

// Components
import { SelectField as AsyncSelectField } from '../../../../../../../../components';
import { FieldLabel } from '../../../../../../../../components/Form';

const SelectIdentityField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.IDENTITY,
  identityDocumentNumber,
  onClear,
  isClearable,
}) => {
  const { formatMessage } = useIntl();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { isDiploma, isTauron } = useConfigContext();
  const { identities, isIdentitiesFetching, onChangeIdentityInput } =
    useIdentitiesOptions('documentNumber', true);

  const identityPlaceHolderId = (() => {
    if (isDiploma) {
      return MAP_IDENTITY_DOCUMENT_TYPE.INDEX_NUMBER;
    }
    return MAP_IDENTITY_DOCUMENT_TYPE.UNASSIGNED;
  })();

  const handleClear = (...args) => {
    onClear && onClear(...args);
  };

  useEffect(() => {
    if (!isIdentitiesFetching && identityDocumentNumber) {
      setValue(
        name,
        identities.find((s) => s.value === identityDocumentNumber),
      );
    }
  }, [
    isIdentitiesFetching,
    identityDocumentNumber,
    setValue,
    identities,
    name,
  ]);

  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Published for" defaultMessage="Published for" />
        {isTauron && (
          <>
            {' ('}
            <FormattedMessage id="Recipient ID" defaultMessage="Recipient ID" />
            {')'}
          </>
        )}
      </FieldLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <AsyncSelectField
            input={{
              ...field,
              onInputChange: (inputText) => onChangeIdentityInput(inputText),
              onClear: handleClear,
            }}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            options={identities}
            isClearable={isClearable}
            isLoading={isIdentitiesFetching}
            placeholder={formatMessage({
              id: identityPlaceHolderId,
            })}
          />
        )}
      />
    </>
  );
};

export default SelectIdentityField;
