import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import useFilters from '../../../../../../hooks/useFilters';
import { useTicker } from '../../../../hooks/useTicker';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';

// constants
import {
  DOCUMENT_STATUS,
  DOCUMENT_PUBLICATION_STATUS,
  UserRoles,
  SIGNING_STATUS,
  SIGNING_ORDER,
  SETTINGS_CONSTANTS,
  DOCUMENT_TYPE,
} from '../../../../../../constraints';
import appRoutes from '../../../../../../app-routes';

// helpers
import { includesValues } from '../../../../../../helpers/includesValues';

// Components
import CustomToolTip from '../../../../../../components/CustomToolTip';
import { ProtectedComponent } from '../../../../../Auth/components';
import * as Styled from './styled';
import { ExecuteButton } from '../ExecuteButton';
import CancelPublicationCounter from '../CancelPublicationCounter';
import { CancelPublicationModal } from '../CancelPublicationModal';
import { Utils, Grid } from 'billon-ui';

const { Button: ButtonModule, Icon } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

export const ActionButtons = ({
  record,
  onDeleteDocument,
  onPublishDocument,
}) => {
  const { push } = useHistory();
  const { enablePublicationDelay, enableSigning } = useConfigContext();
  const { navigateWithNewSearchQuery } = useFilters();

  const { getSettingValueByKey } = useSettingsContext();
  const publicationDelayMinutes = getSettingValueByKey(
    SETTINGS_CONSTANTS.PUBLICATION_DELAY_MINUTES,
  );
  const isPrivateOneStepPublication = getSettingValueByKey(
    SETTINGS_CONSTANTS.PRIVATE_ONE_STEP_PUBLISH,
  );

  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);
  const togglesCancelModal = () => setIsCancelModalOpened(!isCancelModalOpened);

  const {
    publicationStartDate,
    documentPublicationStatus,
    signingStatus,
    status,
    jobId,
    documentType: documentTypeList,
  } = record || {};

  const isTwoStepPreparedToSign =
    !isPrivateOneStepPublication &&
    [documentPublicationStatus, status, signingStatus].includes(
      DOCUMENT_PUBLICATION_STATUS.PREPARED_TO_SIGN,
    );

  const isDeletingAvailable =
    includesValues(
      [documentPublicationStatus, status, signingStatus],
      [
        DOCUMENT_PUBLICATION_STATUS.NOT_EXIST,
        DOCUMENT_PUBLICATION_STATUS.PUBLISHING_ERROR,
        DOCUMENT_PUBLICATION_STATUS.WAITING_FOR_REPUBLISH,
      ],
    ) || isTwoStepPreparedToSign;

  const isPublishingAvailable =
    isTwoStepPreparedToSign &&
    ![SIGNING_ORDER.NO_SIGNATURE, SIGNING_ORDER.RECEIVER_ONLY].includes(
      record?.signingOrder,
    );

  const isDocumentDeprecated = record.status === DOCUMENT_STATUS.DEPRECATED;
  const isMovingToDocumentDetailsAvailable =
    (!enableSigning && record?.status === DOCUMENT_STATUS.CURRENT) ||
    (record?.documentType === DOCUMENT_TYPE.PUBLIC &&
      record?.status === DOCUMENT_STATUS.CURRENT) ||
    (enableSigning &&
      [
        SIGNING_STATUS.WAITING_FOR_RECEIVER,
        SIGNING_STATUS.REJECTED,
        SIGNING_STATUS.PUBLISHING_OK,
      ].includes(record?.signingStatus)) ||
    isDocumentDeprecated;

  const handleDeleteDocument = () => onDeleteDocument(record);

  const handlePublishDocument = () => {
    navigateWithNewSearchQuery(
      { documentTypeList, jobId, signingStep: true },
      appRoutes.PUBLISH_DOCUMENT,
    );
  };

  const handleCancelPublication = () => {
    setIsCancelModalOpened(true);
  };

  const onMoveToDocumentDetails = () =>
    isDocumentDeprecated
      ? push(`/document/${record.documentBlockchainAddress}`)
      : push(`/document/${record?.documentBlockchainAddress ?? record?.jobId}`);

  const { end } = useTicker(publicationStartDate, publicationDelayMinutes);

  return (
    <Styled.Row>
      {enablePublicationDelay &&
        publicationDelayMinutes > 0 &&
        documentPublicationStatus ===
          DOCUMENT_PUBLICATION_STATUS.PREPARED_TO_SIGN &&
        !end && (
          <Styled.Col md={12}>
            <Row>
              <Col md={6}>
                <FormattedMessage
                  id="Document publication starts in"
                  defaultMessage="Document publication starts in"
                />{' '}
                <CustomToolTip
                  id={`cancelPublicationToolTip-${record?.jobId}-${record?.documentBlockchainAddress}`}
                  trigger={<Icon color="grey" name="info-circle" />}
                >
                  <Styled.ToolTipContentWrapper>
                    <FormattedMessage
                      id="If no action is taken, the document will be automatically published."
                      defaultMessage="If no action is taken, the document will be automatically published."
                    />
                  </Styled.ToolTipContentWrapper>
                </CustomToolTip>
                <br />
                <CancelPublicationCounter
                  publicationStartDate={publicationStartDate}
                  publicationDelayMinutes={publicationDelayMinutes}
                />
              </Col>
              <ExecuteButton onClick={handleCancelPublication}>
                <FormattedMessage
                  id="Cancel publication"
                  defaultMessage="Cancel publication"
                />
              </ExecuteButton>
            </Row>
            <CancelPublicationModal
              isOpen={isCancelModalOpened}
              toggle={togglesCancelModal}
              document={record}
            />
          </Styled.Col>
        )}
      {isDeletingAvailable && (
        <ExecuteButton onClick={handleDeleteDocument}>
          <FormattedMessage
            id="Discard document"
            defaultMessage="Discard document"
          />
        </ExecuteButton>
      )}
      {isPublishingAvailable && (
        <ProtectedComponent
          roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
          render={false}
        >
          <Styled.Col md={6}>
            <Button outline onClick={handlePublishDocument} block>
              <FormattedMessage
                id="Publish document"
                defaultMessage="Publish document"
              />
            </Button>
          </Styled.Col>
        </ProtectedComponent>
      )}
      {isMovingToDocumentDetailsAvailable && (
        <Styled.Col md={6}>
          <Button outline onClick={onMoveToDocumentDetails} block>
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
          </Button>
        </Styled.Col>
      )}
    </Styled.Row>
  );
};
