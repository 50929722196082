import base from '../../base';
import { CUSTOMER } from '../../../constraints';

export default {
  ...base,
  backendUrl: 'https://apipsk.trusted-document.com',
  enablePrivate: true,
  customer: CUSTOMER.DIPLOMA,
  manageRecipients: true,
  // observer: true,
  // activeDelivery: false,
  theme: {
    ...base.theme,
    linkColor: '#b51f2c',
    linkHoverColor: '#901823',
    paginationColor: '#b51f2c',
    paginationHoverColor: '#901823',
    paginationActiveColor: '#901823',

    palette: {
      ...base.theme.palette,
      primary: '#b51f2c',
      primaryLight: '#da8f95',
      primaryLightest: '#f7e8e9',
      primaryDark: '#901823',
    },
  },
};
