import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  overflow-x: auto;
`;

export const Row = styled.div`
  display: flex;
  ${({ bold }) => bold && `font-weight: 700;`}
`;

export const FirstRow = styled(Row)`
  border-bottom: 1px solid #abc4c6;
`;

export const Cell = styled.div`
  font-size: 12px;
  display: flex;

  @media (min-width: 1200px) {
    flex: 0 0 200px;
  }

  @media (min-width: 1400px) {
    flex: 0 0 250px;
  }

  @media (max-width: 1199px) {
    flex: 0 0 140px;
  }

  @media (max-width: 800px) {
    flex: 0 0 100px;
  }

  padding: 0.2rem;
  align-items: center;
  justify-content: center;

  .formatted-switch {
    width: auto;
  }
`;

export const FirstCell = styled(Cell)`
  flex: 0 0 20px;
  padding: 0px;
`;

export const NameCell = styled(Cell)`
  flex: 1 0 auto;
  justify-content: flex-start;
  min-width: 20px;
  padding-left: 0px;
`;
