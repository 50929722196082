import React from 'react';
import * as Styled from './styled';

export const ExecuteButton = ({ onClick, children }) => (
  <Styled.Col md={6}>
    <Styled.RedButton outline block onClick={onClick}>
      {children}
    </Styled.RedButton>
  </Styled.Col>
);
