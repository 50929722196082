import React from 'react';
import { PageLayout, Statistics } from '../../components';

const StatisticsPage = () => {
  return (
    <PageLayout>
      <Statistics />
    </PageLayout>
  );
};

export default StatisticsPage;
