import React from 'react';
import { HelpCard } from '../../components';
import ContentContainerGrey from '../../../../ui/ContentContainerGrey';
import { useSettingsContext } from '../../../Settings/hooks/useSettings';
import { SETTINGS_CONSTANTS } from '../../../../constraints';
import { useHistory } from 'react-router-dom';

const HelpPage = () => {
  const history = useHistory();
  const { getSettingValueByKey } = useSettingsContext();
  const supportEmail = getSettingValueByKey(SETTINGS_CONSTANTS.SUPPORT_EMAIL);

  const onBack = () => {
    history.goBack();
  };

  return (
    <ContentContainerGrey>
      <br />
      <HelpCard supportEmail={supportEmail} onBack={onBack} />
    </ContentContainerGrey>
  );
};

export default HelpPage;
