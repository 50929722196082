import React from 'react';
import { StatusTooltip } from '../../../../components';
import StatusWithIcon from '../../../../ui/StatusWithIcon';

const RecipientStatusToolTip = ({ label, content, id }) => {
  return (
    <StatusWithIcon label={label}>
      <StatusTooltip label={content} id={id} delay={false} />
    </StatusWithIcon>
  );
};

export default RecipientStatusToolTip;
