import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomConfirmModal from '../../../../../../ui/CustomConfirmModal';
import { Utils } from 'billon-ui';

const { Text } = Utils;

const ForcedPasswordSetModal = ({
  onSubmit,
  onClose,
  isOpen,
  isSubmitting,
}) => {
  return (
    <CustomConfirmModal
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      isOpen={isOpen}
      toggle={onClose}
      title={
        <FormattedMessage
          id="Force password change"
          defaultMessage="Force password change"
        />
      }
    >
      <Text>
        <FormattedMessage
          id="Are you sure you want to force a password change?"
          defaultMessage="Are you sure you want to force a password change?"
        />
      </Text>
    </CustomConfirmModal>
  );
};

export default ForcedPasswordSetModal;
