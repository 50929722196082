import React from 'react';
import * as Styled from './styled';

export const UniCardRowCol = ({ children }) => {
  return (
    <Styled.ContentRow>
      <Styled.ContentCol
        sm={{ size: 12, offset: 0 }}
        md={{ size: 9, offset: 0 }}
        lg={{ size: 5, offset: 0 }}
      >
        {children}
      </Styled.ContentCol>
    </Styled.ContentRow>
  );
};

export const UniCardContent = ({ children }) => {
  return (
    <Styled.ContentContainerGrey fluid>
      <UniCardRowCol>{children}</UniCardRowCol>
    </Styled.ContentContainerGrey>
  );
};

export const CenteredContent = ({ children }) => {
  return (
    <Styled.ContentContainerCentered fluid>
      {children}
    </Styled.ContentContainerCentered>
  );
};

export const DefaultContentCol = ({ children }) => (
  <Styled.ContentCol
    sm={{ size: 12, offset: 0 }}
    md={{ size: 9, offset: 0 }}
    lg={{ size: 5, offset: 0 }}
    xl={{ size: 4, offset: 0 }}
  >
    {children}
  </Styled.ContentCol>
);
