import styled from 'styled-components';

export const FieldFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`;

export const FC = styled.div`
  align-self: center;
`;

export const FC2 = styled.div`
  padding-top: 2px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  margin-right: 17px;
`;
