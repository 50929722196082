import React from 'react';
import { useConfigContext } from '../../../../Config/hooks/useConfig';
import { getCustomerProperties } from './getCustomerProperties';

// Components
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const RecipientManagerTemplate = ({ children }) => {
  const { customer } = useConfigContext();
  const customerProperties = getCustomerProperties(customer);

  return (
    <>
      <Styled.Title>
        <FormattedMessage
          id={customerProperties.manageTitleId}
          defaultMessage={customerProperties.manageTitleId}
        />
      </Styled.Title>
      <Styled.SubTitle>
        <Styled.ZeroParagraph>
          <FormattedMessage
            id={customerProperties.subtitleMessageId}
            defaultMessage={customerProperties.subtitleMessageId}
          />
          {'.'}
        </Styled.ZeroParagraph>
        <Styled.ZeroParagraph>
          <Styled.ActionAnchor
            href={customerProperties.templateHref}
            target="_blank"
            download={customerProperties.templateFileName}
          >
            <FormattedMessage id="Download" defaultMessage="Download" />{' '}
          </Styled.ActionAnchor>
          <FormattedMessage
            id={customerProperties.downloadTemplateMessageId}
            defaultMessage={customerProperties.downloadTemplateMessageId}
          />
          {'.'}
        </Styled.ZeroParagraph>
      </Styled.SubTitle>
      {children}
    </>
  );
};

export default RecipientManagerTemplate;
