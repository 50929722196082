import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  'documents.read': (
    <FormattedMessage
      id="Documents: Read only access"
      defaultMessage="Documents: Read only access"
    />
  ),
  'documents.manage': (
    <FormattedMessage
      id="Documents: Ability to manage"
      defaultMessage="Documents: Ability to manage"
    />
  ),
  'categories.read': (
    <FormattedMessage
      id="Categories: Read only access"
      defaultMessage="Categories: Read only access"
    />
  ),
  'categories.manage': (
    <FormattedMessage
      id="Categories: Ability to manage"
      defaultMessage="Categories: Ability to manage"
    />
  ),
  'users.manage': (
    <FormattedMessage
      id="Users: Ability to manage"
      defaultMessage="Users: Ability to manage"
    />
  ),
  // 'settings.manage': (
  //   <FormattedMessage
  //     id="Settings: Ability to manage"
  //     defaultMessage="Settings: Ability to manage"
  //   />
  // ),
  'recipients.read': (
    <FormattedMessage
      id="Recipients: Read only access"
      defaultMessage="Recipients: Read only access"
    />
  ),
  'recipients.manage': (
    <FormattedMessage
      id="Recipients: Ability to manage"
      defaultMessage="Recipients: Ability to manage"
    />
  ),
};
