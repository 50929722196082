import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../api-routes';
import { postRequest } from '../../../helpers/apiClient';

const transformFromFileParams = (params) => {
  const { file, purpose, publicDocumentType } = params;
  const formData = new FormData();

  formData.append('file', file[0]);
  formData.append('purpose', purpose);
  if (!!publicDocumentType) {
    formData.append('publicDocumentType', publicDocumentType);
  }

  return formData;
};

export const useValidateRecipients = (options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: createData } = await postRequest({
      url: apiRoutes.RECIPIENTS_FILE_VALIDATE,
      data: transformFromFileParams(data),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return createData;
  }, options);
