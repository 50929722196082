import React, { useState } from 'react';
import { useCategoriesOptions } from '../../hooks/useCategoriesOptions';
import { useConfigContext } from '../../modules/Config/hooks/useConfig';
import { UserRoles, CUSTOMER } from '../../constraints';

// components
import { FormattedMessage } from 'react-intl';
import { ProtectedComponent } from '../../modules/Auth/components';
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { CreateForm as CategoryCreateFrom } from '../../modules/Categories/components/CreateForm';
import * as Styled from './styled';
import { Modal as ModalModule, Utils } from 'billon-ui';

const { CreateModal } = ModalModule;
const { Text } = Utils;

export const SelectCategoryField = ({
  createCategoryEnabled,
  disabled,
  name = 'category',
}) => {
  const { customer } = useConfigContext();

  const { categories, isCategoriesFetching, onChangeCategoryInput } =
    useCategoriesOptions(true, true);

  const [isCreateCategoryModalOpen, setCreateCategoryModalOpen] =
    useState(false);
  const [isSuccessCreateCategoryModalOpen, setSuccessCreateCategoryModalOpen] =
    useState(false);

  const toggleCreateCategoryModal = () => {
    setCreateCategoryModalOpen(!isCreateCategoryModalOpen);
  };

  const onCreateCategoryFinished = () => {
    toggleCreateCategoryModal();
    toggleSuccessModalCategoryClose();
  };

  const toggleSuccessModalCategoryClose = () => {
    setSuccessCreateCategoryModalOpen(!isSuccessCreateCategoryModalOpen);
  };

  const CreateCategoryPermissionWrapper = ({ children }) => {
    if (customer === CUSTOMER.TAURON) {
      return (
        <ProtectedComponent
          roles={[UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
          render={false}
        >
          {children}
        </ProtectedComponent>
      );
    } else {
      return (
        <ProtectedComponent
          roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
          render={false}
        >
          {children}
        </ProtectedComponent>
      );
    }
  };

  return (
    <>
      <Text as="h6" fontSize="12px" fontWeight="600">
        <FormattedMessage id="Category" defaultMessage="Category" />
      </Text>
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          onInputChange={onChangeCategoryInput}
          options={categories}
          disabled={disabled}
          isLoading={isCategoriesFetching}
        />
        {createCategoryEnabled && (
          <CreateCategoryPermissionWrapper>
            <Styled.IconButton
              icon="folder-plus"
              onClick={toggleCreateCategoryModal}
            />
          </CreateCategoryPermissionWrapper>
        )}
      </Styled.FormGroup>
      <CreateModal
        isOpen={isCreateCategoryModalOpen}
        onSuccess={onCreateCategoryFinished}
        onCancel={toggleCreateCategoryModal}
        initialValues={{}}
        createMessage={
          <FormattedMessage
            id="Adding category"
            defaultMessage="Adding category"
          />
        }
      >
        <CategoryCreateFrom
          onSuccess={onCreateCategoryFinished}
          toggle={onCreateCategoryFinished}
        />
      </CreateModal>
    </>
  );
};
