import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import {
  RECIPIENT_FILTER_FIELD_NAMES,
  MAP_RECIPIENT_PLACEHOLDERS,
  MAP_RECIPIENT_FIELD_NAMES,
} from '../../../../../../constraints';

import { ControlledTextField } from '../../../../../../components/ControlledTextField';

import { Form as FormModule } from 'billon-ui';
const { FormGroup } = FormModule;

const TextNamesField = () => {
  const { recipientsConfig } = useConfigContext();
  const { isFullName } = recipientsConfig || {};

  if (isFullName) {
    return (
      <FormGroup>
        <ControlledTextField
          name={RECIPIENT_FILTER_FIELD_NAMES.NAME}
          label={
            <FormattedMessage
              id={MAP_RECIPIENT_FIELD_NAMES.NAME}
              defaultMessage={MAP_RECIPIENT_FIELD_NAMES.NAME}
            />
          }
          placeholder={MAP_RECIPIENT_PLACEHOLDERS.NAME}
        />
      </FormGroup>
    );
  }

  return (
    <>
      <FormGroup>
        <ControlledTextField
          name={RECIPIENT_FILTER_FIELD_NAMES.FIRST_NAME}
          label={
            <FormattedMessage
              id={MAP_RECIPIENT_FIELD_NAMES.FIRST_NAME}
              defaultMessage={MAP_RECIPIENT_FIELD_NAMES.FIRST_NAME}
            />
          }
          placeholder={MAP_RECIPIENT_PLACEHOLDERS.FIRST_NAME}
        />
      </FormGroup>
      <FormGroup>
        <ControlledTextField
          name={RECIPIENT_FILTER_FIELD_NAMES.LAST_NAME}
          label={
            <FormattedMessage
              id={MAP_RECIPIENT_FIELD_NAMES.LAST_NAME}
              defaultMessage={MAP_RECIPIENT_FIELD_NAMES.LAST_NAME}
            />
          }
          placeholder={MAP_RECIPIENT_PLACEHOLDERS.LAST_NAME}
        />
      </FormGroup>
    </>
  );
};

export default TextNamesField;
