import styled from 'styled-components';

export const Content = styled.div`
  padding-bottom: 32px;
  padding-top: 32px;
  text-align: center;
`;

export const Title = styled.span`
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;
