import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const Title = styled(Text)`
  font-size: 2.5rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const SubTitle = styled(Text)`
  font-size: 13px;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin-bottom: 2rem;
`;

export const ZeroParagraph = styled.p`
  margin: 0px;
`;

export const ActionAnchor = styled.a`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;
