import React, { useState } from 'react';

// utils
import { FormattedMessage } from 'react-intl';
import appRoutes from '../../../../app-routes';
import { QueryKeys } from '../../../../queryKeys';
import { UserRoles, USER_ACTIONS } from '../../../../constraints';

// hooks
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSingleUser } from '../../hooks/useSingleUser';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// components
import { SuccessModal as CustomSuccessModal } from '../../../../components/SuccessModal';
import { Layout as LayoutModule, Modal as ModalModule, Utils } from 'billon-ui';
import { ProtectedComponent } from '../../../../modules/Auth/components';
import UserDetails from '../../components/UserDetails';
import UserHistoryTable from '../../components/UserHistoryTable';
import CreateForm from '../../components/CreateForm';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { ContentContainer, Content } = ContentModule;
const { CreateModal } = ModalModule;

const UserDetailsPage = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const history = useHistory();
  const { customer, config } = useConfigContext();
  const { enablePrivate } = config;

  const { params } = useRouteMatch(appRoutes.USER_DETAILS);
  const id = params?.id;
  const { data: user, isLoading } = useSingleUser({ id: id });

  const handleSuccess = () => {
    setCreateModalOpen(false);
    setSuccessModalOpen(true);
    queryClient.invalidateQueries([QueryKeys.SINGLE_USER, id]);
  };

  const handleFailure = () => {};

  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const handleOnBack = () => {
    history.push(appRoutes.USERS_BLANK);
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <ContentContainer fluid>
      <Content fluid>
        <UserDetails
          handleCreate={handleOpenCreateModal}
          user={user}
          customer={customer}
          onBackClick={handleOnBack}
          backButtonLabel={
            <FormattedMessage
              id="Administrators"
              defaultMessage="Administrators"
            />
          }
        />
        <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
          <UserHistoryTable historyRecords={user?.history} />
        </ProtectedComponent>

        <CreateModal
          isOpen={createModalOpen}
          onSuccess={handleSuccess}
          onCancel={handleCloseCreateModal}
          initialValues={user}
          editMessage={
            <FormattedMessage
              id="Editing administrator"
              defaultMessage="Editing administrator"
            />
          }
        >
          <CreateForm
            mode={USER_ACTIONS.UPDATE}
            initialValues={user}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            enablePrivate={enablePrivate}
            customer={customer}
          />
        </CreateModal>
        <CustomSuccessModal
          isOpen={successModalOpen}
          toggle={handleCloseSuccessModal}
          onClose={handleCloseSuccessModal}
          closeIconEnabled={false}
        >
          <FormattedMessage
            id={'Administrator has been successfully saved.'}
            defaultMessage={'Administrator has been successfully saved.'}
          />
        </CustomSuccessModal>
      </Content>
    </ContentContainer>
  );
};

export default UserDetailsPage;
