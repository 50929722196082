import styled from 'styled-components';
import { Grid } from 'billon-ui';
const { Col } = Grid;

export const ModalTitle = styled.span`
  text-align: left;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const TitleContainer = styled(Col)`
  align-self: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;
