import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { BackButton: BillonBackButton } = ButtonModule;

export const CardBodyWrapper = styled.div`
  padding-top: 35px;
`;

export const BackButton = styled(BillonBackButton)`
  padding: 0;
  margin-top: 22px;
`;
