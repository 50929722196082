import React from 'react';
import { useParams } from 'react-router-dom';
import useFilters from '../../../../hooks/useFilters';
import { useSingleDocument } from '../../hooks/useSingleDocument';
import { PublicationPreviewModal } from '../../components/PublicationPreviewModal/PublicationPreviewModal';

const PublicationPreviewPage = () => {
  const { id } = useParams();
  const { filters, navigateWithNewSearchQuery } = useFilters();
  const { data: document, isLoading, isError } = useSingleDocument({ id: id });

  const handleClose = () => {
    navigateWithNewSearchQuery({ filters }, `/documents`);
  };

  if (isLoading || isError || !document) {
    return null;
  }

  return (
    <PublicationPreviewModal isOpen document={document} onClose={handleClose} />
  );
};

export default PublicationPreviewPage;
