import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { FormattedMessage } from 'react-intl';

// utils
import {
  SETTINGS_CONSTANTS,
  USER_STATUSES,
  NOTIFICATION_TYPE,
} from '../../../../../../constraints';
import { QueryKeys } from '../../../../../../queryKeys';
import appRoutes from '../../../../../../app-routes';

// utils
import { getExpirationDays } from '../../../../../../modules/Users/heplers';
import { momentInTimezoneFormatter } from '../../../../../../formatters';
import WithValueMessage from '../../../../../../helpers/WithValueMessage';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';
import { usePasswordExpired } from '../../../../../../modules/Users/hooks/usePasswordExpired';
import { useUsersNotification } from '../../../../hooks/useUsersNotification';

// components
import { SuccessModal as CustomSuccessModal } from '../../../../../../components/SuccessModal';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';
import { ErrorModal } from '../../../../../../components/ErrorModal';
import { LoadingActionButton } from '../../../../../../components';
import ForcedPasswordSetModal from '../ForcedPasswordSetModal';
import * as Styled from './styled';
import { Layout as LayoutModule, Grid } from 'billon-ui';

const { Content: ContentModule } = LayoutModule;
const { ErrorContent } = ContentModule;
const { Row } = Grid;

const PasswordManager = ({ user }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { usersConfig } = useConfigContext();
  const { enableAdministrativePasswordChange } = usersConfig;
  const { getSettingValueByKey } = useSettingsContext();

  const [isForcedPasswdModalOpen, setIsForcedPasswdModalOpen] = useState(false);
  const [expSuccessModal, setExpSuccessModal] = useState(false);
  const [expErrorModal, setExpErrorModal] = useState(false);
  const [notifyingType, setNotifyingType] = useState();
  const [notifySuccessModal, setNotifySuccessModal] = useState(false);
  const [notifyErrorModal, setNotifyErrorModal] = useState(false);

  const isManagerHeaderDisplayed =
    [
      USER_STATUSES.REGISTERED,
      USER_STATUSES.PENDING,
      USER_STATUSES.SHELL,
    ].includes(user?.status) || enableAdministrativePasswordChange;

  const daysToExpiration = useMemo(() => {
    const passwordExpirationTimeInDays = getSettingValueByKey(
      SETTINGS_CONSTANTS.PASSWORD_EXPIRATION_TIME,
    );
    return getExpirationDays(
      user.passwordUpdatedAt,
      passwordExpirationTimeInDays,
    );
  }, [user, getSettingValueByKey]);

  const invalidateUser = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_USER, user.id]);
  };

  const onPasswordChange = () => {
    if (enableAdministrativePasswordChange && user.id) {
      history.push(appRoutes.ADMIN_PASSWORD_CHANGE.replace(':id', user.id));
    }
  };

  const onPasswordSet = () => {
    if (enableAdministrativePasswordChange && user.id) {
      history.push(appRoutes.TEMPORATY_PASSWORD_SET.replace(':id', user.id));
    }
  };

  const onNotifySuccess = () => {
    setNotifyingType();
    setNotifySuccessModal(true);
  };

  const onNotifyError = () => {
    setNotifyingType();
    setNotifyErrorModal(true);
  };

  const onNotifySuccessClose = () => {
    setNotifySuccessModal(false);
    invalidateUser();
  };

  const onNotifyErrorClose = () => {
    setNotifyErrorModal(false);
  };

  const { isLoading: isNotifing } = useUsersNotification(
    {
      id: user.id,
      type: notifyingType,
    },
    {
      enabled: notifyingType ? true : false,
      onSuccess: () => onNotifySuccess(),
      onError: () => onNotifyError(),
    },
  );

  const onNotification = (operationType) => {
    setNotifyingType(operationType);
  };

  const onToggleForcedPswdSetModal = () => {
    setIsForcedPasswdModalOpen(!isForcedPasswdModalOpen);
  };

  const onForcePasswordSet = () => {
    expirePassword({ id: user.id });
  };

  const handleExpSuccessClose = () => {
    setIsForcedPasswdModalOpen(false);
    setExpSuccessModal(false);
    invalidateUser();
  };

  const handleExpFailureClose = () => {
    setExpErrorModal(false);
  };

  const handleEnforcePasswordExpSuccess = () => {
    setExpSuccessModal(true);
  };

  const handleEnforcePasswordExpFailure = () => {
    setExpErrorModal(true);
  };

  const { mutate: expirePassword, isLoading: isExpiring } = usePasswordExpired({
    onSuccess: handleEnforcePasswordExpSuccess,
    onError: handleEnforcePasswordExpFailure,
  });

  return (
    <>
      <Styled.SectionHeaderContainer>
        <Styled.TextColumnContainer>
          {isManagerHeaderDisplayed && (
            <Styled.SectionHeaderLabel>
              <FormattedMessage
                id="Password change"
                defaultMessage="Password change"
              />
              {':'}
            </Styled.SectionHeaderLabel>
          )}
        </Styled.TextColumnContainer>
        <Styled.TextColumnContainer>
          {[USER_STATUSES.REGISTERED, USER_STATUSES.PENDING].includes(
            user.status,
          ) && (
            <Styled.ExpirationLabel>
              <WithValueMessage
                messageId={'Password expires in {n} days'}
                name="n"
                value={daysToExpiration}
              />
            </Styled.ExpirationLabel>
          )}
        </Styled.TextColumnContainer>
      </Styled.SectionHeaderContainer>
      {!enableAdministrativePasswordChange && (
        <>
          {user.status === USER_STATUSES.PENDING && (
            <>
              <Styled.ColumnSectionText>
                <FormattedMessage
                  id={'Invitation to set password has been sent on'}
                  defaultMessage={'Invitation to set password has been sent on'}
                />{' '}
                <Styled.Underlined>
                  {momentInTimezoneFormatter(user.lastNotificationAt)}
                </Styled.Underlined>
              </Styled.ColumnSectionText>
              <Row>
                <BtnCol lg={6}>
                  <LoadingActionButton
                    isLoading={
                      isNotifing &&
                      notifyingType === NOTIFICATION_TYPE.SET_PASSWORD
                    }
                    onClick={() =>
                      onNotification(NOTIFICATION_TYPE.SET_PASSWORD)
                    }
                    outline={true}
                  >
                    <FormattedMessage
                      id="Resend invitation"
                      defaultMessage="Resend invitation"
                    />
                  </LoadingActionButton>
                </BtnCol>
              </Row>
            </>
          )}
          {user.status === USER_STATUSES.SHELL && (
            <>
              <Styled.ColumnSectionText>
                <FormattedMessage
                  id="Send invitation to change password"
                  defaultMessage="Send invitation to change password"
                />
              </Styled.ColumnSectionText>
              <Row>
                <BtnCol lg={6}>
                  <LoadingActionButton
                    isLoading={
                      isNotifing &&
                      notifyingType === NOTIFICATION_TYPE.SET_PASSWORD
                    }
                    onClick={() =>
                      onNotification(NOTIFICATION_TYPE.SET_PASSWORD)
                    }
                    outline={true}
                  >
                    <FormattedMessage id="Send" defaultMessage="Send" />
                  </LoadingActionButton>
                </BtnCol>
              </Row>
            </>
          )}
          {user.status === USER_STATUSES.REGISTERED && (
            <>
              <Styled.ColumnSectionText>
                <FormattedMessage
                  id="Force password change"
                  defaultMessage="Force password change"
                />
              </Styled.ColumnSectionText>
              <Row>
                <BtnCol lg={6}>
                  <LoadingActionButton
                    isLoading={
                      isNotifing &&
                      notifyingType === NOTIFICATION_TYPE.PASSWORD_EXPIRED
                    }
                    onClick={onToggleForcedPswdSetModal}
                    outline={true}
                  >
                    <FormattedMessage id="Send" defaultMessage="Send" />
                  </LoadingActionButton>
                </BtnCol>
              </Row>
            </>
          )}
        </>
      )}
      {enableAdministrativePasswordChange && (
        <Row>
          {[USER_STATUSES.SHELL].includes(user.status) && (
            <BtnCol lg={6}>
              <LoadingActionButton onClick={onPasswordSet} outline={true}>
                <FormattedMessage
                  id="Set password"
                  defaultMessage="Set password"
                />
              </LoadingActionButton>
            </BtnCol>
          )}
          {user.status !== USER_STATUSES.SHELL && (
            <BtnCol lg={6}>
              <LoadingActionButton
                lg={6}
                onClick={onPasswordChange}
                outline={true}
              >
                <FormattedMessage
                  id="Password change"
                  defaultMessage="Password change"
                />
              </LoadingActionButton>
            </BtnCol>
          )}
          {user.status === USER_STATUSES.REGISTERED && (
            <BtnCol lg={6}>
              <LoadingActionButton
                lg={6}
                onClick={onToggleForcedPswdSetModal}
                outline={true}
              >
                <FormattedMessage
                  id="Force password change"
                  defaultMessage="Force password change"
                />
              </LoadingActionButton>
            </BtnCol>
          )}
        </Row>
      )}
      <ForcedPasswordSetModal
        isOpen={isForcedPasswdModalOpen}
        onSubmit={onForcePasswordSet}
        onClose={onToggleForcedPswdSetModal}
        isSubmitting={isExpiring}
      />
      <CustomSuccessModal
        isOpen={expSuccessModal}
        toggle={handleExpSuccessClose}
        onClose={handleExpSuccessClose}
        closeIconEnabled={false}
      >
        <FormattedMessage
          id={'Password change forced'}
          defaultMessage={'Password change forced'}
        />
      </CustomSuccessModal>
      <ErrorModal
        isOpen={expErrorModal}
        toggle={handleExpFailureClose}
        onClose={handleExpFailureClose}
        closeIconEnabled={false}
      >
        <ErrorContent>
          <FormattedMessage
            id={'Error forcing password change'}
            defaultMessage={'Error forcing password change'}
          />
        </ErrorContent>
      </ErrorModal>
      <CustomSuccessModal
        isOpen={notifySuccessModal}
        toggle={onNotifySuccessClose}
        onClose={onNotifySuccessClose}
        closeIconEnabled={false}
      >
        <FormattedMessage
          id="Message has been sent"
          defaultMessage="Message has been sent"
        />
      </CustomSuccessModal>
      <ErrorModal
        isOpen={notifyErrorModal}
        toggle={onNotifyErrorClose}
        onClose={onNotifyErrorClose}
        closeIconEnabled={false}
      >
        <ErrorContent>
          <FormattedMessage
            id="Error while sending a message"
            defaultMessage="Error while sending a message"
          />
        </ErrorContent>
      </ErrorModal>
    </>
  );
};

export default PasswordManager;
