import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

export const useTenantList = (options) =>
  useQueryWithLogout(
    [QueryKeys.TENANT_LIST],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.TENANT,
      });

      return data;
    },
    options,
  );
