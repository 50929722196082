import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../CustomToolTip';
import * as Styled from './styled';

export const ActiveDeliveryToolTip = () => {
  return (
    <CustomToolTip
      id="CustomToolTip-ActiveDeliveryToolTip"
      trigger={<Styled.HeaderIcon name="info-circle" />}
    >
      <Styled.ToolTipContentWrapper>
        <FormattedMessage
          id="Confirmation of receipt also includes confirmations that notifications have been sent"
          defaultMessage="Confirmation of receipt also includes confirmations that notifications have been sent"
        />
        {'. '}{' '}
        <FormattedMessage
          id="On the other hand, acknowledgement of reading the document also includes confirmation of sending and receiving it"
          defaultMessage="On the other hand, acknowledgement of reading the document also includes confirmation of sending and receiving it"
        />
        {'.'}
      </Styled.ToolTipContentWrapper>
    </CustomToolTip>
  );
};
