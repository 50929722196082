import styled from 'styled-components';
import { Modal as ModalModule } from 'billon-ui';

const { CreateModal: CM } = ModalModule;

export const CreateModal = styled(CM)`
  max-width: 600px;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: #011627;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 12px;
`;

export const TitleRefreshWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
