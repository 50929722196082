import React from 'react';
import { Form as FormModule, Utils } from 'billon-ui';
import { useUpdateIdentity } from '../../hooks/useUpdateIdentity';
import { ProtectedComponent } from '../../../Auth/components';
import { UserRoles } from '../../../../constraints';

const { Loader } = Utils.Loader;
const { SwitchField } = FormModule;

const IsActiveSwitch = ({ value, row, onSuccess }) => {
  const { mutate: editIdentity, isLoading } = useUpdateIdentity({
    onSuccess: onSuccess,
  });

  if (isLoading) {
    return <Loader width={40} />;
  }

  return (
    <>
      <ProtectedComponent
        roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
        render={false}
      >
        <SwitchField
          input={{
            value,
            name: `isActive[${row.id}]`,
            checked: value,
            onClick: () =>
              editIdentity({
                id: row.id,
                data: {
                  isActive: !row.isActive,
                },
              }),
          }}
          slim
          type="checkbox"
          meta={{}}
        />
      </ProtectedComponent>
      <ProtectedComponent
        roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
        reverse={true}
        render={false}
      >
        <SwitchField
          input={{
            value,
            name: `isActive[${row.id}]`,
            checked: value,
          }}
          slim
          type="checkbox"
          meta={{}}
        />
      </ProtectedComponent>
    </>
  );
};

export default IsActiveSwitch;
