import styled from 'styled-components';
import { Utils, Grid } from 'billon-ui';
const { Button, ButtonLoader } = Utils.Button;
const { Col } = Grid;

export const FullHeightBtn = styled(Button)`
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => props.inActive && '#ffffff !important'};
  color: ${(props) => props.inActive && '#bebebe !important'};
  border: ${(props) => props.inActive && '1px solid #bebebe !important'};
  color: ${({ theme, dangerColor }) =>
    dangerColor && theme.palette.error} !important;
  border-color: ${({ theme, dangerColor }) =>
    dangerColor && theme.palette.error} !important;
`;

export const FullHeightButtonLoader = styled(ButtonLoader)`
  height: 100%;
`;

export const BtnCol = styled(Col)`
  margin-bottom: 16px;

  @media (max-width: 1400px) {
    min-width: ${({ autoSizeDisabled }) =>
      !autoSizeDisabled && '100% !important'};
    max-width: ${({ autoSizeDisabled }) =>
      !autoSizeDisabled && '100% !important'};
  }

  @media (min-width: 1700px) {
    width: ${({ oneThirdSplit }) => oneThirdSplit && '33.3%'};
  }
`;
