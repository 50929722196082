import apiRoutes from '../../../api-routes';
import { getRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

export const useResetPassword = (options) => {
  return useMutationWithLogout(async (email) => {
    const { data } = await getRequest({
      url: apiRoutes.RESET_PASSWORD,
      params: email,
    });
    return data;
  }, options);
};
