import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { USER_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// Components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { FieldLabel } from '../../../../../../components/Form';

const TextUserNameField = ({ name = USER_FILTER_FIELD_NAMES.USERNAME }) => {
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Login" defaultMessage="Login" />
      </FieldLabel>
      <ControlledTextField name={name} />
    </>
  );
};

export default TextUserNameField;
