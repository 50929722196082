import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const BarToolTip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <Styled.Tooltip>
        <p>
          <FormattedMessage
            id="% of disc space usage"
            defaultMessage="% of disc space usage"
          />
          : {payload[0]?.value}%
        </p>

        {payload.length > 0 && (
          <p>
            <FormattedMessage
              id="total resources available"
              defaultMessage="total resources available"
            />
            : {payload[0]?.payload?.availableMemory} GB
          </p>
        )}
      </Styled.Tooltip>
    );
  }

  return null;
};

export default BarToolTip;
