/* global sessionStorage */
import { createReducer } from 'redux-act';
import * as actions from './actions';

import { UserRoles } from 'js/userRoles';

const token = sessionStorage.getItem('token');

export default createReducer(
  {
    [actions.requestDecodeToken]: (state) => ({
      ...state,
      isFetching: true,
      isVerifying: true,
      isVerified: false,
    }),

    [actions.receiveTokenSuccess]: (state, payload) => ({
      ...state,
      isVerified: true,
      isVerifying: false,
      isFetching: false,
      user: payload.user,
    }),

    [actions.receiveTokenFailure]: (state, payload) => ({
      ...state,
      isVerified: false,
      isVerifying: false,
      isFetching: false,
      user: payload.user,
    }),

    [actions.receiveAllowed]: (state) => ({
      ...state,
      isForbidden: false,
      isFetching: false,
    }),
    [actions.receiveForbidden]: (state) => ({
      ...state,
      isForbidden: true,
      isVerified: false,
      isFetching: false,
    }),
    [actions.requestAuthorizationByToken]: (state) => ({
      ...state,
      isFetching: true,
      payload: null,
    }),
    [actions.receiveAuthorizationByTokenSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      didFetched: true,
      payload,
    }),
    [actions.requestAuthentication]: (state) => ({
      ...state,
      isFetching: true,
      payload: null,
    }),
    [actions.receiveAuthenticationSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      isAuthenticated: true,
      payload,
    }),
    [actions.receiveAuthenticationFailure]: (state, payload) => ({
      ...state,
      isFetching: false,
      isAuthenticated: false,
      payload,
    }),
    [actions.requestLogout]: (state) => ({
      ...state,
      isFetching: true,
      payload: null,
    }),
    [actions.receiveLogoutSuccess]: (state) => ({
      ...state,
      isForbidden: false,
      isFetching: false,
      isVerified: false,
      isVerifying: false,
      isAuthenticated: false,
      user: {
        role: UserRoles.UNLOGGED,
      },
    }),
  },
  {
    isForbidden: false,
    isFetching: false,
    isVerified: false,
    isVerifying: false,
    isAuthenticated: !!token,
    user: {
      role: UserRoles.UNLOGGED,
    },
    payload: null,
    didFetched: false,
  },
);
