import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { MAP_SMS_ERROR_CODES } from '../../../../constraints';

export const useSchema = () => {
  const { formatMessage } = useIntl();

  const requiredMessage = formatMessage({
    id: MAP_SMS_ERROR_CODES.EMPTY,
    defaultMessage: MAP_SMS_ERROR_CODES.EMPTY,
  });

  const invalidMessage = formatMessage({
    id: MAP_SMS_ERROR_CODES.INVALID,
    defaultMessage: MAP_SMS_ERROR_CODES.INVALID,
  });

  return yup.object({
    smsCode: yup
      .string()
      .test('requiredLength', invalidMessage, function (value) {
        return value ? value.length === 6 : true;
      })
      .required(requiredMessage),
  });
};
