import styled from 'styled-components';
import { Dropdown as DropdownModule, Utils } from 'billon-ui';

const { DropdownToggle: DT } = DropdownModule;
const { Button: ButtonModule } = Utils;
const { IconButton: IC } = ButtonModule;

export const DropDownDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const DropdownToggle = styled(DT)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0 0 0;
  &:after {
    display: none;
  }
`;

export const IconButton = styled(IC)`
  font-size: 20px;
  color: #cad5dd;
`;
