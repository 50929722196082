import React, { useMemo } from 'react';

// hooks
import { useTenantsContext } from '../../../Tenants/hooks/useTenants';
import { useSingleDocument } from '../../hooks/useSingleDocument';
import { useSettingsContext } from 'js/modules/Settings/hooks/useSettings';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import useFilters from '../../../../hooks/useFilters';

// constants
import {
  PUBLICATION_MODES,
  PUBLICATION_MODES_TITLE,
  DOCUMENT_TYPE,
  SETTINGS_CONSTANTS,
  CUSTOMER,
} from '../../../../constraints';

// Components
import { Content } from '../../../../components/Content/Content';
import {
  OneStepPublication,
  TwoStepPublication,
} from '../../components/PublishDocumentModal/components';
import { OneStepPublicationAsync } from '../../components/PublishDocumentModal/components/OneStepPublicationAsync/OneStepPublicationAsync';
import { PublicationFormWrapper } from './PublicationFormWrapper';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const PublishDocumentPage = () => {
  const { areTenantsFetching, areTenantsFetched, areTenantsAvailable } =
    useTenantsContext();
  const { filters } = useFilters();
  const { jobId, mode, signingStep, documentTypeList } = filters || {};
  const {
    data: document,
    isLoading,
    isError,
  } = useSingleDocument({ id: jobId, enabled: !!jobId });

  const { customer } = useConfigContext();
  const { getSettingValueByKey } = useSettingsContext();

  const isPublicOneStepPublication = getSettingValueByKey(
    SETTINGS_CONSTANTS.PUBLIC_ONE_STEP_PUBLISH,
  );

  const isPrivateOneStepPublication = getSettingValueByKey(
    SETTINGS_CONSTANTS.PRIVATE_ONE_STEP_PUBLISH,
  );

  const initialStep = useMemo(() => {
    if (areTenantsFetched && areTenantsAvailable) return 0;
    return 1;
  }, [areTenantsAvailable, areTenantsFetched]);

  const documentType = documentTypeList || DOCUMENT_TYPE.PRIVATE;
  let publicationMode = mode ? mode : PUBLICATION_MODES.NEW;
  let isPreparedToSign =
    signingStep ||
    (document?.jobId && publicationMode === PUBLICATION_MODES.NEW);

  let pageHeaderId = PUBLICATION_MODES_TITLE[publicationMode];

  if (areTenantsFetching || isLoading || isError) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  if (documentType !== DOCUMENT_TYPE.PRIVATE && isPublicOneStepPublication) {
    return (
      <PublicationFormWrapper pageHeaderId={pageHeaderId}>
        <OneStepPublicationAsync
          publicationMode={PUBLICATION_MODES.NEW}
          initialStep={initialStep}
        />
      </PublicationFormWrapper>
    );
  }

  if (documentType === DOCUMENT_TYPE.PRIVATE && !isPrivateOneStepPublication) {
    return (
      <PublicationFormWrapper pageHeaderId={pageHeaderId}>
        <TwoStepPublication
          publicationMode={publicationMode}
          document={document}
          isPreparedToSign={isPreparedToSign}
        />
      </PublicationFormWrapper>
    );
  }

  if (
    documentType === DOCUMENT_TYPE.PRIVATE &&
    isPrivateOneStepPublication &&
    customer === CUSTOMER.TAURON
  ) {
    return (
      <PublicationFormWrapper pageHeaderId={pageHeaderId}>
        <OneStepPublicationAsync
          publicationMode={PUBLICATION_MODES.NEW}
          initialStep={initialStep}
        />
      </PublicationFormWrapper>
    );
  }

  return (
    <PublicationFormWrapper pageHeaderId={pageHeaderId}>
      <OneStepPublication
        publicationMode={PUBLICATION_MODES.NEW}
        initialStep={initialStep}
      />
    </PublicationFormWrapper>
  );
};

export default PublishDocumentPage;
