import { useState } from 'react';
import { useNotificationContent } from '../../Notifications/hooks/useNotificationContent';
import { removeUndefinedValues } from '../../../helpers/removeUndefinedValues';

const parseParams = ({ clientId, contactDetails }) => ({
  clientId: clientId !== null ? clientId : undefined,
  contactDetails,
});

const useNotificationPreview = () => {
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);
  const [params, setParams] = useState({});
  const [jobId, setJobId] = useState({});

  const showContent = (jobId, clientId, contactDetails) => {
    const newParams = removeUndefinedValues(
      parseParams({ clientId, contactDetails }),
    );
    setParams(newParams);
    setJobId(jobId);
    setIsPreviewOpened(true);
  };

  const closeContent = () => {
    setIsPreviewOpened(false);
  };

  const {
    data: content,
    isLoading: isPreviewLoading,
    isError,
    isSuccess,
  } = useNotificationContent({ params }, jobId, { enabled: isPreviewOpened });

  return {
    content,
    setIsPreviewOpened,
    isPreviewOpened,
    isPreviewLoading,
    isError,
    isSuccess,
    showContent,
    closeContent,
  };
};

export default useNotificationPreview;
