import React from 'react';
import { Modal as ModalModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { Layout, Grid, Utils } from 'billon-ui';
import Modal from '../../../../../../ui/Modal';
import HeaderToolTip from './HeaderToolTip';
import * as Styled from './styled';

const { ModalFooter, ModalHeader } = ModalModule;
const { Col } = Grid;
const { Button: ButtonModule, Loader: LoaderModule } = Utils;
const { Button } = ButtonModule;
const { Content: ContentModule } = Layout;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;

const SelectResendMode = ({
  isOpen,
  isLoading,
  onClose,
  onSelectGroup,
  onSearchList,
}) => {
  if (isLoading) {
    return (
      <Modal
        isOpen={true}
        closeIconEnabled={true}
        onClose={onClose}
        toggle={onClose}
      >
        <Content fluid>
          <PageLoader />
        </Content>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
    >
      <ModalHeader>
        <Styled.Title>
          <FormattedMessage
            id="Resend notification"
            defaultMessage="Resend notification"
          />{' '}
          <HeaderToolTip />
        </Styled.Title>
      </ModalHeader>
      <ModalFooter>
        <Col md={5}>
          <Button size="lg" block onClick={onSelectGroup}>
            <FormattedMessage
              id="Select a group of recipients"
              defaultMessage="Select a group of recipients"
            />
          </Button>
        </Col>
        <Col md={5}>
          <Button size="lg" block onClick={onSearchList}>
            <FormattedMessage
              id="Search from list"
              defaultMessage="Search from list"
            />
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default SelectResendMode;
