import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomConfirmModal from '../../../../../../ui/CustomConfirmModal';
import { Utils } from 'billon-ui';

const { Text } = Utils;

const ConfirmForgettingModal = ({
  onSubmit,
  onClose,
  isOpen,
  isSubmitting,
}) => (
  <CustomConfirmModal
    isSubmitting={isSubmitting}
    onSubmit={onSubmit}
    isOpen={isOpen}
    toggle={onClose}
    title={<FormattedMessage id="Execute GDPR" defaultMessage="Execute GDPR" />}
  >
    <Text>
      <FormattedMessage
        id="Are you sure you want to remove the recipient?"
        defaultMessage="Are you sure you want to remove the recipient?"
      />
    </Text>
    <Text>
      <FormattedMessage
        id="It will make the recipient permanently erased"
        defaultMessage="It will make the recipient permanently erased"
      />
      {'. '}
    </Text>
  </CustomConfirmModal>
);

export default ConfirmForgettingModal;
