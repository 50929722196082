import { useMemo } from 'react';

// hooks
import { useConfigContext } from '../../../../Config/hooks/useConfig';
import { useIdentityDocumentType } from '../../../hooks/useIdentityDocumentType';
import { useSharedSecretOptions } from './useSharedSecretOptions';

export const useRecipientCreateFormDefaultValues = (initialValues) => {
  const { recipientsConfig } = useConfigContext();
  const { enableIdentityDocumentTypeSelection, enableSharedSecret } =
    recipientsConfig || {};
  const { identityDocumentTypeOptions, defaultIdentityDocumentTypeOption } =
    useIdentityDocumentType();
  const { defaultSharedSecretOption, sharedSecretOptions } =
    useSharedSecretOptions();

  const { documentType, sharedSecret } = initialValues || {};

  const computeDocumentType = (
    documentType,
    enableIdentityDocumentTypeSelection,
  ) => {
    if (!enableIdentityDocumentTypeSelection) return null;
    if (documentType)
      return identityDocumentTypeOptions.find(
        (option) => option.value === documentType,
      );
    return defaultIdentityDocumentTypeOption;
  };

  const computeSharedSecretOption = (sharedSecretType) => {
    if (!!sharedSecretType) {
      return sharedSecretOptions.filter(
        (option) => option.value === sharedSecret?.type,
      )?.[0];
    }
    if (enableSharedSecret) {
      return defaultSharedSecretOption;
    }
  };

  const computedDefaultValues = useMemo(
    () => ({
      isActive: true,
      ...initialValues,
      documentType: computeDocumentType(
        documentType,
        enableIdentityDocumentTypeSelection,
      ),
      sharedSecretType: computeSharedSecretOption(sharedSecret?.type),
      sharedSecretValue: sharedSecret?.value,
    }),
    [
      initialValues,
      documentType,
      enableIdentityDocumentTypeSelection,
      sharedSecret,
      sharedSecretOptions,
    ],
  );

  return { computedDefaultValues };
};
