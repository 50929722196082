import React from 'react';
import { Redirect } from 'react-router-dom';
import { CUSTOMER } from '../../../../constraints';

import config from 'js/config';
const { customer } = config;

const HomePage = () => {
  if (customer === CUSTOMER.DEFAULT) {
    return <Redirect to="/select-document-type" push />;
  } else {
    return <Redirect to="/documents" push />;
  }
};

export default HomePage;
