import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useTenantsContext } from '../../../../../Tenants/hooks/useTenants';
import { useTenantOptions } from '../../../../../../hooks/useTenantOptions';

// Components
import { SelectField as AsyncSelectField } from '../../../../../../components';
import { FieldLabel } from '../../../../../../components/Form';

const SelectTenantField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.TENANT,
  tenantId,
  onClear,
  isClearable,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { customer, publicationFormConfig } = useConfigContext();
  const { enableTenants } = publicationFormConfig;
  const { tenantHeaderMessageId } = useTenantsContext();
  const { tenants, areTenantsFetching, onChangeTenantInput } = useTenantOptions(
    customer,
    'id',
    enableTenants,
  );

  const handleClear = (...args) => {
    onClear && onClear(...args);
  };

  useEffect(() => {
    if (!areTenantsFetching && tenantId) {
      setValue(
        name,
        tenants.find((c) => c.value === tenantId),
      );
    }
  }, [areTenantsFetching, tenantId, setValue, tenants, name]);

  return (
    <>
      <FieldLabel>
        <FormattedMessage
          id={tenantHeaderMessageId}
          defaultMessage={tenantHeaderMessageId}
        />
      </FieldLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <AsyncSelectField
            input={{
              ...field,
              onInputChange: (inputText) => onChangeTenantInput(inputText),
              onClear: handleClear,
            }}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            options={tenants}
            isClearable={isClearable}
            isLoading={areTenantsFetching}
          />
        )}
      />
    </>
  );
};

export default SelectTenantField;
