import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as Styled from './styled';
import { Form as FormModule, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { FormGroup } = FormModule;

const FormNavigator = ({
  isSubmitButtonLoading,
  handleBack,
  submitMessageId,
}) => (
  <Styled.CardBodyWrapper>
    <FormGroup>
      {isSubmitButtonLoading ? (
        <ButtonLoader block size="lg" />
      ) : (
        <Button type="submit" size="lg" block>
          <FormattedMessage
            id={submitMessageId}
            defaultMessage={submitMessageId}
          />
        </Button>
      )}
    </FormGroup>
    <FormGroup>
      <Styled.BackButton secondary onClick={handleBack}>
        <FormattedMessage id="back" defaultMessage="back" />
      </Styled.BackButton>
    </FormGroup>
  </Styled.CardBodyWrapper>
);

export default FormNavigator;
