import { postRequest } from '../../../../helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../../api-routes';

export const usePostPrivateSingleNotificationGroupTemplate = (options) =>
  useMutationWithLogout(async ({ groupId, data }) => {
    const { data: createData } = await postRequest({
      url: apiRoutes.PRIVATE_NOTIFICATION_GROUP_TEMPLATES.replace(
        '{groupId}',
        groupId,
      ),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return createData;
  }, options);
