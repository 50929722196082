import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';
import * as Styled from './styled';
import CustomToolTip from '../../../../ui/CustomToolTip';
import { ProtectedComponent } from '../../../Auth/components';
import { CATEGORY_STATUSES, UserRoles } from '../../../../constraints';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { CUSTOMER } from '../../../../constraints';
import { shortenStr } from '../../../../helpers/shortenStr';

const { Button: ButtonModule } = Utils;
const { IconButton } = ButtonModule;

export const AddCategoryCell = ({ id, name, status, isActive, onClick }) => {
  const { customer } = useConfigContext();

  const maxNameLength = 30;
  const displayName = shortenStr(name, maxNameLength, 10, 3);

  const ReturnWrapper = ({ children }) => (
    <Styled.Wrapper>
      <div>
        {name?.length > maxNameLength ? (
          <CustomToolTip
            id={'catFullName-' + id}
            trigger={displayName}
            delay={false}
            minWidth={true}
          >
            {name}
          </CustomToolTip>
        ) : (
          <>{name}</>
        )}
      </div>
      {children}
    </Styled.Wrapper>
  );

  const AddCategoryToolTip = (
    <>
      <CustomToolTip
        id={'CreateCategoryTooltip-' + id}
        trigger={<IconButton icon="plus" onClick={onClick} />}
        delay={false}
        minWidth={true}
      >
        <FormattedMessage
          id="Create subcategory"
          defaultMessage="Create subcategory"
        />
      </CustomToolTip>
    </>
  );

  const AddingForbiddenToolTip = (
    <>
      <CustomToolTip
        id={'CreatingCategoryForbiddenTooltip-' + id}
        trigger={
          <IconButton
            iconColor="lightgrey"
            iconHoverColor="grey"
            icon="plus"
            onClick={() => {}}
          />
        }
        delay={false}
        minWidth={true}
      >
        <FormattedMessage
          id="To add a subcategory, the parent category must be active"
          defaultMessage="To add a subcategory, the parent category must be active"
        />
      </CustomToolTip>
    </>
  );

  if (status !== CATEGORY_STATUSES.IN_PROGRESS && isActive) {
    if (customer === CUSTOMER.TAURON) {
      return (
        <ReturnWrapper>
          <ProtectedComponent
            roles={[UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
            render={false}
          >
            {AddCategoryToolTip}
          </ProtectedComponent>
        </ReturnWrapper>
      );
    } else {
      return (
        <ReturnWrapper>
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
            render={false}
          >
            {AddCategoryToolTip}
          </ProtectedComponent>
        </ReturnWrapper>
      );
    }
  }

  return <ReturnWrapper>{AddingForbiddenToolTip}</ReturnWrapper>;
};
