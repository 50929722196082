import styled from 'styled-components';

import { Form as FormModule } from 'billon-ui';

export const Form = styled(FormModule.Form)`
  h2 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    margin-top: -3.5rem;
    width: calc(100% - 35px);
  }
`;
