import styled from 'styled-components';
import { Grid, Utils } from 'billon-ui';

const { Row, Col } = Grid;
const { Text } = Utils;

export const SingleTemplateHeader = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: ${(props) => props.theme.fontSizeLg};
  margin-bottom: 0;
`;

export const CenteredVericallyRow = styled(Row)`
  align-items: center;
  margin-bottom: 16px;
`;

export const ButtonCol = styled(Col)`
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
`;
