import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';

export const FlatpickrWrapper = styled(Flatpickr)`
  position: relative;
  z-index: 2;
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  transition: ${(props) => props.theme.transitionBase};
  font-size: ${(props) => props.theme.fontSizeSm};
  box-shadow: none;
  padding: 0.6rem 1rem;
  height: 42px;
  -webkit-appearance: none;

  &::placeholder {
    color: ${(props) => props.theme.palette.grayLight};
    font-weight: ${(props) => props.theme.fontWeightBase};
  }

  &:not([disabled]) {
    background-color: transparent;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    border-color: ${(props) => props.theme.palette.borderHoverColor};
    /* border-color: ${(props) => props.theme.palette.grayLight}; */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
    background-color: transparent;
    outline: 0;

    + i {
      color: ${(props) => props.theme.palette.gray};
    }

    + button:not(.btn) {
      color: ${(props) => props.theme.palette.iconHoverColor};
    }
  }

  &[disabled] {
    cursor: ${(props) => props.theme.disabledCursor};
    background-color: ${(props) => props.theme.disabledBackground};
  }
`;
