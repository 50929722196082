import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PUBLICATION_MODES,
  DOCUMENT_STATUS,
  SIGNING_STATUS,
} from '../../../../constraints';
import {
  SecondaryBadge,
  SuccessBadge,
  SecondaryDarkerBadge,
  FailureBadge,
} from '../../../../ui/Badge';
import * as Styled from './styled';

const SigningExtendedStatusBasedBadge = ({
  publicationMode,
  status: dcoumentStatus,
  signingStatus = SIGNING_STATUS.PUBLISHING_OK,
  nextPublicationMode,
}) => {
  if (
    dcoumentStatus === DOCUMENT_STATUS.CURRENT &&
    signingStatus === SIGNING_STATUS.PUBLISHING_OK
  ) {
    return (
      <SuccessBadge margin={0}>
        <FormattedMessage id="Approved" defaultMessage="Approved" />
      </SuccessBadge>
    );
  }

  if (
    dcoumentStatus === DOCUMENT_STATUS.CURRENT &&
    signingStatus === SIGNING_STATUS.REJECTED
  ) {
    return (
      <FailureBadge margin={0}>
        <FormattedMessage id="Rejected" defaultMessage="Rejected" />
      </FailureBadge>
    );
  }

  if (
    (dcoumentStatus === DOCUMENT_STATUS.CURRENT &&
      signingStatus === SIGNING_STATUS.WAITING_FOR_RECEIVER) ||
    (publicationMode === PUBLICATION_MODES.INCIDENT &&
      dcoumentStatus === DOCUMENT_STATUS.CURRENT &&
      signingStatus === SIGNING_STATUS.WAITING_FOR_RECEIVER)
  ) {
    return (
      <SecondaryBadge margin={0}>
        <FormattedMessage
          id="Waiting for receiver"
          defaultMessage="Waiting for receiver"
        />
      </SecondaryBadge>
    );
  }

  if (
    (dcoumentStatus === DOCUMENT_STATUS.DEPRECATED &&
      signingStatus === SIGNING_STATUS.PUBLISHING_OK) ||
    (publicationMode === PUBLICATION_MODES.INCIDENT &&
      dcoumentStatus === DOCUMENT_STATUS.DEPRECATED &&
      signingStatus === SIGNING_STATUS.PUBLISHING_OK) ||
    (dcoumentStatus === DOCUMENT_STATUS.DEPRECATED &&
      signingStatus === SIGNING_STATUS.REJECTED) ||
    (publicationMode === PUBLICATION_MODES.INCIDENT &&
      dcoumentStatus === DOCUMENT_STATUS.DEPRECATED &&
      signingStatus === SIGNING_STATUS.REJECTED)
  ) {
    return (
      <SecondaryDarkerBadge margin={0}>
        <FormattedMessage
          id="Submitted for signature"
          defaultMessage="Submitted for signature"
        />{' '}
        <Styled.CenterDiv>
          <FormattedMessage
            id="[previous version]"
            defaultMessage="[previous version]"
          />
        </Styled.CenterDiv>
      </SecondaryDarkerBadge>
    );
  }

  if (
    dcoumentStatus === DOCUMENT_STATUS.DEPRECATED &&
    nextPublicationMode === PUBLICATION_MODES.INCIDENT
  ) {
    return (
      <FailureBadge margin={0}>
        <FormattedMessage id="Incident" defaultMessage="Incident" />
      </FailureBadge>
    );
  }

  if (
    [DOCUMENT_STATUS.NOT_EXIST, DOCUMENT_STATUS.NOT_AVAILABLE].includes(
      dcoumentStatus,
    )
  ) {
    return (
      <FailureBadge margin={'0 0 0 15px'}>
        <FormattedMessage
          id="Publishing error"
          defaultMessage="Publishing error"
        />
      </FailureBadge>
    );
  }

  if (dcoumentStatus === DOCUMENT_STATUS.PREPARED_TO_SIGN) {
    return (
      <SecondaryBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="Prepared" defaultMessage="Prepared" />
      </SecondaryBadge>
    );
  }

  if (
    dcoumentStatus === DOCUMENT_STATUS.UPLOADING ||
    signingStatus === DOCUMENT_STATUS.PUBLISHING_INITIATED
  ) {
    return (
      <SecondaryBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="Uploading" defaultMessage="Uploading" />
      </SecondaryBadge>
    );
  }

  return null;
};

export default SigningExtendedStatusBasedBadge;
