import React from 'react';

// utils
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CUSTOMER } from '../../../../constraints';
import * as formatters from '../../../../formatters';

// hooks
import useFeatureEnabled from '../../../Config/hooks/useFeatureEnabled';
import { useHistory } from 'react-router-dom';

// components
import { Table as TableModule } from 'billon-ui';
import { PreviewDownloadButton } from '../PreviewDownloadButton';
import * as Styled from './styled';
import StatusBasedBadge from '../StatusBasedBadge';

const { Table, TableColumn } = TableModule;

const DocumentHistory = ({
  historyRecords,
  currentId,
  title,
  customer,
  signingStatus,
  signingOrder,
}) => {
  const { featureEnabled } = useFeatureEnabled();

  const history = useHistory();

  const onClickRow = (record) => {
    const { documentBlockchainAddress } = record || {};
    if (documentBlockchainAddress && documentBlockchainAddress !== currentId)
      history.push(`/document/${record.documentBlockchainAddress}`);
  };

  const formatRow = (row) => {
    return row.documentBlockchainAddress === currentId ? 'current-row' : null;
  };

  const getNextRowPublicationMode = (bc) => {
    const currentIndex = historyRecords.findIndex(
      ({ documentBlockchainAddress }) => documentBlockchainAddress === bc,
    );

    if (currentIndex > 0) {
      return historyRecords[currentIndex - 1]?.publicationMode;
    }

    return null;
  };

  const badgeFormatter = (value, row) => {
    const { publicationMode, status, documentBlockchainAddress } = row || {};

    const nextPublicationMode = getNextRowPublicationMode(
      documentBlockchainAddress,
    );

    return (
      <Styled.PairRow>
        <Styled.PairCol>{value}</Styled.PairCol>
        <StatusBasedBadge
          publicationMode={publicationMode}
          status={status}
          signingStatus={signingStatus}
          signingOrder={signingOrder}
          nextPublicationMode={nextPublicationMode}
        />
      </Styled.PairRow>
    );
  };

  if (!historyRecords?.length) {
    return null;
  }

  return (
    <Styled.HistoryContainer>
      <h3>
        <FormattedMessage id="History" defaultMessage="History" />
      </h3>
      <Table
        responsive
        tableData={historyRecords}
        onRowClick={onClickRow}
        formatRow={formatRow}
      >
        <TableColumn
          fieldName="publicationDate"
          formatter={formatters.noValueFormatter(
            formatters.noBreakWordFormatter(
              formatters.momentInTimezoneFormatter,
            ),
          )}
        >
          <FormattedMessage
            id="Publication date"
            defaultMessage="Publication date"
          />
        </TableColumn>
        <TableColumn fieldName="title" formatter={badgeFormatter}>
          {featureEnabled(null, [CUSTOMER.TAURON, CUSTOMER.DEFAULT]) && (
            <FormattedMessage id="Title" defaultMessage="Title" />
          )}
          {featureEnabled(null, [CUSTOMER.DIPLOMA]) && (
            <FormattedMessage
              id="Diploma number"
              defaultMessage="Diploma number"
            />
          )}
        </TableColumn>
        <TableColumn
          fieldName="documentBlockchainAddress"
          formatter={formatters.documentIDFormatter}
        >
          <FormattedMessage
            id="Blockchain address"
            defaultMessage="Blockchain address"
          />
        </TableColumn>
        {customer !== CUSTOMER.DIPLOMA && (
          <TableColumn fieldName="versionName">
            <FormattedMessage
              id="Document version"
              defaultMessage="Document version"
            />
          </TableColumn>
        )}

        <TableColumn
          className="text-center fixed-width"
          fieldName="Preview"
          formatter={(value, record) => (
            <PreviewDownloadButton record={record} isPreview />
          )}
        >
          <FormattedMessage id="Preview" defaultMessage="Preview" />
        </TableColumn>

        <TableColumn
          className="text-center fixed-width"
          fieldName="Download"
          formatter={(value, record) => (
            <PreviewDownloadButton record={record} />
          )}
        >
          <FormattedMessage id="Download" defaultMessage="Download" />
        </TableColumn>
      </Table>
    </Styled.HistoryContainer>
  );
};

DocumentHistory.propTypes = {
  historyRecords: PropTypes.array,
  history: PropTypes.object,
  currentId: PropTypes.string,
  customer: PropTypes.string.isRequired,
  fileDownload: PropTypes.func,
  isDownloading: PropTypes.array.isRequired,
};

export default DocumentHistory;
