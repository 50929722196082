import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Form } from 'billon-ui';

export const SearchBar = styled.form`
  position: relative;
  width: 20rem;

  ${down('sm')} {
    width: 100%;
  }
`;

export const TextField = styled(Form.TextField)`
  margin: 0;
`;

export const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: ${(props) => props.theme.transitionBase};
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;

  &:hover {
    background: transparent;

    svg[data-icon] {
      color: ${(props) => props.theme.palette.iconHoverColor};
    }
  }

  &:focus {
    outline: 0;
  }

  svg[data-icon] {
    font-size: 1rem;
    color: ${(props) => props.theme.palette.grayLighter};
    transition: ${(props) => props.theme.transitionBase};
  }
`;

export const ClearButton = styled(Button)`
  right: 40px;
`;

export const SearchButton = styled(Button)`
  right: 10px;
`;
