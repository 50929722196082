import styled from 'styled-components';

export const ModalBodyWrapper = styled.div`
  padding-bottom: 30px;
`;

export const LabelFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
