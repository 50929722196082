import { useIntl } from 'react-intl';

import { UserRoles, MAP_USER_ROLES } from '../../../../../constraints';
import { useConfigContext } from '../../../../Config/hooks/useConfig';

export const useRoleOptions = () => {
  const { formatMessage } = useIntl();
  const { isTauron } = useConfigContext();

  const defaultRoles = [
    UserRoles.VISITOR,
    UserRoles.AGENT,
    UserRoles.BUSINESS_ADMIN,
    UserRoles.ADMIN,
    UserRoles.DPI,
  ];

  const tauronRoles = [
    UserRoles.VISITOR,
    UserRoles.AGENT,
    UserRoles.BUSINESS_ADMIN,
    UserRoles.ADMIN,
  ];

  const roles = isTauron ? tauronRoles : defaultRoles;

  const roleOptions = roles.map((role) => {
    const msgId = MAP_USER_ROLES[role];
    return {
      label: formatMessage({
        id: msgId,
        defaultMessage: msgId,
      }),
      value: role,
    };
  });

  return { roleOptions };
};
