import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectField from '../SelectField';

export const ControlledSelectField = ({
  name,
  options,
  onInputChange,
  disabled,
  placeholder,
  isLoading,
  onClear,
  isClearable,
  label,
  clean,
  searchable = true,
  menuShouldBlockScroll = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const handleInputChange = (...args) => {
    onInputChange && onInputChange(...args);
  };
  const handleClear = (...args) => {
    onClear && onClear(...args);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <SelectField
            input={{
              ...field,
              onInputChange: handleInputChange,
              onClear: handleClear,
            }}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            options={options}
            disabled={disabled}
            placeholder={placeholder}
            isLoading={isLoading}
            isClearable={isClearable}
            label={label}
            clean={clean}
            searchable={searchable}
            menuShouldBlockScroll={menuShouldBlockScroll}
          />
        );
      }}
    />
  );
};
