import { Layout as LayoutModule } from 'billon-ui';
import styled from 'styled-components';

const { Content: ContentModule } = LayoutModule;
const { ContentContainer } = ContentModule;

const ContentContainerGrey = styled(ContentContainer)`
  background: rgb(250, 250, 250);
`;

export default ContentContainerGrey;
