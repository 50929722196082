import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Grid } from 'billon-ui';
import { Controller } from 'react-hook-form';
import * as Styled from './styled';
import { REPORT_ROLE, MAP_REPORT_ROLE } from '../../../../constraints';
import { FancyRadioField } from '../../../../ui/FancyRadioField/FancyRadioField';

const { Row, Col } = Grid;
const { FormGroup } = FormModule;

const ReportPanelAccessibility = ({ control, name = 'reportRole' }) => {
  const colWidth = 3;

  return (
    <Styled.Permissions>
      <h4>
        <FormattedMessage
          id="Report Panel Accessibility"
          defaultMessage="Report Panel Accessibility"
        />
      </h4>

      <Row>
        <Col lg={colWidth} md={6}>
          <FormGroup>
            <Controller
              name={name}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: REPORT_ROLE.NO_ACCESS,
                      checked: field.value === REPORT_ROLE.NO_ACCESS,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id={MAP_REPORT_ROLE.NO_ACCESS}
                        defaultMessage={MAP_REPORT_ROLE.NO_ACCESS}
                      />
                    }
                  ></FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>

        <Col lg={colWidth} md={6}>
          <FormGroup>
            <Controller
              name={name}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: REPORT_ROLE.AGENT,
                      checked: field.value === REPORT_ROLE.AGENT,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id={MAP_REPORT_ROLE.AGENT}
                        defaultMessage={MAP_REPORT_ROLE.AGENT}
                      />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="report generation"
                          defaultMessage="report generation"
                        />
                      </li>
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>

        <Col lg={colWidth} md={6}>
          <FormGroup>
            <Controller
              name={name}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: REPORT_ROLE.ADMIN,
                      checked: field.value === REPORT_ROLE.ADMIN,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id={MAP_REPORT_ROLE.ADMIN}
                        defaultMessage={MAP_REPORT_ROLE.ADMIN}
                      />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="report generation"
                          defaultMessage="report generation"
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="Daily Report re-publication"
                          defaultMessage="Daily Report re-publication"
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="event re-synchronization"
                          defaultMessage="event re-synchronization"
                        />
                      </li>
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Styled.Permissions>
  );
};

export default ReportPanelAccessibility;
