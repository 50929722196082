import React from 'react';
import { FormattedMessage } from 'react-intl';

// helpers
import { boundLengthFormatterLong } from '../../../../../../../../formatters';

// components
import { NavButton, SingleTemplateHeader } from './styled';

const HorizontalTenantsList = ({
  activeTenantId,
  tenants,
  handleActiveTenantIdChange,
  small,
}) => {
  return (
    <>
      <SingleTemplateHeader small={small}>
        <FormattedMessage
          id={'Preview for Publisher'}
          defaultMessage={'Preview for Publisher'}
        />
        :
      </SingleTemplateHeader>
      {tenants?.map(({ id, name, disabled }) => (
        <NavButton
          type="button"
          small={small}
          isSelected={id === activeTenantId}
          disabled={disabled}
          onClick={() => handleActiveTenantIdChange(id)}
        >
          {boundLengthFormatterLong(name)}
        </NavButton>
      ))}
    </>
  );
};

export default HorizontalTenantsList;
