import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const DataDetailsSection = ({
  headerMessageId,
  columns,
  activityTagEnabled,
  contentIndentEnabled,
  isActive,
}) => {
  const detailColumnSize = 12 / columns.length;
  const headerColumnSize = activityTagEnabled ? 12 - detailColumnSize : 12;

  return (
    <>
      <Styled.DetailsHeaderRow xl={12}>
        <Styled.HeaderTagColumn xl={headerColumnSize} lg={12}>
          <Styled.HeaderText>
            <FormattedMessage
              id={headerMessageId}
              defaultMessage={headerMessageId}
            />
            :
          </Styled.HeaderText>
        </Styled.HeaderTagColumn>
        {activityTagEnabled && (
          <Styled.HeaderTagColumn xl={detailColumnSize} lg={12}>
            <Styled.HeaderText>
              <FormattedMessage id="Active3" defaultMessage="Active3" />
              {': '}
              {isActive ? (
                <FormattedMessage id="Yes" defaultMessage="Yes" />
              ) : (
                <FormattedMessage id="No" defaultMessage="No" />
              )}
            </Styled.HeaderText>
          </Styled.HeaderTagColumn>
        )}
      </Styled.DetailsHeaderRow>
      <Styled.MarginZeroRow xl={12} contentIndentEnabled={contentIndentEnabled}>
        {columns.map((detailsColumn) => (
          <Styled.DetailsCol>
            {detailsColumn
              .filter((v) => v?.enabled)
              .map((v) => (
                <Styled.DetailRow warning={v?.warning}>
                  <Styled.DetailTag>
                    <FormattedMessage id={v.title} defaultMessage={v.title} />:
                  </Styled.DetailTag>
                  <Styled.DetailValue>{v.data}</Styled.DetailValue>
                </Styled.DetailRow>
              ))}
          </Styled.DetailsCol>
        ))}
      </Styled.MarginZeroRow>
    </>
  );
};

export default DataDetailsSection;
