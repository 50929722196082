import axios from 'axios';
import { useMutation } from 'react-query';
import apiRoutes from '../../../api-routes';

export const useLogin = (options) => useMutation(async ({ data }) => {
    const { data: loginData } = await axios
      .post(apiRoutes.LOGIN, data);

    return loginData;
  },
  options
);
