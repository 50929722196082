import React from 'react';
import { SIGNING_STATUS, SIGNING_ORDER } from '../../../../constraints';
import StandardStatusBasedBadge from './StandardStatusBasedBadge';
import SigningExtendedStatusBasedBadge from './SigningExtendedStatusBasedBadge';
import { useConfigContext } from '../../../Config/hooks/useConfig';

const StatusBasedBadge = ({
  publicationMode,
  status,
  signingStatus = SIGNING_STATUS.PUBLISHING_OK,
  signingOrder,
  nextPublicationMode,
}) => {
  const { enableSigning } = useConfigContext();

  if (
    !enableSigning ||
    [SIGNING_ORDER.NO_SIGNATURE, SIGNING_ORDER.PUBLISHER_ONLY].includes(
      signingOrder,
    ) ||
    !signingOrder
  ) {
    return (
      <StandardStatusBasedBadge
        publicationMode={publicationMode}
        status={status}
        signingStatus={signingStatus ?? SIGNING_STATUS.PUBLISHING_OK}
        nextPublicationMode={nextPublicationMode}
      />
    );
  }

  if (
    [SIGNING_ORDER.SENDER_FIRST, SIGNING_ORDER.RECEIVER_ONLY].includes(
      signingOrder,
    )
  ) {
    return (
      <SigningExtendedStatusBasedBadge
        publicationMode={publicationMode}
        status={status}
        signingStatus={signingStatus}
        nextPublicationMode={nextPublicationMode}
      />
    );
  }

  return null;
};

export default StatusBasedBadge;
