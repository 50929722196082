import { parseDateRange } from '../../../helpers/parseDateRange';

export const parseFilters = ({
  blockchainAddress,
  publicationDateRange,
  documentStatusList,
  title,
}) => ({
  blockchainAddress: blockchainAddress || undefined,
  publicationDateRange: parseDateRange(publicationDateRange),
  documentStatusList: documentStatusList?.value || undefined,
  title: title ? title.toLowerCase() : undefined,
});
