import React from 'react';
import { merge } from 'lodash';
import { ORDER_METHOD } from '../../../../../../constraints';
import useFilters from '../../../../../../hooks/useFilters';
import { useDocumentRecipients } from '../../../../hooks/useDocumentRecipients';
import { Layout as LayoutModule, Utils, Table as TableModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { ModalBody } from '../../../../../../ui/CustomConfirmModal/styled';
import { notificationStatusFormatter } from '../../../NotificationHistory/formatters';

const { Content: ContentModule } = LayoutModule;
const { Loader: LoaderModule, Pagination } = Utils;
const { PageLoader } = LoaderModule;
const { Content, ErrorContent } = ContentModule;
const { Table, TableColumn } = TableModule;

const RecipientsTable = ({
  jobId,
  documentBlockchainAddress,
  children,
  deliveredData,
  customFooter,
  customErrorFooter,
}) => {
  const defaultFilters = {
    pagination: {
      limit: 5,
      page: 1,
    },
    sort: {
      order: ORDER_METHOD.DESC,
    },
  };

  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge({}, defaultFilters, filters);

  const {
    data: requestData,
    isLoading: isRequesting,
    isError,
    failureCount,
  } = useDocumentRecipients(fullFilters, {
    id: documentBlockchainAddress,
    enabled: !deliveredData,
  });

  const { data, isLoading } = deliveredData
    ? deliveredData
    : { data: requestData, isLoading: isRequesting };

  let rows = data?.rows.map((record) => {
    return { ...record, uniqueId: `${record?.externalId}-${record?.type}` };
  });
  let numberOfRecords = data?.count;
  let numberOfPages = Math.ceil(numberOfRecords / fullFilters.pagination.limit);

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  if (isLoading && failureCount <= 1) {
    return (
      <>
        <ModalBody>
          <Content fluid>
            <PageLoader />
          </Content>
        </ModalBody>
        {customFooter}
      </>
    );
  }

  if (isError || (isLoading && failureCount >= 2)) {
    return (
      <>
        <ModalBody>
          <Content fluid>
            <ErrorContent>
              <FormattedMessage
                id="Error while downloading list of recipients"
                defaultMessage="Error while downloading list of recipients"
              />
            </ErrorContent>
          </Content>
        </ModalBody>
        {customErrorFooter}
      </>
    );
  }

  return (
    <>
      <ModalBody>
        <Table tableData={rows} sortMethod={() => {}} responsive>
          <TableColumn fieldName="externalId" className="fixed-width-200">
            <FormattedMessage id="Recipient id" defaultMessage="Recipient id" />
          </TableColumn>
          <TableColumn fieldName="contactDetails">
            <FormattedMessage
              id="Email/Phone number"
              defaultMessage="Email/Phone number"
            />
          </TableColumn>
          <TableColumn
            fieldName="status"
            formatter={notificationStatusFormatter}
            className="fixed-width-200"
          >
            <FormattedMessage id="Status" defaultMessage="Status" />
          </TableColumn>
          {children}
        </Table>
        <Pagination
          onClick={handlePageChange}
          numberOfPages={numberOfPages}
          currentPage={fullFilters.pagination.page}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      </ModalBody>
      {customFooter}
    </>
  );
};

export default RecipientsTable;
