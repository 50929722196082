import styled from 'styled-components';
import { Modal as ModalModule } from 'billon-ui';
import { Grid, Utils } from 'billon-ui';
import { down } from 'styled-breakpoints';

const { Text } = Utils;
const { Button: ButtonModule } = Utils;
const { BackButton: BillonBackButton } = ButtonModule;
const { ModalFooter: BillonModalFooter } = ModalModule;
const { Col } = Grid;

export const ModalFooter = styled(BillonModalFooter)`
  padding-left: 0;
  padding-right: 0;
`;

export const BackButton = styled(BillonBackButton)`
  padding: 0;
`;

export const ZeroCol = styled(Col)`
  padding-left: 11.2px;
`;

export const TitleContainer = styled(Col)`
  align-self: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const ModalTitle = styled.span`
  text-align: left;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const Title = styled(Text)`
  font-size: 2.5rem;
  margin-bottom: 4rem;
  font-weight: ${(props) => props.theme.fontWeightBold};

  ${down('lg')} {
    font-size: 1.9rem;
  }

  ${down('sm')} {
    font-size: 1.75rem;
  }
`;

export const CheckBoxWrapper = styled.span`
  padding-left: 11.2px;
`;
