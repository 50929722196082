import React from 'react';
import { useFormContext, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

// hooks
import { useNotificationGroupOptions } from '../../../../../../hooks/privateNotifications/useNotificationGroupOptions';

// helpers
import { boundLengthManualFormatter } from '../../../../../../../../formatters';

// Components
import CustomToolTip from '../../../../../../../../ui/CustomToolTip';
import { ControlledSelectField } from '../../../../../../../../components/ControlledSelectField/ControlledSelectField';
import EditButton from '../EditButton';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

const EditGroupNameButton = ({
  groupNameValue,
  isEditActive,
  groupNameFieldName,
  isEditing,
  isCreating,
  handleClose,
  handleActive,
}) => {
  const { notificationGroupOptions, areNotificationGroupsLoading } =
    useNotificationGroupOptions();

  const formMethods = useFormContext();

  return (
    <>
      {!isEditActive &&
        !!groupNameValue &&
        boundLengthManualFormatter(groupNameValue?.label, 64)}
      {!isEditActive && !groupNameValue && '---'}
      {isEditActive && (
        <FormProvider {...formMethods}>
          {isEditActive && (
            <ControlledSelectField
              name={groupNameFieldName}
              options={notificationGroupOptions}
              isLoading={areNotificationGroupsLoading}
              disabled={isEditing || isCreating}
              clean
              searchable={false}
              menuShouldBlockScroll={true}
            />
          )}
          {(isEditing || isCreating) && <Loader width={32} />}
          {!isEditing && !isCreating && (
            <>
              <CustomToolTip
                id={'save-' + groupNameFieldName}
                minWidth="8px"
                trigger={
                  <Styled.ActiveActionButton type="submit">
                    <Styled.SaveIcon />
                  </Styled.ActiveActionButton>
                }
              >
                <FormattedMessage id="Save" defaultMessage="Save" />
              </CustomToolTip>
              <CustomToolTip
                id={'close-' + groupNameFieldName}
                minWidth="8px"
                trigger={<Styled.CloseIcon onClick={handleClose} />}
              >
                <FormattedMessage id="Cancel" defaultMessage="Cancel" />
              </CustomToolTip>
            </>
          )}
        </FormProvider>
      )}
      {!isEditActive && !isEditing && !isCreating && (
        <EditButton handleActive={handleActive} name={groupNameFieldName} />
      )}
    </>
  );
};

export default EditGroupNameButton;
