import React from 'react';
import { useIntl } from 'react-intl';

// helpers
import { boundLengthManualFormatter } from '../../../../../../../../formatters';

// Components
import { ControlledTextFieldNotStyled } from '../../../../../../../../components/ControlledTextFieldNotStyled/ControlledTextFieldNotStyled';
import EditButton from '../EditButton';

const EditEmailTitleButton = ({
  isEditActive,
  emailTitleFieldName,
  handleActive,
  contentValue,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {!isEditActive && boundLengthManualFormatter(contentValue, 80)}
      {!isEditActive && !contentValue && '---'}
      {isEditActive && (
        <ControlledTextFieldNotStyled
          name={emailTitleFieldName}
          placeholder={formatMessage({
            id: 'Enter Subject content',
            defaultMessage: 'Enter Subject content',
          })}
          disabled={disabled}
        />
      )}
      {!isEditActive && (
        <EditButton handleActive={handleActive} name={emailTitleFieldName} />
      )}
    </>
  );
};

export default EditEmailTitleButton;
