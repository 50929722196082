import React from 'react';
import styled from 'styled-components';
import { Utils } from 'billon-ui';

export const AbsoluteDivForExitIcon = styled.div`
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
`;

export const Header = ({ children }) => (
  <Utils.Text
    as="h3"
    fontSize={'14px'}
    fontWeight={700}
    margin={'0 0 0.75rem 0'}
  >
    {children}
  </Utils.Text>
);
