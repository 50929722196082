export enum QueryKeys {
  ME = 'ME',
  SMS_CODE_REQUEST = 'SMS_CODE_REQUEST',
  UsersRequest = 'UsersRequest',
  DOCUMENT_LIST = 'DOCUMENT_LIST',
  USERS_LIST = 'USERS_LIST',
  SINGLE_USER = 'SINGLE_USER',
  SINGLE_DOCUMENT = 'SINGLE_DOCUMENT',
  DOCUMENT_FILE = 'DOCUMENT_FILE',
  DOCUMENT_NOTIFICATION_HISTORY = 'DOCUMENT_NOTIFICATION_HISTORY',
  DOCUMENT_RECIPIENTS = 'DOCUMENT_RECIPIENTS',
  SETTINGS = 'settings',
  CATEGORY_LIST = 'CATEGORY_LIST',
  IDENTITY_LIST = 'IDENTITY_LIST',
  IDENTITY_FILE = 'IDENTITY_FILE',
  SINGLE_IDENTITY = 'SINGLE_IDENTITY',
  UPDATE_IDENTITY_ASYNC_STATUS = 'UPDATE_IDENTITY_ASYNC_STATUS',
  NOTIFY_USER = 'NOTIFY_USER',
  // Reports
  YEARLY_REPORT = 'YEARLY_REPORT',
  FAIR_USAGE = 'FAIR_USAGE',
  CUSTOM_REPORT = 'CUSTOM_REPORT',
  // Tenants
  TENANT_LIST = 'TENANT_LIST',
  PUBLISH_DOCUMENT_ASYNC_STATUS = 'PUBLISH_DOCUMENT_ASYNC_STATUS',
  // Notifications
  NOTIFICATION_TEMPLATES = 'NOTIFICATION_TEMPLATES',
  NOTIFICATION_CONTENT = 'NOTIFICATION_CONTENT',
  NOTIFICATION_USER_INPUTS = 'NOTIFICATION_USER_INPUTS',
  // Private Notifications
  PRIVATE_NOTIFICATION_GROUP_TEMPLATES = 'PRIVATE_NOTIFICATION_GROUP_TEMPLATES',
  PRIVATE_SINGLE_NOTIFICATION_GROUP_TEMPLATE = 'PRIVATE_SINGLE_NOTIFICATION_GROUP_TEMPLATE',
  PRIVATE_NOTIFICATION_TEMPLATE_GROUPS = 'PRIVATE_NOTIFICATION_TEMPLATE_GROUPS',
  PRIVATE_SINGLE_NOTIFICATION_TEMPLATE_GROUP = 'PRIVATE_SINGLE_NOTIFICATION_TEMPLATE_GROUP',
  PRIVATE_NOTIFICATION_GROUP_CONTENTS = 'PRIVATE_NOTIFICATION_GROUP_CONTENTS',
  PRIVATE_SINGLE_NOTIFICATION_GROUP_CONTENT = 'PRIVATE_SINGLE_NOTIFICATION_GROUP_CONTENT',
  // Public Notifications
  PUBLIC_NOTIFICATION_TEMPLATES = 'PUBLIC_NOTIFICATION_TEMPLATES',
  PUBLIC_NOTIFICATION_TEMPLATE = 'PUBLIC_NOTIFICATION_TEMPLATE',
}
