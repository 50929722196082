import styled from 'styled-components';
import { FailureBadge } from '../../../../../ui/Badge';
import { Grid, Utils } from 'billon-ui';

const { Row: R, Col } = Grid;
const { Text } = Utils;

export const SectionColumn = styled(Col)``;

export const SuspendedBadge = styled(FailureBadge)`
  font-size: ${(props) => props.theme.fontSizeSm} !important;
  fill: ${(props) => props.theme.palette.grayLighter} !important;
  font-weight: ${(props) => props.theme.fontWeightMedium} !important;
  padding: 8px 24px;
`;

export const SuspensionRow = styled(R)`
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
`;
export const SuspensionCol = styled(Col)`
  padding: 0;
`;

export const Row = styled(R)`
  margin-bottom: 16px;
  padding: 0;
`;

export const Label = styled(Text)`
  font-size: 18px;
  font-weight: ${(props) => props.theme.fontWeightBold} !important;
  margin-bottom: 0;
`;
