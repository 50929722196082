import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { USER_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useRoleOptions } from '../../hooks/useRoleOptions';

// Components
import { ControlledSelectField } from '../../../../../../components/ControlledSelectField/ControlledSelectField';
import { FieldLabel } from '../../../../../../components/Form';

const SelectRoleField = ({
  onClear,
  isClearable,
  name = USER_FILTER_FIELD_NAMES.ROLE,
}) => {
  const { roleOptions } = useRoleOptions();
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Account type" defaultMessage="Account type" />
      </FieldLabel>
      <ControlledSelectField
        name={name}
        options={roleOptions}
        onClear={onClear}
        isClearable={isClearable}
      />
    </>
  );
};

export default SelectRoleField;
