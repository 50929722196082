import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

export const useUsersNotification = ({ id, type }, options) => {
  return useQueryWithLogout(
    [QueryKeys.NOTIFY_USER, id, type],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.NOTIFY_USER.replace('{id}', id) + `?type=${type}`,
        params: type,
      });
      return data;
    },
    options,
  );
};
