import styled from 'styled-components';
import { CSVLink } from 'react-csv';

export const ActionPanel = styled.div`
  position: absolute;
  top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;

export const ActionAnchor = styled.a`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
  margin-right: 1rem;
`;

export const CustomCSVLink = styled(CSVLink)`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
  margin-right: 1rem;
`;
