import styled from 'styled-components';

import { Grid } from 'billon-ui';
const { Row, Col } = Grid;

export const BorderedRow = styled(Row)`
  border-bottom: 1px lightGrey solid;
  margin-bottom: 16px;
`;

export const CenteredCol = styled(Col)`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
