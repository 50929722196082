import React from 'react';
import { ReportHistoryHeader, ReportHistoryTable } from './components';
import { useCustomReport } from '../../../../hooks/useCustomReport';
import { merge } from 'lodash';
import useFilters from '../../../../../../hooks/useFilters';
import { DOCUMENTS_OPTIONS, REPORT_TYPE } from '../../../../../../constraints';
import { Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const ReportHistory = () => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    filters: {
      historyDocumentType: DOCUMENTS_OPTIONS.ALL,
      reportType: REPORT_TYPE.MONTHLY,
      dateRange: '',
    },
  };
  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge({}, defaultFilters, filters);

  const { data: customReport, isLoading: isCustomReportLoading } =
    useCustomReport(fullFilters);

  const { pagination } = fullFilters;
  const { reportType, historyDocumentType, dateRange } = fullFilters.filters;

  const handleChangeHistoryDocumentType = (historyDocumentType) => {
    navigateWithSearchQuery({
      filters: {
        historyDocumentType: historyDocumentType,
      },
    });
  };

  const handleChangeReportType = (reportType) => {
    navigateWithSearchQuery({
      filters: {
        reportType: reportType,
      },
    });
  };

  const handleChangeDateRange = (dateRange) => {
    navigateWithSearchQuery({
      filters: {
        dateRange: dateRange,
      },
    });
  };

  const onPaginationChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  return (
    <>
      <ReportHistoryHeader
        reportType={reportType}
        onChangeReportType={handleChangeReportType}
        historyDocumentType={historyDocumentType}
        onChangeHistoryDocumentType={handleChangeHistoryDocumentType}
        dateRange={dateRange}
        onChangeDateRange={handleChangeDateRange}
      />

      {isCustomReportLoading ? (
        <PageLoader />
      ) : customReport ? (
        <ReportHistoryTable
          reportType={reportType}
          historyDocumentType={historyDocumentType}
          customReport={customReport}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
        />
      ) : (
        <FormattedMessage id="No data" defaultMessage="No data" />
      )}
    </>
  );
};

export default ReportHistory;
