import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../../../constraints';

// Components
import { CardHeader } from '../../../CardHeader/CardHeader';
import CreateTemplateLargeButton from '../CreateTemplateForm/components/CreateTemplateLargeButton';
import * as Styled from './styled';
import { Grid, Utils } from 'billon-ui';

const { Row, Col } = Grid;
const { Text } = Utils;

const HeaderNoGroupsWithCreation = () => (
  <Row md={12}>
    <Col md={12}>
      <CardHeader>
        <FormattedMessage
          id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES}
          defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES}
        />
      </CardHeader>
      <Styled.CenteredVericallyRow md={12} margin="4rem 0 0.5rem 0">
        <Col lg={4}>
          <Text as="h4" margin={0} textAlign="center">
            <FormattedMessage
              id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES_NONE}
              defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES_NONE}
            />
          </Text>
        </Col>
      </Styled.CenteredVericallyRow>
      <Styled.CenteredVericallyRow md={12} margin="0 0 0.5rem 0">
        <Col lg={4}>
          <Text as="h4" textAlign="center">
            <FormattedMessage
              id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES_FIRST}
              defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES_FIRST}
            />
          </Text>
        </Col>
      </Styled.CenteredVericallyRow>
      <Styled.CenteredVericallyRow md={12} margin="0 0 4rem 0">
        <CreateTemplateLargeButton />
      </Styled.CenteredVericallyRow>
    </Col>
  </Row>
);

export default HeaderNoGroupsWithCreation;
