import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const Label = styled.label`
  color: ${(props) => props.theme.palette.error};
  font-size: 0.75rem !important;
  margin-bottom: 0;

  &:not(.expand-error) {
    position: absolute;
  }
`;

const Error = ({ error, expandError }) => (
  <Label
    className={classNames({
      'expand-error': expandError,
    })}
  >
    {JSON.stringify(error) ? (
      error
    ) : (
      <FormattedMessage id={error} defaultMessage={error} />
    )}
  </Label>
);

Error.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  expandError: PropTypes.bool.isRequired,
};

export default Error;
