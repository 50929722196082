import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../constraints';

// hooks
import useFilters from '../../../../hooks/useFilters';
import { usePublicNotificationTemplateGroups } from '../../hooks/publicNotifications/usePublicNotificationTemplateGroups';
import { useSinglePublicNotificationTemplate } from '../../hooks/publicNotifications/useSinglePublicNotificationTemplate';
import useActivePublicTemplateId from '../../hooks/publicNotifications/useActivePublicTemplateId';

// components
import {
  HeaderWithLoader,
  HeaderWithGroupCreation,
  HorizontalPublicNotificationGroupList,
  HeaderNoGroupsWithCreation,
} from './components';
import PublicGroupContent from './components/GroupContent/PublicGroupContent';
import { Grid, Utils, Layout } from 'billon-ui';

const { Row } = Grid;
const { Text } = Utils;
const { Content } = Layout;
const { ErrorContent } = Content;

const PublicTemplates = () => {
  const { filters, navigateWithSearchQuery } = useFilters();
  const { activeTemplateId } = useActivePublicTemplateId();

  const { data: notificationGroups, isFetching: areNotificationGroupsLoading } =
    usePublicNotificationTemplateGroups();

  if (!filters?.filters?.templateId && !!activeTemplateId) {
    navigateWithSearchQuery({
      filters: { templateId: activeTemplateId },
    });
  }

  const { data: activeTemplate, isFetching: isActiveTemplateLoading } =
    useSinglePublicNotificationTemplate(activeTemplateId, {
      enabled: !!activeTemplateId,
    });

  const handleNotificationGroupClick = (templateId) => {
    navigateWithSearchQuery({
      filters: { templateId },
    });
  };

  if (areNotificationGroupsLoading || isActiveTemplateLoading) {
    return <HeaderWithLoader />;
  }

  if (!notificationGroups || notificationGroups?.length === 0) {
    return <HeaderNoGroupsWithCreation />;
  }

  return (
    <>
      <Row md={12}>
        <HeaderWithGroupCreation />
      </Row>
      <Row margin={'0 0 20px 0'}>
        <HorizontalPublicNotificationGroupList
          activeNotificationGroupId={activeTemplateId}
          notificationGroups={notificationGroups}
          handleNotificationGroupClick={handleNotificationGroupClick}
        />
      </Row>
      {!!activeTemplate ? (
        <PublicGroupContent templateData={activeTemplate} />
      ) : (
        <ErrorContent>
          <Text as="h4" margin="4rem 0 0 0">
            <FormattedMessage
              id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_ERROR}
              defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_ERROR}
            />
          </Text>
        </ErrorContent>
      )}
    </>
  );
};

export default PublicTemplates;
