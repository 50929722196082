import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// hooks
import { useSettingsContext } from '../../../../../../modules/Settings/hooks/useSettings';

// constants
import appRoutes from '../../../../../../app-routes';
import {
  DOCUMENT_STATUS,
  DOCUMENT_PUBLICATION_STATUS,
  SETTINGS_CONSTANTS,
} from '../../../../../../constraints';

// Components
import * as Styled from './styled';
import { DeleteDocumentButton } from '../DeleteDocumentButton';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const ActionButtons = ({ record, ...props }) => {
  const { push } = useHistory();
  const { getSettingValueByKey } = useSettingsContext();
  const isOneStepPublication = getSettingValueByKey(
    SETTINGS_CONSTANTS.PUBLIC_ONE_STEP_PUBLISH,
  );

  const onDeleteDocument = () => props.onDeleteDocument(record);
  const onPublishDocument = () => props.onPublishDocument(record, true);
  const onMoveToDocumentDetails = () =>
    push(
      appRoutes.DAILY_REPORT_DETAILS.replace(
        ':id',
        record?.documentBlockchainAddress ?? record?.jobId,
      ),
    );
  const onMoveToDeprecatedDocumentDetails = () =>
    push(`/document/${record.documentBlockchainAddress}`);

  return (
    <Styled.Row>
      {record.status === DOCUMENT_STATUS.PREPARED_TO_SIGN && (
        <>
          <DeleteDocumentButton onDeleteDocument={onDeleteDocument} />
          {!isOneStepPublication && (
            <Styled.Col md={6}>
              <Button outline onClick={onPublishDocument} block>
                <FormattedMessage
                  id="Publish document"
                  defaultMessage="Publish document"
                />
              </Button>
            </Styled.Col>
          )}
        </>
      )}
      {record.status === DOCUMENT_STATUS.CURRENT && (
        <Styled.Col md={6}>
          <Button outline onClick={onMoveToDocumentDetails} block>
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
          </Button>
        </Styled.Col>
      )}
      {record.status === DOCUMENT_STATUS.DEPRECATED && (
        <Styled.Col md={6}>
          <Button outline onClick={onMoveToDeprecatedDocumentDetails} block>
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
          </Button>
        </Styled.Col>
      )}
      {record.documentPublicationStatus ===
        DOCUMENT_PUBLICATION_STATUS.PUBLISHING_ERROR && (
        <DeleteDocumentButton onDeleteDocument={onDeleteDocument} />
      )}
    </Styled.Row>
  );
};
