import { useMemo } from 'react';

import { usePrivateNotificationGroupContents } from './usePrivateNotificationGroupContents';

const useValidNotificationGroupContent = ({
  enabled,
  activeNotificationGroupId,
}) => {
  const {
    data: groupContents,
    isFetching: areGroupContentsLoading,
    isSuccess: isContentSuccess,
    error: groupContentsError,
  } = usePrivateNotificationGroupContents(null, activeNotificationGroupId, {
    enabled: enabled ?? !!activeNotificationGroupId,
  });

  const validGroupContent = useMemo(() => {
    if (isContentSuccess && groupContents?.rows?.length > 0) {
      const foundValidContent = groupContents?.rows
        ?.filter(({ validUntil }) => !!validUntil)
        ?.find(
          ({ validSince, validUntil }) =>
            new Date(validSince) < new Date(Date.now()) &&
            new Date(Date.now()) < new Date(validUntil),
        );
      if (!!foundValidContent) {
        return foundValidContent;
      }
      const foundUnsetValidUntilContent = groupContents?.rows?.find(
        ({ validUntil }) => !validUntil,
      );
      return (
        foundUnsetValidUntilContent ??
        groupContents?.rows[groupContents.rows?.length - 1]
      );
    }
  }, [groupContents, isContentSuccess]);

  return {
    groupContents,
    areGroupContentsLoading,
    isContentSuccess,
    groupContentsError,
    validGroupContent,
  };
};

export default useValidNotificationGroupContent;
