import { useMemo } from 'react';

import { usePublicNotificationTemplateGroups } from './usePublicNotificationTemplateGroups';
import useFilters from '../../../../hooks/useFilters';

const useActivePublicTemplateId = () => {
  const { filters } = useFilters();

  const {
    data: notificationTemplates,
    isSuccess,
    isLoading,
  } = usePublicNotificationTemplateGroups();

  const activeTemplateId = useMemo(() => {
    if (isLoading && !isSuccess) {
      return;
    }
    const fitlerTemplateId = filters?.filters?.templateId;
    if (!!fitlerTemplateId) {
      return fitlerTemplateId;
    }
    if (notificationTemplates?.length === 0) {
      return;
    }
    const firstTemplateId = notificationTemplates[0]?.id;
    if (!fitlerTemplateId && !!firstTemplateId) {
      return firstTemplateId;
    }
  }, [filters, notificationTemplates, isSuccess, isLoading]);

  return { activeTemplateId };
};

export default useActivePublicTemplateId;
