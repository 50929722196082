import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Button: ButtonModule, Card } = Utils;
const { Button: B, IconButton } = ButtonModule;

export const TableCard = styled(Card)`
  padding: 0 0 20px 0;
  box-shadow: none;
`;

export const Button = styled(B)`
  gap: 0.5rem;
`;

export const RefreshButton = styled(IconButton)`
  margin: -30px 0px 0px 8px;
`;
