import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import * as Styled from './styled';
import CategoryTree from '../CategoryTree';

const CategoriesContainer = () => (
  <Styled.Container>
    <Styled.FirstRow>
      <Styled.FirstCell />
      <Styled.NameCell bold>
        <FormattedMessage id="Category name" defaultMessage="Category name" />
      </Styled.NameCell>
      <Styled.Cell bold>
        <FormattedMessage id="Category ID" defaultMessage="Category ID" />
      </Styled.Cell>
      <Styled.WiderCell bold>
        <FormattedMessage id="E-mail title" defaultMessage="E-mail title" />
      </Styled.WiderCell>
      <Styled.Cell bold>
        <FormattedMessage
          id="Notification template"
          defaultMessage="Notification template"
        />
      </Styled.Cell>
    </Styled.FirstRow>
    <CategoryTree />
  </Styled.Container>
);

export default CategoriesContainer;
