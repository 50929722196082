import React from 'react';

import * as Styled from './styled';
import { Grid } from 'billon-ui';

const { Row, Col } = Grid;

const FeatureCard = ({ children }) => {
  return (
    <Row md={12}>
      <Col md={12}>
        <Styled.Card>{children}</Styled.Card>
      </Col>
    </Row>
  );
};

export default FeatureCard;
