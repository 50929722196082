import React from 'react';
import { Modal as ModalModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { map_PUBLICATION_MODES } from '../../../../constraints';

const { Modal } = ModalModule;

const UpdateModal = ({ isOpen, toggle, onClose, mode, children }) => {
  const title = map_PUBLICATION_MODES[mode];

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClose={onClose}
      title={title && <FormattedMessage id={title} defaultMessage={title} />}
    >
      {children}
    </Modal>
  );
};

export default UpdateModal;
