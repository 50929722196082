import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';

// constants
import {
  RECIPIENT_FILTER_FIELD_NAMES,
  SHARED_SECRET_FIELD_NAMES,
  SHARED_SECRET_TYPE,
  MAP_SHARED_SECRET_TYPE,
  MAP_SHARED_SECRET_TYPE_PLACEHOLDER,
  MAP_RECIPIENT_FIELD_NAMES,
} from '../../../../../../constraints';

// hooks
import { useSharedSecretOptions } from '../../hooks/useSharedSecretOptions';

// Components
import { ControlledSelectField } from '../../../../../../components/ControlledSelectField/ControlledSelectField';
import { ControlledTextField } from '../../../../../../components/ControlledTextField';

const SelectSharedSecretField = () => {
  const { watch, setValue, clearErrors } = useFormContext();
  const { sharedSecretOptions } = useSharedSecretOptions();

  const documentTypeWatcher = watch(RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_TYPE);
  const documentNumberWatcher = watch(
    RECIPIENT_FILTER_FIELD_NAMES.DOCUMENT_NUMBER,
  );
  const initialSharedSecretWatcher = watch(
    RECIPIENT_FILTER_FIELD_NAMES.SHARED_SECRET,
  );
  const sharedSecretTypeWatcher = watch(
    SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_TYPE,
  );

  const isSharedSecretValueDisplayed = useMemo(() => {
    const sharedSecretType =
      sharedSecretTypeWatcher?.value || sharedSecretTypeWatcher;

    if (sharedSecretType === SHARED_SECRET_TYPE.NONE) {
      return false;
    }

    return !!sharedSecretType;
  }, [sharedSecretTypeWatcher]);

  const isSharedSecretValueDisabled = useMemo(() => {
    const documentType = documentTypeWatcher?.value || documentTypeWatcher;
    const sharedSecretType =
      sharedSecretTypeWatcher?.value || sharedSecretTypeWatcher;

    if (
      documentType === SHARED_SECRET_TYPE.PESEL &&
      sharedSecretType === SHARED_SECRET_TYPE.PESEL
    ) {
      return true;
    }

    return false;
  }, [documentTypeWatcher, sharedSecretTypeWatcher]);

  const sharedSecretValueLabelId = useMemo(() => {
    if (!!sharedSecretTypeWatcher?.value || !!sharedSecretTypeWatcher) {
      return MAP_SHARED_SECRET_TYPE[
        sharedSecretTypeWatcher?.value || sharedSecretTypeWatcher
      ];
    }
  }, [sharedSecretTypeWatcher]);

  const sharedSecretValuePlaceholder = useMemo(() => {
    if (!!sharedSecretTypeWatcher?.value || !!sharedSecretTypeWatcher) {
      return MAP_SHARED_SECRET_TYPE_PLACEHOLDER[
        sharedSecretTypeWatcher?.value || sharedSecretTypeWatcher
      ];
    }
  }, [sharedSecretTypeWatcher]);

  useEffect(() => {
    const documentType = documentTypeWatcher?.value;
    const documentNumberValue = documentNumberWatcher;
    const sharedSecretType = sharedSecretTypeWatcher?.value;
    const initialSharedSecretValue = initialSharedSecretWatcher?.value;
    const initialSharedSecretType = initialSharedSecretWatcher?.type;

    const isSsvCopiedFromDocumentNumber =
      (documentType === SHARED_SECRET_TYPE.PESEL &&
        sharedSecretType === SHARED_SECRET_TYPE.PESEL) ||
      (documentType === SHARED_SECRET_TYPE.NIP &&
        sharedSecretType === SHARED_SECRET_TYPE.NIP);
    if (isSsvCopiedFromDocumentNumber) {
      setValue(
        SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_VALUE,
        documentNumberValue,
      );
      clearErrors(SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_VALUE);
      return;
    }

    const isSsvCopiedFromInitialSsv =
      sharedSecretType === initialSharedSecretType;
    if (isSsvCopiedFromInitialSsv) {
      setValue(
        SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_VALUE,
        initialSharedSecretValue,
      );
      return;
    }

    const isSsvCleared =
      !!sharedSecretType && sharedSecretType !== documentType;
    if (isSsvCleared && !isSsvCopiedFromInitialSsv) {
      setValue(SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_VALUE, '');
      clearErrors(SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_VALUE);
      return;
    }
  }, [
    documentTypeWatcher,
    documentNumberWatcher,
    sharedSecretTypeWatcher,
    initialSharedSecretWatcher,
  ]);

  return (
    <>
      <ControlledSelectField
        name={SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_TYPE}
        options={sharedSecretOptions}
        label={
          <FormattedMessage
            id={MAP_RECIPIENT_FIELD_NAMES.SHARED_SECRET_TYPE}
            defaultMessage={MAP_RECIPIENT_FIELD_NAMES.SHARED_SECRET_TYPE}
          />
        }
      />
      {isSharedSecretValueDisplayed && (
        <ControlledTextField
          name={SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_VALUE}
          disabled={isSharedSecretValueDisabled}
          placeholder={sharedSecretValuePlaceholder}
          label={
            <>
              <FormattedMessage
                id={MAP_RECIPIENT_FIELD_NAMES.SHARED_SECRET_TYPE}
                defaultMessage={MAP_RECIPIENT_FIELD_NAMES.SHARED_SECRET_TYPE}
              />
              {' - '}
              <FormattedMessage
                id={sharedSecretValueLabelId}
                defaultMessage={sharedSecretValueLabelId}
              />
            </>
          }
        />
      )}
    </>
  );
};

export default SelectSharedSecretField;
