import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button: Btn } = ButtonModule;

export const Button = styled(Btn)`
  font-weight: ${(props) => props.theme.fontWeightMedium} !important;
  padding: 0.5rem 3.5rem;
  margin-top: ${(props) => !props.outline && '1px'} !important;
`;
