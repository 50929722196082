import {
  SIGNING_STATUS,
  SIGNING_ORDER,
  DOCUMENT_STATUS,
} from '../../../constraints';

export const getSigningFlags = (
  enableSigning,
  signingStatus,
  signingOrder,
  documentStatus,
  nextDocumentStatus,
) => {
  const areSigningStepsDisabled = () => {
    if (!enableSigning || !signingOrder) {
      return true;
    }

    return (
      signingStatus === SIGNING_STATUS.PUBLISHING_OK &&
      [SIGNING_ORDER.NO_SIGNATURE, SIGNING_ORDER.PUBLISHER_ONLY].includes(
        signingOrder,
      )
    );
  };

  const areAdvancedFeaturesEnabled = () => {
    if (
      [
        DOCUMENT_STATUS.PREPARED_TO_SIGN,
        DOCUMENT_STATUS.UPLOADING,
        DOCUMENT_STATUS.PUBLISHING_INITIATED,
      ].includes(nextDocumentStatus)
    ) {
      return false;
    }
    if (enableSigning) {
      if ([SIGNING_STATUS.WAITING_FOR_RECEIVER].includes(signingStatus)) {
        return true;
      }
      return areSigningStepsDisabled();
    }
    return true;
  };

  const isDocumentAccepted = signingStatus === SIGNING_STATUS.PUBLISHING_OK;

  const isDocumentRejected = signingStatus === SIGNING_STATUS.REJECTED;

  const isDocumentDeprecated =
    ([SIGNING_STATUS.PUBLISHING_OK, SIGNING_STATUS.REJECTED].includes(
      signingStatus,
    ) &&
      documentStatus === DOCUMENT_STATUS.DEPRECATED) ||
    documentStatus === DOCUMENT_STATUS.DEPRECATED;

  const isWaitingForReceiver =
    signingStatus === SIGNING_STATUS.WAITING_FOR_RECEIVER;

  const isIncidentEnabled =
    areAdvancedFeaturesEnabled() && documentStatus === DOCUMENT_STATUS.CURRENT;

  const isErratumEnabled =
    areAdvancedFeaturesEnabled() &&
    ![SIGNING_STATUS.WAITING_FOR_RECEIVER].includes(signingStatus);

  const isNewVersionEnabled =
    areAdvancedFeaturesEnabled() &&
    ![SIGNING_STATUS.WAITING_FOR_RECEIVER].includes(signingStatus);

  const isResendNotificationEnabled = () => {
    if (!enableSigning || !signingOrder) {
      return true;
    }

    return (
      !isDocumentDeprecated &&
      !isDocumentRejected &&
      (!areSigningStepsDisabled() ||
        (areAdvancedFeaturesEnabled() &&
          [SIGNING_STATUS.WAITING_FOR_RECEIVER].includes(signingStatus)) ||
        isDocumentAccepted)
    );
  };

  return {
    areSigningStepsDisabled: areSigningStepsDisabled(),
    areAdvancedFeaturesEnabled: areAdvancedFeaturesEnabled(),
    isResendNotificationEnabled: isResendNotificationEnabled(),
    isNewVersionEnabled,
    isErratumEnabled,
    isIncidentEnabled,
    isWaitingForReceiver,
    isDocumentRejected,
  };
};
