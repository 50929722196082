import React from 'react';
import { Utils } from 'billon-ui';
import CustomToolTip from '../CustomToolTip';
import * as Styled from './styled';

const { Icon } = Utils;

const HeaderWithToolTip = ({
  title,
  tooltipId,
  iconColor = 'grey',
  iconName = 'info-circle',
  children,
}) => {
  return (
    <>
      {title}{' '}
      <CustomToolTip
        id={tooltipId}
        trigger={<Icon name={iconName} color={iconColor} />}
      >
        <Styled.ToolTipContentWrapper>{children}</Styled.ToolTipContentWrapper>
      </CustomToolTip>
    </>
  );
};

export default HeaderWithToolTip;
