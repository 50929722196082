import React from 'react';

// utils
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  mapERRORS,
  UNKNOWN_ERRORS,
  MAP_RECOVER_PASSWORD_ERRORS,
} from '../../../../constraints';
import { useResetPassword } from '../../hooks/useResetPassword';

// hooks
import { useSchema } from './useSchema';

// components
import { Form, Utils } from 'billon-ui';
import * as Styled from './styled';

const { Form: F, TextField, FormGroup } = Form;
const { Button: ButtonModule } = Utils;
const { ButtonLoader } = ButtonModule;

const RecoverPasswordForm = ({ onBack, onSuccess, deliveredReasons }) => {
  const schema = useSchema();

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setError,
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const onRecoverPasswordFailure = ({ response }) => {
    const statusCode =
      response?.data?.statusCode || UNKNOWN_ERRORS.UNKNOWN_ERROR;
    const message = response?.data?.statusCode
      ? MAP_RECOVER_PASSWORD_ERRORS[statusCode]
      : mapERRORS.UNKNOWN_ERROR;

    setError('email', {
      type: 'custom',
      message: message,
    });
  };

  const { mutate: resetPassword } = useResetPassword({
    onSuccess: onSuccess,
    onError: onRecoverPasswordFailure,
  });

  const handleSubmitRecovery = (data) => {
    resetPassword(data);
  };

  return (
    <F onSubmit={handleSubmit(handleSubmitRecovery)}>
      <FormGroup>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                  error: errors?.[field.name]?.message,
                }}
                label={<FormattedMessage id="Email" defaultMessage="Email" />}
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup className="form-submit">
        {!isSubmitting ? (
          <Styled.SubmitButton block size="lg">
            <FormattedMessage id="Next" defaultMessage="Next" />
          </Styled.SubmitButton>
        ) : (
          <ButtonLoader block size="lg" />
        )}
      </FormGroup>
      <FormGroup>
        <Styled.BackButton secondary onClick={onBack}>
          <FormattedMessage id="back" defaultMessage="back" />
        </Styled.BackButton>
      </FormGroup>
    </F>
  );
};

export default RecoverPasswordForm;
