import React, { useMemo, useEffect } from 'react';
import { Form, Grid, Utils } from 'billon-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import useFilters from '../../../../../../hooks/useFilters';
import {
  DOCUMENT_RECIPIENTS_GROUPS,
  DOCUMENT_RECIPIENTS_FILTERS_OPTIONS,
} from '../../../../../../constraints';
import WithValueMessage from '../../../../../../helpers/WithValueMessage';
import { useForm, Controller } from 'react-hook-form';
import { merge } from 'lodash';
import { removeUndefinedValues } from '../../../../../../helpers/removeUndefinedValues';
import { parseDocumentRecipientsFilters } from '../../../../helpers';
import * as Styled from './styled';

const { SelectField, TextField } = Form;
const { Button: ButtonModule } = Utils;
const { ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

const RecipientsFilterForm = ({ isSearchByGroupEnabled, initialValues }) => {
  const { formatMessage } = useIntl();
  const { filters, navigateWithSearchQuery, onClear } = useFilters();

  let fieldsOptions = [
    {
      label: formatMessage({ id: 'Email/Phone' }),
      value: DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.CONTACT_DETAILS,
    },
    {
      label: formatMessage({ id: 'Identifier' }),
      value: DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.RECIPIENT_ID,
    },
  ];

  if (isSearchByGroupEnabled) {
    fieldsOptions = [
      ...fieldsOptions,
      {
        label: formatMessage({ id: 'Group' }),
        value: DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.GROUPS,
      },
    ];
  }

  const groupOptions = [
    {
      label: formatMessage({
        id: 'New recipients',
      }),
      value: DOCUMENT_RECIPIENTS_GROUPS.NEW,
    },
    {
      label: formatMessage({
        id: 'Incorrectly notified',
      }),
      value: DOCUMENT_RECIPIENTS_GROUPS.ERROR,
    },
    {
      label: formatMessage({
        id: 'Delivered',
      }),
      value: DOCUMENT_RECIPIENTS_GROUPS.SUCCESS,
    },
  ];

  const { field } = filters || {};
  const { groups } = initialValues || {};

  const formInitialValues = {
    contactDetails: '',
    recipientId: '',
    groups: groups,
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: merge({}, formInitialValues, {
      field: field
        ? fieldsOptions.filter((option) => option.value === field)
        : fieldsOptions[0],
      ...filters,
    }),
  });

  const selectedField = watch('field');

  const customClearField = () => {
    setValue('recipientId', formInitialValues.clientId);
    setValue('contactDetails', formInitialValues.contactDetails);
    isSearchByGroupEnabled
      ? setValue('groups', formInitialValues.notificationStatusList)
      : setValue('groups', formInitialValues.groups);
  };

  const onClearFilters = () => {
    onClear({ filters: { groups: formInitialValues.groups } });
    customClearField();
  };

  const onSubmit = (values) => {
    navigateWithSearchQuery({
      filters: removeUndefinedValues(parseDocumentRecipientsFilters(values)),
    });
  };

  let memoizedFieldValue = useMemo(
    () => selectedField.value,
    [selectedField.value],
  );

  useEffect(() => {
    onClearFilters();
  }, [memoizedFieldValue]);

  let ValueField = () => {
    if (!selectedField) {
      return;
    } else if (
      selectedField.value ===
      DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.CONTACT_DETAILS
    ) {
      return (
        <Controller
          name="contactDetails"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              input={field}
              meta={{
                touched: fieldState.invalid,
                error: fieldState.error,
              }}
            />
          )}
        />
      );
    } else if (
      selectedField.value === DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.RECIPIENT_ID
    ) {
      return (
        <Controller
          name="recipientId"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              input={field}
              meta={{
                touched: fieldState.invalid,
                error: fieldState.error,
              }}
            />
          )}
        />
      );
    } else if (
      selectedField.value === DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.GROUPS &&
      isSearchByGroupEnabled
    ) {
      return (
        <Controller
          name="groups"
          control={control}
          render={({ field, fieldState }) => (
            <SelectField
              input={field}
              meta={{
                touched: fieldState.invalid,
                error: fieldState.error,
              }}
              options={groupOptions}
            />
          )}
        />
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row md={12}>
        <Col md={4}>
          <Styled.Text>
            <FormattedMessage id="Search by" defaultMessage="Search by" />
            {':'}
          </Styled.Text>
          <Controller
            name="field"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                input={field}
                meta={{
                  touched: fieldState.invalid,
                  error: fieldState.error,
                }}
                options={fieldsOptions}
              />
            )}
          />
        </Col>
        <Col md={5}>
          <Styled.Text>
            {WithValueMessage({
              messageId: 'Type {name}',
              name: 'name',
              value: formatMessage({
                id: ['group', 'grupa'].includes(
                  selectedField?.label.toLowerCase(),
                )
                  ? 'group2'
                  : selectedField?.label.toLowerCase() || 'value',
              }),
            })}
            {':'}
          </Styled.Text>
          <ValueField />
        </Col>
        <Col md={3}>
          <Row md={12}>
            <Col md={6}>
              <Styled.Text>
                <FormattedMessage id="Clear" defaultMessage="Clear" />
              </Styled.Text>
              <Styled.ActionButton
                onClick={onClearFilters}
                block
                outline
                type="button"
              >
                <Styled.Close />
              </Styled.ActionButton>
            </Col>
            <Col md={6}>
              <Styled.Text>
                <FormattedMessage id="Search" defaultMessage="Search" />
              </Styled.Text>
              {!isSubmitting ? (
                <Styled.ActionButton block outline type="submit">
                  <Styled.Search />
                </Styled.ActionButton>
              ) : (
                <ButtonLoader block outline />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

export default RecipientsFilterForm;
