import React from 'react';
import { connect } from 'react-redux';
import CalendarField from '../../ui/CalendarField';

const CF = (props) => <CalendarField {...props} />;

const mapStateToProps = ({ config, language }) => ({
  locale: language.language || config.language,
});

export default connect(mapStateToProps)(CF);
