import React from 'react';

// components
import * as Styled from './styled';
import {
  StatisticsHeader,
  Consumption,
  YearlyPublication,
  ReportHistory,
} from './components';
import { Grid } from 'billon-ui';

const { Row, Col } = Grid;

const Statistics = () => {
  return (
    <>
      <StatisticsHeader />
      <Row>
        <Col md={6}>
          <Styled.Card>
            <YearlyPublication />
          </Styled.Card>
        </Col>
        <Col md={6}>
          <Styled.Card>
            <ReportHistory />
          </Styled.Card>
        </Col>
      </Row>
      <Styled.FairUsageRowWrapper>
        <Col md={12}>
          <Styled.Card>
            <Consumption />
          </Styled.Card>
        </Col>
      </Styled.FairUsageRowWrapper>
    </>
  );
};

export default Statistics;
