import styled from 'styled-components';
import { Utils, Grid, Layout } from 'billon-ui';

const { Content: ContentModule } = Layout;
const { ErrorContent: BillonErrorContent } = ContentModule;
const { Text } = Utils;
const { Row, Col } = Grid;

export const FormHeader = styled(Text)`
  font-size: 13px;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const CenterRow = styled(Row)`
  margin: 0;
  align-items: center;
  padding: 2rem 0 1rem;
  flex-direction: row;
  justify-content: center;
`;

export const EditPanel = styled.div`
  border: 1px solid #c9d4de;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 240px;
  justify-content: center;
`;

export const PanelText = styled(Text)`
  font-size: 14px;
  text-align: center;
  margin-bottom: 31px;
`;

export const CenteredSingleRow = styled(Row)`
  justify-content: center;
`;

export const DownloadButtonContainer = styled(Col)`
  width: 164px;
  padding: 0;
  height: 46px;
`;

export const TextDanger = styled(Text)`
  color: #ad113b;
`;

export const ErrorRow = styled.div`
  padding-left: 3rem;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const LightBold = styled.span`
  background-color: ${(props) => props.theme.palette.grayLightest};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  border: 1px grey solid;
  padding: 1px 5px 1px 5px;
`;

export const SpanDanger = styled.span`
  color: #ad113b;
`;

export const Title = styled(Text)`
  font-size: 2.5rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const SubTitle = styled(Text)`
  font-size: 13px;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin-bottom: 2rem;
`;

export const ZeroParagraph = styled.p`
  margin: 0px;
`;

export const ErrorContent = styled(BillonErrorContent)``;

export const ErrorContentWrapper = styled.div`
  margin-bottom: 4rem;
`;

export const MinContent = styled.div`
  min-height: 400px;
`;

export const ErrorContentSubtitle = styled.p`
  font-weight: 200 !important;
  font-size: 1rem;
  margin-bottom: 0;
  color: grey;
`;
