import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// hooks
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useDownloadSingleDocument } from '../../hooks';
import { useResendDocumentNotification } from '../../hooks/useResendDocumentNotification';
import { useDownloadRecipientsList } from '../../hooks/useDownloadRecipientsList';

// utils
import { downloadQrCode } from '../../helpers/utils';

// connstants
import {
  DOCUMENT_TYPE,
  RESEND_NOTIFICATION_MODES,
  UserRoles,
} from '../../../../constraints';

// Components
import { BlockchainVisual, CopiedInfo } from '../../../../components';
import { ProtectedComponent } from '../../../Auth/components';
import {
  AdvancedFeatures,
  BadgedTitle,
  DetailsSection,
  ManageSection,
} from './components';
import { Grid, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { BackButton } = ButtonModule;
const { Row, Col } = Grid;

const DocumentDetails = ({ document, onBackClick, backButtonLabel }) => {
  const history = useHistory();
  const { customer } = useConfigContext();

  const {
    mutate: resendDocumentNotification,
    isLoading: isResendingAuthorizationCode,
  } = useResendDocumentNotification({
    id: document.documentBlockchainAddress,
    documentType: document.documentType,
  });

  const { mutate: download, isLoading: isDownloading } =
    useDownloadSingleDocument(document.jobId, document.title);

  const {
    mutate: downloadRecipientsList,
    isLoading: isDownloadingRecipientsList,
  } = useDownloadRecipientsList(document.jobId, document.title);

  const handleNotificationHistoryModalOpen = () => {
    history.push(`/document/${document.jobId}/notificationHistory`);
  };

  const handleResendNotificationClick = () => {
    if (document?.documentType === DOCUMENT_TYPE.PRIVATE) {
      history.push(
        `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.EDIT_TYPE_VIEW}`,
      );
    } else {
      history.push(
        `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SELECT_TYPE}`,
      );
    }
  };

  const handleDownloadQRClick = () =>
    downloadQrCode(
      document.documentPreviewUrl,
      document.documentBlockchainAddress,
    );

  const handleDownloadRecipientsList = () => {
    downloadRecipientsList();
  };

  const nextDocument = useMemo(() => {
    const currentIndex = document?.history?.findIndex(
      ({ documentBlockchainAddress }) =>
        documentBlockchainAddress === document.documentBlockchainAddress,
    );
    if (currentIndex > 0) {
      return document?.history[currentIndex - 1];
    }
    return null;
  }, [document]);

  return (
    <Row margin={0} padding={0}>
      <Col xl={12}>
        <Row margin={'0 0 20px 0 '}>
          <BackButton secondary onClick={onBackClick}>
            {backButtonLabel}
          </BackButton>
        </Row>
        <Row margin={0}>
          <BadgedTitle
            title={document.title}
            errata={document.errata}
            publicationMode={document.publicationMode}
            status={document.status}
            signingStatus={document?.signingStatus}
            signingOrder={document?.signingOrder}
            nextPublicationMode={nextDocument?.publicationMode}
          />
        </Row>
        <Row xl={12}>
          <Col xl={4}>
            <Col margin={'0 0 20px 0'} padding={0}>
              <CopiedInfo
                label={'Document blockchain address'}
                alt={'Document does not yet have a blockchain address'}
                scale={'section'}
                emphasisedHeader
                valuePlacedUnder
                value={document?.documentBlockchainAddress}
              />
            </Col>
            <ManageSection
              documentType={document.documentType}
              customer={customer}
              handleNotificationHistoryModalOpen={
                handleNotificationHistoryModalOpen
              }
              handleDownloadRecipientsList={handleDownloadRecipientsList}
              isDownloadingRecipientsList={isDownloadingRecipientsList}
              handleResendNotificationClick={handleResendNotificationClick}
              resendAuthorizationCode={resendDocumentNotification}
              isResendingAuthorizationCode={isResendingAuthorizationCode}
              onDownload={download}
              isDownloading={isDownloading}
              document={document}
            />
            <ProtectedComponent
              roles={[
                UserRoles.AGENT,
                UserRoles.BUSINESS_ADMIN,
                UserRoles.ADMIN,
              ]}
              render={false}
            >
              <AdvancedFeatures
                handleDownloadQRClick={handleDownloadQRClick}
                document={document}
                customer={customer}
                nextDocumentStatus={nextDocument?.status}
              />
            </ProtectedComponent>
          </Col>
          <Col xl={{ size: 5, offset: 0.5 }}>
            <DetailsSection document={document} />
          </Col>
          <Col
            xl={{ size: 2, offset: 0 }}
            lg={{ size: 6, offset: 3 }}
            sm={{ size: 8, offset: 2 }}
          >
            <BlockchainVisual remoteSignAnimation size="100%" fixed />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DocumentDetails;
