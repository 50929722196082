import React from 'react';
import * as Styled from './styled';
import { get } from 'lodash';
import { useConfigContext } from '../../modules/Config/hooks/useConfig';

export const RefreshButton = ({ isRefreshDisabled, handleRefresh }) => {
  const { config } = useConfigContext();

  const refreshButtonIcon = isRefreshDisabled ? 'ban' : 'sync';
  const refreshButtonIconColor = isRefreshDisabled
    ? 'gray'
    : get(config, 'theme.palette.primary', '#00adff');

  return (
    <Styled.RefreshButtonWrapper
      onClick={handleRefresh}
      icon={refreshButtonIcon}
      iconColor={refreshButtonIconColor}
      disabled={isRefreshDisabled}
    />
  );
};
