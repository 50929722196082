import React from 'react';

// formatters
import { notificationReasonWithToolTipFormatter } from '../../formatters';
import * as formatters from '../../../../../../formatters';

// components
import { Table as TableModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import TableColumn from '../../../../../../ui/TableColumn';
import { CopiedInfo } from '../../../../../../components';
import * as Styled from './styled';

const { Table } = TableModule;

const NotificationHistoryDetails = ({ ancestor, errorsHistory }) => {
  const indexFormatter = (value, row) => errorsHistory.indexOf(row) + 1;
  const sendingStartDateFormatter = (value, row) =>
    value
      ? formatters.momentInTimezoneFormatter(value)
      : formatters.momentInTimezoneFormatter(ancestor.sendingStartDate);

  return (
    <>
      <Styled.EmbededCopiedInfoWrapper>
        <CopiedInfo
          label={'Notification ID'}
          value={ancestor.jobId}
          valuePlacedBetween
          alt={'No ID to display'}
          emphasisedHeader={true}
        />
      </Styled.EmbededCopiedInfoWrapper>

      <Styled.EmbededTableWrapper>
        <Styled.DetailsTitle>
          <FormattedMessage
            id="Attempts at automatic renewal"
            defaultMessage="Attempts at automatic renewal"
          />
          {':'}
        </Styled.DetailsTitle>

        <Table responsive tableData={errorsHistory} padding={0}>
          <TableColumn
            className="fixed-width-200"
            fieldName="#"
            formatter={indexFormatter}
          >
            <FormattedMessage id="Attempt" defaultMessage="Attempt" />
          </TableColumn>

          <TableColumn
            className="fixed-width-200"
            fieldName="sendingStartDate"
            formatter={sendingStartDateFormatter}
          >
            <FormattedMessage
              id="Date of delivery"
              defaultMessage="Date of delivery"
            />
          </TableColumn>
          <TableColumn
            fieldName="errorDate"
            formatter={formatters.momentInTimezoneFormatter}
          >
            <FormattedMessage
              id="End date of the action"
              defaultMessage="End date of the action"
            />
          </TableColumn>
          <TableColumn
            className="fixed-width-200"
            fieldName="reason"
            formatter={notificationReasonWithToolTipFormatter}
          >
            <FormattedMessage id="Status" defaultMessage="Status" />
          </TableColumn>
          <TableColumn className="fixed-width-56" fieldName="empty-1" />
        </Table>
      </Styled.EmbededTableWrapper>
    </>
  );
};

export default NotificationHistoryDetails;
