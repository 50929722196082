import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

export const useDocumentNotificationHistory = (filters, options) => {
  return useQueryWithLogout(
    [QueryKeys.DOCUMENT_NOTIFICATION_HISTORY, filters, options.id],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.DOCUMENT_NOTIFICATION_HISTORY.replace(
          '{id}',
          options?.id,
        ),
        params: filters,
      });

      return data;
    },
    options,
  );
};
