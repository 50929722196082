import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const TemplateHeader = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeightBold};
  color: ${(props) => props.theme.palette.primary};
  font-size: ${(props) => props.theme.fontSizeLg};
`;

export const EmailSubject = styled(Text)`
  /* margin-top: -8px; */
  margin-bottom: 8px;
`;

export const EmailSubjectTag = styled.span`
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  font-size: ${(props) => props.theme.fontSizeSm};
`;

export const TenantName = styled(Text)`
  @media (min-width: 900px) {
    margin-top: ${({ isMinusMarginTop }) =>
      isMinusMarginTop ? '-28px' : '0px'};
  }
  margin-bottom: 8px;
  font-size: ${(props) => props.theme.fontSizeBase};
`;
