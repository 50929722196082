import Select from 'react-select';
import styled from 'styled-components';

export const CustomizedSelect = styled(Select)`
  width: 100%;
  transition: ${(props) => props.theme.transitionBase};

  svg {
    width: auto;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    background-color: transparent;
  }

  > div {
    border-color: ${(props) => props.theme.palette.grayLighter} !important;
    box-shadow: none !important;
    border-radius: ${(props) => props.theme.borderRadius};
  }

  .billon-select__menu-list {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }

  .billon-select__control--is-disabled {
    cursor: ${(props) => props.theme.disabledCursor};
    background-color: ${(props) => props.theme.disabledBackground};
  }

  .billon-select__placeholder {
    color: ${(props) => props.theme.palette.grayLight} !important;
  }
`;

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    fontSize: '.875rem',
    padding: '0.2rem 0.1rem',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 500,
  }),
};
