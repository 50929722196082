import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';
import { QueryKeys } from '../../../queryKeys';
import apiRoutes from '../../../api-routes';
import { getRequest } from '../../../helpers/apiClient';

export const useUpdateIdentityAsyncWithStatusCheck = (id, options) => {
  return useQueryWithLogout(
    [QueryKeys.UPDATE_IDENTITY_ASYNC_STATUS, id],
    async () => {
      const { data: updatedIdentityData } = await getRequest({
        url: apiRoutes.SINGLE_RECIPIENT_UPDATE_STATUS.replace('{id}', id),
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
      });

      return updatedIdentityData;
    },
    options,
  );
};
