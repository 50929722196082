import apiRoutes from '../../../api-routes';
import { getRequest } from '../../../helpers/apiClient';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';
import { QueryKeys } from '../../../queryKeys';

export const useDownloadDocument = (id, options) =>
  useQueryWithLogout(
    [QueryKeys.DOCUMENT_FILE, { id }],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.SINGLE_DOCUMENT_DOWNLOAD.replace('{id}', id),
        responseType: 'arraybuffer',
      });

      return data;
    },
    options,
  );
