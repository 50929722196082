import React from 'react';
import { Modal as ModalModule } from 'billon-ui';

const { Modal } = ModalModule;

const NotificationContentModal = ({
  isLoading,
  isOpen,
  toggle,
  error,
  title,
  children,
}) => {
  if (isLoading || error) {
    return null;
  }

  return (
    <Modal toggle={toggle} isOpen={isOpen} title={title}>
      {children}
    </Modal>
  );
};

export default NotificationContentModal;
