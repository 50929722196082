import React from 'react';

// contants
import appRoutes from '../../../../app-routes';
import { PASSWORD_FORM_MODES } from '../../../../constraints';

// hooks
import { useRouteMatch } from 'react-router-dom';
import { useSingleUser } from '../../hooks/useSingleUser';

// Components
import { CenteredContent } from '../../../../components/UniCardContent/UniCardContent';
import AdministrativePasswordChange from '../../components/AdministrativePasswordChange';
import { Layout as LayoutModule, Utils } from 'billon-ui';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const AdministrativePasswordChangePage = () => {
  const { params } = useRouteMatch(appRoutes.ADMIN_PASSWORD_CHANGE);
  const { id } = params;

  const { data: user, isLoading: isUserLoading } = useSingleUser({ id });

  if (isUserLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <CenteredContent>
      <AdministrativePasswordChange
        id={id}
        mode={PASSWORD_FORM_MODES.ADMINISTRATIVE_PASSWORD_CHANGE}
        role={user.role}
      />
    </CenteredContent>
  );
};

export default AdministrativePasswordChangePage;
