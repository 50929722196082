import styled from 'styled-components';
import { Grid } from 'billon-ui';

const { Row } = Grid;

export const ModalContent = styled.div`
  padding: 0 48px 0 48px;
`;

export const ContentRow = styled(Row)`
  margin-left: 0;
  margin-bottom: 16px;
`;

export const Tag = styled.span`
  font-weight: ${(props) => props.theme.fontWeightBold} !important;
  margin-right: 8px;
`;
