import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// hooks
import useFilters from '../../../../../../hooks/useFilters';
import { useCategoryList } from '../../../../../Categories/hooks/useCategoryList';

// components
import CategoryRow from '../CategoryRow/CategoryRow';

import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule, Text } = Utils;
const { Loader, PageLoader } = LoaderModule;

const CategoryTree = ({ parentId, depth = 0, onCreate }) => {
  const { filters } = useFilters();
  const [openCategories, setOpenCategories] = useState([]);

  const { data, isLoading } = useCategoryList({
    filters: {
      parentId,
      ...filters.filters,
    },
  });

  const isCategoryOpen = (categoryId) =>
    openCategories.some((cat) => cat === categoryId);
  const toggleOpenCategory = (categoryId) => {
    const alreadyOpened = isCategoryOpen(categoryId);
    if (alreadyOpened) {
      setOpenCategories(openCategories.filter((cat) => cat !== categoryId));
    } else {
      setOpenCategories([categoryId, ...openCategories]);
    }
  };

  if (isLoading) {
    if (depth === 0) {
      return <PageLoader />;
    }
    return (
      <Styled.LoaderPadding>
        <Loader width={48} />
        <Text as="body">
          <FormattedMessage
            id="Loading data..."
            defaultMessage="Loading data..."
          />
        </Text>
      </Styled.LoaderPadding>
    );
  }

  return (
    <>
      {(data?.rows || []).map((category) => (
        <CategoryRow
          parentId={parentId}
          depth={depth}
          category={category}
          isLoading={isLoading}
          onCreate={onCreate}
          isCategoryOpen={isCategoryOpen}
          toggleOpenCategory={toggleOpenCategory}
        />
      ))}
    </>
  );
};

export default CategoryTree;
