import { SHARED_SECRET_TYPE } from '../../../../constraints';

export const parseSharedSecret = (sharedSecretType, sharedSecretValue) => {
  if (
    !!sharedSecretType &&
    !!sharedSecretValue &&
    sharedSecretType?.value !== SHARED_SECRET_TYPE.NONE
  ) {
    return {
      type: sharedSecretType?.value,
      value: sharedSecretValue,
    };
  }
};
