import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProtectedComponent } from '../../../../../../modules/Auth/components';
import { LoadingActionButton } from '../../../../../../components';
import { UserRoles } from '../../../../../../constraints';
import { useDownloadAnalyticsReport } from '../../../../hooks/useDownloadAnalyticsReport';
import { ErrorModal } from '../../../../../../components/ErrorModal';
import { ExportableErrorContent } from '../../../../../../components/ExportableErrorContent';
import { mapERRORS } from '../../../../../../constraints';

export const ReportDownloadBtn = ({ fullFilters }) => {
  const {
    mutate: downloadAnalyticsReport,
    isLoading: isDownloadingAnalyticsReport,
    isError,
    error,
    reset,
  } = useDownloadAnalyticsReport();

  const handleDownloadClick = () => {
    downloadAnalyticsReport(fullFilters);
  };

  const handleCloseErrorModal = () => {
    reset();
  };

  const errorStatusCode =
    error &&
    JSON.parse(Buffer.from(error?.response?.data).toString('utf8'))?.statusCode;
  const chosenStatusCode = mapERRORS[errorStatusCode] && errorStatusCode;

  return (
    <ProtectedComponent
      roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
      render={false}
    >
      <LoadingActionButton
        onClick={handleDownloadClick}
        inActive={isDownloadingAnalyticsReport}
      >
        <FormattedMessage
          id="Download report in xls"
          defaultMessage="Download report in xls"
        />
      </LoadingActionButton>
      <ErrorModal
        isOpen={isError}
        toggle={handleCloseErrorModal}
        onClose={handleCloseErrorModal}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={mapERRORS.NOT_FOUND}
          statusCode={chosenStatusCode}
          exportDisabled
        />
      </ErrorModal>
    </ProtectedComponent>
  );
};
