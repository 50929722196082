import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';

// constants
import {
  password_role_based_info_list,
  password_other_info_list,
  MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS,
  REPORT_ROLE,
} from '../../../../../../constraints';
import { UserRoles } from '../../../../../../userRoles';

// Components
import Accordion from '../../../../../../components/Accordion/Accordion';
import ValidationInfo from '../ValidationInfo';
import * as Styled from './styled';

const PasswordInfo = ({ reasons, role, reportRole }) => {
  const { formatMessage } = useIntl();
  const { usersConfig } = useConfigContext();
  const { isPasswordInfoNested } = usersConfig || {};
  const { isTokenExpired } = useSettingsContext();

  const {
    formState: { errors, isSubmitting, isSubmitSuccessful, isSubmitted },
  } = useFormContext();

  const accordionHeader = formatMessage({ id: 'Password setting rules' });

  const PasswordRoleBasedInfo = () =>
    role &&
    password_role_based_info_list.map((requirement) => {
      const keyPrefix =
        [UserRoles.ADMIN].includes(role) ||
        [REPORT_ROLE.ADMIN].includes(reportRole)
          ? UserRoles.ADMIN
          : 'USER';
      const settingsKey = `${keyPrefix}_${MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[requirement]}`;
      const isErrorActive =
        (reasons && reasons.includes(requirement)) ||
        Object.entries(errors).find(
          ([key, value]) => value?.type === requirement,
        )
          ? true
          : false;
      return (
        <ValidationInfo
          settingsKey={settingsKey}
          requirement={requirement}
          isErrorActive={isErrorActive}
        />
      );
    });

  const PasswordOtherInfo = () =>
    password_other_info_list.map((requirement) => {
      const isErrorActive =
        (reasons && reasons.includes(requirement)) ||
        Object.entries(errors).find(
          ([key, value]) => value?.type === requirement,
        )
          ? true
          : false;
      return (
        <ValidationInfo
          settingsKey={MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[requirement]}
          requirement={requirement}
          isErrorActive={isErrorActive}
        />
      );
    });

  const isError = useMemo(() => {
    return Object.keys(errors).length > 0;
  }, [errors]);

  const isFormSubmitted = useMemo(() => {
    return !isSubmitting && !isSubmitSuccessful && isSubmitted;
  }, [isSubmitSuccessful, isSubmitted, isSubmitting]);

  return (
    <Accordion
      header={accordionHeader}
      isNested={isPasswordInfoNested}
      isTriggered={isError}
      isFormSubmitted={isFormSubmitted}
    >
      {isTokenExpired && (
        <Styled.ErrorLabel isErrorActive>
          <FormattedMessage
            id="Link is expired"
            defaultMessage="Link is expired"
          />
        </Styled.ErrorLabel>
      )}
      <PasswordRoleBasedInfo />
      <PasswordOtherInfo />
    </Accordion>
  );
};

export default PasswordInfo;
