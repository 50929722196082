import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom';

// Utils
import appRoutes from '../../../../app-routes';
import { RECIPIENT_STATUSES, UserRoles } from '../../../../constraints';

// hooks
import { useSingleIdentity } from '../../../Recipients/hooks';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// components
import { ProtectedComponent } from '../../../Auth/components';
import {
  RecipientDetails,
  ForgetDocumentsForm,
  RecipientNotFound,
} from '../../components';
import { Layout as LayoutModule, Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { ContentContainer, Content } = ContentModule;

const RecipientPage = () => {
  const { customer, isDiploma } = useConfigContext();
  const { params } = useRouteMatch(appRoutes.GDPR_RECIPIENT);
  const id = params?.id;
  const {
    data: recipient,
    isLoading,
    isError,
  } = useSingleIdentity({ id: id }, { enabled: true });

  const history = useHistory();
  const { formatMessage } = useIntl();

  const handleOnBack = () => {
    history.push(appRoutes.GDPR);
  };

  const backBtnMessageId = isDiploma ? 'Students' : 'Recipients';
  const backButtonLabel = formatMessage({ id: backBtnMessageId });

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  if (recipient?.status === RECIPIENT_STATUSES.FORGETTING) {
    return <Redirect to={appRoutes.GDPR} />;
  }

  if (!recipient || isError) {
    return (
      <ContentContainer fluid>
        <Content fluid>
          <RecipientNotFound
            onBackClick={handleOnBack}
            backButtonLabel={backButtonLabel}
          />
        </Content>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer fluid>
      <Content fluid>
        <ProtectedComponent roles={[UserRoles.DPI]} render={true}>
          <RecipientDetails
            recipient={recipient}
            customer={customer}
            onBackClick={handleOnBack}
            backButtonLabel={backButtonLabel}
          />
          <ForgetDocumentsForm
            recipient={recipient}
            onBackClick={handleOnBack}
          />
        </ProtectedComponent>
      </Content>
    </ContentContainer>
  );
};

export default RecipientPage;
