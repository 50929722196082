import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

// constants
import { PUBLICATION_NOTIFICATION_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useNotificationContentForm } from '../../../PublishDocumentModal/components/NotificationContentForm/hooks/useNotificationContentForm';

// COmponents
import {
  SelectEditTypeModal,
  EmailNotificationModal,
  SmsNotificationModal,
} from './components';
import { Utils, Layout } from 'billon-ui';

const { Content } = Layout;
const { ErrorContent } = Content;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const EnterNotificationContent = ({
  isOpen,
  isLoading: isResending,
  onBack,
  onClose,
  onNext,
  defaultValues = {},
  documentBlockchainAddress,
  documentType: notificationTemplateType,
}) => {
  const publicDocumentType = defaultValues?.publicDocumentType;
  const categoryNotificationTemplate =
    defaultValues?.category?.notificationTemplate;
  const categoryId = defaultValues?.categoryId;
  const tenantId = defaultValues?.tenantId;

  const formMethods = useForm({
    defaultValues: {
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_INPUT]: '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_INPUT]: '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_INPUT]: '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_USER_INPUT_TAG_FIELD]:
        '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_USER_INPUT_TAG_FIELD]:
        '',
      [PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_USER_INPUT_TAG_FIELD]:
        '',
    },
  });
  const { handleSubmit, watch, setValue } = formMethods;

  const emailSubjectUserInputTagField = watch(
    PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_USER_INPUT_TAG_FIELD,
  );
  const emailContenttUserInputTagField = watch(
    PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_USER_INPUT_TAG_FIELD,
  );
  const smsContenttUserInputTagField = watch(
    PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_USER_INPUT_TAG_FIELD,
  );

  const {
    isLoading,
    isError,
    contents,
    defaultInputs,
    isEmailSubjectUserInputTagFieldEnabled,
    isEmailContenttUserInputTagFieldEnabled,
    isSmsContentUserInputTagFieldEnabled,
    emailSubjectRenderValue,
    emailRenderValue,
    smsRenderValue,
  } = useNotificationContentForm({
    documentBlockchainAddress,
    categoryId,
    categoryNotificationTemplate,
    tenantId,
    publicDocumentType,
    notificationTemplateType,
    emailSubjectUserInputTagField,
    emailContenttUserInputTagField,
    smsContenttUserInputTagField,
  });

  useEffect(() => {
    const { emailUserInput, smsUserInput } = defaultInputs || {};
    if (!!emailUserInput) {
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_USER_INPUT_TAG_FIELD,
        emailUserInput,
      );
    }
    if (!!smsUserInput) {
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_USER_INPUT_TAG_FIELD,
        smsUserInput,
      );
    }
  }, [defaultInputs]);

  useEffect(() => {
    if (!!contents) {
      const { subjectInput, emailInput, smsInput } = contents || {};
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_SUBJECT_INPUT,
        subjectInput,
      );
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_INPUT,
        emailInput,
      );
      setValue(
        PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_INPUT,
        smsInput,
      );
    }
  }, [contents]);

  const handleFormSubmit = (values) => {
    onNext(values);
  };

  const [isEmailContentOpened, setIsEmailContentOpened] = useState(false);
  const [isSmsContentOpened, setIsSmsContentOpened] = useState(false);

  const onEditEmailNotification = () => {
    setIsEmailContentOpened(true);
  };
  const onEditSmsNotification = () => {
    setIsSmsContentOpened(true);
  };
  const onEditEmailNotificationClose = () => {
    setIsEmailContentOpened(false);
  };
  const onEditSmsNotificationClose = () => {
    setIsSmsContentOpened(false);
  };

  if (isLoading) {
    return (
      <div>
        <PageLoader
          message={
            <FormattedMessage
              id="Templates are loading..."
              defaultMessage="Templates are loading..."
            />
          }
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <ErrorContent>
          <FormattedMessage
            id="Error while loading templates..."
            defaultMessage="Error while loading templates..."
          />
        </ErrorContent>
      </div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <SelectEditTypeModal
          isOpen={isOpen}
          isLoading={isResending || isLoading}
          onBack={onBack}
          onClose={onClose}
          onNext={handleSubmit(handleFormSubmit)}
          onEditSmsNotification={onEditSmsNotification}
          onEditEmailNotification={onEditEmailNotification}
          isEmailEditable={isEmailContenttUserInputTagFieldEnabled}
          isSmsEditable={isSmsContentUserInputTagFieldEnabled}
        />
        <EmailNotificationModal
          isOpen={isEmailContentOpened}
          isLoading={isResending || isLoading}
          onBack={onEditEmailNotificationClose}
          onClose={onEditEmailNotificationClose}
          onNext={onEditEmailNotificationClose}
          emailPreviewValue={emailRenderValue}
          isEditable={isEmailContenttUserInputTagFieldEnabled}
          fieldName={
            PUBLICATION_NOTIFICATION_FIELD_NAMES.EMAIL_CONTENT_USER_INPUT_TAG_FIELD
          }
        />
        <SmsNotificationModal
          isOpen={isSmsContentOpened}
          isLoading={isResending || isLoading}
          onBack={onEditSmsNotificationClose}
          onClose={onEditSmsNotificationClose}
          onNext={onEditSmsNotificationClose}
          smsContentPreviewValue={smsRenderValue}
          isEditable={isSmsContentUserInputTagFieldEnabled}
          fieldName={
            PUBLICATION_NOTIFICATION_FIELD_NAMES.SMS_CONTENT_USER_INPUT_TAG_FIELD
          }
        />
      </form>
    </FormProvider>
  );
};

export default EnterNotificationContent;
