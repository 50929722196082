import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { isUndefined, mergeWith } from 'lodash';

const useFilters = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const filters = useMemo(() => {
    return qs.parse(search.slice(1), { allowDots: true });
  }, [search]);

  const compileUrlWithQuery = (url) => `${url}?${qs.stringify(filters)}`;

  const customizer = (previousValue, currentValue) => {
    if (isUndefined(currentValue)) {
      return { ...currentValue };
    }

    return { ...previousValue, ...currentValue };
  };

  const navigateWithSearchQuery = (newFilters, url) => {
    history.push(
      `${url || pathname}?${qs.stringify(
        mergeWith(filters, newFilters, customizer),
      )}`,
    );
  };

  const navigateWithNewSearchQuery = (newFilters, url) => {
    history.push(`${url || pathname}?${qs.stringify(newFilters)}`);
  };

  const onClear = (defaultFilters) => {
    history.push({
      ...history.location,
      search: qs.stringify(defaultFilters),
    });
  };

  const onSingleFilterClear = (name, defaultFilters) => {
    const { [name]: _, ...rest } = filters?.filters || {};

    history.push({
      ...history.location,
      search: qs.stringify({ filters: rest }),
    });
  };

  return {
    navigateWithSearchQuery,
    navigateWithNewSearchQuery,
    filters,
    compileUrlWithQuery,
    onClear,
    onSingleFilterClear,
  };
};

export default useFilters;
