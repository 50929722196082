import base from '../../base';
import { CUSTOMER } from '../../../constraints';

const config = {
  ...base,
  backendUrl: 'https://apipublisher.bc.sygnity.pl',
  enablePrivate: true,
  customer: CUSTOMER.DEFAULT,
  language: 'pl',
};

export default config;
