import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { Form as FormModule, Utils, Grid } from 'billon-ui';
const { Button: ButtonModule, Icon } = Utils;
const { Row } = Grid;

export const Form = styled(FormModule.Form)`
  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    margin-top: -3.5rem;
    width: calc(100% - 35px);
  }
`;

export const FormGroup = styled(FormModule.FormGroup)`
  position: relative;
`;

export const IconButton = styled(ButtonModule.IconButton)`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
`;

export const CenterRow = styled(Row)`
  margin: 0;
  align-items: center;
  padding: 2rem 0 1rem;

  ${down('lg')} {
    flex-direction: column-reverse;
  }
`;

export const BackButton = styled(ButtonModule.BackButton)`
  ${down('lg')} {
    width: 100%;
    margin: 0.5rem 0;
  }
`;

export const ToolTipContentWrapper = styled.p`
  padding: 16px;
  margin: 0;
`;

export const HeaderIcon = styled(Icon)`
  color: grey;
`;
