import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Utils, Grid } from 'billon-ui';
import { LoginCard } from '../../components';
import config from 'js/config';
import * as Styled from './styled';
import BlockedModal from '../../components/BlockedModal/BlockedModal';
import { BlockedMessage } from '../../components/BlockedModal/BlockedMessage';
import { LoginForm } from '../../components';

const { customer } = config;
const { Col } = Grid;
const { BillonLogo } = Utils;

const HomePage = () => {
  const [accountBlockedModalOpen, setAccountBlockedModalOpen] = useState(false);
  const [accountBlockedUnblockDate, setAccountBlockedUnblockDate] =
    useState(null);

  const handleCloseAccountBlockedModal = () => {
    setAccountBlockedModalOpen(false);
  };

  const handleAccountBlocked = (value) => {
    setAccountBlockedUnblockDate(value);
    setAccountBlockedModalOpen(true);
  };

  return (
    <Styled.LoginContent>
      <Col
        sm={{ size: 12, offset: 0 }}
        md={{ size: 8, offset: 2 }}
        lg={{ size: 4, offset: 1 }}
      >
        <Styled.Description>
          <Styled.Title>
            <FormattedMessage
              id="Trusted document register"
              defaultMessage="Trusted document register"
            />
          </Styled.Title>
          <Styled.Small>
            <FormattedMessage id="POWERED BY" defaultMessage="POWERED BY" />
          </Styled.Small>
          <BillonLogo black />
        </Styled.Description>
      </Col>
      <Col
        sm={{ size: 12, offset: 0 }}
        md={{ size: 8, offset: 2 }}
        lg={{ size: 5, offset: 1 }}
      >
        <LoginCard customer={customer}>
          <LoginForm onBlocked={handleAccountBlocked} />
        </LoginCard>
      </Col>
      <BlockedModal
        isOpen={accountBlockedModalOpen}
        toggle={handleCloseAccountBlockedModal}
        onClose={handleCloseAccountBlockedModal}
        closeIconEnabled={false}
      >
        <BlockedMessage rawDate={accountBlockedUnblockDate} />
      </BlockedModal>
    </Styled.LoginContent>
  );
};

export default HomePage;
