import React from 'react';
import * as Styled from './styled';

const ValidationIndicator = () => (
  <Styled.ValidationIndicatorRow>
    <Styled.ValidationIndicatorLine />
    <Styled.ValidationIndicatorLine />
    <Styled.ValidationIndicatorLine />
  </Styled.ValidationIndicatorRow>
);

export default ValidationIndicator;
