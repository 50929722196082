import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// constants
import {
  RECIPIENT_ACTIONS,
  RECIPIENT_UPLOAD_ERROR_VALUES,
} from '../../../../../../../constraints';
import { RECIPIENT_ERROR_CODES } from '../../../../../../../constants';

// Components
import * as Styled from '../styled';
import { Grid, Utils } from 'billon-ui';

const { Text } = Utils;
const { Col } = Grid;

const RowsToUpdateColumn = ({
  statusCode,
  mode,
  validationErrors,
  isNeighbourDisplayed,
}) => {
  const { formatMessage } = useIntl();

  const RowsToUpdateColSize = isNeighbourDisplayed ? 6 : 12;

  const MappedRowsToUpdate =
    statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR &&
    [
      RECIPIENT_ACTIONS.INSERT_LIST,
      RECIPIENT_ACTIONS.EXTERNAL_VALIDATION,
    ].includes(mode) &&
    validationErrors.filter((singleError) => {
      const needsUpdateEntries = Object.entries(singleError.errorInfo).filter(
        ([key, value]) => value === RECIPIENT_UPLOAD_ERROR_VALUES.NEEDS_UPDATE,
      );
      return needsUpdateEntries?.length > 0;
    });

  const MergedRowsToUpdate = () => (
    <>
      {statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR &&
        [
          RECIPIENT_ACTIONS.INSERT_LIST,
          RECIPIENT_ACTIONS.EXTERNAL_VALIDATION,
        ].includes(mode) &&
        MappedRowsToUpdate.map((singleError) => (
          <div>
            <Text margin={0}>
              {formatMessage({
                id: 'Row number {n} will be updated',
              }).replace('{n}', singleError.line)}
            </Text>
          </div>
        ))}
    </>
  );

  return (
    <Col md={RowsToUpdateColSize}>
      <Text fontWeight={800}>
        <FormattedMessage
          id="Added to update"
          defaultMessage="Added to update"
        />
        {':'}
      </Text>
      <Styled.EditPanel>
        <MergedRowsToUpdate />
      </Styled.EditPanel>
    </Col>
  );
};

export default RowsToUpdateColumn;
