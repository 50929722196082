import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Button: ButtonModule, Text } = Utils;
const { Button } = ButtonModule;

export const ActionButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary};
`;

export const TemplateHeader = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeightBold};
  color: ${(props) => props.theme.palette.primary};
  font-size: ${(props) => props.theme.fontSizeLg};
`;

export const TemplateTenantHeader = styled(TemplateHeader)`
  font-weight: ${(props) => props.theme.fontWeightExtraBold};
  font-size: ${(props) => props.theme.fontSize24};
`;

export const TemplateWrapper = styled.div`
  padding-bottom: 16px;
`;

export const SingleTemplateHeader = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: ${(props) => props.theme.fontSizeBase};
`;

export const EmailSubject = styled(Text)`
  margin-top: -8px;
  margin-bottom: 8px;
`;

export const EmailSubjectTag = styled.span`
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  font-size: ${(props) => props.theme.fontSizeSm};
`;
