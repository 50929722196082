import React, { useState, useMemo, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { merge } from 'lodash';
import { QueryKeys } from '../../../../queryKeys';
import {
  DOCUMENT_TYPE,
  documentPublicationCkkERRORS,
} from '../../../../constraints';

// hooks
import useFilters from '../../../../hooks/useFilters';
import { useQueryClient } from 'react-query';
import { useForgetDocuments } from '../../hooks/useForgetDocuments';
import { useDocumentList } from '../../../Documents/hooks';

// components
import { SuccessModal } from '../../../../components/SuccessModal';
import { ErrorModal } from '../../../../components/ErrorModal';
import { FormattedMessage } from 'react-intl';
import { Layout as LayoutModule } from 'billon-ui';
import {
  RecipientDocuments,
  ConfirmGDPRModal,
  ExecuteButton,
} from './components';

const { Content: ContentModule } = LayoutModule;
const { ErrorContent } = ContentModule;

const ForgetDocumentsForm = ({ recipient }) => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    filters: {
      documentTypeList: DOCUMENT_TYPE.PRIVATE,
      identity: {
        documentNumber: recipient.documentNumber,
      },
    },
  };

  const { filters } = useFilters();
  const fullFilters = merge({}, defaultFilters, filters);
  const queryClient = useQueryClient();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const shortFilters = {
    documentTypeList: fullFilters.filters.documentTypeList,
    identity: fullFilters.filters.identity,
  };
  const { isFetching: areDocumentsFetching } = useDocumentList(
    { filters: shortFilters },
    { enabled: true },
  );

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };
  const handleConfirmModalOpen = () => {
    queryClient.invalidateQueries([
      QueryKeys.DOCUMENT_LIST,
      { filters: shortFilters },
    ]);
    setIsConfirmModalOpen(true);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  const formMethods = useForm({
    defaultValues: { rodoDocuments: [], documentsToDelete: [] },
  });

  const { handleSubmit, getValues, watch, setValue } = formMethods;

  const handleFormSubmit = () => {};

  const theWatcher = watch();

  const storedAdresses = useMemo(() => {
    const rodoDocuments = getValues('rodoDocuments');
    return Object.entries(rodoDocuments)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);
  }, [theWatcher]);

  const storedJobIds = useMemo(() => {
    const documentsToDelete = getValues('documentsToDelete');
    return Object.entries(documentsToDelete)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);
  }, [theWatcher]);

  let isExecuteButtonVisible = storedAdresses.length + storedJobIds.length > 0;

  const unSelectFields = () => {
    storedAdresses.forEach((address) => {
      setValue(`rodoDocuments[${address}]`, false);
    });
    storedJobIds.forEach((jobId) => {
      setValue(`documentsToDelete[${jobId}]`, false);
    });
  };

  const selectAllFields = useCallback(() => {
    Object.entries(theWatcher.rodoDocuments).forEach(([key, value]) =>
      setValue(`rodoDocuments[${key}]`, true),
    );
    Object.entries(theWatcher.documentsToDelete).forEach(([key, value]) =>
      setValue(`documentsToDelete[${key}]`, true),
    );
  }, [theWatcher]);

  const handleOnSubmit = () => {
    const mutationValues = {
      adressess: storedAdresses,
      jobIds: storedJobIds,
    };
    forgetDocuments(mutationValues);
  };

  const handleForgetSuccess = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_IDENTITY, recipient.id]);
    unSelectFields();
    queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST, fullFilters]);
    handleConfirmModalClose();
    setIsSuccessModalOpen(true);
  };

  const handleForgetFailure = (statusCode) => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_IDENTITY, recipient.id]);
    unSelectFields();
    queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST, fullFilters]);
    handleConfirmModalClose();
    unSelectFields();
    const statusCodeCheck = documentPublicationCkkERRORS[statusCode];
    if (
      !!statusCodeCheck &&
      [
        documentPublicationCkkERRORS.FORGETTING_INITIATED,
        documentPublicationCkkERRORS.FORGETTING_IN_PROGRESS,
        documentPublicationCkkERRORS.FORGETTING_OK,
      ].includes(statusCodeCheck)
    ) {
      setIsSuccessModalOpen(true);
    } else {
      setIsErrorModalOpen(true);
    }
  };

  const { mutate: forgetDocuments, isLoading: isForgetting } =
    useForgetDocuments({
      onSuccess: handleForgetSuccess,
      onError: ({ response }) =>
        handleForgetFailure(response?.data?.statusCode),
    });

  const handleSelectUnselectAll = () => {
    if (storedAdresses.length + storedJobIds.length > 0) {
      unSelectFields();
    } else {
      selectAllFields();
    }
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <RecipientDocuments
            recipient={recipient}
            defaultFilters={defaultFilters}
          >
            <>
              <ExecuteButton
                messageId="Select/unselect all"
                outline
                handleTrigger={handleSelectUnselectAll}
              >
                {` (${storedAdresses.length + storedJobIds.length})`}
              </ExecuteButton>{' '}
              {isExecuteButtonVisible ? (
                <ExecuteButton
                  messageId="Execute GDPR"
                  handleTrigger={handleConfirmModalOpen}
                />
              ) : (
                <ExecuteButton messageId="Execute GDPR" disabled outline />
              )}
            </>
          </RecipientDocuments>
          <ConfirmGDPRModal
            isOpen={isConfirmModalOpen}
            onSubmit={handleOnSubmit}
            onClose={handleConfirmModalClose}
            documentsNumber={storedAdresses.length + storedJobIds.length}
            isSubmitting={isForgetting || areDocumentsFetching}
          />
        </form>
      </FormProvider>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        toggle={handleSuccessModalClose}
        onClose={handleSuccessModalClose}
        closeIconEnabled={true}
      >
        <FormattedMessage
          id="GDPR execution began"
          defaultMessage="GDPR execution began"
        />
      </SuccessModal>
      <ErrorModal
        isOpen={isErrorModalOpen}
        toggle={handleErrorModalClose}
        onClose={handleErrorModalClose}
        closeIconEnabled={true}
      >
        <ErrorContent>
          <FormattedMessage
            id="Error during GDPR execution"
            defaultMessage="Error during GDPR execution"
          />
        </ErrorContent>
      </ErrorModal>
    </>
  );
};

export default ForgetDocumentsForm;
