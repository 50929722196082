import apiRoutes from '../../../api-routes';
import { patchRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

export const useAdministrativePatchPassword = (options) => {
  return useMutationWithLogout(async ({ id, passwordData }) => {
    const { data } = await patchRequest({
      url: apiRoutes.ADMINISTRATIVE_MODIFY_PASSWORD.replace('{id}', id),
      data: passwordData,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });
    return data;
  }, options);
};
