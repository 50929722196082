import { postRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../api-routes';

export const useCategoryCreate = (options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: updateData } = await postRequest({
      url: apiRoutes.CATEGORY,
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return updateData;
  }, options);
