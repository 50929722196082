import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

// constants
import { QueryKeys } from '../../../../../../queryKeys';

// hooks
import { usePatchPrivateSingleNotificationGroupTemplate } from '../../../../hooks/privateNotifications/usePatchPrivateSingleNotificationGroupTemplate';
import { usePostPrivateSingleNotificationGroupTemplate } from '../../../../hooks/privateNotifications/usePostPrivateSingleNotificationGroupTemplate';
import { useNotificationCategoryRow } from './hooks/useNotificationCategoryRow';
import { usePrivateEmailTitleSchema } from '../../../../hooks/schemas/usePrivateEmailTitleSchema';

// components
import CategoryTree from '../CategoryTree';
import { ExpandButton } from '../../../../../Categories/components/ExpandButton';
import EditGroupNameButton from './components/EditGroupNameButton';
import EditEmailTitleButton from './components/EditEmailTitleButton';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

const CategoryRow = ({
  parentId,
  depth,
  category,
  onCreate,
  isLoading,
  isCategoryOpen,
  toggleOpenCategory,
}) => {
  const queryClient = useQueryClient();

  const {
    id: categoryId,
    hasChildren,
    name,
    notificationTemplate,
  } = category || {};
  const { emailTitle, groupName, groupId } = notificationTemplate || {};

  const [isEditActive, setIsActive] = useState(false);

  const {
    computedDefaultValues,
    emailTitleValue,
    firstNotificationGroupOption,
    emailTitleFieldName,
    groupNameFieldName,
    notificationGroupOptions,
    areNotificationGroupsLoading,
  } = useNotificationCategoryRow({
    groupId: categoryId,
    emailTitle,
    groupName,
  });
  const schema = usePrivateEmailTitleSchema({
    validationActive: true,
    fieldName: emailTitleFieldName,
  });
  const formMethods = useForm({
    defaultValues: computedDefaultValues,
    resolver: yupResolver(schema),
  });

  const { setValue, watch, reset, handleSubmit } = formMethods;

  const groupNameValue = useMemo(() => {
    if (areNotificationGroupsLoading) {
      return;
    }
    if (!!groupId) {
      const currentOption =
        groupId &&
        notificationGroupOptions?.find(({ value }) => groupId === value);

      if (currentOption?.length > 0) {
        return currentOption[0];
      }
      return currentOption;
    }
  }, [
    groupName,
    groupId,
    notificationGroupOptions,
    areNotificationGroupsLoading,
  ]);

  const handleActive = useCallback(() => {
    if (groupId && !!groupNameValue) {
      setValue(groupNameFieldName, groupNameValue);
    }
    if (!groupId) {
      setValue(groupNameFieldName, firstNotificationGroupOption);
    }
    setValue(emailTitleFieldName, emailTitleValue);
    setIsActive(true);
  }, [groupId, groupNameValue, emailTitleValue, firstNotificationGroupOption]);

  const handleClose = () => {
    reset();
    setIsActive(false);
  };

  const { mutate: editContent, isLoading: isEditing } =
    usePatchPrivateSingleNotificationGroupTemplate(categoryId, {
      onSuccess: () => {
        handleClose();
        if (parentId) {
          queryClient.invalidateQueries([
            QueryKeys.CATEGORY_LIST,
            { parentId },
          ]);
        }
        queryClient.invalidateQueries([QueryKeys.CATEGORY_LIST]);
      },
    });

  const { mutate: createContent, isLoading: isCreating } =
    usePostPrivateSingleNotificationGroupTemplate({
      onSuccess: () => {
        handleClose();
        if (parentId) {
          queryClient.invalidateQueries([
            QueryKeys.CATEGORY_LIST,
            { parentId },
          ]);
        }
        queryClient.invalidateQueries([QueryKeys.CATEGORY_LIST]);
      },
    });

  const emailTitleSubmitContent = watch(emailTitleFieldName);
  const groupNameSubmitContent = watch(groupNameFieldName);

  const onSubmit = () => {
    if (!!groupId) {
      editContent({
        groupId: groupNameSubmitContent?.value,
        emailTitle: emailTitleSubmitContent,
      });
    } else {
      createContent({
        groupId: groupNameSubmitContent?.value,
        data: {
          templateId: categoryId,
          name: `${categoryId}_${groupNameSubmitContent?.value}`,
          emailTitle: emailTitleSubmitContent,
        },
      });
    }
  };

  return (
    <div key={categoryId}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Row bold={!parentId}>
            {Array.from(Array(depth)).map(() => (
              <Styled.FirstCell />
            ))}
            <Styled.FirstCell>
              {hasChildren && (
                <ExpandButton
                  isOpen={isCategoryOpen(categoryId)}
                  onClick={() => toggleOpenCategory(categoryId)}
                />
              )}
            </Styled.FirstCell>
            <Styled.NameCell>
              {name} {isLoading && <Loader width={24} />}
            </Styled.NameCell>
            <Styled.Cell>{categoryId}</Styled.Cell>
            <Styled.WiderCell>
              <EditEmailTitleButton
                isEditActive={isEditActive}
                emailTitleFieldName={emailTitleFieldName}
                handleActive={handleActive}
                contentValue={emailTitleValue}
                disabled={isCreating || isEditing}
              />
            </Styled.WiderCell>
            <Styled.Cell>
              <EditGroupNameButton
                groupNameValue={groupNameValue}
                isEditActive={isEditActive}
                groupNameFieldName={groupNameFieldName}
                isEditing={isEditing}
                isCreating={isCreating}
                handleClose={handleClose}
                handleActive={handleActive}
              />
            </Styled.Cell>
          </Styled.Row>
        </form>
      </FormProvider>
      {isCategoryOpen(category.id) && (
        <CategoryTree
          parentId={category.id}
          parent={category}
          depth={depth + 1}
          onCreate={onCreate}
        />
      )}
    </div>
  );
};

export default CategoryRow;
