import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { UserRoles, DOCUMENT_TYPE } from '../../../../../../constraints';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// helpers
import { getSigningFlags } from '../../../../helpers/getSigningFlags';

// Components
import { ProtectedComponent } from '../../../../../../modules/Auth/components';
import { LoadingActionButton } from '../../../../../../components';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';
import { UpdateRecipientListButton } from '../UpdateRecipientListButton/UpdateRecipientListButton';

import { Utils, Grid } from 'billon-ui';

const { Text } = Utils;
const { Row } = Grid;

const ManageSection = ({
  documentType,
  handleResendNotificationClick,
  handleNotificationHistoryModalOpen,
  handleDownloadRecipientsList,
  resendAuthorizationCode,
  isResendingAuthorizationCode,
  isDownloadingRecipientsList,
  isDownloading,
  onDownload,
  document,
}) => {
  const { enableSigning, isTauron, isDiploma } = useConfigContext();
  const { isResendNotificationEnabled } = getSigningFlags(
    enableSigning,
    document?.signingStatus,
    document?.signingOrder,
    document?.status,
  );
  const isDocumentTypePublic = documentType === DOCUMENT_TYPE.PUBLIC;
  const enableResendAuthorizationCode = isDocumentTypePublic && isTauron;
  const enableResendNotification =
    (!enableSigning && documentType === DOCUMENT_TYPE.PRIVATE) ||
    (!isTauron && isResendNotificationEnabled);
  const enableNotificationHistory = !isDiploma;
  const enablePublicResendNotification = isDocumentTypePublic && isTauron;
  const enableDownloadRecipientList = isDocumentTypePublic;
  const enableUpdateRecipients = isDocumentTypePublic;

  const handleResendAuthorizationCode = () => {
    resendAuthorizationCode({ data: { userInput: {} } });
  };

  return (
    <>
      <Text fontSize={'18px'} margin={'0 0 11px 0'} fontWeight={700}>
        <FormattedMessage
          id="Manage document"
          defaultMessage="Manage document"
        />
      </Text>
      <Row>
        <BtnCol oneThirdSplit>
          <LoadingActionButton isLoading={isDownloading} onClick={onDownload}>
            <FormattedMessage
              id="Download document"
              defaultMessage="Download document"
            />
          </LoadingActionButton>
        </BtnCol>
        {enableResendNotification && (
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
            render={false}
          >
            <BtnCol oneThirdSplit>
              <LoadingActionButton
                isLoading={isResendingAuthorizationCode}
                onClick={
                  enableResendAuthorizationCode
                    ? handleResendAuthorizationCode
                    : handleResendNotificationClick
                }
                outline
              >
                <FormattedMessage
                  id="Resend notification"
                  defaultMessage="Resend notification"
                />
              </LoadingActionButton>
            </BtnCol>
          </ProtectedComponent>
        )}
        {enableDownloadRecipientList && (
          <BtnCol oneThirdSplit>
            <LoadingActionButton
              isLoading={isDownloadingRecipientsList}
              onClick={handleDownloadRecipientsList}
              outline
            >
              <FormattedMessage
                id="Download the list of recipients"
                defaultMessage="Download the list of recipients"
              />
            </LoadingActionButton>
          </BtnCol>
        )}
      </Row>
      <Row>
        {enableNotificationHistory && (
          <BtnCol oneThirdSplit>
            <LoadingActionButton
              onClick={handleNotificationHistoryModalOpen}
              outline
            >
              <FormattedMessage
                id="Notification history"
                defaultMessage="Notification history"
              />
            </LoadingActionButton>
          </BtnCol>
        )}
        {enablePublicResendNotification && (
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
            render={false}
          >
            <BtnCol oneThirdSplit>
              <LoadingActionButton
                onClick={handleResendNotificationClick}
                outline
              >
                <FormattedMessage
                  id="Resend notification"
                  defaultMessage="Resend notification"
                />
              </LoadingActionButton>
            </BtnCol>
          </ProtectedComponent>
        )}
        {enableUpdateRecipients && (
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
            render={false}
          >
            <BtnCol oneThirdSplit>
              <UpdateRecipientListButton
                publicDocumentType={document?.publicDocumentType}
              />
            </BtnCol>
          </ProtectedComponent>
        )}
      </Row>
    </>
  );
};

export default ManageSection;
