import styled from 'styled-components';
import { Grid } from 'billon-ui';

const { Row } = Grid;

export const StepperRowWrapper = styled(Row)`
  align-items: center;
  justify-content: center;
`;

export const CenterWrapper = styled(Row)`
  align-items: center;
  justify-content: center;
`;

export const StepFailedColorWrapper = styled.span`
  .bs-stepper-circle {
    background-color: ${({ theme, failed, active }) => {
      if (failed) {
        return theme.palette.error2;
      }
      if (active) {
        return theme.palette.secondary;
      }
      return theme.palette.primaryLightest;
    }};

    border: ${({ theme, failed, active }) =>
      !active && !failed && `solid 1px ${theme.palette.secondary}`};
    color: ${({ theme, failed, active }) =>
      !active && !failed && theme.palette.secondary};
  }
`;

export const WarningColorWrapper = styled.span`
  color: ${({ theme }) => theme.palette.error2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export const NameWrapper = styled.span`
  font-weight: ${({ theme, active }) =>
    active ? theme.fontWeightBold : theme.fontWeightMedium};
`;

export const SplitWrapper = styled.span`
  white-space: pre-line;
  text-align: center;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
`;

export const LineWrapper = styled.div`
  margin-top: 19px !important;
`;

export const SuccessBadgeWrapper = styled.div`
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.successLight};
  padding: 24px;
`;

export const WaitingBadgeWrapper = styled(SuccessBadgeWrapper)`
  background-color: ${({ theme }) => theme.palette.grayLighter};
`;

export const IconWrapper = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  svg path {
    fill: ${({ theme, isProcessing }) =>
      isProcessing
        ? theme.palette.iconColor
        : theme.palette.secondary} !important;
  }
  svg:hover path {
    fill: black !important;
  }
`;

export const BadgeHeader = styled.span`
  font-size: 18px;
`;

export const CenteredThinWrapper = styled.div`
  @media (min-width: 1200px) {
    margin-left: -8px !important;
  }
`;
