import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const IconContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;
