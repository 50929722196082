import React from 'react';
import { QueryKeys } from '../../../../../../queryKeys';
import {
  mapDOCUMENT_ACTIONS_ERROR,
  mapERRORS,
} from '../../../../../../constraints';

// Hooks
import { useQueryClient } from 'react-query';
import { useDeleteDocument } from '../../../../hooks';

// Components
import { FormattedMessage } from 'react-intl';
import { ExportableErrorContent } from '../../../../../../components/ExportableErrorContent';
import { ErrorModal } from '../../../../../../components/ErrorModal';
import { Modal as ModalModule } from 'billon-ui';

const { SuccessModal, ConfirmModal } = ModalModule;

export const CancelPublicationModal = ({ isOpen, toggle, document }) => {
  const queryClient = useQueryClient();

  const {
    mutate: deleteDocument,
    isLoading: isDeletingDocument,
    isSuccess,
    error,
    isError,
    reset,
  } = useDeleteDocument({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]);
    },
  });

  const onAcceptDeleteDocument = () => deleteDocument({ id: document.jobId });

  const onCloseSuccessErrorModal = () => {
    toggle();
    reset();
  };

  if (!isOpen || !document) return null;

  if (isError) {
    const errorStatusCode = error?.response?.data?.statusCode;
    const chosenStatusCode = mapERRORS[errorStatusCode] && errorStatusCode;

    return (
      <ErrorModal
        isOpen
        toggle={onCloseSuccessErrorModal}
        onClose={onCloseSuccessErrorModal}
      >
        <ExportableErrorContent
          headerTitleId={mapDOCUMENT_ACTIONS_ERROR.DELETE}
          statusCode={chosenStatusCode}
          jobId={document.jobId}
          title={document.title}
        />
      </ErrorModal>
    );
  }

  if (isSuccess) {
    return (
      <SuccessModal
        isOpen
        title={
          <FormattedMessage
            id="Publication cancelled"
            defaultMessage="Publication cancelled"
          />
        }
        onClose={onCloseSuccessErrorModal}
      >
        <FormattedMessage
          id="Publication has been cancelled successfully."
          defaultMessage="Publication has been cancelled successfully."
        />
      </SuccessModal>
    );
  }

  return (
    <ConfirmModal
      isLoading={isDeletingDocument}
      isOpen
      title={
        <FormattedMessage
          id="Publishing cancelation confirmation"
          defaultMessage="Publishing cancelation confirmation"
        />
      }
      onAccept={onAcceptDeleteDocument}
      onCancel={toggle}
    >
      <FormattedMessage
        id="Are You sure You want to cancel publication of this document?"
        defaultMessage="Are You sure You want to cancel publication of this document?"
      />
    </ConfirmModal>
  );
};
