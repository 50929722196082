import styled from 'styled-components';

import { Utils } from 'billon-ui';

const { Icon } = Utils;

export const InfoIcon = styled(Icon)`
  color: #cad5dd;
  font-size: ${({ scale }) => (scale === 'big' ? '100px' : '26px')};
  margin-right: ${({ scale }) => (scale === 'big' ? '37px' : '11px')};
  align-self: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const InfoIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

export const UserNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const UserName = styled.div`
  display: flex;
  gap: 0.25rem;
  font-weight: ${({ theme }) => theme.fontWeightBase};
  align-items: center;

  strong {
    color: #011627;
    font-weight: 600;
    font-size: ${({ scale }) => (scale === 'big' ? '23px' : '12px')};
  }
`;

export const SessionLeft = styled.div`
  font-size: 0.7rem;
  font-weight: ${({ theme }) => theme.fontWeightBase};

  strong {
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }
`;
