import React, { useCallback, useMemo } from 'react';

// Components
import { DocumentAtrributesForm } from '../DocumentAtrributesForm';
import { DocumentSigningForm } from '../DocumentSigningForm';
import { ErrorModal } from '../../../../../../components/ErrorModal';
import { ExportableErrorContent } from '../../../../../../components/ExportableErrorContent';
import FileErrorSummary from '../RecipientsFileForm/components/FileErrorSummary';
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { Modal as ModalModule } from 'billon-ui';
import { DocumentPublicationSummary } from '../../../DocumentPublicationSummary';
import { RecipientsFileForm } from '../RecipientsFileForm';
import { NotificationContentForm } from '../NotificationContentForm/NotificationContentForm';

// Hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';
import {
  usePublishDocument,
  usePublishSignedDocument,
} from '../../../../hooks';
import { useUpdateDocument } from '../../../../hooks/useUpdateDocument';
import { useHistory } from 'react-router-dom';
import useFilters from '../../../../../../hooks/useFilters';

// Helpers
import {
  CUSTOMER,
  mapERRORS,
  MAP_DIPLOMA_ERRORS,
  TAURON_VERIFICATION_CODE_STATUSES,
  mapDOCUMENT_ACTIONS_ERROR,
  RECIPIENT_ACTIONS,
  DOCUMENT_TYPE,
  UNKNOWN_ERRORS,
  SIGNING_ORDER,
  PUBLICATION_MODES,
  SETTINGS_CONSTANTS,
} from '../../../../../../constraints';
import {
  RECIPIENT_ERROR_CODES,
  RECIPIENT_VALIDATION_PURPOSE,
} from '../../../../../../constants';
import { parsePublishRequestData } from '../../../../helpers';
import { signingOrderOptions } from '../../../../../../components/SelectSigningOrderField/SelectSigningOrderField';
import { decalculateRetainUntil } from '../../../../helpers';
import { activeDeliveryOptions } from '../../../../../../components/SelectActiveDeliveryField/SelectActiveDeliveryField';

const { SuccessModal } = ModalModule;

export const TwoStepPublication = ({
  onCreateIdentity,
  onCreateCategory,
  isPreparedToSign,
  publicationMode,
  onCancel,
  document,
  onSuccess,
}) => {
  const history = useHistory();

  const { customer, enablePublicationDelay } = useConfigContext();
  const { getSettingValueByKey } = useSettingsContext();
  const publicationDelayMinutes = getSettingValueByKey(
    SETTINGS_CONSTANTS.PUBLICATION_DELAY_MINUTES,
  );
  const { filters, navigateWithNewSearchQuery } = useFilters();
  const [step, setStep] = React.useState(isPreparedToSign ? 5 : 1);

  const signingOrderOneStepPublish = getSettingValueByKey(
    SETTINGS_CONSTANTS.ONE_STEP_PUBLISH,
  );

  const signingOrderSenderFirstOneStepPublish = getSettingValueByKey(
    SETTINGS_CONSTANTS.SENDER_FIRST_ONE_STEP_PUBLISH,
  );

  const {
    signingOrder: rawSigningOrder,
    retainUntil: rawRetainUntil,
    publicationStartDate,
    activeDeliveryType: rawActiveDeliveryType,
    ...restDocInfo
  } = document || {};

  const initialFormData = useMemo(() => {
    if (publicationMode === PUBLICATION_MODES.NEW) {
      return {};
    }

    const signingOrder =
      rawSigningOrder &&
      signingOrderOptions?.find(({ value }) => rawSigningOrder === value);

    const retainUntil = decalculateRetainUntil(
      publicationStartDate,
      rawRetainUntil,
    );

    const activeDeliveryType =
      rawActiveDeliveryType &&
      activeDeliveryOptions?.find(
        ({ value }) => rawActiveDeliveryType === value,
      );

    return {
      signingOrder,
      publicationStartDate,
      retainUntil,
      activeDeliveryType,
      ...restDocInfo,
    };
  }, [
    publicationMode,
    rawRetainUntil,
    publicationStartDate,
    rawSigningOrder,
    rawActiveDeliveryType,
    restDocInfo,
  ]);

  const [formData, setFormData] = React.useState(initialFormData);
  const [jobId, setJobId] = React.useState(
    isPreparedToSign ? document?.jobId : null,
  );
  const [documentTitle, setDocumentTitle] = React.useState(
    isPreparedToSign ? document.title : null,
  );

  const [isSuccessModalOpened, setSuccessModalOpened] = React.useState(false);

  const documentType = filters.documentTypeList || DOCUMENT_TYPE.PRIVATE;

  let signingOrder = formData?.signingOrder?.value;

  const isRecipientFormActive = useMemo(() => {
    return !documentType === DOCUMENT_TYPE.PRIVATE;
  }, [documentType]);

  const toggleSuccessModal = () => {
    setSuccessModalOpened(!isSuccessModalOpened);
  };

  const handleCloseProcess = () => {
    if (publicationMode && publicationMode !== PUBLICATION_MODES.NEW) {
      if (document?.documentBlockchainAddress) {
        history.push(`/document/${document?.documentBlockchainAddress}`);
      } else if (jobId) {
        history.push(`/document/${jobId}`);
      } else {
        history.push(`/document/${document?.jobId}`);
      }
    } else {
      navigateWithNewSearchQuery(
        { filters: { documentTypeList: documentType } },
        '/documents',
      );
    }
  };

  const {
    mutate: prepareDocument,
    error: prepareError,
    reset: resetPrepareError,
    isLoading: isPrepareLoading,
  } = usePublishDocument(documentType, {
    onSuccess: (data) => {
      setJobId(data?.jobId);
      if (documentType === DOCUMENT_TYPE.PRIVATE && step === 1) {
        setStep(4);
      } else {
        setStep(step + 1);
      }
    },
  });

  const { mutate: updateDocument } = useUpdateDocument(documentType, {
    onSuccess: (data) => {
      setJobId(data?.jobId);

      if (documentType === DOCUMENT_TYPE.PRIVATE && step === 1) {
        setStep(4);
      } else {
        setStep(step + 1);
      }
    },
  });

  const {
    mutate: publishSignedDocument,
    error: publishError,
    reset: resetPublishError,
    isLoading: isPublishLoading,
    isSuccess: isPublishSuccess,
  } = usePublishSignedDocument(documentType, {
    onSuccess: (response) => {
      if (enablePublicationDelay) {
        const { jobId } = response;
        setJobId(jobId);
        if (publicationDelayMinutes === 0) {
          toggleSuccessModal();
        } else {
          navigateWithNewSearchQuery({ filters }, `/documents/${jobId}`);
        }
      } else {
        toggleSuccessModal();
      }
    },
  });

  const {
    mutate: publishOneStepDocument,
    error: oneStepError,
    reset: resetOneStepError,
    isLoading: isOneStepLoading,
    isSuccess: isOneStepSuccess,
  } = usePublishDocument(documentType, {
    onSuccess: (response) => {
      const { jobId } = response;
      if (publicationDelayMinutes === 0) {
        toggleSuccessModal();
      } else {
        navigateWithNewSearchQuery(
          { filters: { documentTypeList: documentType } },
          `/documents/${jobId}`,
        );
      }
    },
  });

  const mapErrorBase = useMemo(() => {
    if (customer === CUSTOMER.TAURON) {
      return { ...mapERRORS, ...TAURON_VERIFICATION_CODE_STATUSES };
    }
    if (customer === CUSTOMER.DIPLOMA) {
      return { ...mapERRORS, ...MAP_DIPLOMA_ERRORS };
    }
    return mapERRORS;
  }, [customer]);

  const handleBack = () => {
    if (step === 3) {
      if (isRecipientFormActive) {
        setStep(2);
      } else {
        setStep(1);
      }
    } else {
      setStep(step - 1);
    }
  };

  const handleResetRecipientForm = () => {
    if (prepareError) {
      resetPrepareError();
    }
    if (publishError) {
      resetPublishError();
    }

    if (oneStepError) {
      resetOneStepError();
    }

    setStep(2);
  };

  const onPublishSignedDocument = ({ file }) => {
    publishSignedDocument({
      id: jobId,
      data: {
        file,
      },
    });
  };

  const handleOneStepPublishDocument = (values) => {
    setDocumentTitle(values?.title);

    publishOneStepDocument({
      data: parsePublishRequestData(
        values,
        publicationMode,
        document?.documentBlockchainAddress,
        rawRetainUntil,
      ),
    });
  };

  const handleReturnDocList = () => {
    history.goBack();
  };

  const handleCloseError = () => {
    if (prepareError) {
      resetPrepareError();
      setStep(4);
    }

    if (oneStepError) {
      resetOneStepError();
      setStep(4);
    }

    if (publishError) {
      resetPublishError();
      setStep(5);
    }
  };

  const handleAppendFormData = useCallback(
    (moreData) => {
      setFormData({
        ...formData,
        ...moreData,
      });
    },
    [formData],
  );

  const handleSubmitStep = useCallback(
    (stepData, isStepObligatory = true) => {
      if (isStepObligatory) {
        handleAppendFormData(stepData);
      }

      if (step === 1) {
        if (isRecipientFormActive) {
          setStep(2);
        } else {
          setStep(3);
        }
      } else {
        setStep(step + 1);
      }
    },
    [isRecipientFormActive, step, handleAppendFormData],
  );

  const onPrepareDocument = (values) => {
    setDocumentTitle(values.title);

    prepareDocument({
      data: parsePublishRequestData(
        values,
        publicationMode,
        document?.documentBlockchainAddress,
        rawRetainUntil,
      ),
    });
  };

  const onPrepareUpdate = (values) => {
    updateDocument({
      data: parsePublishRequestData(
        values,
        publicationMode,
        document?.documentBlockchainAddress,
        rawRetainUntil,
      ),
    });
  };

  const handleSubmitSummary = (v) => {
    if (
      [PUBLICATION_MODES.CORRECTED, PUBLICATION_MODES.INCIDENT].publicationMode
    ) {
      onPrepareUpdate(v);
    } else {
      if (
        [SIGNING_ORDER.NO_SIGNATURE, SIGNING_ORDER.RECEIVER_ONLY].includes(
          signingOrder,
        ) ||
        (signingOrder === SIGNING_ORDER.PUBLISHER_ONLY &&
          signingOrderOneStepPublish === true) ||
        (signingOrder === SIGNING_ORDER.SENDER_FIRST &&
          signingOrderSenderFirstOneStepPublish === true)
      ) {
        handleOneStepPublishDocument(v);
      } else {
        onPrepareDocument(v);
      }
    }
  };

  const sumbitSummaryMessage = useMemo(() => {
    if (
      [SIGNING_ORDER.NO_SIGNATURE, SIGNING_ORDER.RECEIVER_ONLY].includes(
        signingOrder,
      ) ||
      (signingOrder === SIGNING_ORDER.PUBLISHER_ONLY &&
        signingOrderOneStepPublish === true) ||
      (signingOrder === SIGNING_ORDER.SENDER_FIRST &&
        signingOrderSenderFirstOneStepPublish === true)
    ) {
      return 'Publish document';
    }

    return 'Prepare document';
  }, [
    signingOrder,
    signingOrderOneStepPublish,
    signingOrderSenderFirstOneStepPublish,
  ]);

  const atributesAvailabilityStep =
    publicationMode === PUBLICATION_MODES.NEW ? 1 : 2;

  if (prepareError || publishError || oneStepError) {
    const statusCode =
      (prepareError || publishError || oneStepError)?.response?.data
        ?.statusCode || UNKNOWN_ERRORS.UNKNOWN_PUBLISHING_ERROR;

    if (
      documentType !== DOCUMENT_TYPE.PRIVATE &&
      statusCode !== UNKNOWN_ERRORS.INTERNAL_SERVER_ERROR &&
      RECIPIENT_ERROR_CODES[statusCode]
    ) {
      return (
        <>
          <br />
          <FileErrorSummary
            mode={RECIPIENT_ACTIONS.EXTERNAL_VALIDATION}
            errorData={
              (prepareError || publishError || oneStepError)?.response?.data
            }
            handleResetMutation={handleResetRecipientForm}
            handleExit={handleCloseProcess}
            validationPurpose={RECIPIENT_VALIDATION_PURPOSE.PUBLISH}
          />
        </>
      );
    } else {
      const chosenStatusCode =
        statusCode === UNKNOWN_ERRORS.INTERNAL_SERVER_ERROR
          ? UNKNOWN_ERRORS.UNKNOWN_PUBLISHING_ERROR
          : statusCode;
      const deliveredLabel =
        mapErrorBase[chosenStatusCode] || mapErrorBase.UNKNOWN_PUBLISHING_ERROR;

      return (
        <ErrorModal
          isOpen
          toggle={handleCloseError}
          onClose={handleCloseError}
          closeIconEnabled={false}
        >
          <ExportableErrorContent
            headerTitleId={
              step === 1
                ? mapDOCUMENT_ACTIONS_ERROR.PREPARE
                : mapDOCUMENT_ACTIONS_ERROR.PUBLISH
            }
            statusCode={statusCode}
            jobId={jobId}
            title={documentTitle}
            deliveredLabel={deliveredLabel}
          />
        </ErrorModal>
      );
    }
  }

  return (
    <>
      {step === 1 && (
        <>
          <Styled.BackButton onClick={handleReturnDocList}>
            <FormattedMessage id="back" defaultMessage="back" />
          </Styled.BackButton>
          <DocumentAtrributesForm
            step={atributesAvailabilityStep}
            document={formData}
            publicationMode={publicationMode}
            onSubmit={handleSubmitStep}
            onCreateIdentity={onCreateIdentity}
            onCreateCategory={onCreateCategory}
            submitButtonText="Next"
            isLoading={isPrepareLoading}
          />
        </>
      )}
      {step === 2 && (
        <RecipientsFileForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
          validationPurpose={RECIPIENT_VALIDATION_PURPOSE.PUBLISH}
          mode={RECIPIENT_ACTIONS.INSERT_LIST}
          handleCancelProcess={handleCloseProcess}
        />
      )}
      {step === 3 && (
        <NotificationContentForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
          documentType={documentType}
        />
      )}
      {step === 4 && (
        <DocumentPublicationSummary
          onSubmit={handleSubmitSummary}
          onBack={handleBack}
          isLoading={isPrepareLoading || isOneStepLoading}
          defaultValues={formData}
          documentType={documentType}
          submitButtonText={sumbitSummaryMessage}
          publicationMode={publicationMode}
        />
      )}
      {step === 5 && (
        <DocumentSigningForm
          document={document}
          onSuccess={onPublishSignedDocument}
          documentTitle={documentTitle}
          jobId={jobId}
          isLoading={isPublishLoading}
          onExit={handleReturnDocList}
        />
      )}

      {(isPublishSuccess || isOneStepSuccess) && (
        <>
          <SuccessModal
            isOpen={isSuccessModalOpened}
            onClose={handleCloseProcess}
            toggle={handleCloseProcess}
          >
            <FormattedMessage
              id="Document publication has been initiated."
              defaultMessage="Document publication has been initiated."
            />
          </SuccessModal>
        </>
      )}
    </>
  );
};
