import React from 'react';
import { Grid, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import privIcon from '../../../../ui/Assets/priv-docs.png';
import pubIcon from '../../../../ui/Assets/pub-docs.png';
import * as Styled from './styled';
import { DOCUMENT_TYPE } from '../../../../constraints';

const { Button: ButtonModule, Text } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

const SelectDocumentTypePage = () => {
  return (
    <Styled.ContentContainerGrey>
      <Row>
        <Col md={6}>
          <Styled.Card>
            <Styled.CardContent>
              <Styled.Icon src={pubIcon} />
              <Text as="h4">
                <FormattedMessage
                  id="Publish documents"
                  defaultMessage="Publish documents"
                />{' '}
                <b>
                  <FormattedMessage id="public2" defaultMessage="public" />
                </b>
              </Text>
            </Styled.CardContent>
            <Link
              to={`/documents?filters.documentTypeList=${DOCUMENT_TYPE.PUBLIC}`}
            >
              <Button block size="lg" width="55%">
                <FormattedMessage id="Next" defaultMessage="Next" />
              </Button>
            </Link>
          </Styled.Card>
        </Col>
        <Col md={6}>
          <Styled.Card>
            <Styled.CardContent>
              <Styled.Icon src={privIcon} />
              <Text as="h4">
                <FormattedMessage
                  id="Publish documents"
                  defaultMessage="Publish documents"
                />{' '}
                <b>
                  <FormattedMessage id="private2" defaultMessage="private" />
                </b>
              </Text>
            </Styled.CardContent>
            <Link
              to={`/documents?filters.documentTypeList=${DOCUMENT_TYPE.PRIVATE}`}
            >
              <Button block size="lg" width="55%" onClick={() => {}}>
                <FormattedMessage id="Next" defaultMessage="Next" />
              </Button>
            </Link>
          </Styled.Card>
        </Col>
      </Row>
    </Styled.ContentContainerGrey>
  );
};

export default SelectDocumentTypePage;
