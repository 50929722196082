import { useMemo } from 'react';

// hooks
import useValidNotificationGroupContent from './useValidNotificationGroupContent';
import { usePrivateSingleNotificationGroupContent } from './usePrivateSingleNotificationGroupContent';
import { usePrivateSingleNotificationGroupTemplate } from './usePrivateSingleNotificationGroupTemplate';

export const useTemplatesByCategoryData = ({
  categoryNotificationTemplate,
  categoryId,
  enabled,
}) => {
  const { areGroupContentsLoading, groupContentsError, validGroupContent } =
    useValidNotificationGroupContent({
      enabled: enabled && !!categoryNotificationTemplate,
      activeNotificationGroupId: categoryNotificationTemplate?.groupId,
    });

  const {
    data: categoryIdTemplate,
    isFetching: isCategoryIdTemplateLoading,
    isSuccess: isCategoryIdTemplateSuccess,
    error: categoryIdTemplateError,
  } = usePrivateSingleNotificationGroupTemplate(categoryId, {
    enabled: enabled && !!categoryId && !categoryNotificationTemplate,
  });

  const resultGroupContentId = useMemo(() => {
    if (!!validGroupContent) {
      return validGroupContent?.id;
    }
    if (isCategoryIdTemplateSuccess && !!categoryIdTemplate) {
      return categoryIdTemplate?.currentGroupContentId;
    }
  }, [validGroupContent, categoryIdTemplate, isCategoryIdTemplateSuccess]);

  const {
    data: groupContentData,
    isFetching: isGroupContentLoading,
    error: contentError,
  } = usePrivateSingleNotificationGroupContent(resultGroupContentId, {
    enabled: !!resultGroupContentId,
  });

  const isLoading = useMemo(
    () =>
      areGroupContentsLoading ||
      isGroupContentLoading ||
      isCategoryIdTemplateLoading,
    [
      areGroupContentsLoading,
      isGroupContentLoading,
      isCategoryIdTemplateLoading,
    ],
  );

  const data = useMemo(() => {
    if (!!groupContentData) {
      const { emailTemplate, smsTemplate } = groupContentData || {};
      return {
        emailInput: emailTemplate,
        smsInput: smsTemplate,
        subjectInput: categoryNotificationTemplate?.emailTitle,
      };
    }
    return;
  }, [groupContentData]);

  const error = useMemo(() => {
    if (!!groupContentsError) {
      return groupContentsError;
    }
    if (!!categoryIdTemplateError) {
      return categoryIdTemplateError;
    }
    if (!!contentError) {
      return contentError;
    }
    return;
  }, [groupContentsError, categoryIdTemplateError, contentError]);

  return {
    data,
    error,
    isLoading,
  };
};
