import React from 'react';
import { NOTIFICATION_TYPES } from '../../../../../constraints';
import { NotifyIcon, ChatIcon } from '../components/ActionIcons';
import { ContentActionButton } from '../components';

export const contentFormatter = (value, record) => {
  const StatusBasedIcon = () => {
    if (record.type === NOTIFICATION_TYPES.EMAIL) {
      return <NotifyIcon />;
    } else {
      return <ChatIcon />;
    }
  };

  return (
    <ContentActionButton
      jobId={record.jobId}
      clientId={record.clientId}
      contactDetails={record.contactDetails}
      type={record.type}
    >
      <StatusBasedIcon />
    </ContentActionButton>
  );
};
