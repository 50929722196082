export const getIdentityValue = (identity) => {
  if (!identity) {
    return '';
  }

  const { value, id, label, name, documentNumber, firstName, lastName } =
    identity || {};

  return {
    ...identity,
    value: id || value,
    label: !!label
      ? label
      : !!name
      ? `${documentNumber} ${name}`
      : `${documentNumber} ${firstName} ${lastName}`,
  };
};
