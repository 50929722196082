import * as yup from 'yup';
import { useIntl } from 'react-intl';

import {
  VALIDATION_ERRORS,
  NOTIFICATION_FIELD_ERRORS,
  NOTIFICATION_CONTENT_TAGS,
  NOTIFICATION_PREVIEW_TYPES,
  PUBLIC_DOCUMENT_TYPES,
} from '../../../../constraints';

export const usePublicNotificationSchema = ({
  previewType,
  publicDocumentType,
  emailTitleFieldName,
  templateFieldName,
}) => {
  const { formatMessage } = useIntl();

  return yup.object(
    previewType === NOTIFICATION_PREVIEW_TYPES.EMAIL
      ? {
          [templateFieldName]: yup
            .string()
            .test(
              'emailLinkTagPresence',
              formatMessage(
                {
                  id: NOTIFICATION_FIELD_ERRORS.REQUIRED_TAG,
                  defaultMessage: NOTIFICATION_FIELD_ERRORS.REQUIRED_TAG,
                },
                { TAG: NOTIFICATION_CONTENT_TAGS.LINK },
              ),
              (value) =>
                value ? value?.includes(NOTIFICATION_CONTENT_TAGS.LINK) : true,
            )
            .test(
              'emailPpeTagPresence',
              formatMessage(
                {
                  id: NOTIFICATION_FIELD_ERRORS.REQUIRED_TAG,
                  defaultMessage: NOTIFICATION_FIELD_ERRORS.REQUIRED_TAG,
                },
                { TAG: NOTIFICATION_CONTENT_TAGS.PPE_NUMBER },
              ),
              (value) => {
                if (
                  !value ||
                  publicDocumentType !== PUBLIC_DOCUMENT_TYPES.TARIFF
                ) {
                  return true;
                }
                return value?.includes(NOTIFICATION_CONTENT_TAGS.PPE_NUMBER);
              },
            )
            .required(VALIDATION_ERRORS.REQUIRED),
          [emailTitleFieldName]: yup
            .string()
            .test(
              'emailUserInputTagPresence',
              formatMessage(
                {
                  id: NOTIFICATION_FIELD_ERRORS.FORBIDDEN_TAG,
                  defaultMessage: NOTIFICATION_FIELD_ERRORS.FORBIDDEN_TAG,
                },
                { TAG: NOTIFICATION_CONTENT_TAGS.USER_INPUT },
              ),
              (value) =>
                value
                  ? !value?.includes(NOTIFICATION_CONTENT_TAGS.USER_INPUT)
                  : true,
            )
            .test(
              'emailTitleEmpty',
              formatMessage({
                id: NOTIFICATION_FIELD_ERRORS.TITLE_EMPTY,
                defaultMessage: NOTIFICATION_FIELD_ERRORS.TITLE_EMPTY,
              }),

              (value) =>
                value ? value?.replaceAll(' ', '')?.length > 0 : true,
            )
            .max(
              255,
              formatMessage(
                {
                  id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
                  defaultMessage:
                    VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
                },
                {
                  length: 255,
                },
              ),
            )
            .required(VALIDATION_ERRORS.REQUIRED),
        }
      : {
          [templateFieldName]: yup
            .string()
            .max(
              255,
              formatMessage(
                {
                  id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
                  defaultMessage:
                    VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
                },
                {
                  length: 255,
                },
              ),
            )
            .required(VALIDATION_ERRORS.REQUIRED),
        },
  );
};
