import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const Label = styled(Text)`
  font-size: 13px;
  margin: 7px 0 20px 0;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const LightWeight = styled.span`
  font-weight: ${(props) => props.theme.fontWeightLight} !important;
`;
