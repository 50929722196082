import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../../../../ui/CustomToolTip';
import * as Styled from './styled';

const ValidSinceToolTip = () => {
  return (
    <CustomToolTip
      id="CustomToolTip-validSince"
      trigger={<Styled.HeaderIcon name="info-circle" />}
    >
      <Styled.ToolTipContentWrapper>
        <FormattedMessage
          id="The start date of the document must be earlier than or the same as the end date of the document"
          defaultMessage="The start date of the document must be earlier than or the same as the end date of the document"
        />
      </Styled.ToolTipContentWrapper>
    </CustomToolTip>
  );
};

export default ValidSinceToolTip;
