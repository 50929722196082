import React from 'react';
import { FormattedMessage } from 'react-intl';
import { mapERRORS } from '../../constraints';

import { Layout } from 'billon-ui';

const { Content: ContentModule } = Layout;
const { ErrorContent } = ContentModule;

const InternalServerError = () => {
  return (
    <ErrorContent>
      <FormattedMessage
        id={mapERRORS.INTERNAL_SERVER_ERROR}
        defaultMessage={mapERRORS.INTERNAL_SERVER_ERROR}
      />
    </ErrorContent>
  );
};

export default InternalServerError;
