import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { validateValidUntilWithRetainUntil } from '../../../../helpers';
import {
  PUBLICATION_FIELD_NAMES,
  VALIDATION_ERRORS,
} from '../../../../../../constraints';

export const useSchema = ({
  isPublicDocument = false,
  isValidUntilRequired = false,
  isRetentionRequired = false,
  maxFileSize = 5000,
}) => {
  const { formatMessage } = useIntl();

  return yup.object({
    [PUBLICATION_FIELD_NAMES.PUBLISHED_BY]: yup
      .string()
      .required(VALIDATION_ERRORS.REQUIRED),

    [PUBLICATION_FIELD_NAMES.TITLE]: yup
      .string()
      .test(
        'whitespaces',
        formatMessage({
          id: VALIDATION_ERRORS.WHITESPACES_ONLY,
          defaultMessage: VALIDATION_ERRORS.WHITESPACES_ONLY,
        }),
        (value) => (value ? value.trim().length > 0 : true),
      )
      .test(
        'nameMaxLength',
        formatMessage({
          id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED,
          defaultMessage: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED,
        }),
        (value) => (value ? value.length <= 128 : true),
      )
      .required(VALIDATION_ERRORS.REQUIRED),

    [PUBLICATION_FIELD_NAMES.CATEGORY]: yup.mixed().test(
      'category',
      formatMessage({
        id: VALIDATION_ERRORS.REQUIRED,
        defaultMessage: VALIDATION_ERRORS.REQUIRED,
      }),
      (value) => !!value,
    ),

    validSince: yup.mixed().required(
      formatMessage({
        id: VALIDATION_ERRORS.REQUIRED,
        defaultMessage: VALIDATION_ERRORS.REQUIRED,
      }),
    ),

    validUntil: yup
      .mixed()
      .test(
        'validSinceRequiredFirst',
        formatMessage({
          id: 'Set the Valid Since date first',
          defaultMessage: 'Set the Valid Since date first',
        }),
        function (validUntil) {
          const isValidSinceSet = !!this.parent.validSince;
          const isValidUntilSet = !!validUntil;
          const isError = !isValidSinceSet && isValidUntilSet;
          return !isError;
        },
      )
      .test(
        'validSinceExceededRetainUntil',
        formatMessage({
          id: 'Retention date exceeded',
          defaultMessage: 'Retention date exceeded',
        }),
        function (validUntil) {
          if (!isRetentionRequired) {
            return true;
          }
          const retainUntil = this.parent?.retainUntil;
          const isRetainUntilSet = !!retainUntil;
          const isValidUntilSet = !!validUntil;
          const isValidateValidUntilWithRetainUntil =
            validateValidUntilWithRetainUntil(validUntil, retainUntil);
          const isError =
            isRetainUntilSet &&
            isValidUntilSet &&
            !isValidateValidUntilWithRetainUntil;
          return !isError;
        },
      )
      .test(
        'validUntilRequired',
        formatMessage({
          id: VALIDATION_ERRORS.REQUIRED,
          defaultMessage: VALIDATION_ERRORS.REQUIRED,
        }),
        function (validUntil) {
          const isValidUntilSet = !!validUntil;
          const isError = !isValidUntilSet && isValidUntilRequired;
          return !isError;
        },
      ),

    retainUntil: yup.mixed().test(
      'retainUntil',
      formatMessage({
        id: VALIDATION_ERRORS.REQUIRED,
        defaultMessage: VALIDATION_ERRORS.REQUIRED,
      }),
      (retainUntil) => (isRetentionRequired ? retainUntil?.value : true),
    ),

    [PUBLICATION_FIELD_NAMES.IDENTITY]: yup.mixed().test(
      'identity',
      formatMessage({
        id: VALIDATION_ERRORS.REQUIRED,
        defaultMessage: VALIDATION_ERRORS.REQUIRED,
      }),
      (value) => isPublicDocument || !!value,
    ),

    documentFile: yup
      .mixed()
      .test(
        'file',
        formatMessage({
          id: VALIDATION_ERRORS.REQUIRED,
          defaultMessage: VALIDATION_ERRORS.REQUIRED,
        }),
        (value) => !!value && value.length > 0,
      )
      .test(
        'fileType',
        formatMessage({
          id: VALIDATION_ERRORS.FILE_FORMAT,
          defaultMessage: VALIDATION_ERRORS.FILE_FORMAT,
        }),
        (value) => {
          const splitter = value?.[0]?.path?.split('.');

          if (!splitter || splitter?.length === 0) {
            return true;
          }

          return splitter[splitter.length - 1]?.toLowerCase() === 'pdf';
        },
      )
      .test(
        'fileSize',
        formatMessage({
          id: VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE,
          defaultMessage: VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE,
        }),
        (value) => {
          const FILE_SIZE = maxFileSize * 1024;
          return value?.[0] ? value[0].size <= FILE_SIZE : true;
        },
      ),
  });
};
