import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const FiltersWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-shrink: 0;
  flex-basis: 220px;
  min-height: 100px;
  background: #ffffff;
  border: 2px solid;
  border-color: ${({ isSelected }) => (isSelected ? 'grey' : 'lightGrey')};
  border-radius: 15px;
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  padding: 16px;
  margin-right: 20px;
  font-size: 12px;
  text-align: left;
  background-color: ${({ isSelected }) => isSelected && '#f9fbfc'};
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    min-height: 50px;
    flex-basis: 100px;
    margin-bottom: 20px;
  }
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    border: 2px solid #acbbc7;
    background-color: #f9fbfc;
  }
`;

export const CustomText = styled(Text)`
  margin: 0;
  font-size: 12px;
  white-space: break-spaces;
`;

export const Title = styled(CustomText)`
  font-weight: 600;
`;

export const SeeLabel = styled(CustomText)`
  align-self: flex-end;
`;
