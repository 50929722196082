import { CUSTOMER } from '../../../constraints';

const config = {
  backendUrl: 'https://apirspublication.demo.tdm.bybillon.com/application/v1.0',
  enablePublic: true,
  enablePrivate: true,
  enableSigning: true,
  enableActiveDelivery: true,
  manageRecipients: true,
  enablePublicationDelay: false,
  customer: CUSTOMER.DEFAULT,
  privateDocuments: {
    enableHeaderLink: true,
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  publicDocuments: {
    enableHeaderLink: true,
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  enableDocumentDetails: {
    publicationStartDate: false,
    publicationDate: true,
    categoryFullPath: false,
    status: true,
    publisherName: true,
    publishedBy: true,
    versionName: true,
    retainUntil: true,
    validSince: true,
    validUntil: true,
    identity: false,
    documentNumber: false,
  },
  SMS_CODE_STATUSES: null,
};

export default config;
