import styled from 'styled-components';
import { Utils, Grid } from 'billon-ui';
const { Button: ButtonModule, Card } = Utils;

export const TableCard = styled(Card)`
  padding: 0 0 20px 0;
  box-shadow: none;
`;

export const Button = styled(ButtonModule.Button)`
  gap: 0.5rem;
`;

export const ToEndRow = styled(Grid.Row)`
  align-items: flex-end;
  justify-content: flex-end;
`;
