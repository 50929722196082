import { patchRequest } from '../../../../helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../../api-routes';

export const usePatchPrivateSingleNotificationGroupContent = (
  groupContentId,
  options,
) =>
  useMutationWithLogout(async (data) => {
    const { data: patchData } = await patchRequest({
      url: apiRoutes.PRIVATE_SINGLE_NOTIFICATION_GROUP_CONTENT.replace(
        '{groupContentId}',
        groupContentId,
      ),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });
    return patchData;
  }, options);
