import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

import { Grid, Utils } from 'billon-ui';
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Col } = Grid;

const FormActions = ({
  onClearFilters,
  isSubmitting,
  submitMessageId = 'Filter',
}) => (
  <Styled.FlexEndRow>
    <Col lg={6} xl={3}>
      <Button onClick={onClearFilters} block outline type="button">
        <FormattedMessage id="Clear" defaultMessage="Clear" />
      </Button>
    </Col>
    <Col lg={6} xl={3}>
      {!isSubmitting ? (
        <Button type="submit" block>
          <FormattedMessage
            id={submitMessageId}
            defaultMessage={submitMessageId}
          />
        </Button>
      ) : (
        <ButtonLoader size="lg" block />
      )}
    </Col>
  </Styled.FlexEndRow>
);

export default FormActions;
