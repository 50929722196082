import * as yup from 'yup';
import { useIntl } from 'react-intl';

export const useSchema = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    groups: yup.array().test(
      'requiredOne',
      formatMessage({
        id: 'Select at least one group to continue',
        defaultMessage: 'Select at least one group to continue',
      }),
      (value) =>
        value
          ? value.filter(({ checked }) => checked).map(({ value }) => value)
              .length > 0
          : true,
    ),
  });
};
