import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import {
  PUBLIC_DOCUMENT_TYPES,
  MAP_PUBLIC_DOCUMENT_TYPES,
  PUBLICATION_FIELD_NAMES,
} from '../../constraints';

// Components
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import * as Styled from '../SelectCategoryField/styled';
import { Utils } from 'billon-ui';
const { Text } = Utils;

const defaultPublicDocumentTypeKey = PUBLIC_DOCUMENT_TYPES.PUBLIC;

export const defaultPublicDocumentType = {
  label: (
    <FormattedMessage
      id={MAP_PUBLIC_DOCUMENT_TYPES[defaultPublicDocumentTypeKey]}
    />
  ),
  value: defaultPublicDocumentTypeKey,
};

const publicDocumentTypeList = [
  PUBLIC_DOCUMENT_TYPES.PUBLIC,
  PUBLIC_DOCUMENT_TYPES.TARIFF,
];

export const publicDocumentTypeOptions = publicDocumentTypeList.map(
  (option) => ({
    label: <FormattedMessage id={MAP_PUBLIC_DOCUMENT_TYPES[option]} />,
    value: option,
  }),
);

export const SelectPublicDocumentTypeField = ({
  name = PUBLICATION_FIELD_NAMES.PUBLIC_DOCUMENT_TYPE,
  disabled,
}) => {
  return (
    <>
      <Text as="h6" fontSize="12px" fontWeight="600">
        <FormattedMessage id="Document type" defaultMessage="Document type" />
      </Text>
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          options={publicDocumentTypeOptions}
          disabled={disabled}
        />
      </Styled.FormGroup>
    </>
  );
};
