import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';
import { useAuthenticatedContext } from '../../hooks/useAuthenticated';
import { UserRoles } from 'js/constraints';
import { hasRole } from '../../helpers';

const { Alert } = Utils;

const ProtectedComponent = ({
  roles,
  permissions,
  children,
  render,
  reverse,
  message,
}) => {
  const { user } = useAuthenticatedContext();
  const role = user ? user?.role : UserRoles.UNLOGGED;

  if (
    (!reverse && hasRole(roles, role)) ||
    (reverse && !hasRole(roles, role))
  ) {
    return children;
  }

  if (render) {
    return <Alert color="warning">{message}</Alert>;
  } else {
    return null;
  }
};

ProtectedComponent.propTypes = {
  children: PropTypes.any.isRequired,
  roles: PropTypes.array,
  permissions: PropTypes.array,
  render: PropTypes.bool,
  reverse: PropTypes.bool,
  message: PropTypes.any,
};

ProtectedComponent.defaultProps = {
  render: true,
  reverse: false,
  message: (
    <FormattedMessage
      id="You do not have access to this page."
      defaultMessage="You do not have access to this page."
    />
  ),
};

export default ProtectedComponent;
