import { useMemo } from 'react';
import { merge } from 'lodash';

// hooks
import useFilters from '../../../../../hooks/useFilters';
import { useRecipientStatusOptions } from './useRecipientStatusOptions';
import { useIdentityDocumentType } from '../../../hooks/useIdentityDocumentType';

// constants
import { RECIPIENT_STATUSES } from '../../../../../constraints';

export const useRecipientFormDefaultValues = (defaultFilters) => {
  const { filters } = useFilters();
  const { identityDocumentTypeOptions } = useIdentityDocumentType();
  const { statusOptions } = useRecipientStatusOptions();

  const formInitialValues = {
    firstName: '',
    lastName: '',
    name: '',
    documentType: null,
    documentNumber: '',
    id: '',
    email: '',
    phoneNumber: '',
    createdBy: '',
    createdAtDateRange: '',
    status: null,
  };

  const { createdAtDateRange, status, documentType, isGdprSuspended } = useMemo(
    () => merge({}, defaultFilters, filters)?.filters || {},
    [defaultFilters, filters],
  );

  const computeStatusOption = (status, isGdprSuspended) => {
    if (isGdprSuspended === 'true') {
      return statusOptions.filter(
        (option) => option.value === RECIPIENT_STATUSES.SUSPENDED,
      );
    }
    return statusOptions.filter((option) => option.value === status);
  };

  const computedDefaultValues = useMemo(
    () =>
      merge({}, formInitialValues, {
        ...defaultFilters.filters,
        createdAtDateRange: [createdAtDateRange?.from, createdAtDateRange?.to],
        status: computeStatusOption(status, isGdprSuspended),
        isGdprSuspended: isGdprSuspended === 'true',
        documentType:
          documentType &&
          identityDocumentTypeOptions.filter(
            (option) => option.value === documentType,
          ),
      }),
    [
      defaultFilters,
      formInitialValues,
      createdAtDateRange,
      status,
      documentType,
      isGdprSuspended,
      statusOptions,
    ],
  );

  return { formInitialValues, computedDefaultValues };
};
