import React from 'react';
import { DailyReportTable } from '../../components';
import ContentContainerGrey from 'js/ui/ContentContainerGrey';
import { Layout as LayoutModule } from 'billon-ui';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const HomePage = () => {
  return (
    <ContentContainerGrey fluid>
      <Content fluid>
        <DailyReportTable />
      </Content>
    </ContentContainerGrey>
  );
};

export default HomePage;
