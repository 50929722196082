import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const NavButton = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 16px;
  color: ${({ theme, disabled }) => !disabled && theme.linkColor};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.65)};
  padding-left: 0;
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  font-weight: 600;
  text-decoration: ${({ isSelected }) => (isSelected ? 'underline' : 'none')};
  font-size: ${({ theme, small }) => (small ? '16px' : theme.fontSize18)};
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: ${({ disabled }) => !disabled && 'underline'};
    opacity: 1;
  }
`;

export const SingleTemplateHeader = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: ${({ theme, small }) => (small ? '16px' : theme.fontSize18)};
  margin: 0 16px 0 0;
  padding: 1px 0 1px 0;
`;
