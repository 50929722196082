import React from 'react';
import * as Styled from './styled';

const StatusWithIcon = ({ label, children }) => (
  <Styled.Container>
    <Styled.LabelContainer>{label}</Styled.LabelContainer>
    <Styled.IconContainer>{children}</Styled.IconContainer>
  </Styled.Container>
);

export default StatusWithIcon;
