import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Utils } from 'billon-ui';

import * as Styled from './styled';

const { Icon } = Utils;

const Modal = ({
  title,
  children,
  isOpen,
  toggle,
  onOpened,
  onClosed,
  className,
  backdrop,
  titleUnderline,
  closeIconEnabled,
  closePadding,
}) => (
  <Styled.Modal
    isOpen={isOpen}
    toggle={toggle}
    onOpened={onOpened}
    onClosed={onClosed}
    className={classNames('modal-lg', className)}
    centered
    fade={false}
    backdrop={backdrop}
    title={title}
  >
    {closeIconEnabled && (
      <Styled.CloseIconButton onClick={toggle}>
        <Icon name="times" />
      </Styled.CloseIconButton>
    )}
    <Styled.ModalBody closePadding={closePadding}>{children}</Styled.ModalBody>
  </Styled.Modal>
);

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  closePadding: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.any,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  titleUnderline: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  backdrop: 'static',
  titleUnderline: false,
};

export default Modal;
