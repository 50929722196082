import React from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';

// Components
import * as Styled from './styled';

const EditContentButton = ({ formEditActivityName, isEditing }) => {
  const formMethods = useFormContext();
  const { watch, setValue, reset } = formMethods;

  const formEditActivityValue = watch(formEditActivityName);

  const handleActive = () => {
    setValue(formEditActivityName, true);
  };
  const handleClose = () => {
    setValue(formEditActivityName, false);
    reset();
  };

  if (isEditing) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      {formEditActivityValue ? (
        <Styled.ActionButton>
          <Styled.CloseIcon onClick={handleClose} />
        </Styled.ActionButton>
      ) : (
        <Styled.ActionButton>
          <Styled.EditIcon onClick={handleActive} />
        </Styled.ActionButton>
      )}
    </FormProvider>
  );
};

export default EditContentButton;
