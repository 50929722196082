import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../../../../../../ui/CustomToolTip';
import * as Styled from './styled';

const SubtitleToolTip = () => {
  return (
    <CustomToolTip
      id="CustomToolTip-resend-notification-EDIT_TYPE_VIEW-EMAIL"
      trigger={<Styled.HeaderIcon name="info-circle" />}
    >
      <Styled.JustifiedContent>
        <FormattedMessage
          id="The content you enter will be inserted into the template"
          defaultMessage="The content you enter will be inserted into the template"
        />
        {'. '}
      </Styled.JustifiedContent>
    </CustomToolTip>
  );
};

export default SubtitleToolTip;
