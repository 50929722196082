import React from 'react';
import { Layout as LayoutModule, Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { Content: ContentModule } = LayoutModule;
const { PageLoader: PL, LoadingFailed } = LoaderModule;
const { Content } = ContentModule;

export const PageLoader = () => {
  return (
    <Content fluid>
      <PL />
    </Content>
  );
};

export const PageLoadingFailed = () => {
  return (
    <Content fluid>
      <LoadingFailed />
    </Content>
  );
};
