import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as Styled from './styled';

export const DeleteDocumentButton = ({ onDeleteDocument }) => (
  <Styled.Col md={6}>
    <Styled.RedButton outline block onClick={onDeleteDocument}>
      <FormattedMessage
        id="Discard document"
        defaultMessage="Discard document"
      />
    </Styled.RedButton>
  </Styled.Col>
);
