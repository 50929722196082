import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { Utils } from 'billon-ui';

import * as Styled from './styled';

// Hooks
import useFilters from 'js/hooks/useFilters';

const { Icon } = Utils;

const SearchBarField = ({ name, defaultValue = '', placeholder }) => {
  const formInitialValues = {
    [name]: defaultValue,
  };

  const { filters, navigateWithSearchQuery, onClear } = useFilters();

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = useForm({
    defaultValues: merge({}, formInitialValues, filters),
  });

  const onSubmit = (values) => {
    navigateWithSearchQuery({ filters: { [name]: values[name] } });
  };

  const onClearFilters = () => {
    onClear();
    reset(formInitialValues);
  };

  return (
    <Styled.SearchBar onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <Styled.TextField
            input={field}
            meta={{
              touched: fieldState.invalid,
              error: fieldState.error,
            }}
            placeholder={placeholder}
          />
        )}
      />

      <Styled.ClearButton type="button" onClick={onClearFilters}>
        <Icon name="times" />
      </Styled.ClearButton>

      <Styled.SearchButton type="submit" disabled={isSubmitting}>
        <Icon name="search" />
      </Styled.SearchButton>
    </Styled.SearchBar>
  );
};

SearchBarField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SearchBarField;
