import React from 'react';
import { Modal as ModalModule, Grid } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

const { Modal } = ModalModule;
const { Row } = Grid;

const UpdateModal = ({ isOpen, toggle, onClose, title, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClose={onClose}
      title={
        title && (
          <Row>
            <FormattedMessage id={title} defaultMessage={title} />
          </Row>
        )
      }
    >
      {children}
    </Modal>
  );
};

export default UpdateModal;
