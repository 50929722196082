import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { USER_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useStatusOptions } from '../../hooks/useStatusOptions';

// Components
import { ControlledSelectField } from '../../../../../../components/ControlledSelectField/ControlledSelectField';
import { FieldLabel } from '../../../../../../components/Form';

const SelectStatusField = ({
  onClear,
  isClearable,
  name = USER_FILTER_FIELD_NAMES.STATUS,
}) => {
  const { statusOptions } = useStatusOptions();
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Status" defaultMessage="Status" />
      </FieldLabel>
      <ControlledSelectField
        name={name}
        options={statusOptions}
        onClear={onClear}
        isClearable={isClearable}
      />
    </>
  );
};

export default SelectStatusField;
