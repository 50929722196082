import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from '../styled';
import { FormFooter } from '../../../../../../components/FormFooter';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

const ValidateRecipientsError = ({ onClose, children, onBack }) => {
  const { isDiploma } = useConfigContext();

  const titleId = isDiploma
    ? 'An error occurred while adding the list of students'
    : 'An error occurred while adding the list of recipients';

  return (
    <>
      <Styled.Title>
        <FormattedMessage id={titleId} defaultMessage={titleId} />
      </Styled.Title>
      <Styled.MinContent> {children}</Styled.MinContent>
      <FormFooter submitButtonText="Close" onNext={onClose} onBack={onBack} />
    </>
  );
};

export default ValidateRecipientsError;
