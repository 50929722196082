import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const LoadingButton = styled(Button)`
  padding: 0;
  padding-top: 0.8em;
  svg {
    color: #5d5d5d;
  }
`;

export const PrevButton = styled(Button)`
  padding: 0;
  padding-top: 0.5em;
  padding-left: 1em;
  svg {
    color: #5d5d5d;
  }
`;
