import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Modal as M, ModalFooter as MF } from 'reactstrap';

export const Modal = styled(M)`
  position: relative;
  width: auto;
  margin: 1.75rem auto;
  pointer-events: none;
  max-width: 704px;

  ${down('lg')} {
    margin: 0 auto;
    width: 90%;
  }

  &.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);

    ${down('lg')} {
      align-items: flex-start;
      min-height: 100%;
    }

    &::before {
      display: block; // IE10
      height: calc(100vh - 3.5rem);
      content: '';
    }
  }

  .modal-content {
    padding: 20px 40px 40px 40px;
    min-height: 350px;
    justify-content: space-between;
    align-items: stretch;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${(props) => props.theme.bodyColor};
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: ${(props) => props.theme.borderRadius};
    box-shadow: ${(props) => props.theme.boxShadow};
    outline: 0;
  }
`;

export const ModalHeader = styled.div`
  margin-top: 3.6rem;
  font-weight: ${(props) => props.theme.fontWeightExtraBold};
  text-align: left;
  font-size: 1.8rem;
  align-self: center;
`;

export const ModalBody = styled.div`
  padding: 0 48px 0 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

export const CloseIconButton = styled.button`
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.grayLight};
  padding: 0;
  transition: ${(props) => props.theme.transitionBase};
  position: absolute;
  right: 25px;
  top: 14px;

  &:hover {
    color: ${(props) => props.theme.bodyColor};
  }
`;

export const ModalFooter = styled(MF)`
  display: flex;
  padding: 0 48px 0 48px;
  div {
    padding: 0;
  }
`;
