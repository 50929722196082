import apiRoutes from '../../../api-routes';
import { patchRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

export const useGdprSuspend = (options) => {
  return useMutationWithLogout(async ({ id, data }) => {
    const { data: suspendData } = await patchRequest({
      url: apiRoutes.RECIPIENT_GDPR_SUSPEND.replace('{id}', id),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });
    return suspendData;
  }, options);
};
