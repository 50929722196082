import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CB = styled.div`
  &.custom-logo-preview {
    width: 100%;
    height: 20vh;
    background-image: url('./assets/DMLogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &.custom-logo {
    /* height: 40px;
    width: 200px; */
    width: 120px;
    height: auto;
    margin: auto 0;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

const CustomBackground = ({ background, className, children }) => (
  <CB
    className={className}
    style={{
      backgroundImage: background ? `url('${background}')` : null,
    }}
  >
    {children}
  </CB>
);

CustomBackground.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.object,
};

export default CustomBackground;
