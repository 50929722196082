import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// constants
import {
  ACTIVE_DELIVERY,
  DOCUMENT_TYPE,
  SETTINGS_CONSTANTS,
} from '../../../../constraints';

// hooks
import { useSettingsContext } from '../../../Settings/hooks/useSettings';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useSingleDocument } from '../../hooks/useSingleDocument';

// helpers
import { getSigningFlags } from '../../helpers/getSigningFlags';

// Components
import DocumentDetails from '../../components/DocumentDetails';
import DocumentHistory from '../../components/DocumentHistory';
import { SigningStepper } from '../../components/SigningStepper/SigningStepper';
import { Stepper } from '../../components/ActiveDelivery/components/Stepper/Stepper';
import * as Styled from './styled';
import { Layout as LayoutModule, Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { ContentContainer, Content } = ContentModule;

const DocumentDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { getSettingValueByKey } = useSettingsContext({});
  const { enableSigning, enableActiveDelivery, customer } = useConfigContext();
  const { data: document, isLoading } = useSingleDocument({
    id,
    enabled: !!id,
  });

  const publisherName = getSettingValueByKey(SETTINGS_CONSTANTS.PUBLISHER_NAME);
  const { areSigningStepsDisabled } = getSigningFlags(
    enableSigning,
    document?.signingStatus,
    document?.signingOrder,
    document?.status,
  );

  const { previousDocument, nextDocument } = useMemo(() => {
    let previousDocument;
    let nextDocument;
    const currentIndex = document?.history?.findIndex(
      ({ documentBlockchainAddress }) =>
        documentBlockchainAddress === document.documentBlockchainAddress,
    );
    if (currentIndex - 1 >= 0) {
      previousDocument = document?.history[currentIndex - 1];
    }
    if (currentIndex + 1 < document?.history?.length) {
      nextDocument = document?.history[currentIndex + 1];
    }
    return { previousDocument, nextDocument };
  }, [document]);

  const handleOnBack = () => {
    if (document?.documentType === DOCUMENT_TYPE.PUBLIC) {
      history.push('/documents?filters.documentTypeList=PUBLIC');
    } else {
      history.push('/documents?filters.documentTypeList=PRIVATE');
    }
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <ContentContainer fluid>
      <Content fluid>
        <DocumentDetails
          document={document}
          customer={customer}
          onBackClick={handleOnBack}
          publisherName={publisherName}
          backButtonLabel={
            <FormattedMessage id="Documents" defaultMessage="Documents" />
          }
        />
      </Content>

      {enableActiveDelivery &&
        document?.documentType === DOCUMENT_TYPE.PRIVATE &&
        document?.activeDelivery?.type &&
        document?.activeDelivery?.type !== ACTIVE_DELIVERY.NONE && (
          <ContentContainer fluid>
            <Styled.SigningStepsWrapper>
              <Stepper activeDelivery={document?.activeDelivery} />
            </Styled.SigningStepsWrapper>
          </ContentContainer>
        )}

      {!areSigningStepsDisabled && (
        <ContentContainer fluid>
          <Styled.SigningStepsWrapper isBgColorSuccess>
            <SigningStepper
              status={document?.status}
              signingStatus={document?.signingStatus}
              signingOrder={document?.signingOrder}
              documentStatus={document?.documentStatus}
              publicationDate={document?.publicationDate}
              publicationMode={document?.publicationMode}
              nextPublicationMode={nextDocument?.publicationMode}
              previousPublicationDate={previousDocument?.publicationDate}
              previousPublicationMode={previousDocument?.publicationMode}
              nextPublicationDate={nextDocument?.publicationDate}
            />
          </Styled.SigningStepsWrapper>
        </ContentContainer>
      )}
      {document?.history && (
        <ContentContainer fluid>
          <Styled.HistoryWrapper activeDelivery={enableActiveDelivery}>
            <DocumentHistory
              historyRecords={document.history}
              currentId={document.jobId}
              title={document.title}
              customer={customer}
              signingStatus={document?.signingStatus}
              signingOrder={document?.signingOrder}
            />
          </Styled.HistoryWrapper>
        </ContentContainer>
      )}
    </ContentContainer>
  );
};

export default DocumentDetailsPage;
