import apiRoutes from '../../../api-routes';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';
import { getRequest } from '../../../helpers/apiClient';
import download from 'downloadjs';
import { standardDateFormat } from '../../../helpers/standardDateFormat';

export const useStatisticsFile = (options) =>
  useMutationWithLogout(
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.DOWNLOAD_STATISTICS_EXCEL_REPORT,
        responseType: 'arraybuffer',
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
      });
      return data;
    },
    {
      ...options,
      onSuccess: (response) => {
        const prefix = standardDateFormat(new Date());
        const blob = new Uint8Array(response);
        download(blob, `publication-statistics-${prefix}.xlsx`, 'text/csv');
      },
    },
  );
