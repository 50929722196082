import styled from 'styled-components';

import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;

export const ShowMoreLabelButton = styled(ButtonModule.Button)`
  margin: 0;
  padding: 0;
  font-weight: 700;
`;
