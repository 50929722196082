import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// constants
import appRoutes from '../../../../app-routes';
import { DOCUMENT_TYPE } from '../../../../constraints';

// hooks
import { useSingleDocument } from '../../hooks/useSingleDocument';

// Components
import ResendNotification from '../../components/ResendNotification';
import ResendPrivateNotification from '../../components/ResendNotification/ResendPrivateNotification';

const ResendNotificationPage = () => {
  const history = useHistory();

  const { params } = useRouteMatch(
    appRoutes.DOCUMENTS_DETAILS_RESEND_NOTIFICATION,
  );

  const { id, mode } = params;

  const handleClose = () => {
    history.push(`/document/${id}`);
  };

  const { data: document, isLoading } = useSingleDocument({ id: id });

  if (isLoading) {
    return null;
  }

  if (document?.documentType === DOCUMENT_TYPE.PRIVATE) {
    return (
      <ResendPrivateNotification
        document={document}
        resendMode={mode}
        handleClose={handleClose}
      />
    );
  }

  return (
    <ResendNotification
      document={document}
      resendMode={mode}
      handleClose={handleClose}
    />
  );
};

export default ResendNotificationPage;
