import React from 'react';
import { DOCUMENTS_OPTIONS } from '../../../../../../../../constraints';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';
import { Dropdown as DropdownModule } from 'billon-ui';

const { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } =
  DropdownModule;

const PublicationCardHeader = ({ onChangePublishedDocsType }) => {
  const selectPublicationType = (key) => () => {
    onChangePublishedDocsType(key);
  };

  const publicationTypeOptions = [
    { documentType: DOCUMENTS_OPTIONS.ALL, messageId: 'All' },
    { documentType: DOCUMENTS_OPTIONS.PRIVATE, messageId: 'Private2' },
    { documentType: DOCUMENTS_OPTIONS.PUBLIC, messageId: 'Public2' },
  ];

  return (
    <>
      <Styled.PublishedDocsCardHeader>
        <Styled.CardTitle>
          <FormattedMessage
            id="Number of published documents"
            defaultMessage="Number of published documents"
          />
        </Styled.CardTitle>
        <UncontrolledDropdown>
          <Styled.DropDownDiv>
            <FormattedMessage
              id="Document type"
              defaultMessage="Document type"
            />
            <DropdownToggle />
          </Styled.DropDownDiv>
          <DropdownMenu>
            {publicationTypeOptions.map(({ documentType, messageId }) => (
              <DropdownItem onClick={selectPublicationType(documentType)}>
                <FormattedMessage id={messageId} defaultMessage={messageId} />
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Styled.PublishedDocsCardHeader>
    </>
  );
};

export default PublicationCardHeader;
