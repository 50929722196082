// templates
import tauronTemplate from './assets/recipient-template-tauron.xlsx';
import diplomaTemplate from './assets/recipient-template-diploma.xlsx';
import mvpTemplate from './assets/recipient-template-mvp.xlsx';

import { CUSTOMER } from '../../constraints';

export const getCustomerProperties = (customer) => {
  switch (customer) {
    case CUSTOMER.DIPLOMA:
      return {
        dragDropMessageId: 'Drag and drop here student list',
        templateFileName: 'Szablon listy odbiorców - dyplomy.xlsx',
        templateHref: diplomaTemplate,
      };
    case CUSTOMER.TAURON:
      return {
        dragDropMessageId: 'Drag and drop here recipient list',
        templateFileName: 'Szablon listy odbiorców.xlsx',
        templateHref: tauronTemplate,
      };
    default:
      return {
        dragDropMessageId: 'Drag and drop here recipient list',
        templateFileName: 'Szablon listy odbiorców - mvp.xlsx',
        templateHref: mvpTemplate,
      };
  }
};
