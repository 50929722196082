import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// Components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { FieldLabel } from '../../../../../../components/Form';

const TextTitleField = ({ name = DOCUMENT_FILTER_FIELD_NAMES.TITLE }) => {
  const { isDiploma } = useConfigContext();

  return (
    <>
      <FieldLabel>
        {isDiploma ? (
          <FormattedMessage
            id="Diploma number"
            defaultMessage="Diploma number"
          />
        ) : (
          <FormattedMessage
            id="Document title"
            defaultMessage="Document title"
          />
        )}
      </FieldLabel>
      <ControlledTextField name={name} />
    </>
  );
};

export default TextTitleField;
