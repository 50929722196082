import React from 'react';
import { FormattedMessage } from 'react-intl';
import { momentInTimezoneFormatter } from '../../../formatters';

export const validSinceValidUntilFormatter = (validSince, validUntil) => (
  <>
    {(validSince || validUntil) && (
      <FormattedMessage id="Valid" defaultMessage="Valid" />
    )}
    {validSince && (
      <>
        {' '}
        <FormattedMessage id="since" defaultMessage="since" />{' '}
        {momentInTimezoneFormatter(validSince)}
      </>
    )}
    {validUntil && (
      <>
        {' '}
        <FormattedMessage id="until" defaultMessage="until" />{' '}
        {momentInTimezoneFormatter(validUntil)}
      </>
    )}
  </>
);
