import React from 'react';

import { USER_STATUSES } from '../../../../constraints';
import { useConfigContext } from '../../../Config/hooks/useConfig';

import AccountType from '../../../../components/AccountType';
import IdBadge from '../../../../components/IdBadge';
import { CopiedInfo } from '../../../../components';
import DeleteSection from '../../../Recipients/components/RecipientDetails/components/DeleteSection';
import {
  DetailsSection,
  RecipientActions,
  RecipientForgettingActions,
} from './components';
import { Grid, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { BackButton } = ButtonModule;
const { Row, Col } = Grid;

const RecipientDetails = ({
  recipient,
  customer,
  onBackClick,
  backButtonLabel,
}) => {
  const { isTauron } = useConfigContext();

  const recipientFullName = isTauron
    ? recipient.name
    : `${recipient.firstName} ${recipient.lastName}`;

  return (
    <>
      <Row margin={'0 0 20px 0 '}>
        <BackButton secondary onClick={onBackClick}>
          {backButtonLabel}
        </BackButton>
      </Row>
      <Row margin={'0 0 20px 0'}>
        <Col md={7} lg={5}>
          <Row margin={'0 0 20px 0 '}>
            <IdBadge scale="big" name={recipientFullName} iconDisabled={false}>
              <Col padding={'0 0 0 0'} lg={12}>
                {recipient.isRobot && <AccountType />}

                <Col margin={'20px 0 20px 0'} padding={0}>
                  <CopiedInfo
                    id={recipient.id}
                    label="Internal ID"
                    value={recipient.id}
                    emphasisedHeader
                    valuePlacedBetween
                  />
                </Col>

                {recipient.status === USER_STATUSES.SHELL && (
                  <>
                    <DeleteSection
                      id={recipient.id}
                      onBackClick={onBackClick}
                    />
                    <br />
                  </>
                )}
              </Col>
              <Col padding={'0 0 0 0'} lg={12}>
                {recipient.status !== USER_STATUSES.SHELL && (
                  <RecipientForgettingActions
                    recipient={recipient}
                    handleRedirect={onBackClick}
                  />
                )}
                <RecipientActions recipient={recipient} />
              </Col>
            </IdBadge>
          </Row>
        </Col>

        <Col md={5} lg={7} padding={0}>
          <DetailsSection recipient={recipient} customer={customer} />
        </Col>
      </Row>
    </>
  );
};

export default RecipientDetails;
