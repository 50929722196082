import { useMemo } from 'react';
import { merge } from 'lodash';

// hooks
import useFilters from '../../../../../hooks/useFilters';
import { useReportStatuses } from './useReportStatuses';

export const useDocumentFormDefaultValues = (defaultFilters) => {
  const { filters } = useFilters();
  const { statusOptions: reportStatusOptions } = useReportStatuses();

  const { documentStatusList, publicationDateRange, blockchainAddress, title } =
    useMemo(
      () => merge({}, defaultFilters, filters)?.filters || {},
      [defaultFilters, filters],
    );

  const formInitialValues = {
    blockchainAddress: '',
    publicationDateRange: '',
    documentStatusList: documentStatusList,
    title: '',
  };

  const computedDefaultValues = useMemo(
    () =>
      merge({}, formInitialValues, {
        ...defaultFilters.filters,
        documentStatusList:
          documentStatusList &&
          reportStatusOptions.filter(
            (option) => option.value === documentStatusList,
          ),
        publicationDateRange: [
          publicationDateRange?.from,
          publicationDateRange?.to,
        ],
        blockchainAddress,
        title,
      }),
    [
      defaultFilters,
      documentStatusList,
      reportStatusOptions,
      formInitialValues,
      publicationDateRange,
      blockchainAddress,
      title,
    ],
  );

  return {
    formInitialValues,
    computedDefaultValues,
  };
};
