import React, { Fragment } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';
import styled from 'styled-components';
import {
  AGREEMENT_STATUS,
  REQUEST_STATUS,
  DOCUMENT_STATUS,
  RETAIN_OPTIONS,
  PUBLICATION_STATUSES,
  NOTIFICATION_HISTORY_STATUSES,
  NOTIFICATION_HISTORY_STATUSES_LABELS,
  CATEGORY_STATUSES,
  IDENTITY_DOCUMENT_TYPE,
} from './constraints';
import UserInfo from './ui/UserInfo';
import { LineHeaderRowLine } from './components/LineHeaderRow/styled';
import { BlurContent } from './components/BlurContent/BlurContent';

const { Formatter, UncontrolledTooltip } = Utils;
const { DateFormat, NumberFormat } = Formatter;

const BreakWord = styled.span`
  word-break: break-all;
`;

const NoBreakWord = styled.span`
  white-space: nowrap;
  margin-right: 15px;
`;

const Line = styled(LineHeaderRowLine)`
  margin-right: 15px;
`;

const Recipients = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;

    &:hover {
      div {
        opacity: 1;
        visibility: visible;
      }
    }

    div {
      position: absolute;
      background: #fff;
      padding: 1rem;
      left: 3rem;
      bottom: -1rem;
      z-index: 1;
      border-radius: ${(props) => props.theme.borderRadius};
      box-shadow: ${(props) => props.theme.boxShadow};
      opacity: 0;
      visibility: hidden;
      transition: ${(props) => props.theme.transitionBase};
    }
  }
`;

const Green = styled.span`
  color: ${(props) => props.theme.palette.billonGreen};
`;

const Red = styled.span`
  color: ${(props) => props.theme.palette.error};
`;

const Orange = styled.span`
  color: ${(props) => props.theme.palette.billonOrange};
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const momentFormatter = (value) =>
  value ? (
    <Fragment>
      <DateFormat
        value={value}
        year="numeric"
        month="2-digit"
        day="2-digit"
        hour="2-digit"
        minute="2-digit"
        second="2-digit"
      />
    </Fragment>
  ) : null;

export const momentInTimezoneFormatter = (value) =>
  value ? (
    <DateFormat
      value={value}
      year="numeric"
      month="2-digit"
      day="2-digit"
      hour="2-digit"
      minute="2-digit"
      second="2-digit"
    />
  ) : null;

export const momentInTimezoneFormatterWithoutTimeValue = (date) =>
  moment.utc(date).local().format('DD.MM.YYYY');

export const momentFormatterWithoutTime = (value) =>
  value ? (
    <DateFormat value={value} year="numeric" month="2-digit" day="2-digit" />
  ) : null;

export const momentInTimezoneFormatterWithoutTime = (value) =>
  value ? (
    <DateFormat value={value} year="numeric" month="2-digit" day="2-digit" />
  ) : null;

const boundLength = (value, limit) => {
  if (value && value.length <= limit) {
    return value;
  }

  const id = value
    ? `v-${value.replace(/[\s]+/g, '-').replace(/[^\sA-Za-z0-9]+/g, '')}`
    : '';

  return (
    <>
      {value && (
        <>
          <span href="#" id={id}>
            {value.substring(0, limit).concat('...')}
          </span>
          <UncontrolledTooltip placement="top" target={id}>
            {value}
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};

export const boundLengthFormatter = (value) => boundLength(value, 20);

export const boundLengthFormatterLong = (value) => boundLength(value, 45);

export const boundLengthManualFormatter = (value, maxLength) =>
  boundLength(value, maxLength);

export const breakWordsFormatter = (value) => <BreakWord>{value}</BreakWord>;

export const booleanFormatter = (value) =>
  value ? (
    <FormattedMessage id="Yes" defaultMessage="Yes" />
  ) : (
    <FormattedMessage id="No" defaultMessage="No" />
  );

export const numberFormat = (value) => <NumberFormat value={value} />;

export const booleanBasedOnDateFormatter = (value) => {
  if (value) {
    return (
      <Fragment>
        <FormattedMessage id="Yes" defaultMessage="Yes" /> (
        {momentFormatter(value)})
      </Fragment>
    );
  }

  return <FormattedMessage id="No" defaultMessage="No" />;
};

export const documentTypeFormatter = (value) => {
  if (value === 'PUBLIC') {
    return 'Public documents';
  } else if (value === 'PRIVATE') {
    return 'Private documents';
  } else {
    return 'All documents';
  }
};

export const recipientsFormatter = (value) => {
  if (value.length) {
    return (
      <Recipients>
        {value.map((v, i) => {
          if (!v) {
            return null;
          }
          return (
            <li key={i}>
              {v.firstName} {v.lastName}
              <div>
                <strong>
                  {v.firstName} {v.lastName}
                </strong>
                <br />
                {v.additionalInformation &&
                  v.additionalInformation.indexNumber && (
                    <>
                      {v.additionalInformation.indexNumber}
                      <br />
                    </>
                  )}
                {v.phoneNumber && (
                  <>
                    {v.phoneNumber}
                    <br />
                  </>
                )}
                {v.email}
              </div>
            </li>
          );
        })}
      </Recipients>
    );
  }

  return null;
};

export const indexNumberFormatter = (value) => {
  return value.map((v) => (v && v.externalId ? v.externalId : ''));
};

export const statusFormatter = (value) => {
  switch (value) {
    case AGREEMENT_STATUS.WAITING_FOR_SENDER:
      return <FormattedMessage id="Prepared" defaultMessage="Prepared" />;
    case AGREEMENT_STATUS.WAITING_FOR_RECEIVER:
      return <FormattedMessage id="Waiting2" defaultMessage="Waiting" />;
    case AGREEMENT_STATUS.PUBLISHED:
      return <FormattedMessage id="Confirmed2" defaultMessage="Confirmed" />;
    case AGREEMENT_STATUS.REJECTED:
      return <FormattedMessage id="Prepared" defaultMessage="Prepared" />;
    default:
      return <FormattedMessage id="Incorrect" defaultMessage="Incorrect" />;
  }
};

export const historyStatusFormatter = (value) => {
  if (value === AGREEMENT_STATUS.WAITING_FOR_SENDER) {
    return <FormattedMessage id="Prepared" defaultMessage="Prepared" />;
  } else if (value === AGREEMENT_STATUS.WAITING_FOR_RECEIVER) {
    return (
      <FormattedMessage
        id="Submitted for signature"
        defaultMessage="Submitted for signature"
      />
    );
  } else if (value === AGREEMENT_STATUS.PUBLISHED) {
    return (
      <>
        <FormattedMessage id="Confirmed2" defaultMessage="Confirmed" />.{' '}
        <FormattedMessage
          id="Document signed"
          defaultMessage="Document signed"
        />
      </>
    );
  } else if (value === AGREEMENT_STATUS.REJECTED) {
    return (
      <FormattedMessage
        id="Document rejected by the recipient"
        defaultMessage="Document rejected by the recipient"
      />
    );
  }

  return <FormattedMessage id="Incorrect" defaultMessage="Incorrect" />;
};

export const diplomaStatusFormatter = (value) => {
  if (value === 'PUBLISHING-OK') {
    return <FormattedMessage id="Published" defaultMessage="Published" />;
  } else {
    return <FormattedMessage id="Incorrect" defaultMessage="Incorrect" />;
  }
};

export const requestStatusFormatter = (value) => {
  switch (value) {
    case REQUEST_STATUS.REQUESTED:
      return <FormattedMessage id="Pending" defaultMessage="Pending" />;
    case REQUEST_STATUS.ACCESS_GRANTED:
      return <FormattedMessage id="Approved" defaultMessage="Approved" />;
    case REQUEST_STATUS.REJECTED:
      return <FormattedMessage id="Rejected" defaultMessage="Rejected" />;
    case REQUEST_STATUS.EXPIRED:
      return <FormattedMessage id="Expired" defaultMessage="Expired" />;
    case REQUEST_STATUS.INITIATED:
      return <FormattedMessage id="Initiated" defaultMessage="Initiated" />;
    default:
      return <FormattedMessage id="Invalid" defaultMessage="Invalid" />;
  }
};

export const requestStatusToString = (value) => {
  switch (value) {
    case REQUEST_STATUS.REQUESTED:
      return 'Pending';
    case REQUEST_STATUS.ACCESS_GRANTED:
      return 'Approved';
    case REQUEST_STATUS.REJECTED:
      return 'Rejected';
    case REQUEST_STATUS.EXPIRED:
      return 'Expired';
    case REQUEST_STATUS.INITIATED:
      return 'Initiated';
    default:
      return 'Invalid';
  }
};

export const statusFormatterMVP = (value) => {
  switch (value) {
    case DOCUMENT_STATUS.CURRENT:
      return <FormattedMessage id="Published" defaultMessage="Published" />;
    case DOCUMENT_STATUS.NOT_EXIST:
      return <FormattedMessage id="Error" defaultMessage="Error" />;
    case DOCUMENT_STATUS.PREPARED_TO_SIGN:
      return <FormattedMessage id="Prepared" defaultMessage="Prepared" />;
    case DOCUMENT_STATUS.DEPRECATED:
      return (
        <FormattedMessage
          id="Previous Version"
          defaultMessage="Previous Version"
        />
      );
    case DOCUMENT_STATUS.RETIRED:
      return <FormattedMessage id="Retired" defaultMessage="Retired" />;
    case DOCUMENT_STATUS.UPLOADING:
      return <FormattedMessage id="Uploading" defaultMessage="Uploading" />;
    case DOCUMENT_STATUS.NOT_AVAILABLE:
      return <FormattedMessage id="Forgetting" defaultMessage="Forgetting" />;
    default:
      return <FormattedMessage id="Invalid" defaultMessage="Invalid" />;
  }
};

export const retainOptionToString = (value) => {
  switch (value) {
    case RETAIN_OPTIONS.YEARS_1:
      return '1 year';
    case RETAIN_OPTIONS.YEARS_6:
      return '6 years';
    case RETAIN_OPTIONS.YEARS_10:
      return '10 years';
    case RETAIN_OPTIONS.YEARS_20:
      return '20 years';
    case RETAIN_OPTIONS.YEARS_50:
      return '50 years';
    default:
      return '6 years';
  }
};

export const mapPublicationStatuses = (value) => {
  switch (value) {
    case PUBLICATION_STATUSES.PUBLISHING_OK:
      return <FormattedMessage id="Published" defaultMessage="Published" />;
    case PUBLICATION_STATUSES.PUBLISHING_INITIATED:
      return <FormattedMessage id="Uploading" defaultMessage="Uploading" />;
    case PUBLICATION_STATUSES.PREPARED_TO_SIGN:
      return <FormattedMessage id="Prepared" defaultMessage="Prepared" />;
    default:
      return <FormattedMessage id="Incorrect" defaultMessage="Incorrect" />;
  }
};

export const mapNotificationHistoryStatuses = (value) => {
  let label;
  switch (value) {
    case NOTIFICATION_HISTORY_STATUSES.DELIVERED:
      label = NOTIFICATION_HISTORY_STATUSES_LABELS.DELIVERED;
      return (
        <Green>
          <FormattedMessage id={label} defaultMessage={label} />
        </Green>
      );
    case NOTIFICATION_HISTORY_STATUSES.UPDATED:
      label = NOTIFICATION_HISTORY_STATUSES_LABELS.UPDATED;
      return (
        <Green>
          <FormattedMessage id={label} defaultMessage={label} />
        </Green>
      );
    case NOTIFICATION_HISTORY_STATUSES.NEW:
      label = NOTIFICATION_HISTORY_STATUSES_LABELS.NEW;
      return (
        <Green>
          <FormattedMessage id={label} defaultMessage={label} />
        </Green>
      );
    case NOTIFICATION_HISTORY_STATUSES.IN_PROGRESS:
      label = NOTIFICATION_HISTORY_STATUSES_LABELS.IN_PROGRESS;
      return (
        <Orange>
          <FormattedMessage id={label} defaultMessage={label} />
        </Orange>
      );
    case NOTIFICATION_HISTORY_STATUSES.PREPARED:
      label = NOTIFICATION_HISTORY_STATUSES_LABELS.PREPARED;
      return (
        <Orange>
          <FormattedMessage id={label} defaultMessage={label} />
        </Orange>
      );
    default:
      label = NOTIFICATION_HISTORY_STATUSES_LABELS.ERROR;
      return (
        <Red>
          <FormattedMessage id={label} defaultMessage={label} />
        </Red>
      );
  }
};

export const publicationStatusFormatter = (value) =>
  mapPublicationStatuses(PUBLICATION_STATUSES[value]);

export const filterFormatters = {
  // doc-filters
  categoryId: undefined,
  blockchainAddress: undefined,
  documentType: (value) => documentTypeFormatter(value),
  publicationStatusList: (value) => publicationStatusFormatter(value),
  publishedBy: undefined,
  validUntilDateRange: undefined,
  validSinceDateRange: undefined,
};

export const categoryFormatter = (value) => {
  return value?.name;
};

export const dateToRequestDataFormatter = (value) =>
  moment(value).format('YYYY-MM-DDTHH:mm:ss');

export const toDateTime = (value) => moment(value).format('DD.MM.YYYY HH:mm');

export const categoryStatusFormatter = (value) => {
  switch (value) {
    default:
    case CATEGORY_STATUSES.ADDING_ERROR:
      return <FormattedMessage id="Error" defaultMessage="Error" />;
    case CATEGORY_STATUSES.SUCCESS:
      return <FormattedMessage id="Success" defaultMessage="Success" />;
    case CATEGORY_STATUSES.IN_PROGRESS:
      return <FormattedMessage id="In progress" defaultMessage="In progress" />;
  }
};

export const userInfoFormatter = (id, user) => {
  const { firstName, lastName, username } = user;
  return (
    <UserInfo
      scale="small"
      name={`${firstName ? firstName : '---'} ${lastName ? lastName : '---'}`}
    >
      {username}
    </UserInfo>
  );
};

export const noBreakWordFormatter = (formatter) => (value, record) => {
  return <NoBreakWord>{formatter(value, record)}</NoBreakWord>;
};

export const noValueFormatter = (formatter) => (value, record) => {
  if (value) {
    return formatter(value, record);
  }

  return <Line />;
};

export const documentIDFormatter = (value, record) => {
  if (value || value?.length > 0) {
    return <BreakWord>{value}</BreakWord>;
  }

  const { jobId } = record || {};

  if (!jobId) {
    return <Line />;
  }

  return (
    <div>
      <div>
        <FormattedMessage
          id="Document does not yet have a blockchain address"
          defaultMessage="Document does not yet have a blockchain address"
        />
      </div>
      <div>
        <BreakWord>
          <FormattedMessage
            id="Publication ID"
            defaultMessage="Publication ID"
          />
          {': '}
          {jobId}
        </BreakWord>
      </div>
    </div>
  );
};

export const centerMultiFormatter = (formatter) => (value, record) => {
  return <Centered>{formatter(value, record)}</Centered>;
};

export const centerSingleFormatter = (value, record) => {
  return <Centered>{value}</Centered>;
};

export const blurMultiFormatter = (formatter, blurEnabled) => (value, record) =>
  blurEnabled && record?.documentType === IDENTITY_DOCUMENT_TYPE.PESEL ? (
    <BlurContent>{formatter(value, record)}</BlurContent>
  ) : (
    formatter(value, record)
  );

export const blurSingleFormatter = (value, blurEnabled) =>
  blurEnabled ? <BlurContent>{value}</BlurContent> : value;
