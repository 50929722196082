import { QueryKeys } from '../../../../queryKeys';
import { getRequest } from '../../../../helpers/apiClient';
import apiRoutes from '../../../../api-routes';
import { useQueryWithLogout } from '../../../../hooks/useQueryWithLogout';

export const useSinglePublicNotificationTemplate = (templateId, options) => {
  return useQueryWithLogout(
    [QueryKeys.PUBLIC_NOTIFICATION_TEMPLATE, templateId],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.PUBLIC_NOTIFICATION_TEMPLATE.replace(
          '{templateId}',
          templateId,
        ),
      });
      return data;
    },
    options,
  );
};
