import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

// constants
import {
  DOCUMENT_TYPE,
  DOCUMENT_FILTER_FIELD_NAMES,
} from '../../../../../../constraints';

// hooks
import useDocumentType from '../../../../hooks/useDocumentType';
import { useIdentitiesOptions } from '../../../../../../hooks/useIdentitiesOptions';

// Components
import { SelectField as AsyncSelectField } from '../../../../../../components';
import { FieldLabel } from '../../../../../../components/Form';

const SelectIdentityNameField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.IDENTITY,
  identityName,
  onClear,
  isClearable,
}) => {
  const { formatMessage } = useIntl();
  const { documentType } = useDocumentType();
  const isDocumentPrivate = documentType === DOCUMENT_TYPE.PRIVATE;
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const {
    identities: identityNames,
    isIdentitiesFetching: isIdentityNamesFetching,
    onChangeIdentityInput: onChangeIdentityNamesInput,
  } = useIdentitiesOptions('name', isDocumentPrivate);

  const handleClear = (...args) => {
    onClear && onClear(...args);
  };

  useEffect(() => {
    if (!isIdentityNamesFetching && identityName) {
      setValue(
        name,
        identityNames.find((s) => s.value === identityName),
      );
    }
  }, [isIdentityNamesFetching, identityName, setValue, identityNames, name]);

  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Published for" defaultMessage="Published for" />
        {' ('}
        <FormattedMessage
          id="Name and surname"
          defaultMessage="Name and surname"
        />
        {')'}
      </FieldLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <AsyncSelectField
            input={{
              ...field,
              onInputChange: (inputText) =>
                onChangeIdentityNamesInput(inputText),
              onClear: handleClear,
            }}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            options={identityNames}
            isClearable={isClearable}
            isLoading={isIdentityNamesFetching}
            placeholder={formatMessage({
              id: 'Name and surname',
            })}
          />
        )}
      />
    </>
  );
};

export default SelectIdentityNameField;
