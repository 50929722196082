import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

const formatIntoSameTZ = (date) => moment(date).tz('America/Los_Angeles');

const isDateDelayed = (startDate, delayInMinutes) => {
  const delayDate = formatIntoSameTZ(new Date(startDate))
    .add(delayInMinutes, 'minutes')
    .valueOf();

  return delayDate - formatIntoSameTZ(new Date()).valueOf() <= 0;
};

const useTicker = (startDate, delayInMinutes) => {
  const tickerDate = formatIntoSameTZ(new Date(startDate))
    .add(delayInMinutes, 'minutes')
    .valueOf();

  const [ticker, setTicker] = useState(
    tickerDate - formatIntoSameTZ(new Date()).valueOf(),
  );

  useEffect(() => {
    if (isDateDelayed(tickerDate, delayInMinutes)) {
      return () => null;
    }
    const interval = setInterval(() => {
      setTicker(tickerDate - formatIntoSameTZ(new Date()).valueOf());
    }, 1000);
    return () => clearInterval(interval);
  }, [tickerDate, delayInMinutes]);

  return getReturnValues(ticker);
};

const getReturnValues = (countDown) => {
  const end = countDown < 0;
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { end, days, hours, minutes, seconds };
};

export { useTicker };
