import { useIntl } from 'react-intl';
import {
  documentStatuses,
  MAP_DOCUMENT_STATUSES,
} from '../../../../../constraints';

export const useDocumentStatusOptions = () => {
  const { formatMessage } = useIntl();

  const statusOptions = documentStatuses.map((status) => ({
    label: formatMessage({
      id: MAP_DOCUMENT_STATUSES[status],
    }),
    value: status,
  }));

  return { statusOptions };
};
