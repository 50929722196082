import React, { useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// components
import { FormattedMessage, useIntl } from 'react-intl';
import * as Styled from './styled';
import {
  Form as FormModule,
  Grid,
  Utils,
  Layout as LayoutModule,
} from 'billon-ui';
import { Red } from '../../../../../../components/StatusColorSpans';

// Hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useTenantsContext } from '../../../../../Tenants/hooks/useTenants';
import { useSchema } from './useSchema';

// Helpers
import { CUSTOMER } from '../../../../../../constraints';

const { FormGroup, FancyRadioField } = FormModule;
const { Button: ButtonModule, Text, Loader: LoaderModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

const { Content: ContentModule } = LayoutModule;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;

export const PublicatorForm = ({
  onSubmit,
  submitButtonText,
  isLoading: isSubmitting,
  defaultValues,
}) => {
  const { customer } = useConfigContext();
  const { tenants, areTenantsLoading } = useTenantsContext();

  const schema = useSchema();

  const formMethods = useForm({
    defaultValues: { tenantId: defaultValues.tenantId },
    resolver: yupResolver(schema),
  });

  const { formatMessage } = useIntl();
  const unspecifiedId = formatMessage({ id: 'Unspecified' });

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const titleId =
    customer === CUSTOMER.TAURON
      ? 'Publisher selection'
      : 'Publishing Institution selection';

  const errorMessage = useMemo(() => {
    return errors?.tenantId?.message;
  }, [errors]);

  if (areTenantsLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Styled.Title>
          <FormattedMessage id={titleId} defaultMessage={titleId} />
        </Styled.Title>
        <br />

        <Styled.TenantsRow>
          {tenants.map(({ name, id }) => {
            return (
              <Col md={4}>
                <FormGroup>
                  <Controller
                    name="tenantId"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <FancyRadioField
                          input={{
                            ...field,
                            value: id,
                            checked: field.value === id,
                          }}
                          meta={{
                            dirty: fieldState.isDirty,
                            touched: fieldState.invalid,
                          }}
                        >
                          {name ? name : unspecifiedId}
                        </FancyRadioField>
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            );
          })}
        </Styled.TenantsRow>

        <Row margin={'-15px 0 0 0'}>
          {errorMessage && (
            <Red>
              <Text>
                <FormattedMessage
                  id={errorMessage}
                  defaultMessage={errorMessage}
                />
              </Text>
            </Red>
          )}
        </Row>

        <Styled.CenterRow>
          <Col md={3}></Col>
          <Col md={6}>
            {!isSubmitting ? (
              <Button type="submit" size="lg" block>
                <FormattedMessage
                  id={submitButtonText}
                  defaultMessage={submitButtonText}
                />
              </Button>
            ) : (
              <ButtonLoader size="lg" block />
            )}
          </Col>
        </Styled.CenterRow>
      </Styled.Form>
    </FormProvider>
  );
};
