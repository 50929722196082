import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';
import { Grid, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Col } = Grid;

export const SubmittingFooter = ({
  isError,
  onBack,
  onClose,
  onNext,
  disabled,
}) => (
  <Styled.ModalFooter>
    <Styled.ActionRow>
      <Styled.ZeroCol md={3}>
        <Styled.BackButton secondary onClick={onBack}>
          <FormattedMessage id="back" defaultMessage="back" />
        </Styled.BackButton>
      </Styled.ZeroCol>
      <Col md={6}>
        {isError ? (
          <Button size="lg" block onClick={onClose}>
            <FormattedMessage id="Close" defaultMessage="Close" />
          </Button>
        ) : (
          <Button size="lg" block onClick={onNext} disabled={disabled}>
            <FormattedMessage id="Next" defaultMessage="Next" />
          </Button>
        )}
      </Col>
    </Styled.ActionRow>
  </Styled.ModalFooter>
);
