import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

// constants
import { NOTIFICATION_TEMPLATE_FIELD_NAMES } from '../../../../../../../../constraints';

// formatters
import { momentInTimezoneFormatter } from '../../../../../../../../formatters';

// Components
import { ControlledTextAreaField } from '../../../../../../../../components/ControlledTextAreaField';
import EditRestoreContentButton from '../EditRestoreContentButton';
import * as Styled from './styled';
import { Grid } from 'billon-ui';
const { Col } = Grid;

const RowHistoryFields = ({ contentData }) => {
  const {
    id: contentId,
    validSince,
    validUntil,
    emailTemplate,
    smsTemplate,
  } = contentData || {};

  const formMethods = useForm({
    defaultValues: {
      [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE]: emailTemplate,
      [NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_SMS_TEMPLATE]: smsTemplate,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <Styled.BorderedRow>
        <Col md={4}>
          <ControlledTextAreaField
            name={NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_EMAIL_TEMPLATE}
            maxLength={null}
            disabled={true}
          />
        </Col>
        <Col md={4}>
          <ControlledTextAreaField
            name={NOTIFICATION_TEMPLATE_FIELD_NAMES.CONTENT_SMS_TEMPLATE}
            maxLength={null}
            disabled={true}
          />
        </Col>
        <Styled.CenteredCol md={2}>
          <div>{momentInTimezoneFormatter(validSince) ?? '---'}</div>
        </Styled.CenteredCol>
        <Styled.CenteredCol md={2}>
          <div>{momentInTimezoneFormatter(validUntil) ?? '---'}</div>
        </Styled.CenteredCol>
        <EditRestoreContentButton
          contentId={contentId}
          validSince={validSince}
          validUntil={validUntil}
        />
      </Styled.BorderedRow>
    </FormProvider>
  );
};

export default RowHistoryFields;
