import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import appRoutes from '../../../../app-routes';
import RecipientManager from '../../components/RecipientManager';
import { RootRedirectPage } from '../../../Documents/screens';
import { useConfigContext } from '../../../Config/hooks/useConfig';

const RecipientManagerPage = () => {
  const history = useHistory();
  const { enablePrivate } = useConfigContext();
  const { params } = useRouteMatch(appRoutes.RECIPIENT_MANAGER);
  const { mode } = params;

  const handleClose = () => {
    history.push(appRoutes.RECIPTIENTS);
  };

  if (!enablePrivate) {
    return <RootRedirectPage />;
  }

  return <RecipientManager managerMode={mode} onClose={handleClose} />;
};

export default RecipientManagerPage;
