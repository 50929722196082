import styled from 'styled-components';

import { Utils } from 'billon-ui';

export const Card = styled(Utils.Card)`
  padding: 2rem;
  overflow: auto;
  .recharts-surface {
    margin-right: 40px;
  }
  .recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 10px;
  }
  .recharts-layer.recharts-bar-rectangle {
    opacity: 0.7;
    border-radius: 10px;
  }
  margin-bottom: 16px;
`;
