import React from 'react';
import { Dropdown as DropdownModule } from 'billon-ui';
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { DOCUMENT_TYPE, CUSTOMER } from '../../../../constraints';

const { UncontrolledDropdown, DropdownItem, DropdownMenu } = DropdownModule;

const DocumentNavigator = ({ customer, onNavigate }) => {
  if (customer === CUSTOMER.TAURON) {
    return (
      <Styled.IconButton
        icon="folder-open"
        onClick={() => onNavigate(DOCUMENT_TYPE.PUBLIC)}
      />
    );
  } else if (customer === CUSTOMER.DIPLOMA) {
    return (
      <Styled.IconButton
        icon="folder-open"
        onClick={() => onNavigate(DOCUMENT_TYPE.PRIVATE)}
      />
    );
  } else {
    return (
      <UncontrolledDropdown>
        <Styled.DropDownDiv>
          <Styled.DropdownToggle>
            <Styled.IconButton icon="folder-open" />
          </Styled.DropdownToggle>
        </Styled.DropDownDiv>
        <DropdownMenu>
          <DropdownItem onClick={() => onNavigate(DOCUMENT_TYPE.PRIVATE)}>
            <FormattedMessage id="Private2" defaultMessage="Private" />
          </DropdownItem>
          <DropdownItem onClick={() => onNavigate(DOCUMENT_TYPE.PUBLIC)}>
            <FormattedMessage id="Public2" defaultMessage="Public" />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
};

export default DocumentNavigator;
