import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button, BackButton: BillonBackButton } = ButtonModule;

export const SubmitButton = styled(Button)`
  margin-top: 4rem;
`;
export const BackButton = styled(BillonBackButton)`
  margin-top: 10px;
`;
