import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

// helpers
import { SETTINGS_CONSTANTS } from '../../../../../../constraints';
import { RECIPIENT_VALIDATION_PURPOSE } from '../../../../../../constants';

// hooks
import { useSchema } from './useSchema';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';
import { useValidateRecipients } from '../../../../../Recipients/hooks/useValidateRecipients';

// Components
import { UploadRecipientsField } from '../../../../../../components/UploadRecipientsField/UploadRecipientsField';
import { FormFooter } from '../../../../../../components/FormFooter';
import FileErrorSummary from './components/FileErrorSummary';
import FileSuccessSummary from './components/FileSuccessSummary';
import * as Styled from './styled';

import { Form as FormModule, Layout as LayoutModule, Utils } from 'billon-ui';

const { FormGroup, Form } = FormModule;
const { Text, Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

export const RecipientsFileForm = ({
  onSubmit,
  onBack,
  isLoading,
  defaultValues = {},
  validationPurpose,
  customTitle,
  mode,
  backBtnDisabled,
  isEmbedded,
  submitMessageId,
  handleCancelProcess,
  publicDocumentType,
}) => {
  const { getSettingValueByKey } = useSettingsContext();
  const maxRecipientFileSize = getSettingValueByKey(
    SETTINGS_CONSTANTS.MAX_RECIPIENTS_FILE_SIZE,
  );
  const areRecipientsObligatory =
    getSettingValueByKey(SETTINGS_CONSTANTS.RECIPIENTS_OBLIGATORY) ||
    validationPurpose === RECIPIENT_VALIDATION_PURPOSE.UPDATE;

  const schema = useSchema(maxRecipientFileSize, areRecipientsObligatory);

  const formMethods = useForm({
    defaultValues: {
      contactDetails: [],
      ...defaultValues,
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, watch } = formMethods;

  const handleSuccess = () => {
    const values = watch();
    onSubmit(values);
  };

  const {
    mutate: validateRecipients,
    isLoading: isValidating,
    isError,
    isSuccess,
    error,
    data: successData,
    reset,
  } = useValidateRecipients({
    onSuccess: ({ total }) => {
      if (total === undefined) {
        handleSuccess();
      }
    },
  });

  const handleFormSubmit = (values) => {
    if (
      areRecipientsObligatory ||
      (!areRecipientsObligatory && values.contactDetails.length > 0)
    ) {
      validateRecipients({
        data: {
          file: values.contactDetails,
          purpose: validationPurpose
            ? validationPurpose
            : RECIPIENT_VALIDATION_PURPOSE.PUBLISH,
          publicDocumentType:
            values?.publicDocumentType?.value || publicDocumentType,
        },
      });
    } else {
      onSubmit(values, areRecipientsObligatory);
    }
  };

  const handleResetMutation = () => {
    reset();
  };

  const handleIgnoreErrors = () => {
    const values = watch();
    onSubmit(values);
  };

  let errorData = error?.response?.data;

  const cancelMessageId =
    validationPurpose === RECIPIENT_VALIDATION_PURPOSE.PUBLISH
      ? 'Cancel publication'
      : 'Cancel';

  if (isValidating) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  if (isError && errorData) {
    return (
      <FileErrorSummary
        mode={mode}
        errorData={errorData}
        handleResetMutation={handleResetMutation}
        handleIgnoreErrors={handleIgnoreErrors}
        handleExit={handleCancelProcess}
        validationPurpose={validationPurpose}
        cancelMessageId={cancelMessageId}
      />
    );
  }

  if (isSuccess && successData) {
    return (
      <FileSuccessSummary
        mode={mode}
        successData={successData}
        handleResetMutation={handleResetMutation}
        handleSuccess={handleSuccess}
        handleExit={handleCancelProcess}
        cancelMessageId={cancelMessageId}
      />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Styled.FormHeader>
          {customTitle ? (
            customTitle
          ) : (
            <FormattedMessage
              id="Provide recipients of the notification"
              defaultMessage="Provide recipients of the notification"
            />
          )}
        </Styled.FormHeader>
        <br />
        <FormGroup>
          <UploadRecipientsField
            name="contactDetails"
            isEmbedded={isEmbedded}
            maxSize={maxRecipientFileSize}
          />
        </FormGroup>
        {!areRecipientsObligatory && (
          <Text>
            <FormattedMessage
              id="Field optional"
              defaultMessage="Field optional"
            />
          </Text>
        )}
        <FormFooter
          isLoading={isLoading}
          onBack={onBack}
          backBtnDisabled={backBtnDisabled}
          submitMessageId={submitMessageId}
        />
      </Form>
    </FormProvider>
  );
};
