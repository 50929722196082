import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

// constants
import { MAP_SMS_ERROR_CODES } from '../../../../constraints';

// hooks
import { useTokenParamContext } from '../../../Auth/hooks/useTokenParam';
import { usePatchPassword } from '../../hooks/usePatchPassword';
import { useSchema } from './useSchema';

// components
import SmsCodeInput from './SmsCodeInput';
import * as Styled from './styled';
import { Form as FormModule, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { FormGroup } = FormModule;
const { Text } = Utils;

const TypeVerificationCode = ({
  passwords,
  handleOnSubmitCodeSuccess,
  handleOnBack,
}) => {
  const { formatMessage } = useIntl();
  const schema = useSchema();
  const { token } = useTokenParamContext();

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const smsCode = watch('smsCode');

  const handleError = ({ response }) => {
    const error = response?.data?.errorInfo?.CODE;
    if (error) {
      const errorMessage = MAP_SMS_ERROR_CODES[error];
      setError('smsCode', {
        type: 'custom',
        message: formatMessage({ id: errorMessage }),
      });
    }
  };

  const { mutate: patchPassword, isLoading: isPatchingPassword } =
    usePatchPassword(
      {
        onSuccess: handleOnSubmitCodeSuccess,
        onError: handleError,
      },
      token,
    );

  const handleSubmitCode = () => {
    const passwordData = {
      ...passwords,
      smsCode: smsCode,
    };
    patchPassword({ passwordData: passwordData });
  };

  const handleOnChange = (value) => {
    setValue('smsCode', value);
  };

  const isSubmitButtonLoading = useMemo(
    () => isPatchingPassword || isSubmitting,
    [isPatchingPassword, isSubmitting],
  );

  return (
    <Styled.Card>
      <Styled.Title>
        <FormattedMessage
          id="Confirm via SMS"
          defaultMessage="Confirm via SMS"
        />
      </Styled.Title>
      <Text>
        <FormattedMessage
          id="We have sent a 6-digit verification code to your phone number"
          defaultMessage="We have sent a 6-digit verification code to your phone number"
        />
      </Text>
      <Styled.CardBodyWrapper>
        <Styled.Form>
          <SmsCodeInput
            setSmsCodeValue={handleOnChange}
            control={control}
            errors={errors}
          />
          <Styled.CardBodyWrapper>
            <FormGroup>
              {isSubmitButtonLoading ? (
                <ButtonLoader block size="lg" />
              ) : (
                <Button
                  type="submit"
                  size="lg"
                  block
                  onClick={handleSubmit(handleSubmitCode)}
                >
                  <FormattedMessage id="Next" defaultMessage="Next" />
                </Button>
              )}
              <Text textAlign={'center'} margin={'10px 0 30px 0'}>
                <FormattedMessage
                  id="Got a problem?"
                  defaultMessage="Got a problem?"
                />{' '}
                <Link to="/support">
                  <FormattedMessage
                    id="Contact the support team"
                    defaultMessage="Contact the support team"
                  />
                </Link>
              </Text>
            </FormGroup>
          </Styled.CardBodyWrapper>
        </Styled.Form>
      </Styled.CardBodyWrapper>
      <Styled.ZeroCol md={3}>
        <Styled.BackButton secondary onClick={handleOnBack}>
          <FormattedMessage id="back" defaultMessage="back" />
        </Styled.BackButton>
      </Styled.ZeroCol>
    </Styled.Card>
  );
};

TypeVerificationCode.propTypes = {
  handleOnSubmitCodeSuccess: PropTypes.func.isRequired,
  handleOnBack: PropTypes.func.isRequired,
  passwords: PropTypes.object.isRequired,
  token: PropTypes.object,
};

export default TypeVerificationCode;
