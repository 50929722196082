import React from 'react';
import { StatusTooltip } from '../../../../components';
import StatusWithIcon from '../../../../ui/StatusWithIcon';
import { USER_STATUS_MESSAGES } from '../../../../constraints';
import { generateRecordBasedId } from '../../../../utils';
import { userStatusFormatter } from '../../formatters';

const UserStatus = ({ value, record }) => {
  const label = userStatusFormatter(value);

  return (
    <StatusWithIcon label={label}>
      <StatusTooltip
        label={USER_STATUS_MESSAGES[value]}
        id={generateRecordBasedId(record)}
        delay={false}
      />
    </StatusWithIcon>
  );
};

export default UserStatus;
