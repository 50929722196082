import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useSigningStatusOptions } from '../../hooks/useSigningStatusOptions';
import { useDocumentStatusOptions } from '../../hooks/useDocumentStatusOptions';

// Components
import { ControlledSelectField } from '../../../../../../components/ControlledSelectField/ControlledSelectField';
import { FieldLabel } from '../../../../../../components/Form';

const SelectStatusField = ({ onClear, isClearable }) => {
  const { enableSigning } = useConfigContext();

  const { statusOptions: signingStatusOptions } = useSigningStatusOptions();
  const { statusOptions: documentStatusOptions } = useDocumentStatusOptions();

  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Status" defaultMessage="Status" />
      </FieldLabel>
      <ControlledSelectField
        name={
          enableSigning
            ? DOCUMENT_FILTER_FIELD_NAMES.SIGNING_STATUS
            : DOCUMENT_FILTER_FIELD_NAMES.DOCUMENT_STATUS_LIST
        }
        options={enableSigning ? signingStatusOptions : documentStatusOptions}
        onClear={onClear}
        isClearable={isClearable}
      />
    </>
  );
};

export default SelectStatusField;
