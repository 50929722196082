import styled from 'styled-components';
import { Form as FormModule, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;

export const FormGroup = styled(FormModule.FormGroup)`
  position: relative;
`;

export const IconButton = styled(ButtonModule.IconButton)`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
`;

export const ShowMoreLabelButton = styled(ButtonModule.Button)`
  margin: 0;
  padding: 0;
  font-weight: 700;
`;

export const ShowNoMoreLabelButton = styled(ShowMoreLabelButton)`
  font-weight: 300 !important;
  color: lightgrey !important;
`;
