import React from 'react';
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { DefaultContentCol } from '../../../../components/UniCardContent/UniCardContent';

const SingleCustomCard = ({ titleId, children }) => {
  return (
    <DefaultContentCol>
      <Styled.Card>
        {titleId && (
          <Styled.Title>
            <FormattedMessage id={titleId} defaultMessage={titleId} />
          </Styled.Title>
        )}
        <Styled.CardBodyWrapper>{children}</Styled.CardBodyWrapper>
      </Styled.Card>
    </DefaultContentCol>
  );
};

export default SingleCustomCard;
