import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { union } from 'lodash';
import PropTypes from 'prop-types';

// helpers
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';

// constants
import {
  VALIDATION_ERRORS,
  VALIDATION_ERROR_TYPES,
  MAP_VALIDATION_ERROR_TYPES,
  PASSWORD_FORM_MODES,
  PASSWORD_FORM_FIELD_NAMES,
} from '../../../../constraints';

// hooks
import { useSchema } from '../PasswordForm/useSchema';
import { useConfigContext } from '../../../../modules/Config/hooks/useConfig';
import { useValidatePassword } from '../../../../modules/Settings/hooks/useValidatePassword';
import { useTokenParamContext } from '../../../Auth/hooks/useTokenParam';
import { useValidationConstraints } from './hooks/useValidationConstraints';
import { useFormUserRoleInfo } from './hooks/useFormUserRoleInfo';

// components
import {
  ValidationIndicator,
  PasswordInfo,
  ControlledPasswordField,
  FormNavigator,
} from './components';
import * as Styled from './styled';

const PasswordForm = ({
  deliveredRole,
  formConfig,
  deliveredReasons,
  deliveredErrorHandler,
  deliveredLoading,
  onBack,
}) => {
  const {
    mode,
    handleSuccess: onSuccess,
    submitMessageId,
    oldPasswordId = 'Old password',
    passwordLabelId,
    repeatPasswordLabelId,
  } = formConfig;

  const history = useHistory();
  const { usersConfig } = useConfigContext();
  const { enableTwoStepPassword } = usersConfig || {};
  const { token } = useTokenParamContext();
  const {
    roleKeyPrefix: keyPrefix,
    role,
    reportRole,
    isLoading: isRoleLoading,
  } = useFormUserRoleInfo({ deliveredRole });
  const { validationConstraints } = useValidationConstraints({
    mode,
    keyPrefix,
    deliveredRole,
  });

  const schema = useSchema({ validationConstraints: validationConstraints });
  const formMethods = useForm({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, formState, getValues, setError } = formMethods;
  const { isSubmitting } = formState || {};

  const [reasons, setReasons] = useState(deliveredReasons);

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };
  const handleError = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };

  const { mutate: validatePassword, isLoading: isPasswordValidating } =
    useValidatePassword(
      {
        onSuccess: () => onSuccess(getValues()),
        onError: deliveredErrorHandler ? deliveredErrorHandler : handleError,
      },
      token,
    );

  const isSubmitButtonLoading = useMemo(
    () =>
      deliveredLoading || isSubmitting || isRoleLoading || isPasswordValidating,
    [isSubmitting, isRoleLoading, deliveredLoading, isPasswordValidating],
  );

  const onSubmit = (values) => {
    const { oldPassword, password } = values;
    const body = { oldPassword: oldPassword, password: password };
    if (enableTwoStepPassword) {
      validatePassword({
        data: removeUndefinedValues(body),
      });
    } else {
      onSuccess(getValues());
    }
  };

  useEffect(() => {
    setReasons(union(reasons, deliveredReasons));
    if (deliveredReasons.includes(VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS)) {
      if (mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE) {
        setError(PASSWORD_FORM_FIELD_NAMES.OLD_PASSWORD, {
          type: 'custom',
          message: MAP_VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS,
        });
      } else {
        setError(PASSWORD_FORM_FIELD_NAMES.PASSWORD, {
          type: 'custom',
          message: VALIDATION_ERRORS.EMPTY,
        });
        setError(PASSWORD_FORM_FIELD_NAMES.REPEAT_PASSWORD, {
          type: 'custom',
          message: MAP_VALIDATION_ERROR_TYPES.INCORRECT_PREVIOUS,
        });
      }
    }
    if (deliveredReasons.includes(VALIDATION_ERROR_TYPES.IDENTICAL)) {
      setError(PASSWORD_FORM_FIELD_NAMES.PASSWORD, {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
      setError(PASSWORD_FORM_FIELD_NAMES.REPEAT_PASSWORD, {
        type: 'custom',
        message: MAP_VALIDATION_ERROR_TYPES.IDENTICAL,
      });
    }
    if (deliveredReasons.includes(VALIDATION_ERROR_TYPES.CHANGED_TOO_OFTEN)) {
      setError(PASSWORD_FORM_FIELD_NAMES.PASSWORD, {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
      setError(PASSWORD_FORM_FIELD_NAMES.REPEAT_PASSWORD, {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
    }
  }, [deliveredReasons]);

  return (
    <FormProvider {...formMethods}>
      <Styled.Form>
        <form onSubmit={handleSubmit(onSubmit)}>
          {mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE && (
            <ControlledPasswordField
              name={PASSWORD_FORM_FIELD_NAMES.OLD_PASSWORD}
              labelMessageId={oldPasswordId}
            />
          )}
          <ControlledPasswordField
            name={PASSWORD_FORM_FIELD_NAMES.PASSWORD}
            labelMessageId={passwordLabelId}
          />
          <ValidationIndicator />
          <ControlledPasswordField
            name={PASSWORD_FORM_FIELD_NAMES.REPEAT_PASSWORD}
            labelMessageId={repeatPasswordLabelId}
          />
          <PasswordInfo reasons={reasons} role={role} reportRole={reportRole} />
          <FormNavigator
            isSubmitButtonLoading={isSubmitButtonLoading}
            handleBack={handleBack}
            submitMessageId={submitMessageId}
          />
        </form>
      </Styled.Form>
    </FormProvider>
  );
};

PasswordForm.propTypes = {
  token: PropTypes.object,
  deliveredErrorHandler: PropTypes.func,
  role: PropTypes.string,
  formConfig: PropTypes.object.isRequired,
};

export default PasswordForm;
