import { useIntl } from 'react-intl';

import { mapUSER_STATUSES, USER_STATUSES } from '../../../../../constraints';

export const useStatusOptions = () => {
  const { formatMessage } = useIntl();

  const statusOptions = [
    ...Object.values(USER_STATUSES)
      .filter((key) => ![USER_STATUSES.ACCOUNT_TEMPORARY_BLOCKED].includes(key))
      .map((key) => {
        const msgId = mapUSER_STATUSES[key];
        return {
          label: formatMessage({
            id: msgId,
            defaultMessage: msgId,
          }),
          value: key,
        };
      }),
  ];

  return { statusOptions };
};
