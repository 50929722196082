import { postRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../api-routes';

export const useLogout = (options) =>
  useMutationWithLogout(async () => {
    const { data } = await postRequest({
      url: apiRoutes.LOGOUT,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return data;
  }, options);
