import React from 'react';
import {
  MAP_DOCUMENT_STATUSES,
  DOCUMENT_STATUS,
} from '../../../../../constraints';
import generateId from '../../../../../utils/generateId';
import { documentStatusFormatter } from '../../../../Documents/formatters/documentStatusFormatter';
import { FormattedMessage } from 'react-intl';
import StatusWithIcon from '../../../../../ui/StatusWithIcon';
import DocumentStatusTooltip from '../../../../Documents/components/DocumentStatusTooltip';

export const forgettingStatusFormatter =
  (enableSigning) =>
  (value, record = {}) => {
    const { forgettingStatus } = record;

    if (!!forgettingStatus) {
      const label = MAP_DOCUMENT_STATUSES[forgettingStatus];
      const formattedLabel = (
        <FormattedMessage id={label} defaultMessage={label} />
      );
      const { jobId, title } = record;
      if (forgettingStatus === DOCUMENT_STATUS.FORGETTING_EXCEPTION) {
        return (
          <StatusWithIcon label={formattedLabel}>
            <DocumentStatusTooltip
              id={generateId(record)}
              label="Failed to forget document, please contact administrator or try again"
              jobId={jobId}
              title={title}
              delay={true}
            />
          </StatusWithIcon>
        );
      } else {
        return formattedLabel;
      }
    } else {
      return documentStatusFormatter(enableSigning)(value, record);
    }
  };
