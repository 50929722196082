import * as yup from 'yup';
import { useIntl } from 'react-intl';

// constants
import {
  VALIDATION_ERRORS,
  NOTIFICATION_FIELD_ERRORS,
} from '../../../../constraints';

export const usePrivateNameSchema = ({ name }) => {
  const { formatMessage } = useIntl();
  return yup.object({
    [name]: yup
      .string()
      .test(
        'nameEmpty',
        formatMessage({
          id: NOTIFICATION_FIELD_ERRORS.TITLE_EMPTY,
          defaultMessage: NOTIFICATION_FIELD_ERRORS.TITLE_EMPTY,
        }),
        (value) => (value ? value?.replaceAll(' ', '')?.length > 0 : true),
      )
      .max(
        255,
        formatMessage(
          {
            id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
            defaultMessage: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED_LENGTH,
          },
          {
            length: 255,
          },
        ),
      )
      .required(VALIDATION_ERRORS.REQUIRED),
  });
};
