import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PUBLICATION_MODES,
  DOCUMENT_STATUS,
  SIGNING_STATUS,
} from '../../../../constraints';
import {
  SecondaryBadge,
  SuccessBadge,
  SecondaryDarkerBadge,
  FailureBadge,
} from '../../../../ui/Badge';

const StandardStatusBasedBadge = ({
  publicationMode,
  status: dcoumentStatus,
  signingStatus = SIGNING_STATUS.PUBLISHING_OK,
  nextPublicationMode,
}) => {
  if (
    [DOCUMENT_STATUS.DEPRECATED].includes(dcoumentStatus) &&
    signingStatus === SIGNING_STATUS.PUBLISHING_OK &&
    nextPublicationMode === PUBLICATION_MODES.INCIDENT
  ) {
    return (
      <FailureBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="Incident" defaultMessage="Incident" />
      </FailureBadge>
    );
  }

  if (
    publicationMode === PUBLICATION_MODES.UPDATED &&
    dcoumentStatus === DOCUMENT_STATUS.CURRENT &&
    signingStatus === SIGNING_STATUS.PUBLISHING_OK
  ) {
    return (
      <SuccessBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="New version" defaultMessage="New version" />
      </SuccessBadge>
    );
  }

  if (
    publicationMode === PUBLICATION_MODES.CORRECTED &&
    [DOCUMENT_STATUS.CURRENT, DOCUMENT_STATUS.DEPRECATED].includes(
      dcoumentStatus,
    ) &&
    signingStatus === SIGNING_STATUS.PUBLISHING_OK
  ) {
    return (
      <SecondaryBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="Erratum" defaultMessage="Erratum" />
      </SecondaryBadge>
    );
  }

  if (
    [
      PUBLICATION_MODES.UPDATED,
      PUBLICATION_MODES.NEW,
      PUBLICATION_MODES.INCIDENT,
    ].includes(publicationMode) &&
    dcoumentStatus === DOCUMENT_STATUS.DEPRECATED &&
    signingStatus === SIGNING_STATUS.PUBLISHING_OK
  ) {
    return (
      <SecondaryDarkerBadge margin={'0 0 0 15px'}>
        <FormattedMessage
          id="Previous Version"
          defaultMessage="Previous Version"
        />
      </SecondaryDarkerBadge>
    );
  }
  if (
    dcoumentStatus === DOCUMENT_STATUS.PREPARED_TO_SIGN &&
    signingStatus === SIGNING_STATUS.PUBLISHING_OK
  ) {
    return (
      <SecondaryBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="Prepared" defaultMessage="Prepared" />
      </SecondaryBadge>
    );
  }
  if (
    [DOCUMENT_STATUS.NOT_EXIST, DOCUMENT_STATUS.NOT_AVAILABLE].includes(
      dcoumentStatus,
    )
  ) {
    return (
      <FailureBadge margin={'0 0 0 15px'}>
        <FormattedMessage
          id="Publishing error"
          defaultMessage="Publishing error"
        />
      </FailureBadge>
    );
  }

  if (
    [DOCUMENT_STATUS.NOT_EXIST, DOCUMENT_STATUS.NOT_AVAILABLE].includes(
      dcoumentStatus,
    )
  ) {
    return (
      <FailureBadge margin={'0 0 0 15px'}>
        <FormattedMessage
          id="Publishing error"
          defaultMessage="Publishing error"
        />
      </FailureBadge>
    );
  }

  if (dcoumentStatus === DOCUMENT_STATUS.PREPARED_TO_SIGN) {
    return (
      <SecondaryBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="Prepared" defaultMessage="Prepared" />
      </SecondaryBadge>
    );
  }

  if (
    dcoumentStatus === DOCUMENT_STATUS.UPLOADING ||
    signingStatus === DOCUMENT_STATUS.PUBLISHING_INITIATED
  ) {
    return (
      <SecondaryBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="Uploading" defaultMessage="Uploading" />
      </SecondaryBadge>
    );
  }

  if (
    dcoumentStatus === DOCUMENT_STATUS.CURRENT &&
    signingStatus === SIGNING_STATUS.PUBLISHING_OK
  ) {
    return (
      <SuccessBadge margin={'0 0 0 15px'}>
        <FormattedMessage id="Approved" defaultMessage="Approved" />
      </SuccessBadge>
    );
  }

  return null;
};

export default StandardStatusBasedBadge;
