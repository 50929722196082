import React from 'react';
import { debounce } from 'lodash';
import { useTenantList } from '../modules/Tenants/hooks/useTenantList';
import { tenantFormatter } from '../modules/Tenants/formatters/tenantFormatter';

export const useTenantOptions = (customer, valueName = 'id', enabled) => {
  const [tenantName, setTenantName] = React.useState(undefined);

  const unspecifiedLabel = tenantFormatter(customer)();

  const setSearchTextDebounced = React.useRef(
    debounce((callback) => callback(), 700),
  ).current;

  const { data: tenantRows, isFetching: areTenantsFetching } = useTenantList({
    enabled: enabled,
    refetchOnMount: false,
  });

  const tenants = React.useMemo(
    () => [
      ...(tenantRows || []).map((tenant) => ({
        value: tenant[valueName],
        label: tenant?.name || unspecifiedLabel,
      })),
      {
        value: 'UNDEFINED',
        label: unspecifiedLabel,
      },
    ],
    [tenantRows, valueName, unspecifiedLabel],
  );

  const onChangeTenantInput = (inputText) => {
    if (inputText !== tenantName) {
      setSearchTextDebounced(() => setTenantName(inputText));
    }
  };
  return { tenants, areTenantsFetching, onChangeTenantInput };
};
