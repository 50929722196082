import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../../../../../constraints';

// Components
import { FieldTitle } from './styled';
import { Grid } from 'billon-ui';
const { Row, Col } = Grid;

const ContentHistoryHeader = () => {
  return (
    <Row>
      <Col md={4}>
        <FieldTitle>
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.EMAIL}
            defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.EMAIL}
          />
        </FieldTitle>
      </Col>
      <Col md={4}>
        <FieldTitle>
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.SMS}
            defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.SMS}
          />
        </FieldTitle>
      </Col>
      <Col md={2}>
        <FieldTitle textAlign="center">
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_VALID_SINCE}
            defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_VALID_SINCE}
          />
        </FieldTitle>
      </Col>
      <Col md={2}>
        <FieldTitle textAlign="center">
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_VALID_UNTIL}
            defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.CONTENT_VALID_UNTIL}
          />
        </FieldTitle>
      </Col>
    </Row>
  );
};

export default ContentHistoryHeader;
