import { useSettingsContext } from '../../../../Settings/hooks/useSettings';

import {
  PASSWORD_FORM_MODES,
  MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS,
  VALIDATION_ERROR_TYPES,
} from '../../../../../constraints';

export const useValidationConstraints = ({ mode, keyPrefix }) => {
  const { getSettingValueByKey } = useSettingsContext();

  const validationConstraints = {
    oldPasswordEnabled: mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE,
    minLength: getSettingValueByKey(
      `${keyPrefix}_${
        MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[VALIDATION_ERROR_TYPES.TOO_SHORT]
      }`,
    ),
    complexity: getSettingValueByKey(
      `${keyPrefix}_${
        MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[
          VALIDATION_ERROR_TYPES.NO_UNIQUE_CHARACTERS
        ]
      }`,
    ),
  };

  return { validationConstraints };
};
