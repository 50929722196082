import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Grid } from 'billon-ui';

export const Col = styled(Grid.Col)`
  margin: 1rem 0;
`;

export const DocumentInfo = styled.div`
  .btn {
    font-size: 13px;
    height: 100%;

    ${down('sm')} {
      width: 300px;
    }
  }

  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .border-right {
    border-right: 1px solid ${({ theme }) => theme.palette.grayLight};
  }

  label.large {
    display: block;
    margin-bottom: 0;
  }

  .blockchain-address-span {
    word-wrap: break-word;
    font-weight: 600;
  }

  .status-span {
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      font-size: ${({ theme }) => theme.fontSizeSm};
      margin-bottom: 1rem;

      label {
        display: block;
        font-weight: ${({ theme }) => theme.fontWeightBold};
        margin-bottom: 0;
      }
    }
  }
`;
