import React from 'react';
import { Utils, Grid } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';
import { useHistory } from 'react-router-dom';

const { Button: ButtonModule, Card } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

const ChooseType = () => {
  const history = useHistory();

  return (
    <Styled.MyCol xs={12}>
      <Row>
        <Col md={2} />
        <Col md={4}>
          <Card>
            <div>
              <Styled.Title>
                <FormattedMessage id="Tariffs" defaultMessage="Tariffs" />
              </Styled.Title>
              <Styled.Description>
                Drag&drop plik lub wybierz z dysku plik PDF który chcesz
                opulikować
              </Styled.Description>
            </div>
            <Row noGutters>
              <Col md={9}>
                <Button
                  block
                  size="lg"
                  onClick={() => history.push('/documents/tariffs')}
                >
                  <FormattedMessage id="Next" defaultMessage="Next" />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <div>
              <Styled.Title>
                <FormattedMessage id="Agreements" defaultMessage="Agreements" />
              </Styled.Title>
              <Styled.Description>
                Drag&drop plik lub wybierz z dysku plik HTML który chcesz
                opulikować
              </Styled.Description>
            </div>
            <Row noGutters>
              <Col md={9}>
                <Button
                  block
                  size="lg"
                  onClick={() => history.push('/documents/agreements')}
                >
                  <FormattedMessage id="Next" defaultMessage="Next" />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Styled.MyCol>
  );
};

export default ChooseType;
