import React from 'react';
import styled from 'styled-components';
import { Utils, Grid } from 'billon-ui';

export const FilterCard = styled(Utils.Card)`
  position: relative;
  box-shadow: none;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

export const FlexEndRow = styled(Grid.Row)`
  justify-content: flex-end;
`;

export const FlexStartRow = styled(Grid.Row)`
  justify-content: flex-start;
`;
