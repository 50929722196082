import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

// helpers
import { parseFilters } from '../../heplers';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';

// constants
import { USER_FILTER_FIELD_NAMES } from '../../../../constraints';

// hooks
import useFilters from '../../../../hooks/useFilters';
import { useDocumentFormDefaultValues } from './hooks/useUsersFormDefaultValues';

// Components
import {
  FormHeader,
  FormActions,
  FlexStartRow,
  FilterCard,
} from '../../../../components/Form';
import {
  TextUserNameField,
  TextFirstNameField,
  TextLastNameField,
  TextIdField,
  TextCreatedByField,
  TextPhoneNumberField,
  TextEmailField,
  SelectRoleField,
  CalendarCreatedAtField,
  SelectIsActiveField,
  SelectStatusField,
} from './components';
import { Grid } from 'billon-ui';

const { Col } = Grid;

export const FilterForm = ({ initialValues: defaultFilters, closeFilters }) => {
  const { navigateWithSearchQuery, onClear, onSingleFilterClear } =
    useFilters();
  const { formInitialValues, computedDefaultValues } =
    useDocumentFormDefaultValues(defaultFilters);
  const formMethods = useForm({
    defaultValues: computedDefaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    resetField,
  } = formMethods;

  const onClearFilters = () => {
    onClear();
    reset(formInitialValues);
  };
  const onSingleFieldClear = (name) => {
    resetField(name);
    onSingleFilterClear(name, defaultFilters?.filters);
  };
  const onFieldClear = (name) => onSingleFieldClear(name);
  const onSubmit = (values) => {
    navigateWithSearchQuery({
      filters: removeUndefinedValues(parseFilters(values)),
    });
  };

  return (
    <FilterCard>
      <FormHeader closeFilters={closeFilters} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexStartRow>
            <Col lg={6} xl={3}>
              <TextUserNameField name={USER_FILTER_FIELD_NAMES.USERNAME} />
            </Col>
            <Col lg={6} xl={3}>
              <TextFirstNameField name={USER_FILTER_FIELD_NAMES.FIRST_NAME} />
            </Col>
            <Col lg={6} xl={3}>
              <TextLastNameField name={USER_FILTER_FIELD_NAMES.LAST_NAME} />
            </Col>
            <Col lg={6} xl={3}>
              <TextIdField name={USER_FILTER_FIELD_NAMES.ID} />
            </Col>
            <Col lg={6} xl={3}>
              <SelectRoleField
                name={USER_FILTER_FIELD_NAMES.ROLE}
                isClearable
                onClear={onFieldClear}
              />
            </Col>
            <Col lg={6} xl={3}>
              <TextCreatedByField name={USER_FILTER_FIELD_NAMES.CREATED_BY} />
            </Col>
            <Col lg={6} xl={3}>
              <SelectIsActiveField
                name={USER_FILTER_FIELD_NAMES.IS_ACTIVE}
                isClearable
                onClear={onFieldClear}
              />
            </Col>
            <Col lg={6} xl={3}>
              <TextPhoneNumberField
                name={USER_FILTER_FIELD_NAMES.PHONE_NUMBER}
              />
            </Col>
            <Col lg={6} xl={3}>
              <CalendarCreatedAtField
                name={USER_FILTER_FIELD_NAMES.CREATED_AT}
                onClear={onFieldClear}
              />
            </Col>
            <Col lg={6} xl={3}>
              <TextEmailField name={USER_FILTER_FIELD_NAMES.EMAIL} />
            </Col>
            <Col lg={6} xl={3}>
              <SelectStatusField
                name={USER_FILTER_FIELD_NAMES.STATUS}
                isClearable
                onClear={onFieldClear}
              />
            </Col>
          </FlexStartRow>
          <FormActions
            onClearFilters={onClearFilters}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </FilterCard>
  );
};
