import apiRoutes from '../../../api-routes';
import { patchRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

export const usePasswordExpired = (options) => {
  return useMutationWithLogout(async ({ id }) => {
    const { data: password } = await patchRequest({
      url: apiRoutes.PASSWORD_EXPIRED.replace('{id}', id),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });
    return password;
  }, options);
};
