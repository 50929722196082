import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'billon-ui';
import * as Styled from './styled';

const { FormControl } = Form;

const TextareaField = ({
  input,
  maxLength,
  placeholder,
  disabled,
  rows,
  onKeyDown,
  meta: { touched, active, dirty, error, warning, submitting },
  ...rest
}) => (
  <FormControl
    active={active}
    touched={touched}
    dirty={dirty || !!input.value}
    error={error}
    warning={warning}
    {...rest}
  >
    <Styled.Textarea
      {...input}
      rows={rows}
      className="form-control"
      maxLength={maxLength}
      disabled={submitting || disabled}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
    />
  </FormControl>
);

TextareaField.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  maxLength: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  onKeyDown: PropTypes.func,
};

TextareaField.defaultProps = {
  rows: 5,
};

export default TextareaField;
