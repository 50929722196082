import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule } from 'billon-ui';

const { FormGroup, SwitchField } = FormModule;

export const ControlledSwitchField = ({
  name,
  disabled,
  label,
  placeholder,
}) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const isActive = watch(name);

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <SwitchField
            input={{ ...field, checked: isActive }}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            disabled={disabled}
            placeholder={placeholder}
            label={label}
            type="checkbox"
          />
        )}
      />
    </FormGroup>
  );
};
