import React, { useMemo, useEffect } from 'react';
import { Form, Grid, Utils } from 'billon-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import useFilters from '../../../../../../hooks/useFilters';
import {
  NOTIFICATION_HISTORY_STATUSES,
  NOTIFICATION_HISTORY_STATUSES_LABELS,
  NOTIFICATION_HISTORY_FILTERS_OPTIONS,
} from '../../../../../../constraints';
import WithValueMessage from '../../../../../../helpers/WithValueMessage';
import { useForm, Controller } from 'react-hook-form';
import { merge } from 'lodash';
import { removeUndefinedValues } from '../../../../../../helpers/removeUndefinedValues';
import { parseNotificationHistoryFilters } from '../../../../helpers/parseNotificationHistoryFilters';
import * as Styled from './styled';

const { SelectField, TextField } = Form;
const { Button: ButtonModule } = Utils;
const { ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

const FilterForm = () => {
  const { formatMessage } = useIntl();

  const { filters, navigateWithSearchQuery, onClear } = useFilters();

  const fieldsOptions = [
    {
      label: formatMessage({ id: 'Email/Phone' }),
      value: NOTIFICATION_HISTORY_FILTERS_OPTIONS.CONTACT_DETAILS,
    },
    {
      label: formatMessage({ id: 'Identifier' }),
      value: NOTIFICATION_HISTORY_FILTERS_OPTIONS.CLIENT_ID,
    },
    {
      label: formatMessage({ id: 'Status' }),
      value: NOTIFICATION_HISTORY_FILTERS_OPTIONS.STATUS,
    },
  ];

  const statusOptions = [
    {
      label: formatMessage({
        id: NOTIFICATION_HISTORY_STATUSES_LABELS.DELIVERED,
      }),
      value: NOTIFICATION_HISTORY_STATUSES.DELIVERED,
    },
    {
      label: formatMessage({
        id: NOTIFICATION_HISTORY_STATUSES_LABELS.PREPARED,
      }),
      value: NOTIFICATION_HISTORY_STATUSES.PREPARED,
    },
    {
      label: formatMessage({
        id: NOTIFICATION_HISTORY_STATUSES_LABELS.IN_PROGRESS,
      }),
      value: NOTIFICATION_HISTORY_STATUSES.IN_PROGRESS,
    },
    {
      label: formatMessage({
        id: NOTIFICATION_HISTORY_STATUSES_LABELS.ERROR,
      }),
      value: NOTIFICATION_HISTORY_STATUSES.ERROR,
    },
  ];

  const { field } = filters || {};

  const formInitialValues = {
    contactDetails: '',
    clientId: '',
    notificationStatusList: null,
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: merge({}, formInitialValues, {
      field: field
        ? fieldsOptions.filter((option) => option.value === field)
        : fieldsOptions[0],
      ...filters,
    }),
  });

  const selectedField = watch('field');

  const customClearField = () => {
    setValue('clientId', formInitialValues.clientId);
    setValue('contactDetails', formInitialValues.contactDetails);
    setValue(
      'notificationStatusList',
      formInitialValues.notificationStatusList,
    );
  };

  const onClearFilters = () => {
    onClear();
    customClearField();
  };

  const onSubmit = (values) => {
    navigateWithSearchQuery({
      filters: removeUndefinedValues(parseNotificationHistoryFilters(values)),
    });
  };

  let memoizedFieldValue = useMemo(
    () => selectedField.value,
    [selectedField.value],
  );

  useEffect(() => {
    onClearFilters();
  }, [memoizedFieldValue]);

  let ValueField = () => {
    if (!selectedField) {
      return;
    } else if (
      selectedField.value ===
      NOTIFICATION_HISTORY_FILTERS_OPTIONS.CONTACT_DETAILS
    ) {
      return (
        <Controller
          name="contactDetails"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              input={field}
              meta={{
                touched: fieldState.invalid,
                error: fieldState.error,
              }}
            />
          )}
        />
      );
    } else if (
      selectedField.value === NOTIFICATION_HISTORY_FILTERS_OPTIONS.CLIENT_ID
    ) {
      return (
        <Controller
          name="clientId"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              input={field}
              meta={{
                touched: fieldState.invalid,
                error: fieldState.error,
              }}
            />
          )}
        />
      );
    } else if (
      selectedField.value === NOTIFICATION_HISTORY_FILTERS_OPTIONS.STATUS
    ) {
      return (
        <Controller
          name="notificationStatusList"
          control={control}
          render={({ field, fieldState }) => (
            <SelectField
              input={field}
              meta={{
                touched: fieldState.invalid,
                error: fieldState.error,
              }}
              options={statusOptions}
            />
          )}
        />
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row md={12}>
        <Col md={4}>
          <Styled.Text>
            <FormattedMessage id="Search by" defaultMessage="Search by" />
            {':'}
          </Styled.Text>

          <Controller
            name="field"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                input={field}
                meta={{
                  touched: fieldState.invalid,
                  error: fieldState.error,
                }}
                options={fieldsOptions}
              />
            )}
          />
        </Col>

        <Col md={5}>
          <Styled.Text>
            {WithValueMessage({
              messageId: 'Type {name}',
              name: 'name',
              value: formatMessage({
                id: selectedField?.label.toLowerCase() || 'value',
              }),
            })}
            {':'}
          </Styled.Text>
          <ValueField />
        </Col>
        <Col md={3}>
          <Row md={12}>
            <Col md={6}>
              <Styled.Text>
                <FormattedMessage id="Clear" defaultMessage="Clear" />
              </Styled.Text>
              <Styled.ActionButton
                onClick={onClearFilters}
                block
                outline
                type="button"
              >
                <Styled.Close />
              </Styled.ActionButton>
            </Col>
            <Col md={6}>
              <Styled.Text>
                <FormattedMessage id="Search" defaultMessage="Search" />
              </Styled.Text>
              {!isSubmitting ? (
                <Styled.ActionButton block outline type="submit">
                  <Styled.Search />
                </Styled.ActionButton>
              ) : (
                <ButtonLoader block outline />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

export default FilterForm;
