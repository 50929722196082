import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule } from 'billon-ui';
import TextAreaField from '../TextAreaField';
const { FormGroup } = FormModule;

export const ControlledTextAreaField = ({
  name,
  disabled,
  placeholder,
  rows = 3,
  maxLength = 100,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <TextAreaField
            input={field}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        )}
      />
    </FormGroup>
  );
};
