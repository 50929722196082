import React, { useState, useEffect } from 'react';
import { useDownloadDocument } from '../../../../hooks/useDownloadDocument';
import { useFormContext } from 'react-hook-form';

import * as Styled from './styled';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule, Button } = Utils;
const { PageLoader } = LoaderModule;
const { IconButton } = Button;

const PdfPreview = ({ jobId }) => {
  const { data: file, isLoading } = useDownloadDocument(jobId);

  const { setValue } = useFormContext();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setValue('pages', numPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    if (pageNumber > 1) changePage(-1);
  };

  const nextPage = () => {
    if (pageNumber < numPages) changePage(1);
  };

  return (
    <Styled.Wrapper>
      <Styled.PreviewTitle>
        <FormattedMessage id="Preview" defaultMessage="Preview" />
      </Styled.PreviewTitle>
      {isLoading && !file ? (
        <PageLoader />
      ) : (
        <Styled.PreviewWrapper>
          <Styled.DocumentWrapper>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<PageLoader />}
              noData={<PageLoader />}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </Styled.DocumentWrapper>
          <Styled.PaginationWrapper>
            <IconButton icon="angle-left" onClick={previousPage} />

            <Styled.PaginationText>
              <FormattedMessage id="Page" defaultMessage="Page" />{' '}
              {pageNumber || (numPages ? 1 : '--')}/{numPages || '--'}
            </Styled.PaginationText>

            <IconButton icon="angle-right" onClick={nextPage} />
          </Styled.PaginationWrapper>
        </Styled.PreviewWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default PdfPreview;
