import { Utils } from 'billon-ui';
import styled from 'styled-components';

const { Card } = Utils;

export const AfterWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;

  @media (max-width: 768px) {
    margin-top: 12px;
  }

  @media (min-width: 769px) {
    margin-left: 12px;
  }
`;

export const TableCard = styled(Card)`
  padding: 0 0 20px 0;
  box-shadow: none;
`;
