import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Utils } from 'billon-ui';
const { Icon } = Utils;

const StyledCircleDiv = styled.div`
   {
    width: ${(props) => (props.size ? props.size : '24px')};
    height: ${(props) => (props.size ? props.size : '24px')};
    background: ${(props) => (props.color ? props.color : '#000')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      background: ${(props) => props.hoverColor};
    }
  }
`;
export const IconInCircle = (props) => {
  IconInCircle.propTypes = {
    iconName: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.string,
  };
  return (
    <StyledCircleDiv
      color={props.color}
      size={props.size}
      hoverColor={props.hoverColor}
    >
      <Icon billon size={'20px'} name={props.iconName} />
    </StyledCircleDiv>
  );
};
