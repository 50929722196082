import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { merge } from 'lodash';

// hooks
import useFilters from '../../../../hooks/useFilters';
import { useDocumentFormDefaultValues } from './hooks/useDocumentFormDefaultValues';

// utils
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../constraints';
import { parseFilters } from '../../helpers';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';

// Components
import {
  FormHeader,
  FormActions,
  FilterCard,
  FlexStartRow,
} from '../../../../components/Form';
import {
  TextTitleField,
  CalendarPublicationField,
  TextBlockchainAddressField,
} from '../../../Documents/components/FilterForm/components';
import SelectReportStatusField from './components/SelectReportStatusField';

import { Grid } from 'billon-ui';

const { Col } = Grid;

const FilterForm = ({
  initialValues: defaultFilters,
  closeFilters,
  onClear,
}) => {
  const { navigateWithNewSearchQuery, onSingleFilterClear } = useFilters();
  const { formInitialValues, computedDefaultValues } =
    useDocumentFormDefaultValues(defaultFilters);
  const formMethods = useForm({
    defaultValues: computedDefaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    resetField,
  } = formMethods;

  const onClearFilters = () => {
    reset(formInitialValues);
    onClear({ filters: defaultFilters?.filters });
  };
  const onSingleFieldClear = (name) => {
    resetField(name);
    onSingleFilterClear(name, defaultFilters?.filters);
  };
  const onFieldClear = (name) => onSingleFieldClear(name);
  const onSubmit = (values) => {
    navigateWithNewSearchQuery({
      filters: merge(
        defaultFilters.filters,
        removeUndefinedValues(parseFilters(values)),
      ),
      pagination: {
        page: 1,
      },
    });
  };

  return (
    <FilterCard>
      <FormHeader closeFilters={closeFilters} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexStartRow>
            <Col lg={6} xl={3}>
              <TextTitleField name={DOCUMENT_FILTER_FIELD_NAMES.TITLE} />
            </Col>
            <Col lg={6} xl={3}>
              <CalendarPublicationField
                name={DOCUMENT_FILTER_FIELD_NAMES.PUBLICATION_DATE_RANGE}
                onClear={onFieldClear}
              />
            </Col>
            <Col lg={6} xl={3}>
              <TextBlockchainAddressField
                name={DOCUMENT_FILTER_FIELD_NAMES.BLOCKCHAIN_ADDRESS}
              />
            </Col>
            <Col lg={6} xl={3}>
              <SelectReportStatusField
                isClearable
                onClear={onFieldClear}
                name={DOCUMENT_FILTER_FIELD_NAMES.DOCUMENT_STATUS_LIST}
              />
            </Col>
          </FlexStartRow>
          <FormActions
            onClearFilters={onClearFilters}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </FilterCard>
  );
};

export default FilterForm;
