import { Utils } from 'billon-ui';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const { Text, Icon } = Utils;

export const Title = styled(Text)`
  font-size: 2.5rem;
  margin-bottom: 4rem;
  font-weight: ${(props) => props.theme.fontWeightBold};

  ${down('lg')} {
    font-size: 1.9rem;
  }

  ${down('sm')} {
    font-size: 1.75rem;
  }
`;

export const ToolTipContentWrapper = styled.p`
  padding: 16px;
  margin: 0;
`;

export const HeaderIcon = styled(Icon)`
  font-size: 2rem;
  color: grey;
`;
