import React from 'react';

// constants
import { DOCUMENT_TYPE } from '../../../../constraints';

// hooks
import useDocumentType from '../../hooks/useDocumentType';

// components
import { PageMenu, SelectFeatureOptions } from './components';
import { Layout as LayoutModule, Grid } from 'billon-ui';

const { Row } = Grid;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { ContentContainer } = ContentModule;

const PageLayout = ({ children }) => {
  const { documentType } = useDocumentType();

  return (
    <ContentContainer fluid>
      <Content fluid>
        <PageMenu />
        {documentType === DOCUMENT_TYPE.PRIVATE && (
          <>
            <SelectFeatureOptions />
            <br />
          </>
        )}
        <Row md={12}>{children}</Row>
      </Content>
    </ContentContainer>
  );
};

export default PageLayout;
