import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CUSTOMER } from '../../../constraints';

export const tenantFormatter = (customer) => (value, record) => {
  const { name } = value || {};

  if (name) {
    return name;
  }
  const messageId =
    customer === CUSTOMER.TAURON ? 'Unspecified2' : 'Unspecified3';
  return <FormattedMessage id={messageId} defaultMessage={'Unspecified'} />;
};

export const tenantHeaderMessageIdFormatter = (customer) =>
  customer === CUSTOMER.TAURON ? 'Publisher' : 'Publishing Institution';
