import { QueryKeys } from '../../../../queryKeys';
import { getRequest } from '../../../../helpers/apiClient';
import apiRoutes from '../../../../api-routes';
import { useQueryWithLogout } from '../../../../hooks/useQueryWithLogout';

export const usePrivateSingleNotificationGroupTemplate = (
  templateId,
  options,
) => {
  return useQueryWithLogout(
    [QueryKeys.PRIVATE_SINGLE_NOTIFICATION_GROUP_TEMPLATE, templateId],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.PRIVATE_SINGLE_NOTIFICATION_GROUP_TEMPLATE.replace(
          '{templateId}',
          templateId,
        ),
      });

      return data;
    },
    options,
  );
};
