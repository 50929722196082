import React from 'react';
import { DOCUMENTS_OPTIONS } from '../../../../../../../../constraints';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';
import base from '../../../../../../../../config/base';
import * as Styled from './styled';

const BarToolTip = ({ active, payload, label, publishedDocumentType }) => {
  const { Icon } = Utils;
  const { theme } = base;

  if (active) {
    if (publishedDocumentType === DOCUMENTS_OPTIONS.ALL)
      return (
        <Styled.Tooltip>
          {payload.length > 0 && (
            <p>
              <Icon name="square" color={theme.palette.billonGreen} />{' '}
              <FormattedMessage id="public2" default="public2" />:{' '}
              {payload[0].value}
            </p>
          )}
          {payload.length > 1 && (
            <p>
              <Icon name="square" color={theme.palette.billonBlue} />{' '}
              <FormattedMessage id="private2" default="private2" />:{' '}
              {payload[1].value}{' '}
            </p>
          )}
        </Styled.Tooltip>
      );
    else if (publishedDocumentType === DOCUMENTS_OPTIONS.PRIVATE) {
      return (
        <Styled.Tooltip>
          {payload.length > 0 && (
            <p>
              <Icon name="square" color={theme.palette.billonBlue} />{' '}
              <FormattedMessage id="private2" default="private2" />:{' '}
              {payload[0].value}{' '}
            </p>
          )}
        </Styled.Tooltip>
      );
    } else if (publishedDocumentType === DOCUMENTS_OPTIONS.PUBLIC) {
      return (
        <Styled.Tooltip>
          {payload.length > 0 && (
            <p>
              <Icon name="square" color={theme.palette.billonGreen} />{' '}
              <FormattedMessage id="public2" default="public2" />:{' '}
              {payload[0].value}{' '}
            </p>
          )}
        </Styled.Tooltip>
      );
    }
  }

  return null;
};

export default BarToolTip;
