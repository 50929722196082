import { createAction } from 'redux-act';

// Settings
export const requestSettings = createAction(
  'Settings request',
  (token, ...rest) => ({ token, ...rest }),
);
export const receiveSettingsSuccess = createAction('Settings success');
export const receiveSettingsFailure = createAction('Settings failed');

// SmsCode
export const requestSmsCode = createAction('SMS code request');
export const receiveSmsCodeSuccess = createAction('SMS code success');
export const receiveSmsCodeFailure = createAction('SMS code failed');

// me
export const requestMe = createAction('Me request');
export const receiveMeSuccess = createAction('Me success');
export const receiveMeFailure = createAction('Me failure');

// validatePassword
export const requestValidatePassword = createAction(
  'Request password validation',
  (token, values, meta, ...rest) => ({ token, values, meta, ...rest }),
);
export const receiveValidatePasswordSuccess = createAction(
  'Password validation success',
);
export const receiveValidatePasswordFailure = createAction(
  'Password validation failed',
);

export const receiveFailed = createAction('failed');

// SmsCode by token and password
export const requestUnauthorizedSmsCodeByToken = createAction(
  'Unauthorized SMS code request',
  (token, values, meta, ...rest) => ({ token, values, meta, ...rest }),
);
export const receiveUnauthorizedSmsCodeSuccess = createAction(
  'Unauthorized SMS code success',
);
export const receiveUnauthorizedSmsCodeFailure = createAction(
  'Unauthorized SMS code failed',
);

// SmsCode by token and password
export const requestModifyPasswordByToken = createAction(
  'Modify password request',
  (token, values, meta, ...rest) => ({ token, values, meta, ...rest }),
);
export const receiveModifyPasswordSuccess = createAction(
  'Modify password success',
);
export const receiveModifyPasswordFailure = createAction(
  'Modify passwordfailed',
);
