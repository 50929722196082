import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorModal } from '../../../../components/ErrorModal';
import { Grid, Utils, Layout as LayoutModule } from 'billon-ui';

const { Content: ContentModule } = LayoutModule;
const { ErrorContent } = ContentModule;
const { Button: ButtonModule } = Utils;
const { BackButton } = ButtonModule;
const { Row } = Grid;

const RecipientNotFound = ({ onBackClick, backButtonLabel }) => {
  return (
    <>
      <Row margin={'0 0 20px 0 '}>
        <BackButton secondary onClick={onBackClick}>
          {backButtonLabel}
        </BackButton>
      </Row>

      <ErrorModal
        isOpen={true}
        toggle={onBackClick}
        onClose={onBackClick}
        closeIconEnabled={true}
      >
        <ErrorContent>
          <FormattedMessage
            id="Identity not found"
            defaultMessage="Identity not found"
          />
        </ErrorContent>
      </ErrorModal>
    </>
  );
};

export default RecipientNotFound;
