import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// Components
import { ControlledCalendarField } from '../../../../../../components/ControlledCalendarField/ControlledCalendarField';
import { FieldLabel } from '../../../../../../components/Form';

const CalendarPublicationField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.PUBLICATION_DATE_RANGE,
  onClear,
}) => {
  return (
    <>
      <FieldLabel>
        <FormattedMessage
          id="Publication date from-to"
          defaultMessage="Publication date from-to"
        />
      </FieldLabel>
      <ControlledCalendarField
        name={name}
        onClear={onClear}
        enableTime={false}
        showMonths={2}
        range
      />
    </>
  );
};

export default CalendarPublicationField;
