import React from 'react';
import { merge } from 'lodash';
import {
  ORDER_METHOD,
  NOTIFICATION_HISTORY_STATUSES,
} from '../../../../constraints';

// formatters
import { contentFormatter, notificationStatusFormatter } from './formatters';
import * as formatters from '../../../../formatters';

// hooks
import useFilters from '../../../../hooks/useFilters';
import { useDocumentNotificationHistory } from '../../hooks';

// components
import { FormattedMessage } from 'react-intl';
import { NotificationHistoryDetails } from './components';
import { Layout as LayoutModule, Utils, Table as TableModule } from 'billon-ui';

const { Content: ContentModule } = LayoutModule;
const { Loader: LoaderModule, Text, Pagination } = Utils;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;
const { Table, TableColumn } = TableModule;

const NotificationHistory = ({ jobId, documentBlockchainAddress }) => {
  const defaultFilters = {
    pagination: {
      limit: 5,
      page: 1,
    },
    sort: {
      order: ORDER_METHOD.DESC,
    },
  };

  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge({}, defaultFilters, filters);

  const { data, isLoading } = useDocumentNotificationHistory(fullFilters, {
    id: documentBlockchainAddress,
  });

  let rows = data?.rows;
  let numberOfRecords = data?.count;
  let numberOfPages = Math.ceil(numberOfRecords / fullFilters.pagination.limit);

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const detailsComponentRenderer = (record) => {
    let errorsHistory = record.errorsHistory;
    if (
      ![
        NOTIFICATION_HISTORY_STATUSES.UNDELIVERED,
        NOTIFICATION_HISTORY_STATUSES.ERROR,
      ].includes(record.status)
    ) {
      errorsHistory = [
        ...errorsHistory,
        {
          sendingStartDate: record.sendingStartDate,
          errorDate: record.deliveryDate,
          reason: record.status,
        },
      ];
    }
    return (
      <NotificationHistoryDetails
        ancestor={record}
        errorsHistory={errorsHistory}
      />
    );
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  if (!isLoading && (!numberOfRecords || numberOfRecords === 0)) {
    return (
      <Content fluid>
        <Text textAlign={'center'}>
          <FormattedMessage
            id="No data to display"
            defaultMessage="No data to display"
          ></FormattedMessage>
        </Text>
      </Content>
    );
  }

  return (
    <>
      <Table
        tableData={rows}
        sortMethod={() => {}}
        responsive
        detailsComponent={detailsComponentRenderer}
      >
        <TableColumn
          fieldName="sendingStartDate"
          formatter={formatters.momentInTimezoneFormatter}
          className="fixed-width-200"
        >
          <FormattedMessage
            id="Date of delivery"
            defaultMessage="Date of delivery"
          />
        </TableColumn>

        <TableColumn fieldName="clientId" className="fixed-width-200">
          <FormattedMessage id="Recipient id" defaultMessage="Recipient id" />
        </TableColumn>

        <TableColumn fieldName="contactDetails">
          <FormattedMessage
            id="Email/Phone number"
            defaultMessage="Email/Phone number"
          />
        </TableColumn>

        <TableColumn
          fieldName="status"
          formatter={notificationStatusFormatter}
          className="fixed-width-56"
        >
          <FormattedMessage id="Status" defaultMessage="Status" />
        </TableColumn>

        <TableColumn
          fieldName="Content"
          formatter={contentFormatter}
          className="fixed-width-56"
        >
          <FormattedMessage id="Content" defaultMessage="Content" />
        </TableColumn>
      </Table>
      <Pagination
        onClick={handlePageChange}
        numberOfPages={numberOfPages}
        currentPage={fullFilters.pagination.page}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
      />
    </>
  );
};

export default NotificationHistory;
