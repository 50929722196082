import { removeUndefinedValues } from '../../../helpers/removeUndefinedValues';
import { NOTIFICATION_PREVIEW_TYPES } from '../../../constraints';

export const parsePatchPublicTemplateData = ({
  notificationSubjectFieldValue,
  notificationContentFieldValue,
  previewType,
}) =>
  removeUndefinedValues({
    emailTitle: notificationSubjectFieldValue,
    emailTemplate:
      previewType === NOTIFICATION_PREVIEW_TYPES.EMAIL
        ? notificationContentFieldValue
        : undefined,
    smsTemplate:
      previewType === NOTIFICATION_PREVIEW_TYPES.SMS
        ? notificationContentFieldValue
        : undefined,
  });
