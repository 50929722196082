import React from 'react';

// Utils
import { MANAGE_RECIPIENTS_MODES } from '../../../../constraints';
import appRoutes from '../../../../app-routes';

// hooks
import { useHistory } from 'react-router-dom';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// components
import { Layout as LayoutModule } from 'billon-ui';
import RecipientTable from '../../components/RecipientTable';
import { RootRedirectPage } from '../../../Documents/screens';

const { Content: ContentModule } = LayoutModule;
const { ContentContainer } = ContentModule;

const HomePage = () => {
  const history = useHistory();
  const { enablePrivate } = useConfigContext();

  if (!enablePrivate) {
    return <RootRedirectPage />;
  }

  const handleOpenCreateModal = () => {
    history.push(appRoutes.RECIPIENT_CREATOR);
  };

  const handleManage = () => {
    history.push(
      appRoutes.RECIPIENT_MANAGER.replace(
        ':mode',
        MANAGE_RECIPIENTS_MODES.MAIN,
      ),
    );
  };

  return (
    <ContentContainer fluid>
      <RecipientTable
        handleCreate={handleOpenCreateModal}
        handleManage={handleManage}
      />
    </ContentContainer>
  );
};

export default HomePage;
