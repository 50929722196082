import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Icon } = Utils;

export const JustifiedContent = styled.p`
  text-align: justify;
  text-justify: inter-word;
  word-break: keep-all;
  padding: 0.5rem;
  margin-bottom: 0;
`;

export const HeaderIcon = styled(Icon)`
  color: grey;
`;
