import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { RECIPIENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// Components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { FieldLabel } from '../../../../../../components/Form';

const TextCreatedByField = ({
  name = RECIPIENT_FILTER_FIELD_NAMES.CREATED_BY,
}) => {
  return (
    <>
      <FieldLabel>
        <FormattedMessage
          id="Created by (user name)"
          defaultMessage="Created by (user name)"
        />
      </FieldLabel>
      <ControlledTextField name={name} />
    </>
  );
};

export default TextCreatedByField;
