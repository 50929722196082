import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { TechnicalBreak, Language, Config } from 'billon-ui';
import config from './config';

import { reducer as AuthenticationReducer } from 'js/ui/Authentication';

import { reducer as settings } from './modules/Settings';

const { reducer: LanguageReducer } = Language;
const { reducer: TechnicalBreakReducer } = TechnicalBreak;
const { reducer: ConfigReducer } = Config;

const rootReducer = combineReducers({
  form,
  routing,
  authentication: AuthenticationReducer,
  language: LanguageReducer,
  config: ConfigReducer(config),
  checkConnection: TechnicalBreakReducer,
  document,
  settings,
});

export default rootReducer;
