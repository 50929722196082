import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  momentFormatter,
  momentFormatterWithoutTime,
} from '../../../../../../formatters';
import { MAP_DOCUMENT_STATUSES } from '../../../../../../constraints';
import DataDetailsSection from '../../../../../../components/DataDetailsSection';

const DetailsSection = ({ document, publisherName }) => {
  const enabledDetailsCol1 = [
    {
      title: 'Publication start date',
      data: momentFormatter(document.publicationStartDate),
      enabled: true,
    },
    {
      title: 'Publication date',
      data: momentFormatter(document.publicationDate),
      enabled: true,
    },
    {
      title: 'Publication status',
      data: (
        <FormattedMessage
          id={MAP_DOCUMENT_STATUSES[document.status]}
          defaultMessage={MAP_DOCUMENT_STATUSES[document.status]}
        />
      ),
      enabled: true,
    },
    {
      title: 'Publisher',
      data: publisherName ? publisherName : '---',
      enabled: true,
    },
  ];

  const enabledDetailsCol2 = [
    {
      title: 'Retain until',
      data: momentFormatterWithoutTime(document.retainUntil),
      enabled: true,
    },
    {
      title: 'Category',
      data: document.category.fullPath,
      enabled: true,
    },
  ];

  return (
    <DataDetailsSection
      headerMessageId={'Document details'}
      columns={[enabledDetailsCol1, enabledDetailsCol2]}
    />
  );
};

export default DetailsSection;
