import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// Components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { FieldLabel } from '../../../../../../components/Form';

const TextPublishedByField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.PUBLISHED_BY,
}) => {
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Published by" defaultMessage="Published by" />
        {' ('}
        <FormattedMessage id="User name" defaultMessage="User name" />
        {')'}
      </FieldLabel>
      <ControlledTextField name={name} />
    </>
  );
};

export default TextPublishedByField;
