import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Utils, Grid } from 'billon-ui';
import { LoadingActionButton } from '../../../../../../components';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';

const { Text } = Utils;
const { Row } = Grid;

const ManageSection = ({ isDownloading, onDownload }) => {
  return (
    <>
      <Text fontSize={'18px'} margin={'0 0 11px 0'} fontWeight={700}>
        <FormattedMessage
          id="Manage document"
          defaultMessage="Manage document"
        />
      </Text>
      <Row>
        <BtnCol lg={4}>
          <LoadingActionButton isLoading={isDownloading} onClick={onDownload}>
            <FormattedMessage
              id="Download document"
              defaultMessage="Download document"
            />
          </LoadingActionButton>
        </BtnCol>
      </Row>
    </>
  );
};

export default ManageSection;
