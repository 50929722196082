import base from '../base';
import { CUSTOMER } from '../../constraints';

const config = {
  ...base,
  backendUrl: 'localhost:5000',
  customer: CUSTOMER.DEFAULT,
  enablePrivate: false,
  enableSigning: false,
};

export default config;
