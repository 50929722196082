import React from 'react';

// Components
import TwoStepPasswordSet from '../../components/TwoStepPasswordSet';

const ForgottenPasswordPage = () => {
  return <TwoStepPasswordSet />;
};

export default ForgottenPasswordPage;
