import { patchRequest } from '../../../../helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../../api-routes';

export const usePatchPublicSingleNotificationGroupTemplate = (
  templateId,
  options,
) =>
  useMutationWithLogout(async (data) => {
    const { data: createData } = await patchRequest({
      url: apiRoutes.PUBLIC_NOTIFICATION_TEMPLATE.replace(
        '{templateId}',
        templateId,
      ),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return createData;
  }, options);
