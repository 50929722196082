import React from 'react';
import { DOCUMENTS_OPTIONS } from '../../../../../../constraints';
import { merge } from 'lodash';
import { useYearlyReport } from '../../../../hooks';
import useFilters from '../../../../../../hooks/useFilters';
import { FormattedMessage } from 'react-intl';
import { PublicationCardHeader, PublicationBarChart } from './components';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const YearlyPublication = () => {
  const defaultFilters = {
    filters: {
      publishedDocumentType: DOCUMENTS_OPTIONS.ALL,
    },
  };
  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge({}, defaultFilters, filters);
  const { publishedDocumentType } = fullFilters.filters;

  const handleChangePublishedDocsType = (documentType) => {
    navigateWithSearchQuery({
      filters: {
        publishedDocumentType: documentType,
      },
    });
  };

  const { data: yearlyReportData, isLoading: isYearlyReportLoading } =
    useYearlyReport();

  return (
    <>
      <PublicationCardHeader
        onChangePublishedDocsType={handleChangePublishedDocsType}
      />
      {isYearlyReportLoading ? (
        <PageLoader />
      ) : yearlyReportData ? (
        <PublicationBarChart
          publishedDocumentType={publishedDocumentType}
          yearlyReportData={yearlyReportData}
        />
      ) : (
        <FormattedMessage id="No data" defaultMessage="No data" />
      )}{' '}
    </>
  );
};

export default YearlyPublication;
