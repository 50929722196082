import apiRoutes from '../../../api-routes';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';
import { getRequest } from '../../../helpers/apiClient';
import download from 'downloadjs';

export const useDownloadIdentityFile = (options) =>
  useMutationWithLogout(
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.DOWNLOAD_RECIPIENT_LIST,
        responseType: 'arraybuffer',
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
      });
      return data;
    },
    {
      ...options,
      onSuccess: (response) => {
        const blob = new Uint8Array(response);
        download(blob, `IdentityList.xlsx`, 'text/csv');
      },
    },
  );
