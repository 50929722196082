import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  MAP_RECIPIENT_FIELD_NAMES,
  RECIPIENT_FILTER_FIELD_NAMES,
} from '../../../../../../constraints';

import { ControlledSwitchField } from '../../../../../../components/ControlledSwitchField';
import * as Styled from './styled';

const SwitchIsActiveField = () => {
  return (
    <Styled.FieldFlexRow>
      <Styled.FC2>
        <FormattedMessage
          id={MAP_RECIPIENT_FIELD_NAMES.IS_ACTIVE}
          defaultMessage={MAP_RECIPIENT_FIELD_NAMES.IS_ACTIVE}
        />
      </Styled.FC2>
      <Styled.FC>
        <ControlledSwitchField name={RECIPIENT_FILTER_FIELD_NAMES.IS_ACTIVE} />
      </Styled.FC>
    </Styled.FieldFlexRow>
  );
};

export default SwitchIsActiveField;
