export const RECIPIENT_ERROR_CODES = {
  FILE_ERROR: 'FILE_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  RECIPIENTS_EMPTY: 'RECIPIENTS_EMPTY',
  RECIPIENTS_AMOUNT_IN_FILE_EXCEEDED: 'RECIPIENTS_AMOUNT_IN_FILE_EXCEEDED',
};

export const RECIPIENT_VALIDATION_PURPOSE = {
  PUBLISH: 'PUBLISH',
  UPDATE: 'UPDATE',
};

export const RECIPIENT_FILE_FIELDS = {
  ID: 'ID',
  SYSTEM_SOURCE: 'SYSTEM_SOURCE',
  EMAIL: 'EMAIL',
  DOCUMENT_NUMBER: 'DOCUMENT_NUMBER',
};

export const MAP_RECIPIENT_FILE_FIELDS = {
  ID: 'id',
  DOCUMENT_NUMBER: 'documentNumber',
  SYSTEM_SOURCE: 'sourceSystem',
  EMAIL: 'email',
};
