import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { merge } from 'lodash';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// helpers
import { removeUndefinedValues } from '../../../../../../helpers/removeUndefinedValues';
import { parseAnalitycsFilters } from '../../../../helpers/parseAnalitycsFilters';

// Hooks
import useFilters from '../../../../../../hooks/useFilters';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useTenantsContext } from '../../../../../Tenants/hooks/useTenants';
import { useDocumentFormDefaultValues } from './hooks/useDocumentFormDefaultValues';

// Components
import {
  FormHeader,
  FormActions,
  FilterCard,
  FlexStartRow,
} from '../../../../../../components/Form';
import { SelectActiveDeliveryField } from '../../../../../../components/SelectActiveDeliveryField/SelectActiveDeliveryField';
import {
  CalendarPublicationField,
  CalendarValidSinceField,
  CalendarValidUntilField,
  SelectTenantField,
  SelectStatusField,
  SelectCategoryField,
  TextTitleField,
  TextPublishedByField,
  TextBlockchainAddressField,
} from '../../../../../Documents/components/FilterForm/components';
import {
  SelectIdentityField,
  SelectIdentityNameField,
  SelectDocumentTypeField,
} from './components';

import { Grid } from 'billon-ui';

const { Col } = Grid;

export const FilterForm = ({ closeFilters, defaultFilters }) => {
  const { isTauron, isDiploma, enableActiveDelivery } = useConfigContext();
  const { areTenantsAvailable } = useTenantsContext();
  const { navigateWithNewSearchQuery, onClear, onSingleFilterClear } =
    useFilters();
  const { formInitialValues, computedDefaultValues, asyncFieldsValues } =
    useDocumentFormDefaultValues(defaultFilters);
  const formMethods = useForm({
    defaultValues: computedDefaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    resetField,
  } = formMethods;

  const onClearFilters = () => {
    reset(formInitialValues);
    onClear({ filters: defaultFilters?.filters });
  };
  const onSingleFieldClear = (name) => {
    resetField(name);
    onSingleFilterClear(name, defaultFilters?.filters);
  };
  const onFieldClear = (name) => onSingleFieldClear(name);
  const onSubmit = (values) => {
    navigateWithNewSearchQuery({
      filters: merge(
        defaultFilters.filters,
        removeUndefinedValues(parseAnalitycsFilters(values)),
      ),
      pagination: {
        page: 1,
      },
    });
  };

  return (
    <FilterCard>
      <FormHeader
        closeFilters={closeFilters}
        headerMessageId="Generate report by"
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexStartRow>
            <Col lg={6} xl={3}>
              <SelectDocumentTypeField
                name={DOCUMENT_FILTER_FIELD_NAMES.DOCUMENT_TYPE_LIST}
                isClearable
                onClear={onFieldClear}
              />
            </Col>
            <Col lg={6} xl={3}>
              <TextTitleField name={DOCUMENT_FILTER_FIELD_NAMES.TITLE} />
            </Col>
            <Col lg={6} xl={3}>
              <SelectCategoryField
                name={DOCUMENT_FILTER_FIELD_NAMES.CATEGORY}
                isClearable
                onClear={onFieldClear}
                categoryId={asyncFieldsValues?.categoryId}
              />
            </Col>
            <Col lg={6} xl={3}>
              <CalendarPublicationField
                name={DOCUMENT_FILTER_FIELD_NAMES.PUBLICATION_DATE_RANGE}
                onClear={onFieldClear}
              />
            </Col>
            <Col lg={6} xl={3}>
              <TextPublishedByField
                name={DOCUMENT_FILTER_FIELD_NAMES.PUBLISHED_BY}
              />
            </Col>
            <Col lg={6} xl={3}>
              <CalendarValidSinceField
                name={DOCUMENT_FILTER_FIELD_NAMES.VALID_SINCE_DATE_RANGE}
                onClear={onFieldClear}
              />
            </Col>
            {!isDiploma && (
              <Col lg={6} xl={3}>
                <CalendarValidUntilField
                  name={DOCUMENT_FILTER_FIELD_NAMES.VALID_UNTIL_DATE_RANGE}
                  onClear={onFieldClear}
                />
              </Col>
            )}
            <Col lg={6} xl={3}>
              <TextBlockchainAddressField
                name={DOCUMENT_FILTER_FIELD_NAMES.BLOCKCHAIN_ADDRESS}
              />
            </Col>
            <Col lg={6} xl={3}>
              <SelectStatusField isClearable onClear={onFieldClear} />
            </Col>
            <Col lg={6} xl={3}>
              <SelectIdentityField
                name={DOCUMENT_FILTER_FIELD_NAMES.IDENTITY}
                isClearable
                onClear={onFieldClear}
                identityDocumentNumber={
                  asyncFieldsValues?.identityDocumentNumber
                }
              />
            </Col>
            {isTauron && (
              <Col lg={6} xl={3}>
                <SelectIdentityNameField
                  name={DOCUMENT_FILTER_FIELD_NAMES.IDENTITY}
                  isClearable
                  onClear={onFieldClear}
                  identityName={asyncFieldsValues?.identityName}
                />
              </Col>
            )}
            {areTenantsAvailable && (
              <Col lg={6} xl={3}>
                <SelectTenantField
                  name={DOCUMENT_FILTER_FIELD_NAMES.TENANT}
                  isClearable
                  onClear={onFieldClear}
                  tenantId={asyncFieldsValues?.tenantId}
                />
              </Col>
            )}
            {enableActiveDelivery && (
              <Col lg={6} xl={3}>
                <SelectActiveDeliveryField
                  name={DOCUMENT_FILTER_FIELD_NAMES.ACTIVE_DELIVERY_TYPE}
                  isClearable
                  onClear={onFieldClear}
                />
              </Col>
            )}
          </FlexStartRow>
          <FormActions
            onClearFilters={onClearFilters}
            isSubmitting={isSubmitting}
            submitMessageId="Generate report"
          />
        </form>
      </FormProvider>
    </FilterCard>
  );
};
