import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form as FormModule, Grid } from 'billon-ui';
import { Controller } from 'react-hook-form';

const { FormGroup, TextField } = FormModule;
const { Row, Col } = Grid;

const MeDataTab = ({ control }) => (
  <Row>
    <Col md={6}>
      <FormGroup>
        <Controller
          name="username"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                }}
                label={<FormattedMessage id="Login" defaultMessage="Login" />}
                disabled
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                }}
                label={<FormattedMessage id="Email" defaultMessage="Email" />}
                disabled
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                }}
                label={
                  <FormattedMessage
                    id="Phone number"
                    defaultMessage="Phone number"
                  />
                }
                disabled
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="department"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                }}
                label={
                  <FormattedMessage
                    id="Department"
                    defaultMessage="Department"
                  />
                }
                disabled
              />
            );
          }}
        />
      </FormGroup>
    </Col>

    <Col md={6}>
      <FormGroup>
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                }}
                label={
                  <FormattedMessage
                    id="First name"
                    defaultMessage="First name"
                  />
                }
                disabled
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                }}
                label={
                  <FormattedMessage id="Last name" defaultMessage="Last name" />
                }
                disabled
              />
            );
          }}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          name="role"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                }}
                label={
                  <FormattedMessage
                    id="Account type"
                    defaultMessage="Account type"
                  />
                }
                disabled
              />
            );
          }}
        />
      </FormGroup>
    </Col>
  </Row>
);

MeDataTab.propTypes = {
  control: PropTypes.object.isRequired,
};

export default MeDataTab;
