import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useForm, FormProvider } from 'react-hook-form';
import useFilters from '../../../../hooks/useFilters';
import { DOCUMENT_TYPE } from '../../../../constraints';

// Components
import * as Styled from './styled';
import { CancelPublicationModal as ConfirmModal } from '../DocumentTableDetails/components/CancelPublicationModal';
import CancelPublicationCounter from '../DocumentTableDetails/components/CancelPublicationCounter';
import { BtnCol } from '../../../../components/LoadingActionButton/styled';
import { LoadingActionButton } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { ControlledTextField } from '../../../../components/ControlledTextField';
import PdfPreview from './components/PdfPreview';
import {
  Modal as ModalModule,
  Grid,
  Utils,
  Form as FormModule,
} from 'billon-ui';

const { Text } = Utils;
const { Modal } = ModalModule;
const { Row, Col } = Grid;
const { Form } = FormModule;

export const PublicationPreviewModal = ({ isOpen, onClose, document }) => {
  const { formatMessage } = useIntl();
  const { isDiploma } = useConfigContext();
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const formMethods = useForm({
    defaultValues: {
      identity: `${document?.identity?.firstName} ${document?.identity?.lastName}`,
      documentNumber: document?.identity?.documentNumber,
      pages: 0,
    },
  });

  const toggleConfirmModal = () =>
    setIsConfirmModalOpened(!isConfirmModalOpened);

  const { filters } = useFilters();
  const documentType = filters.documentTypeList || DOCUMENT_TYPE.PRIVATE;

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        onClose={onClose}
        title={formatMessage({ id: 'Preview' })}
      >
        <FormProvider {...formMethods}>
          <Row>
            <Col md={4}>
              <Form>
                {documentType === DOCUMENT_TYPE.PRIVATE && (
                  <>
                    <Text as="h6" fontSize="12px" fontWeight="600">
                      <FormattedMessage
                        id="Published for"
                        defaultMessage="Published for"
                      />
                    </Text>
                    <ControlledTextField name="identity" disabled />
                    <Text as="h6" fontSize="12px" fontWeight="600">
                      {isDiploma ? (
                        <FormattedMessage
                          id="Index number"
                          defaultMessage="Index number"
                        />
                      ) : (
                        <FormattedMessage
                          id="Recipient id"
                          defaultMessage="Recipient id"
                        />
                      )}
                    </Text>
                    <ControlledTextField name="documentNumber" disabled />
                  </>
                )}

                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Number of pages"
                    defaultMessage="Number of pages"
                  />
                </Text>
                <ControlledTextField name="pages" disabled />
              </Form>
            </Col>
            <Col md={8}>
              <PdfPreview jobId={document?.jobId} />
            </Col>
          </Row>
        </FormProvider>
        <br />

        <Row>
          <BtnCol autoSizeDisabled md={4}>
            <LoadingActionButton
              isLoading={false}
              outline
              onClick={toggleConfirmModal}
              dangerColor
            >
              <FormattedMessage
                id="Cancel publication"
                defaultMessage="Cancel publication"
              />
            </LoadingActionButton>
          </BtnCol>
          <Col md={8}>
            <Text>
              <FormattedMessage
                id="Your document will be automatically published on blockchain in"
                defaultMessage="Your document will be automatically published on blockchain in"
              />{' '}
              <CancelPublicationCounter
                publicationStartDate={document?.publicationStartDate}
                publicationDelayMinutes={document?.publicationDelayMinutes}
                closingAction={onClose}
              />
              <br />
              <FormattedMessage
                id="By this time it's possible to make this process"
                defaultMessage="By this time it's possible to make this process"
              />{' '}
              <Styled.RedUnderlinedText>
                <FormattedMessage id="cancel2" defaultMessage="cancel2" />
              </Styled.RedUnderlinedText>
            </Text>
          </Col>
        </Row>
      </Modal>
      <ConfirmModal
        isOpen={isConfirmModalOpened}
        toggle={toggleConfirmModal}
        document={document}
      />
    </>
  );
};
