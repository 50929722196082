import base from '../../base';
import { CUSTOMER } from '../../../constraints';

const config = {
  ...base,
  backendUrl: 'https://apizsp.trusted-document.com',
  enablePrivate: true,
  enableSigning: false,
  customer: CUSTOMER.DIPLOMA,
};

export default config;
