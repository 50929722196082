import { useMemo } from 'react';

import { usePrivateNotificationTemplateGroups } from './usePrivateNotificationTemplateGroups';
import useFilters from '../../../../hooks/useFilters';

const useActiveNotificationGroupId = () => {
  const { filters } = useFilters();
  const { data: notificationGroups, isSuccess } =
    usePrivateNotificationTemplateGroups();

  const activeNotificationGroupId = useMemo(() => {
    const fitlerGroupId = filters?.filters?.groupId;
    if (!!fitlerGroupId) {
      return fitlerGroupId;
    }
    if (notificationGroups?.rows?.length === 0) {
      return;
    }
    const firstNotificationGroupId = notificationGroups?.rows[0]?.id;
    if (!fitlerGroupId && !!firstNotificationGroupId) {
      return firstNotificationGroupId;
    }
  }, [filters, notificationGroups, isSuccess]);

  return { activeNotificationGroupId };
};

export default useActiveNotificationGroupId;
