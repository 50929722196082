import React, { useEffect } from 'react';

// hooks
import useDocumentType from '../../hooks/useDocumentType';
import useFilters from '../../../../hooks/useFilters';

// Components
import {
  PageLayout,
  FeatureCard,
  Templates,
  PublicTemplates,
} from '../../components';
import { DOCUMENT_TYPE } from '../../../../constraints';
import { Grid } from 'billon-ui';

const { Col } = Grid;

const NotificationTemplatesPage = () => {
  const { documentType } = useDocumentType();
  const { filters, navigateWithSearchQuery } = useFilters();

  useEffect(() => {
    if (!filters?.filters?.documentTypeList && !!documentType) {
      navigateWithSearchQuery({ filters: { documentTypeList: documentType } });
    }
  }, [filters, documentType]);

  return (
    <PageLayout>
      <Col md={12}>
        <FeatureCard>
          {documentType === DOCUMENT_TYPE.PRIVATE ? (
            <Templates />
          ) : (
            <PublicTemplates />
          )}
        </FeatureCard>
      </Col>
    </PageLayout>
  );
};

export default NotificationTemplatesPage;
