import { Grid, Utils } from 'billon-ui';
import React from 'react';
import { BlockchainVisual, CopiedInfo } from '../../../../components';
import { useDownloadDailyReport } from '../../hooks';
import config from 'js/config';
import { BadgedTitle, DetailsSection, ManageSection } from './components';
import * as Styled from './styled';

const { Button: ButtonModule } = Utils;
const { BackButton } = ButtonModule;
const { Row, Col } = Grid;
const { customer } = config;

const DailyReportDetails = ({
  document,
  onBackClick,
  publisherName,
  backButtonLabel,
}) => {
  const { mutate: download, isLoading: isDownloading } = useDownloadDailyReport(
    document.jobId,
    document.title,
  );

  return (
    <>
      <Row margin={0}>
        <Col xl={9}>
          <Styled.BackBtnRow>
            <BackButton secondary onClick={onBackClick}>
              {backButtonLabel}
            </BackButton>
          </Styled.BackBtnRow>
          <Styled.BadgedTitleRow>
            <BadgedTitle
              title={document.title}
              errata={document.errata}
              publicationMode={document.publicationMode}
              status={document.status}
            />
          </Styled.BadgedTitleRow>
          <Row>
            <Col lg={6}>
              <Styled.CopyBlockchainAdressWrapper>
                <CopiedInfo
                  label={'Document blockchain address'}
                  value={document.documentBlockchainAddress}
                  emphasisedHeader
                  scale={'section'}
                  valuePlacedUnder
                />
              </Styled.CopyBlockchainAdressWrapper>
              <ManageSection
                onDownload={download}
                isDownloading={isDownloading}
              />
            </Col>
            <Col lg={6}>
              <DetailsSection
                document={document}
                publisherName={publisherName}
                customer={customer}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xl={{ size: 3, offset: 0 }}
          lg={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
        >
          <BlockchainVisual remoteSignAnimation size="100%" fixed />
        </Col>
      </Row>
    </>
  );
};

export default DailyReportDetails;
