import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom';

// constants
import appRoutes from '../../../../app-routes';
import { CUSTOMER, RECIPIENT_STATUSES } from '../../../../constraints';

// hooks
import { useSingleIdentity } from '../../hooks/useSingleIdentity';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// components
import RecipientDetails from '../../components/RecipientDetails';
import HistoryTable from '../../components/RecipientDetails/components/HistoryTable';
import { RootRedirectPage } from '../../../Documents/screens';
import { Layout as LayoutModule, Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { ContentContainer, Content } = ContentModule;

const RecipientDetailsPage = () => {
  const history = useHistory();
  const { customer, enablePrivate } = useConfigContext();

  const { params } = useRouteMatch(appRoutes.RECIPTIENT_DETAILS);
  const id = params?.id;

  const { data: recipient, isLoading } = useSingleIdentity({ id: id });

  const handleOnBack = () => {
    history.push(appRoutes.RECIPTIENTS);
  };

  const handleOnEdit = () => {
    history.push(appRoutes.RECIPIENT_EDITOR.replace(':id', recipient?.id));
  };

  const backBtnMessageId =
    customer === CUSTOMER.DIPLOMA ? 'Students' : 'Recipients';

  if (!enablePrivate) {
    return <RootRedirectPage />;
  }

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  if (recipient?.status === RECIPIENT_STATUSES.FORGETTING) {
    return <Redirect to={appRoutes.RECIPTIENTS} />;
  }

  return (
    <ContentContainer fluid>
      <Content fluid>
        <RecipientDetails
          onEdit={handleOnEdit}
          recipient={recipient}
          customer={customer}
          onBackClick={handleOnBack}
          backButtonLabel={
            <FormattedMessage
              id={backBtnMessageId}
              defaultMessage={backBtnMessageId}
            />
          }
        />
        <HistoryTable historyRecords={recipient?.history} />
      </Content>
    </ContentContainer>
  );
};

export default RecipientDetailsPage;
