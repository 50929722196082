import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const LineHeaderRowLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${(props) => props.theme.palette.grayLighter};
  margin-bottom: 20px;
`;

export const ContentText = styled(Text)`
  font-size: 13px;
  margin-bottom: 20px;
`;
