import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import appRoutes from '../../../../../../../../app-routes';
import {
  UserRoles,
  NOTIFICATION_MODULE_MENU_LABEL,
} from '../../../../../../../../constraints';

import useFilters from '../../../../../../../../hooks/useFilters';

// Components
import { ProtectedComponent } from '../../../../../../../Auth/components';
import * as Styled from './styled';

const NotificationManagerButton = () => {
  const { filters, navigateWithNewSearchQuery } = useFilters();

  const handleClick = () => {
    navigateWithNewSearchQuery(filters, appRoutes.NOTIFICATION_TEMPLATE_MANAGE);
  };

  return (
    <ProtectedComponent roles={[UserRoles.BUSINESS_ADMIN]} render={false}>
      <Styled.ButtonCol sm={10} md={2}>
        <Styled.FlexEndButton onClick={handleClick}>
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_NOTIFICATION_MANAGE}
            defaultMessage={
              NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_NOTIFICATION_MANAGE
            }
          />
        </Styled.FlexEndButton>
      </Styled.ButtonCol>
    </ProtectedComponent>
  );
};

export default NotificationManagerButton;
