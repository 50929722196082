import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFairUsage } from '../../../../hooks';
import { ConsumptionCardHeader, ConsumptionBarChart } from './components';
import { Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const Consumption = () => {
  const { data: fairUsageData, isLoading: isFairUsageLoading } = useFairUsage();

  return (
    <>
      <ConsumptionCardHeader />
      {isFairUsageLoading ? (
        <PageLoader />
      ) : fairUsageData ? (
        <ConsumptionBarChart fairUsageData={fairUsageData} />
      ) : (
        <FormattedMessage id="No data" defaultMessage="No data" />
      )}
    </>
  );
};

export default Consumption;
