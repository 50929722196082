import styled from 'styled-components';
import { Grid, Utils } from 'billon-ui';

const { Row } = Grid;
const { Card: C } = Utils;

export const FairUsageRowWrapper = styled(Row)`
  margin: 3rem -1rem;
`;

export const Card = styled(C)`
  padding: 2rem;
  overflow: auto;
  .recharts-surface {
    margin-right: 40px;
  }
  .recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 10px;
  }
  .recharts-layer.recharts-bar-rectangle {
    opacity: 0.7;
    border-radius: 10px;
  }
`;
