import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';
import * as Styled from './styled';

const { Icon } = Utils;

const CopiedInfo = ({
  label,
  alt,
  value,
  scale,
  emphasisedHeader,
  emphasisedValue,
  valuePlacedBetween,
  valuePlacedUnder,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isCopyHover, setIsCopyHover] = useState(false);

  const onCopied = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const Value = () => {
    if (valuePlacedBetween) {
      return (
        <Styled.ValueSpan emphasisedValue={emphasisedValue} scale={scale}>
          {value}
        </Styled.ValueSpan>
      );
    } else {
      return (
        <Styled.ValueLabel emphasisedValue={emphasisedValue} scale={scale}>
          {value}
        </Styled.ValueLabel>
      );
    }
  };

  if (!value && alt) {
    return (
      <Styled.CopyHeader>
        <FormattedMessage id={alt} defaultMessage={alt} />
      </Styled.CopyHeader>
    );
  }

  return (
    <>
      <Styled.CopyHeader emphasisedHeader={emphasisedHeader} scale={scale}>
        <FormattedMessage id={label} defaultMessage={label} />
        {': '}
        {valuePlacedBetween && (
          <>
            <Value />{' '}
          </>
        )}
        <CopyToClipboard text={value} onCopy={onCopied}>
          <Styled.CopyButton
            onMouseOver={() => setIsCopyHover(true)}
            onMouseOut={() => setIsCopyHover(false)}
          >
            <Styled.CopiedInfo show={isCopied}>
              <FormattedMessage id="Copied" defaultMessage="Copied" />
            </Styled.CopiedInfo>
            <Styled.CopiedInfo show={isCopied ? false : isCopyHover}>
              <FormattedMessage id="Copy" defaultMessage="Copy" />
            </Styled.CopiedInfo>
            <Icon name="copy" regular color="#7e7f7f" />
          </Styled.CopyButton>
        </CopyToClipboard>
      </Styled.CopyHeader>
      {valuePlacedUnder && <Value />}
    </>
  );
};

export default CopiedInfo;
