import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../../../../../js/ui/CustomToolTip';
import * as Styled from './styled';

const UpdateRecipientListToolTip = () => {
  return (
    <CustomToolTip
      id="CustomToolTip-update-recipient-list-definition"
      trigger={<Styled.HeaderIcon name="info-circle" />}
    >
      <Styled.JustifiedContent>
        <FormattedMessage
          id="Load a file containing the data of new recipients and those whose data is to be updated"
          defaultMessage="Load a file containing the data of new recipients and those whose data is to be updated"
        />
      </Styled.JustifiedContent>
    </CustomToolTip>
  );
};

export default UpdateRecipientListToolTip;
