import styled from 'styled-components';

import { ReactComponent as Edit } from '../../../../../../../../ui/Icons/pencil.svg';

export const ActionButton = styled.span`
  svg path {
    fill: ${(props) => props.theme.bodyColor} !important;
  }
  svg:hover path {
    fill: ${(props) => props.theme.palette.primary} !important;
  }
  cursor: pointer;
  margin-left: 1rem;
`;

export const EditIcon = styled(Edit)`
  path {
    fill: ${(props) => props.theme.bodyColor} !important;
  }
  width: 16px;
  height: auto;
  align-self: center;
`;
