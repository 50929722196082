import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';

const { Alert } = Utils;

const hasRole = (roles, role) => {
  let rolesArray = roles;
  if (!Array.isArray(roles)) {
    rolesArray = [roles];
  }
  return rolesArray.includes(role);
};

const ProtectedComponent = ({
  roles,
  role,
  isForbidden,
  children,
  render,
  reverse,
  message,
}) => {
  if (
    (!reverse && !isForbidden && hasRole(roles, role)) ||
    (reverse && (isForbidden || !hasRole(roles, role)))
  ) {
    return <>{children}</>;
  }

  if (!render) {
    return null;
  }

  return <Alert color="warning">{message}</Alert>;
};

ProtectedComponent.propTypes = {
  children: PropTypes.any.isRequired,
  isForbidden: PropTypes.bool.isRequired,
  roles: PropTypes.array,
  role: PropTypes.string.isRequired,
  render: PropTypes.bool,
  reverse: PropTypes.bool,
  message: PropTypes.any,
};

ProtectedComponent.defaultProps = {
  render: true,
  reverse: false,
  message: (
    <FormattedMessage
      id="You do not have access to this page."
      defaultMessage="You do not have access to this page."
    />
  ),
};

const mapStateToProps = ({ authentication }) => ({
  isForbidden: authentication.isForbidden,
  role: authentication.user?.role,
});

export default connect(mapStateToProps, null, null, {
  pure: false,
})(ProtectedComponent);
