import moment from 'moment';

export const parseDateRange = (dates) => {
  if (!dates?.[0] && !dates?.[1]) {
    return;
  }

  const from = moment(dates[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
  const to = moment(dates[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');

  return {
    from,
    to,
  };
};
