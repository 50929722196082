import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, matchPath } from 'react-router-dom';

// Constants
import appRoutes from '../../../../../../app-routes';
import {
  DOCUMENT_TYPE,
  NOTIFICATION_MODULE_MENU_LABEL,
} from '../../../../../../constraints';

// hooks
import useFilters from '../../../../../../hooks/useFilters';
import useDocumentType from '../../../../hooks/useDocumentType';

// Components
import * as Styled from './styled';

const SelectFeatureOptions = () => {
  const { pathname } = useLocation();
  const { navigateWithNewSearchQuery } = useFilters();
  const { documentType } = useDocumentType();

  const handleMenuOptionChange = (optionValue) => () => {
    navigateWithNewSearchQuery(
      {
        filters: {
          documentTypeList: documentType,
        },
      },
      optionValue,
    );
  };

  const isSelected = useCallback(
    (value) => matchPath(pathname, { path: value }),
    [pathname],
  );

  const options = [
    {
      labelId: NOTIFICATION_MODULE_MENU_LABEL.TEMPLATES,
      value: appRoutes.NOTIFICATION_TEMPLATES,
    },
    {
      labelId: NOTIFICATION_MODULE_MENU_LABEL.CATEGORIES,
      value: appRoutes.NOTIFICATION_CATEGORIES,
      disabled: documentType === DOCUMENT_TYPE.PUBLIC,
    },
  ];

  return (
    <Styled.Col>
      <Styled.FiltersWrapper>
        {options.map(({ value, labelId, disabled }) => (
          <Styled.HighLevelFilterButton
            isSelected={isSelected(value)}
            onClick={handleMenuOptionChange(value)}
            disabled={disabled}
          >
            <Styled.Title disabled={disabled}>
              <FormattedMessage id={labelId} defaultMessage={labelId} />
            </Styled.Title>
          </Styled.HighLevelFilterButton>
        ))}
      </Styled.FiltersWrapper>
    </Styled.Col>
  );
};

export default SelectFeatureOptions;
