import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from '../SelectCategoryField/styled';
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { Utils } from 'billon-ui';
import { SIGNING_ORDER, MAP_SIGNING_ORDER } from '../../constraints';

const { Text } = Utils;

const defaulSigningOrderKey = SIGNING_ORDER.PUBLISHER_ONLY;

export const defaulSigningOrder = {
  label: <FormattedMessage id={MAP_SIGNING_ORDER[defaulSigningOrderKey]} />,
  value: defaulSigningOrderKey,
};

const signingOrderList = [
  SIGNING_ORDER.NO_SIGNATURE,
  SIGNING_ORDER.PUBLISHER_ONLY,
  SIGNING_ORDER.RECEIVER_ONLY,
  SIGNING_ORDER.SENDER_FIRST,
];

export const signingOrderOptions = signingOrderList.map((option) => ({
  label: <FormattedMessage id={MAP_SIGNING_ORDER[option]} />,
  value: option,
}));

export const SelectSigningOrderField = ({
  name = 'signingOrder',
  disabled,
}) => {
  return (
    <>
      <Text as="h6" fontSize="12px" fontWeight="600">
        <FormattedMessage
          id="Publication Type"
          defaultMessage="Publication Type"
        />
      </Text>
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          options={signingOrderOptions}
          disabled={disabled}
        />
      </Styled.FormGroup>
    </>
  );
};
