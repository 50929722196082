import React from 'react';
import { FormattedMessage } from 'react-intl';

import useFilters from '../../../../../../hooks/useFilters';
import useDocumentType from '../../../../hooks/useDocumentType';

import * as Styled from './styled';

const SelectDocumentTypeOptions = ({ options }) => {
  const { navigateWithNewSearchQuery } = useFilters();
  const { documentType } = useDocumentType();

  const handleMenuOptionChange = (type) => () => {
    navigateWithNewSearchQuery({ filters: { documentTypeList: type } });
  };

  return (
    <Styled.Col>
      <Styled.Header>
        <FormattedMessage id="Notifications" defaultMessage="Notifications" />
      </Styled.Header>
      <Styled.FiltersWrapper>
        {options.map(({ value, labelId, disabled }) => {
          const isSelected = documentType === value;
          return (
            <Styled.HighLevelFilterButton
              isSelected={isSelected}
              onClick={handleMenuOptionChange(value)}
              disabled={disabled}
            >
              <Styled.Title>
                <FormattedMessage id={labelId} defaultMessage={labelId} />
              </Styled.Title>
            </Styled.HighLevelFilterButton>
          );
        })}
      </Styled.FiltersWrapper>
    </Styled.Col>
  );
};

export default SelectDocumentTypeOptions;
