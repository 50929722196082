import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { screens as HomePageScreens } from '../../modules/HomePage';
import { screens as LoginScreens } from '../../modules/Login';
import { screens as UserScreens } from '../../modules/Users';
import appRoutes from '../../app-routes';

const { HelpPage } = HomePageScreens;
const {
  HomePage: LoginPage,
  RecoverPasswordPage,
  RecoverPasswordSuccessPage,
} = LoginScreens;
const { SetFirstPasswordPage, ForgottenPasswordPage } = UserScreens;

const UnauthorizedRoutes = () => {
  return (
    <Switch>
      <Route path={appRoutes.SUPPORT} component={HelpPage} />
      <Route
        path={appRoutes.RECOVER_PASSWORD}
        component={RecoverPasswordPage}
      />
      <Route
        path={appRoutes.RECOVER_PASSWORD_SUCCESS}
        component={RecoverPasswordSuccessPage}
      />
      <Route
        path={appRoutes.FORGOTTEN_PASSWORD}
        component={ForgottenPasswordPage}
      />
      <Route path={appRoutes.PASSWORD_SET} component={SetFirstPasswordPage} />
      <Route path={appRoutes.LOGIN} component={LoginPage} />
      <Route path="/" component={LoginPage} />
    </Switch>
  );
};

export default UnauthorizedRoutes;
