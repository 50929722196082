import React from 'react';

// utils
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  VALIDATION_ERRORS,
  RECIPIENT_ACTIONS,
} from '../../../../../constraints';
import { useConfigContext } from '../../../../Config/hooks/useConfig';

// components
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Grid } from 'billon-ui';
import { UploadRecipientsField } from '../../../../../components/UploadRecipientsField/UploadRecipientsField';
import { FormFooter } from '../../../../../components/FormFooter';
import { LoadingActionButton } from '../../../../../components';
import RecipientManagerTemplate from '../RecipientManagerTemplate';
import ValidateRecipientsError from './components/ValidateRecipientsError';
import FileErrorSummary from './components/FileErrorSummary';

const { FormGroup, Form } = FormModule;
const { Row, Col } = Grid;

export const RecipientsFileForm = ({
  onSubmit,
  defaultValues = {},
  onRecipientListDownload,
  isDownloadingRecipientsList,
  isError,
  errorData,
  onClose,
  onReset,
}) => {
  const { isDiploma } = useConfigContext();

  const formMethods = useForm({
    defaultValues: {
      file: [],
      ...defaultValues,
    },
    resolver: yupResolver(
      yup.object({
        file: yup.array().length(1, VALIDATION_ERRORS.REQUIRED),
      }),
    ),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const handleFormSubmit = (values) => {
    onSubmit({
      data: {
        file: values.file,
      },
    });
  };

  const onCloseError = () => {
    onReset();
  };

  const downloadRecipientsMessageId = isDiploma
    ? 'Download the list of recipients'
    : 'Download the list of recipients';

  return (
    <FormProvider {...formMethods}>
      {isError ? (
        <ValidateRecipientsError
          isOpen={isError}
          onClose={onClose}
          onBack={onCloseError}
        >
          <FileErrorSummary
            mode={RECIPIENT_ACTIONS.UPDATE_LIST}
            errorData={errorData}
          />
        </ValidateRecipientsError>
      ) : (
        <RecipientManagerTemplate>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <Row>
              <Col md={6}>
                <Styled.FormHeader>
                  <FormattedMessage id="Adding" defaultMessage="Adding" />
                </Styled.FormHeader>
                <FormGroup>
                  <UploadRecipientsField
                    name="file"
                    extraActionsDisabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <Styled.FormHeader>
                  <FormattedMessage id="Downloading" defaultMessage="Adding" />
                </Styled.FormHeader>

                <FormGroup>
                  <Styled.EditPanel>
                    <Styled.PanelText>
                      <FormattedMessage
                        id={downloadRecipientsMessageId}
                        defaultMessage={downloadRecipientsMessageId}
                      />
                    </Styled.PanelText>

                    <Styled.CenteredSingleRow>
                      <Styled.DownloadButtonContainer>
                        <LoadingActionButton
                          isLoading={isDownloadingRecipientsList}
                          onClick={onRecipientListDownload}
                          outline
                          type="button"
                        >
                          <FormattedMessage
                            id="Download"
                            defaultMessage="Download"
                          />
                        </LoadingActionButton>
                      </Styled.DownloadButtonContainer>
                    </Styled.CenteredSingleRow>
                    <br />
                  </Styled.EditPanel>
                </FormGroup>
              </Col>
            </Row>
            <FormFooter isLoading={isSubmitting} backBtnDisabled />
          </Form>
        </RecipientManagerTemplate>
      )}
    </FormProvider>
  );
};
