import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const Content = styled.div`
  padding-bottom: 32px;
  padding-top: 32px;
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
  ${down('lg')} {
    font-size: 1.9rem;
  }
  ${down('sm')} {
    font-size: 1.75rem;
  }
`;

export const Description = styled.h5`
  font-size: 1rem;
  font-weight: ${(props) => props.theme.fontWeightMedium};
  margin-bottom: 12rem;
`;

export const Feedback = styled.h6`
  font-size: 0.9rem;
  font-weight: ${(props) => props.theme.fontWeightMedium};
  margin-top: 12px;
  text-align: center;
`;
