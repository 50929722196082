import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Form as FormModule, Utils } from 'billon-ui';

const { FormGroup } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const SubmitButton = ({ isLoading, submitMessage }) => (
  <FormGroup>
    {isLoading ? (
      <ButtonLoader block size="lg" />
    ) : (
      <Button type="submit" size="lg" block>
        {submitMessage ? (
          submitMessage
        ) : (
          <FormattedMessage id="Save" defaultMessage="Save" />
        )}
      </Button>
    )}
  </FormGroup>
);

export default SubmitButton;
