import styled from 'styled-components';
import { Grid, Utils } from 'billon-ui';

const { Row } = Grid;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const CenteredVericallyRow = styled(Row)`
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

export const HeaderButton = styled(Button)`
  padding: 0.5rem 3.5rem;
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  font-size: ${(props) => props.theme.fontSizeLg};
`;
