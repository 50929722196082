import React from 'react';
import { USER_STATUSES } from '../../../../constraints';

// hooks
import { useSettingsContext } from '../../../Settings/hooks/useSettings';
import { useAuthenticatedContext } from '../../../Auth/hooks/useAuthenticated';
import { useHistory } from 'react-router-dom';

// Components
import { Layout as LayoutModule, Utils } from 'billon-ui';
import TwoStepPasswordSet from '../../components/TwoStepPasswordSet';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const SetObsoletePassword = () => {
  const { handleLogout, status, isAuthenticated } = useAuthenticatedContext();
  const { isLoading } = useSettingsContext();
  const history = useHistory();

  const onFinish = () => {
    handleLogout();
    history.push('/');
  };

  const cardTitleId =
    status === USER_STATUSES.PASSWORD_EXPIRED
      ? 'Your password is expired'
      : 'Set password';

  if (!isAuthenticated || isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return <TwoStepPasswordSet titleId={cardTitleId} onFinish={onFinish} />;
};

export default SetObsoletePassword;
