import { QueryKeys } from '../../../../queryKeys';
import { getRequest } from '../../../../helpers/apiClient';
import apiRoutes from '../../../../api-routes';
import { useQueryWithLogout } from '../../../../hooks/useQueryWithLogout';

export const usePrivateSingleNotificationGroupContent = (
  groupContentId,
  options,
) => {
  return useQueryWithLogout(
    [QueryKeys.PRIVATE_SINGLE_NOTIFICATION_GROUP_CONTENT, groupContentId],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.PRIVATE_SINGLE_NOTIFICATION_GROUP_CONTENT.replace(
          '{groupContentId}',
          groupContentId,
        ),
      });

      return data;
    },
    options,
  );
};
