import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';
import { useDeleteIdentity } from '../../../../hooks/useDeleteIdentity';
import { Layout } from 'billon-ui';
import { CUSTOMER } from '../../../../../../constraints';
import { ErrorModal } from '../../../../../../components/ErrorModal';
import { SuccessModal } from '../../../../../../components/SuccessModal';
import { LoadingActionButton } from '../../../../../../components';

const { Content: ContentModule } = Layout;
const { ErrorContent } = ContentModule;

const DeleteSection = ({ id, customer, onBackClick }) => {
  const deleteSuccessMessageId =
    customer === CUSTOMER.DIPLOMA
      ? 'Student removed successfully'
      : 'Recipient removed successfully';

  const deleteFailureMessageId =
    customer === CUSTOMER.DIPLOMA
      ? 'An error occurred while deleting the student'
      : 'An error occurred while deleting the recipient';

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const [isFailureModalOpened, setIsFailureModalOpened] = useState(false);

  const handleDeleteSuccess = () => {
    setIsSuccessModalOpened(true);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpened(false);
    onBackClick();
  };

  const handleDeleteFailure = () => {
    setIsFailureModalOpened(true);
  };

  const handleFailureModalClose = () => {
    setIsFailureModalOpened(false);
  };

  const { mutate: deleteRecipient, isLoading: isDeleting } = useDeleteIdentity({
    id,
    onSuccess: handleDeleteSuccess,
    onError: handleDeleteFailure,
  });

  const handleDelete = () => {
    deleteRecipient();
  };

  return (
    <>
      <Styled.LineHeaderRowLine />
      <Styled.ContentText>
        <FormattedMessage
          id="Identity is not linked to any documents"
          defaultMessage="Identity is not linked to any documents"
        />
      </Styled.ContentText>
      <LoadingActionButton
        outline
        onClick={handleDelete}
        isLoading={isDeleting}
        dangerColor
      >
        <FormattedMessage id="Delete" defaultMessage="Delete" />
      </LoadingActionButton>

      <SuccessModal
        isOpen={isSuccessModalOpened}
        toggle={handleSuccessModalClose}
        onClose={handleSuccessModalClose}
      >
        <FormattedMessage
          id={deleteSuccessMessageId}
          defaultMessage={deleteSuccessMessageId}
        />
      </SuccessModal>
      <ErrorModal
        isOpen={isFailureModalOpened}
        toggle={handleFailureModalClose}
        onClose={handleFailureModalClose}
        closeIconEnabled={false}
      >
        <ErrorContent>
          <FormattedMessage
            id={deleteFailureMessageId}
            defaultMessage={deleteFailureMessageId}
          />
        </ErrorContent>
      </ErrorModal>
    </>
  );
};

export default DeleteSection;
