import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Grid } from 'billon-ui';
import { Controller } from 'react-hook-form';
import * as Styled from './styled';
import { CUSTOMER, UserRoles, MAP_USER_ROLES } from '../../../../constraints';
import { FancyRadioField } from '../../../../ui/FancyRadioField/FancyRadioField';

const { Row, Col } = Grid;
const { FormGroup } = FormModule;

const PermissionsTab = ({ enablePrivate, customer, control }) => {
  const colWidth = customer === CUSTOMER.TAURON ? 3 : 4;

  return (
    <Styled.Permissions>
      <h4>
        <FormattedMessage id="Account type" defaultMessage="Account type" />
      </h4>

      <Row>
        <Col lg={colWidth} md={6}>
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: UserRoles.VISITOR,
                      checked: field.value === UserRoles.VISITOR,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage id="Basic" defaultMessage="Basic" />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="Read only"
                          defaultMessage="Read only"
                        />
                      </li>
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>

        <Col lg={colWidth} md={6}>
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: UserRoles.AGENT,
                      checked: field.value === UserRoles.AGENT,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id="Standard"
                        defaultMessage="Standard"
                      />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="Read only"
                          defaultMessage="Read only"
                        />
                      </li>

                      {customer && (
                        <li>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.TAURON]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <FormattedMessage
                                  id="Diploma publication"
                                  defaultMessage="Diploma publication"
                                />
                              ),
                            }[customer]
                          }
                        </li>
                      )}

                      {enablePrivate && (
                        <>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding recipients"
                                    defaultMessage="Adding recipients"
                                  />
                                </li>
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding students"
                                    defaultMessage="Adding students"
                                  />
                                </li>
                              ),
                            }[customer]
                          }
                          {[CUSTOMER.TAURON].includes(customer) && (
                            <li>
                              <FormattedMessage
                                id="Resending notifications"
                                defaultMessage="Resending notifications"
                              />
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>

        <Col lg={colWidth} md={6}>
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: UserRoles.BUSINESS_ADMIN,
                      checked: field.value === UserRoles.BUSINESS_ADMIN,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id={MAP_USER_ROLES.BUSINESS_ADMIN}
                        defaultMessage={MAP_USER_ROLES.BUSINESS_ADMIN}
                      />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="Read only"
                          defaultMessage="Read only"
                        />
                      </li>
                      {customer && (
                        <li>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.TAURON]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <FormattedMessage
                                  id="Diploma publication"
                                  defaultMessage="Diploma publication"
                                />
                              ),
                            }[customer]
                          }
                        </li>
                      )}

                      {enablePrivate && (
                        <>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding recipients"
                                    defaultMessage="Adding recipients"
                                  />
                                </li>
                              ),
                              [CUSTOMER.TAURON]: (
                                <li>
                                  <FormattedMessage
                                    id="Resending notifications"
                                    defaultMessage="Resending notifications"
                                  />
                                </li>
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding students"
                                    defaultMessage="Adding students"
                                  />
                                </li>
                              ),
                            }[customer]
                          }
                        </>
                      )}
                      <li>
                        <FormattedMessage
                          id="Category management"
                          defaultMessage="Category management"
                        />
                      </li>
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>

        <Col lg={colWidth} md={6}>
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: UserRoles.ADMIN,
                      checked: field.value === UserRoles.ADMIN,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id={MAP_USER_ROLES[UserRoles.ADMIN]}
                        defaultMessage={MAP_USER_ROLES[UserRoles.ADMIN]}
                      />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="Read only"
                          defaultMessage="Read only"
                        />
                      </li>
                      {customer && (
                        <li>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.TAURON]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <FormattedMessage
                                  id="Diploma publication"
                                  defaultMessage="Diploma publication"
                                />
                              ),
                            }[customer]
                          }
                        </li>
                      )}

                      {enablePrivate && (
                        <>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding recipients"
                                    defaultMessage="Adding recipients"
                                  />
                                </li>
                              ),
                              [CUSTOMER.TAURON]: (
                                <li>
                                  <FormattedMessage
                                    id="Resending notifications"
                                    defaultMessage="Resending notifications"
                                  />
                                </li>
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding students"
                                    defaultMessage="Adding students"
                                  />
                                </li>
                              ),
                            }[customer]
                          }
                        </>
                      )}
                      <li>
                        <FormattedMessage
                          id="Category management"
                          defaultMessage="Category management"
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="User management"
                          defaultMessage="User management"
                        />
                      </li>
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>

        <Col lg={colWidth} md={6}>
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: UserRoles.DPI,
                      checked: field.value === UserRoles.DPI,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id="DPI (Data Protection Inspector)"
                        defaultMessage="DPI (Data Protection Inspector)"
                      />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="Read only"
                          defaultMessage="Read only"
                        />
                      </li>
                      <li>
                        <FormattedMessage id="GDPR" defaultMessage="GDPR" />
                      </li>
                      <li>
                        <FormattedMessage
                          id="Correction of personal data"
                          defaultMessage="Correction of personal data"
                        />
                      </li>
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Styled.Permissions>
  );
};

PermissionsTab.propTypes = {
  customer: PropTypes.string,
  enablePrivate: PropTypes.bool,
  control: PropTypes.object.isRequired,
};

export default PermissionsTab;
