import styled from 'styled-components';

export const InputLabel = styled.label`
  margin-bottom: 0.25rem;
  font-size: ${({ labelSize }) => (labelSize ? labelSize : '12px')};
  font-weight: ${({ labelWeight }) => (labelWeight ? labelWeight : 500)};
`;

export const RegularInputWrapper = styled.div`
  background-color: #fff;
  position: relative;

  &.secondary {
    .form-control {
      border-radius: 8px;
      padding: 1.2rem 1.5rem;
      height: auto;

      &::placeholder {
        color: ${(props) => props.theme.palette.grayLight};
        font-weight: ${(props) => props.theme.fontWeightBase};
      }
    }

    .billon-select__control {
      padding: 0.7rem 0.4rem;
    }

    .currency.float-currency.dirty {
      bottom: 4rem;
    }
  }

  &.error {
    .form-control,
    .billon-select__control {
      border-color: ${(props) => props.theme.palette.error} !important;
    }
  }

  &.float-label {
    label {
      position: absolute;
      z-index: 2;
      padding: 0 7px;
      color: ${(props) => props.theme.palette.grayDark};
      font-weight: ${(props) => props.theme.fontWeightBase} !important;
      top: 11px;
      left: 10px;
      transition: ${(props) => props.theme.transitionBase};
      background-color: transparent;
    }

    &.focused {
      ${InputLabel}
    }

    &.focused {
      label {
        font-size: 0.7rem;
        top: -8px;
        z-index: 100;
        color: ${(props) => props.theme.bodyColor};
        background-color: #fff;
        font-weight: ${(props) => props.theme.fontWeightMedium} !important;
      }
    }
  }
`;

export const BtnGroup = styled.div`
  background-color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  right: 0.75rem;
  padding: 0;
  z-index: 10;
  display: flex;
`;

export const InputBtn = styled.button`
  &.clickable {
    cursor: pointer;
  }

  &.button_cancelfilter {
    i {
      font-size: 0.6rem;
    }
  }
  color: ${(props) => props.theme.palette.grayLight};
  transition: ${(props) => props.theme.transitionBase};
  border: none;
  font: inherit;
  background: none;
  outline: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

export const ClearBtn = styled(InputBtn)`
  display: ${(props) => (props.readyToClose ? 'flex' : 'none') + ' !important'};
  margin-right: 14px;
  color: hsl(0, 0%, 80%) !important;
  &:hover {
    color: black !important;
  }
`;
