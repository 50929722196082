import { useMemo } from 'react';
import { usePrivateNotificationTemplateGroups } from './usePrivateNotificationTemplateGroups';

export const useNotificationGroupOptions = () => {
  const { data: notificationGroups, isFetching: areNotificationGroupsLoading } =
    usePrivateNotificationTemplateGroups(null, {});

  const { rows: groupsRows, count: groupsCount } = useMemo(
    () => (!areNotificationGroupsLoading && notificationGroups) || {},
    [notificationGroups, areNotificationGroupsLoading],
  );

  const notificationGroupOptions = useMemo(
    () =>
      groupsCount > 0
        ? groupsRows.map(({ name, id }) => ({
            label: name,
            value: id,
          }))
        : [],
    [groupsRows, groupsCount],
  );

  const firstNotificationGroupOption = useMemo(() => {
    if (areNotificationGroupsLoading) {
      return;
    }
    return notificationGroupOptions?.[0];
  }, [notificationGroupOptions, areNotificationGroupsLoading]);

  return {
    notificationGroupOptions,
    firstNotificationGroupOption,
    areNotificationGroupsLoading,
  };
};
