import {
  PUBLICATION_FIELD_NAMES,
  PUBLICATION_MODES,
} from '../../../constraints';

export const getPublicationModeProperties = (publicationMode) => {
  switch (publicationMode) {
    case PUBLICATION_MODES.NEW:
    default:
      return {
        title: null,
        disabledFormFields: [PUBLICATION_FIELD_NAMES.PUBLISHED_BY],
      };

    case PUBLICATION_MODES.CORRECTED:
      return {
        title: null,
        disabledFormFields: Object.values(PUBLICATION_FIELD_NAMES).filter(
          (value) => value !== PUBLICATION_FIELD_NAMES.DOCUMENT_FILE,
        ),
      };

    case PUBLICATION_MODES.INCIDENT:
      return {
        title: null,
        disabledFormFields: [
          PUBLICATION_FIELD_NAMES.PUBLISHED_BY,
          PUBLICATION_FIELD_NAMES.IDENTITY,
        ],
      };
  }
};
