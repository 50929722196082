import React from 'react';
import useFilters from '../../../../../../hooks/useFilters';
import { useIntl } from 'react-intl';
import { parseFilters } from '../../../../../Recipients/helpers/parseFilters';
import { removeUndefinedValues } from '../../../../../../helpers/removeUndefinedValues';
import { useForm, Controller } from 'react-hook-form';
import * as Styled from './styled';
import { Form as FormModule } from 'billon-ui';

const { SearchBarField } = FormModule;

const RecipientSearchBar = ({ placeholderMessageId }) => {
  const { formatMessage } = useIntl();

  const { navigateWithSearchQuery, filters, onClear } = useFilters();

  const formInitialValues = { ...filters?.filters, documentNumber: '' };

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      ...filters?.filters,
    },
  });

  const onSubmit = (values) => {
    navigateWithSearchQuery({
      filters: removeUndefinedValues(parseFilters(values)),
    });
  };

  const handleReset = () => {
    onClear();
    reset(formInitialValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Styled.Row>
        <Styled.LeftColumn sm={6}>
          <Styled.Text>
            {formatMessage({ id: 'Search for person' })}
          </Styled.Text>
        </Styled.LeftColumn>
        <Styled.RightColumn sm={6}>
          <Controller
            name="documentNumber"
            control={control}
            render={({ field, fieldState }) => (
              <SearchBarField
                input={field}
                meta={{
                  touched: fieldState.invalid,
                  error: fieldState.error,
                }}
                handleReset={handleReset}
                classic
                placeholder={formatMessage({
                  id: placeholderMessageId,
                })}
              />
            )}
          />
        </Styled.RightColumn>
      </Styled.Row>
    </form>
  );
};

export default RecipientSearchBar;
