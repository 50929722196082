import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const PreviewTitle = styled(Text)`
  margin: 0;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DocumentWrapper = styled.div`
  overflow-y: auto;
  height: 250px;
  width: 100%;
  background-color: grey;
  justify-content: center;
  display: flex;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const PaginationText = styled(Text)`
  margin: 0;
`;
