import apiRoutes from '../../../api-routes';
import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

const useSettingsRequest = (options, token) => {
  return useQueryWithLogout(
    [QueryKeys.SETTINGS],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.SETTINGS,
        headers: {
          Authorization: token ? token : sessionStorage.getItem('token'),
        },
      });
      return data;
    },
    options,
  );
};

export default useSettingsRequest;
