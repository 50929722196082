import apiRoutes from '../../../api-routes';
import { deleteRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

export const useForgetRecipient = (options) => {
  return useMutationWithLogout(async (id) => {
    const { data: forgetData } = await deleteRequest({
      url: apiRoutes.RECIPIENT_GDPR_FORGET.replace('{id}', id),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });
    return forgetData;
  }, options);
};
