import { useMemo } from 'react';

import { usePublicNotificationTemplateGroups } from './usePublicNotificationTemplateGroups';
import { useSinglePublicNotificationTemplate } from './useSinglePublicNotificationTemplate';

const useNotificationTemplateByPublicDocumentType = ({
  publicDocumentType,
  disabled,
}) => {
  const {
    data: notificationTemplates,
    isSuccess,
    isLoading: areTemplatesLoading,
    error: templatesError,
  } = usePublicNotificationTemplateGroups(null, { enabled: !disabled });

  const activeTemplateId = useMemo(() => {
    if (!!publicDocumentType && isSuccess) {
      return notificationTemplates?.find(
        ({ publicDocumentType: value }) => value === publicDocumentType,
      )?.id;
    }
  }, [notificationTemplates, isSuccess, areTemplatesLoading]);

  const {
    data: activeTemplate,
    isFetching: isActiveTemplateLoading,
    error: notificationError,
  } = useSinglePublicNotificationTemplate(activeTemplateId, {
    enabled: !!activeTemplateId,
  });

  const isLoading = useMemo(
    () => areTemplatesLoading || isActiveTemplateLoading,
    [areTemplatesLoading, isActiveTemplateLoading],
  );

  const error = useMemo(() => {
    if (!!templatesError) {
      return templatesError;
    }
    if (!!notificationError) {
      return notificationError;
    }
    return;
  }, [templatesError, notificationError]);

  return {
    data: activeTemplate,
    publicDocumentType,
    activeTemplateId,
    isLoading,
    error,
  };
};

export default useNotificationTemplateByPublicDocumentType;
