import styled from 'styled-components';

import { Grid } from 'billon-ui';

export const Wrapper = styled(Grid.Row)`
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  flex-wrap: wrap;
`;

export const Col = styled(Grid.Col)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  min-width: 13rem;
`;

export const Text = styled.h2`
  font-size: 20px;
  color: #011627;
  font-weight: 700;
  margin: 0 18px 0 0;
`;
