import React from 'react';
import {
  PUBLICATION_MODES,
  RETAIN_OPTIONS_VALUES,
  retainOptionsList,
} from '../../../constraints';
import { FormattedMessage } from 'react-intl';
import { retainOptionToString } from '../../../formatters';
import { findEqualOrGreaterNumberFromArray } from '../../../helpers/findEqualOrGreaterNumberFromArray';

const advanceCurrentDateByYears = (nYears) =>
  new Date(new Date().setFullYear(new Date().getFullYear() + nYears));

export const calculateRetainUntil = (
  retainUntilOption,
  mode,
  retainUntilDate,
) => {
  if (mode !== PUBLICATION_MODES.NEW) return retainUntilDate ?? undefined;

  const { value: optionValue } = retainUntilOption || {};

  if (!optionValue) {
    return undefined;
  }

  const value = RETAIN_OPTIONS_VALUES[optionValue];

  return advanceCurrentDateByYears(value)?.toISOString();
};

export const decalculateRetainUntil = (publicationStartDate, retainUntil) => {
  const diff =
    new Date(retainUntil).getFullYear() -
    new Date(publicationStartDate).getFullYear();

  const optionValuesAsNumberArray = retainOptionsList.map(
    (n) => RETAIN_OPTIONS_VALUES[n],
  );

  const rawOptionValue = findEqualOrGreaterNumberFromArray(
    optionValuesAsNumberArray,
    diff,
  );

  const optionValue = retainOptionsList.find(
    (n) => RETAIN_OPTIONS_VALUES[n] === rawOptionValue,
  );

  const optionLabelId = retainOptionToString(optionValue);
  const optionLabel = (
    <FormattedMessage id={optionLabelId} defaultMessage={optionLabelId} />
  );

  return { value: optionValue, label: optionLabel, rawDate: retainUntil };
};

export const validateValidUntilWithRetainUntil = (
  validUntil,
  retainUntilOption,
) => {
  const { value: optionValue } = retainUntilOption || {};

  if (!optionValue) {
    return false;
  }

  const value = RETAIN_OPTIONS_VALUES[optionValue];

  const retainUntil = advanceCurrentDateByYears(value);

  return new Date(validUntil).getTime() <= new Date(retainUntil).getTime();
};
