import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

export const useDocumentList = (filters, options) =>
  useQueryWithLogout(
    [QueryKeys.DOCUMENT_LIST, filters],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.DOCUMENT,
        params: filters,
      });

      return data;
    },
    options,
  );
