import { parseDateRange } from '../../../helpers/parseDateRange';
import { parseIdentity } from './parseIdentity';

export const parseFilters = ({
  blockchainAddress,
  publicationDateRange,
  documentStatusList,
  signingStatus,
  publishedBy,
  title,
  validSinceDateRange,
  validUntilDateRange,
  category,
  identity,
  tenant,
  activeDeliveryType,
}) => ({
  blockchainAddress: blockchainAddress || undefined,
  categoryId: category?.value || undefined,
  identity: parseIdentity(identity),
  publicationDateRange: parseDateRange(publicationDateRange),
  documentStatusList: documentStatusList?.value || undefined,
  signingStatus: signingStatus?.value || undefined,
  publishedBy: publishedBy ? publishedBy.toLowerCase() : undefined,
  title: title ? title.toLowerCase() : undefined,
  validSinceDateRange: parseDateRange(validSinceDateRange),
  validUntilDateRange: parseDateRange(validUntilDateRange),
  tenant: tenant ? { id: tenant?.value } : undefined,
  activeDeliveryType: activeDeliveryType?.value || undefined,
});
