import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

export const useSingleIdentity = (options) => {
  return useQueryWithLogout(
    [QueryKeys.SINGLE_IDENTITY, options.id],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.SINGLE_RECIPIENT.replace('{id}', options?.id),
      });

      return data;
    },
    options,
  );
};
