import apiRoutes from '../../../api-routes';
import { getRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

const useSmsCodeRequest = (options, token) => {
  return useMutationWithLogout(async () => {
    const { data } = await getRequest({
      url: apiRoutes.SMS_CODE,
      headers: {
        Authorization: token ? token : sessionStorage.getItem('token'),
      },
    });
    return data;
  }, options);
};

export default useSmsCodeRequest;
