import React from 'react';

import appRoutes from '../../../../app-routes';

// hooks
import useDocumentType from '../../hooks/useDocumentType';
import useFilters from '../../../../hooks/useFilters';

// Components
import { PageLayout, FeatureCard, Categories } from '../../components';
import { Grid } from 'billon-ui';
import { DOCUMENT_TYPE } from '../../../../constraints';

const { Col } = Grid;

const NotificationCategoriesPage = () => {
  const { documentType } = useDocumentType();
  const { filters, navigateWithSearchQuery } = useFilters();

  if (documentType === DOCUMENT_TYPE.PUBLIC) {
    navigateWithSearchQuery(
      { filters: { documentTypeList: documentType } },
      appRoutes.NOTIFICATION_TEMPLATES,
    );
  }

  if (!filters?.filters?.documentTypeList && !!documentType) {
    navigateWithSearchQuery({ filters: { documentTypeList: documentType } });
  }

  return (
    <PageLayout>
      <Col md={12}>
        <FeatureCard>
          <Categories />
        </FeatureCard>
      </Col>
    </PageLayout>
  );
};

export default NotificationCategoriesPage;
