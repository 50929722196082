import { RECIPIENT_STATUSES } from '../../../../constraints';

export const parseStatus = (status) => {
  const { value } = status || {};
  if (!!value) {
    if (value !== RECIPIENT_STATUSES.SUSPENDED) {
      return value;
    }
  }
};
