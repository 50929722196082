import * as yup from 'yup';
import { useIntl } from 'react-intl';

import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import {
  MAP_VALIDATION_USER_ERRORS,
  SHARED_SECRET_FIELD_NAMES,
  SHARED_SECRET_TYPE,
  MAP_SHARED_SECRET_VALIDATION_ERRORS,
} from '../../../../../../constraints';
import {
  validatePESEL,
  validateNIP,
  validatePostalCode,
} from '../../validators';

export const useSharedSecretValueSchema = () => {
  const { formatMessage } = useIntl();
  const { recipientsConfig } = useConfigContext();
  const { isSharedSecretRequired } = recipientsConfig || {};

  const getSharedSecretValueErrorMessage = (sharedSecretType) => {
    if (sharedSecretType?.value === SHARED_SECRET_TYPE.PESEL) {
      return formatMessage({
        id: MAP_SHARED_SECRET_VALIDATION_ERRORS.PESEL,
        defaultMessage: MAP_SHARED_SECRET_VALIDATION_ERRORS.PESEL,
      });
    }
    if (sharedSecretType?.value === SHARED_SECRET_TYPE.NIP) {
      return formatMessage({
        id: MAP_SHARED_SECRET_VALIDATION_ERRORS.NIP,
        defaultMessage: MAP_SHARED_SECRET_VALIDATION_ERRORS.NIP,
      });
    }
    if (sharedSecretType?.value === SHARED_SECRET_TYPE.POSTAL_CODE) {
      return formatMessage({
        id: MAP_SHARED_SECRET_VALIDATION_ERRORS.POSTAL_CODE,
        defaultMessage: MAP_SHARED_SECRET_VALIDATION_ERRORS.POSTAL_CODE,
      });
    }
    return formatMessage({
      id: MAP_VALIDATION_USER_ERRORS.INVALID_DOCUMENT_NUMBER,
      defaultMessage: MAP_VALIDATION_USER_ERRORS.INVALID_DOCUMENT_NUMBER,
    });
  };

  const conditionalSchema = () => {
    return yup
      .string()
      .test(
        SHARED_SECRET_FIELD_NAMES.SHARED_SECRET_VALUE,
        getSharedSecretValueErrorMessage(),
        function (sharedSecretValue, validationContext) {
          const {
            createError,
            parent: { sharedSecretType },
          } = validationContext;
          if (sharedSecretType?.value === SHARED_SECRET_TYPE.PESEL) {
            if (!validatePESEL(sharedSecretValue)) {
              return createError({
                message: getSharedSecretValueErrorMessage(sharedSecretType),
              });
            }
          }
          if (sharedSecretType?.value === SHARED_SECRET_TYPE.NIP) {
            if (!validateNIP(sharedSecretValue)) {
              return createError({
                message: getSharedSecretValueErrorMessage(sharedSecretType),
              });
            }
          }
          if (sharedSecretType?.value === SHARED_SECRET_TYPE.POSTAL_CODE) {
            if (!validatePostalCode(sharedSecretValue)) {
              return createError({
                message: getSharedSecretValueErrorMessage(sharedSecretType),
              });
            }
          }
          return true;
        },
      );
  };

  return yup.string().when([], {
    is: () => isSharedSecretRequired,
    then: () =>
      conditionalSchema().required(
        formatMessage({
          id: MAP_VALIDATION_USER_ERRORS.EMPTY_DOCUMENT_NUMBER,
          defaultMessage: MAP_VALIDATION_USER_ERRORS.EMPTY_DOCUMENT_NUMBER,
        }),
      ),
    otherwise: () => conditionalSchema(),
  });
};
