import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

// Components
import { Utils, Grid } from 'billon-ui';
const { Row } = Grid;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const EditContentSubmitButton = ({
  notificationParamConfig,
  isEditing,
  onSubmit,
}) => {
  const formMethods = useFormContext();
  const { handleSubmit } = formMethods;

  return (
    <Row margin={'48px 0 16px 0'}>
      {!isEditing ? (
        <Button type="submit" size="lg" block onClick={handleSubmit(onSubmit)}>
          <FormattedMessage
            id={notificationParamConfig.formConfirmMessageId}
            defaultMessage={notificationParamConfig.formConfirmMessageId}
          />
        </Button>
      ) : (
        <ButtonLoader size="lg" block />
      )}
    </Row>
  );
};

export default EditContentSubmitButton;
