// hooks
import { useSettingsContext } from '../../../../Settings/hooks/useSettings';
import { useTokenParamContext } from '../../../../Auth/hooks/useTokenParam';
import useMeRequest from '../../../../Settings/hooks/useMeRequest';

// constants
import { REPORT_ROLE } from '../../../../../constraints';
import { UserRoles } from '../../../../../userRoles';

export const useFormUserRoleInfo = ({ deliveredRole }) => {
  const { token } = useTokenParamContext();
  const { role: contextRole } = useSettingsContext();
  const { data: me, isLoading } = useMeRequest({}, token);
  const { reportRole } = me || {};
  const role = deliveredRole ? deliveredRole : contextRole;

  const roleKeyPrefix =
    [UserRoles.ADMIN].includes(role) || [REPORT_ROLE.ADMIN].includes(reportRole)
      ? UserRoles.ADMIN
      : 'USER';

  return { roleKeyPrefix, role, reportRole, isLoading };
};
