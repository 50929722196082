import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { RECIPIENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// Components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { FieldLabel } from '../../../../../../components/Form';

const TextEmailField = ({ name = RECIPIENT_FILTER_FIELD_NAMES.EMAIL }) => {
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Email" defaultMessage="Email" />
      </FieldLabel>
      <ControlledTextField name={name} />
    </>
  );
};

export default TextEmailField;
