import React, { useState, useEffect } from 'react';

// hooks
import { usePublishDocumentAsyncWithStatusCheck } from '../../hooks/useAsyncPublishDocument';
import { useAsyncUpdateDocument } from '../../hooks/useAsyncUpdateDocument';

// constants
import {
  mapDOCUMENT_ACTIONS_ERROR,
  mapDOCUMENT_ACTIONS_SUCCESS,
  UNKNOWN_ERRORS,
  documentPreparationERRORS,
} from '../../../../constraints';
import { PUBLISHING_DOCUMENT_STATUS } from '../../constants';
import { publicDocumentTypeOptions } from '../../../../components/SelectPublicDocumentTypeField/SelectPublicDocumentTypeField';

// helpers
import { parsePublishRequestData } from '../../helpers';
import { decalculateRetainUntil } from '../../helpers';

// Components
import { DocumentAtrributesForm } from '../PublishDocumentModal/components';
import { NotificationContentForm } from '../PublishDocumentModal/components/NotificationContentForm/NotificationContentForm';
import { DocumentPublicationSummary } from '../DocumentPublicationSummary';

const UpdateFormAsync = ({ initialValues, mode, onSuccess, onError }) => {
  const [step, setStep] = useState(1);

  const {
    tenant,
    documentType,
    documentBlockchainAddress,
    publicationStartDate,
    retainUntil: rawRetainUntil,
    publicDocumentType: rawPublicDocumentType,
    ...restDocInfo
  } = initialValues;
  const tenantId = tenant?.id;

  const retainUntil = decalculateRetainUntil(
    publicationStartDate,
    rawRetainUntil,
  );

  const publicDocumentType =
    rawPublicDocumentType &&
    publicDocumentTypeOptions?.find(
      ({ value }) => rawPublicDocumentType === value,
    );

  const [formData, setFormData] = useState({
    documentType,
    documentBlockchainAddress,
    publicationStartDate,
    retainUntil,
    tenantId,
    publicDocumentType,
    ...restDocInfo,
  });

  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [updateDocumentId, setPublishDocumentId] = useState(null);
  const {
    mutate: updateDocument,
    isLoading: isSaving,
    error: updatingError,
    isError: isUpdatingError,
  } = useAsyncUpdateDocument(documentType, {
    onSuccess: (response) => {
      const { id } = response;
      setIsLoadingStatus(true);
      setPublishDocumentId(id);
    },
  });

  const {
    data: updatingResult,
    isLoading: isLoadingStatusAsync,
    isRefetching: isRefetchingStatus,
  } = usePublishDocumentAsyncWithStatusCheck(updateDocumentId, {
    enabled: !!updateDocumentId,
    refetchInterval: (data) => {
      if (
        data?.status &&
        [
          PUBLISHING_DOCUMENT_STATUS.SUCCESS,
          PUBLISHING_DOCUMENT_STATUS.PREPARING_ERROR,
        ].includes(data?.status)
      ) {
        setIsLoadingStatus(false);
        return;
      }

      return 2000;
    },
  });

  const handleFormSubmit = (values) => {
    updateDocument({
      data: parsePublishRequestData(
        values,
        mode,
        documentBlockchainAddress,
        rawRetainUntil,
      ),
    });
  };

  const handleSubmitStep = (stepData) => {
    handleAppendFormData(stepData);
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const isPublishingDocument =
    isSaving || isLoadingStatus || isLoadingStatusAsync || isRefetchingStatus;

  useEffect(() => {
    if (updatingResult?.status === PUBLISHING_DOCUMENT_STATUS.SUCCESS) {
      onSuccess(mapDOCUMENT_ACTIONS_SUCCESS.UPDATE);
    }

    let statusCode;
    let errorData;

    if (!isSaving && updatingResult?.publishingError) {
      statusCode = updatingResult?.publishingError;

      if (
        statusCode === documentPreparationERRORS.DOCUMENT_TOO_LARGE &&
        updatingResult?.status
      ) {
        statusCode = `${updatingResult.publishingError}_${updatingResult?.status}`;
      }

      errorData = updatingResult;
    }

    if (isUpdatingError && updatingError?.statusCode) {
      statusCode =
        updatingError?.statusCode || UNKNOWN_ERRORS.INTERNAL_SERVER_ERROR;
      errorData = updatingError;
    }

    if (
      [updatingError?.statusCode, updatingError?.publishingError].includes(
        UNKNOWN_ERRORS.INTERNAL_SERVER_ERROR,
      )
    ) {
      statusCode = UNKNOWN_ERRORS.UNKNOWN_PUBLISHING_ERROR;
      errorData = undefined;
    }

    if (statusCode || errorData) {
      onError(
        mapDOCUMENT_ACTIONS_ERROR.UPDATE,
        statusCode ? statusCode : UNKNOWN_ERRORS.UNKNOWN_ERROR,
      );
    }
  }, [
    updatingResult,
    updatingError,
    onSuccess,
    onError,
    isUpdatingError,
    isSaving,
  ]);

  return (
    <>
      {step === 1 && (
        <DocumentAtrributesForm
          step={2} // first step allows to add identity and category
          document={formData}
          publicationMode={mode}
          onSubmit={handleSubmitStep}
          submitButtonText="Next"
          isLoading={isPublishingDocument}
        />
      )}
      {step === 2 && (
        <NotificationContentForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
          disabledTitle
          documentBlockchainAddress={documentBlockchainAddress}
          documentType={documentType}
        />
      )}
      {step === 3 && (
        <DocumentPublicationSummary
          onSubmit={handleFormSubmit}
          onBack={handleBack}
          isLoading={isPublishingDocument}
          defaultValues={formData}
          mode={mode}
          submitButtonText="Confirm2"
          publicationMode={mode}
          documentType={documentType}
        />
      )}
    </>
  );
};

export default UpdateFormAsync;
