import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { MAP_PUBLIC_DOCUMENT_TYPES } from '../../../../../../constraints';

// components
import { NavButton } from './styled';

const HorizontalPublicNotificationGroupList = ({
  activeNotificationGroupId,
  notificationGroups,
  handleNotificationGroupClick,
}) => {
  return (
    <>
      {notificationGroups?.map(({ id, publicDocumentType, disabled }) => {
        const displayedNameId = MAP_PUBLIC_DOCUMENT_TYPES[publicDocumentType];
        return (
          <NavButton
            isSelected={id === activeNotificationGroupId}
            disabled={disabled}
            onClick={() => handleNotificationGroupClick(id)}
          >
            {!!displayedNameId && (
              <FormattedMessage
                id={displayedNameId}
                defaultMessage={displayedNameId}
              />
            )}
          </NavButton>
        );
      })}
    </>
  );
};

export default HorizontalPublicNotificationGroupList;
