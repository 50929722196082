import apiRoutes from '../../../api-routes';
import { postRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

export const useResendDocumentNotification = (options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: recipientsData } = await postRequest({
      url: apiRoutes.RESEND_DOCUMENT_NOTIFICATION.replace(
        '{id}',
        options?.id,
      ).replace('{documentType}', options?.documentType?.toLowerCase()),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return recipientsData;
  }, options);
