import React, { useMemo, useCallback } from 'react';
import useFeatureEnabled from '../../../../../Config/hooks/useFeatureEnabled';
import { useHistory } from 'react-router-dom';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import useFilters from '../../../../../../hooks/useFilters';
import { getSigningFlags } from '../../../../helpers/getSigningFlags';

// constants
import {
  CUSTOMER,
  DOCUMENT_TYPE,
  DOCUMENT_STATUS,
  SIGNING_ORDER,
  PUBLICATION_MODES,
} from '../../../../../../constraints';
import appRoutes from '../../../../../../app-routes';

// Components
import {
  LineHeaderRow,
  LoadingActionButton,
} from '../../../../../../components';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../../../../ui/CustomToolTip';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';
import { Utils, Grid } from 'billon-ui';

const { Text, Icon } = Utils;
const { Row } = Grid;

const AdvancedFeatures = ({
  customer,
  handleDownloadQRClick,
  document,
  nextDocumentStatus,
}) => {
  const history = useHistory();
  const { enableSigning } = useConfigContext();
  const { featureEnabled } = useFeatureEnabled();
  const { navigateWithNewSearchQuery } = useFilters();

  const {
    documentType,
    jobId,
    status,
    signingStatus,
    signingOrder,
    documentBlockingNewVersion,
  } = document || {};

  const documentTypeBasedSetting =
    documentType === DOCUMENT_TYPE.PUBLIC
      ? 'publicDocuments'
      : 'privateDocuments';

  const {
    areAdvancedFeaturesEnabled,
    isDocumentRejected,
    isNewVersionEnabled,
    isErratumEnabled,
    isIncidentEnabled,
  } = getSigningFlags(
    enableSigning,
    signingStatus,
    signingOrder,
    status,
    nextDocumentStatus,
  );

  const isDeprecated = status === DOCUMENT_STATUS.DEPRECATED;

  const isDisableReasonUnknown =
    !isDeprecated && (isDocumentRejected || !areAdvancedFeaturesEnabled);

  const areFeaturesDisabled = useMemo(() => {
    if (
      documentBlockingNewVersion?.jobId ||
      status !== DOCUMENT_STATUS.CURRENT
    ) {
      return true;
    }
    return false;
  }, [documentBlockingNewVersion, status]);

  const isTwoStepFeature =
    enableSigning &&
    [SIGNING_ORDER.PUBLISHER_ONLY, SIGNING_ORDER.SENDER_FIRST].includes(
      signingOrder,
    );

  const firstInHistoryStatus = document?.history[0]?.status;

  const isUploading = firstInHistoryStatus === DOCUMENT_STATUS.UPLOADING;

  const UnavailableFeatureMessage = () => (
    <FormattedMessage
      id="Option unavailable"
      defaultMessage="Option unavailable"
    />
  );

  const NewVersionEnabledMessageId =
    'If there is a subsequent version of a document, a new version linked to the previous version may be published';

  const ErratumEnabledMessageId =
    'If there are minor errors in the text of the document, which were noticed only after publication, it is possible to correct these errors by publishing Errata to the original document.';

  const IncidentEnabledMessageId =
    'If you have mistakenly published a document to the wrong recipient revealing sensitive data, you can publish the correct document for that recipient.';

  const disableFeatureToolTipMessage = useMemo(() => {
    if (isUploading) {
      return (
        <>
          <FormattedMessage
            id="Option unavailable, the most recent document version is being published"
            defaultMessage="Option unavailable, the most recent document version is being published"
          />
          {' - '}
          <FormattedMessage
            id="Publication ID"
            defaultMessage="Publication ID"
          />
          {': '}
          {jobId}
        </>
      );
    }
    if (isDisableReasonUnknown && !isUploading) {
      return <UnavailableFeatureMessage />;
    }
    if (isDeprecated) {
      return (
        <FormattedMessage
          id="Option available only for latest document in history"
          defaultMessage="Option available only for latest document in history"
        />
      );
    }
    return null;
  }, [isUploading, isDisableReasonUnknown, isDeprecated, jobId]);

  const NewVersionTooltipMessage = useCallback(() => {
    if (disableFeatureToolTipMessage) {
      return disableFeatureToolTipMessage;
    }
    if (isNewVersionEnabled) {
      return (
        <FormattedMessage
          id={NewVersionEnabledMessageId}
          defaultMessage={NewVersionEnabledMessageId}
        />
      );
    }
    return <UnavailableFeatureMessage />;
  }, [disableFeatureToolTipMessage, isNewVersionEnabled]);

  const ErratumTooltipMessage = useCallback(() => {
    if (disableFeatureToolTipMessage) {
      return disableFeatureToolTipMessage;
    }
    if (isErratumEnabled) {
      return (
        <FormattedMessage
          id={ErratumEnabledMessageId}
          defaultMessage={ErratumEnabledMessageId}
        />
      );
    }
    return <UnavailableFeatureMessage />;
  }, [disableFeatureToolTipMessage, isErratumEnabled]);

  const IncidentTooltipMessage = useCallback(() => {
    if (disableFeatureToolTipMessage) {
      return disableFeatureToolTipMessage;
    }
    if (isIncidentEnabled) {
      return (
        <FormattedMessage
          id={IncidentEnabledMessageId}
          defaultMessage={IncidentEnabledMessageId}
        />
      );
    }
    return <UnavailableFeatureMessage />;
  }, [disableFeatureToolTipMessage, isIncidentEnabled]);

  const handlePublishDocument = (mode) => {
    navigateWithNewSearchQuery(
      {
        documentTypeList: documentType,
        jobId,
        mode,
      },
      appRoutes.PUBLISH_DOCUMENT,
    );
  };

  const handleNevVersionClick = () => {
    if (isTwoStepFeature) {
      handlePublishDocument(PUBLICATION_MODES.UPDATED);
    } else {
      history.push(`/document/${jobId}/update/${PUBLICATION_MODES.UPDATED}`);
    }
  };

  const handleErratumClick = () => {
    if (isTwoStepFeature) {
      handlePublishDocument(PUBLICATION_MODES.CORRECTED);
    } else {
      history.push(`/document/${jobId}/update/${PUBLICATION_MODES.CORRECTED}`);
    }
  };

  const handleIncidentClick = () => {
    if (isTwoStepFeature) {
      handlePublishDocument(PUBLICATION_MODES.INCIDENT);
    } else {
      history.push(`/document/${jobId}/update/${PUBLICATION_MODES.INCIDENT}`);
    }
  };

  return (
    <>
      <LineHeaderRow>
        <Text fontSize={'13px'} margin={0} fontWeight={300}>
          <FormattedMessage
            id="Advanced features"
            defaultMessage="Advanced features"
          />
        </Text>
      </LineHeaderRow>
      <Row>
        {((!enableSigning &&
          featureEnabled(`${documentTypeBasedSetting}.enableNewVersion`, [
            CUSTOMER.TAURON,
            CUSTOMER.DEFAULT,
          ])) ||
          enableSigning) && (
          <BtnCol xl={4}>
            <CustomToolTip
              id="NewVersionTooltip"
              trigger={
                <LoadingActionButton
                  onClick={
                    areFeaturesDisabled || !isNewVersionEnabled
                      ? () => {}
                      : handleNevVersionClick
                  }
                  outline
                  inActive={areFeaturesDisabled || !isNewVersionEnabled}
                >
                  <FormattedMessage
                    id="New version"
                    defaultMessage="New version"
                  />
                </LoadingActionButton>
              }
              delay={false}
              placement="bottom-start"
            >
              <h4>
                <FormattedMessage
                  id="New version"
                  defaultMessage="New version"
                />
              </h4>
              <NewVersionTooltipMessage />

              {'.'}
            </CustomToolTip>
          </BtnCol>
        )}

        {((!enableSigning &&
          featureEnabled(`${documentTypeBasedSetting}.enableErratum`, [
            CUSTOMER.DEFAULT,
            CUSTOMER.TAURON,
            CUSTOMER.DIPLOMA,
          ])) ||
          enableSigning) && (
          <BtnCol xl={4}>
            <CustomToolTip
              id="ErratumTooltip"
              trigger={
                <LoadingActionButton
                  outline
                  onClick={
                    areFeaturesDisabled || !isErratumEnabled
                      ? () => {}
                      : handleErratumClick
                  }
                  inActive={areFeaturesDisabled || !isErratumEnabled}
                >
                  <FormattedMessage id="Erratum" defaultMessage="Erratum" />
                </LoadingActionButton>
              }
              delay={false}
              placement="bottom-start"
            >
              <h4>
                <FormattedMessage id="Erratum" defaultMessage="Erratum" />
              </h4>
              <ErratumTooltipMessage />
            </CustomToolTip>
          </BtnCol>
        )}

        {documentType === DOCUMENT_TYPE.PRIVATE && (
          <BtnCol xl={4}>
            <CustomToolTip
              id="IncidentTooltip"
              trigger={
                <LoadingActionButton
                  outline
                  onClick={
                    areFeaturesDisabled || !isIncidentEnabled
                      ? () => {}
                      : handleIncidentClick
                  }
                  inActive={areFeaturesDisabled || !isIncidentEnabled}
                >
                  <FormattedMessage id="Incident" defaultMessage="Incident" />
                </LoadingActionButton>
              }
              delay={false}
              placement="bottom-start"
            >
              <h4>
                <FormattedMessage id="Incident" defaultMessage="Incident" />
              </h4>
              <IncidentTooltipMessage />
            </CustomToolTip>
          </BtnCol>
        )}
      </Row>
      <Row>
        {featureEnabled(`${documentTypeBasedSetting}.enableDownloadQR`, null) &&
          customer !== CUSTOMER.DIPLOMA && (
            <BtnCol xl={4}>
              <LoadingActionButton
                xl={4}
                outline
                onClick={handleDownloadQRClick}
              >
                <Icon name="qrcode" />
                <FormattedMessage id="QR Code" defaultMessage="QR Code" />
              </LoadingActionButton>
            </BtnCol>
          )}
      </Row>
    </>
  );
};

export default AdvancedFeatures;
