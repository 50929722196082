import base from '../../base';
import {
  CUSTOMER,
  TAURON_VERIFICATION_CODE_STATUSES,
} from '../../../constraints';
import { theme } from 'billon-ui';

export default {
  ...base,
  backendUrl: 'https://apibchpubpre.tpe.corp/application/v1.0',
  customer: CUSTOMER.TAURON,

  // publication features
  enablePublic: true,
  enablePrivate: true,
  enableSigning: false,
  enableActiveDelivery: false,
  enablePublicationDelay: false,

  // recipients
  manageRecipients: true,
  enableSharedSecret: true,
  isSharedSecretOptional: false,

  // notifications
  enableNotificationManager: true,

  availableLanguages: ['pl'],
  language: 'pl',
  privateDocuments: {
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  publicDocuments: {
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  enableDocumentDetails: {
    publicationStartDate: true,
    publicationDate: true,
    categoryFullPath: false,
    status: true,
    publisherName: true,
    publishedBy: true,
    versionName: true,
    retainUntil: true,
    validSince: true,
    validUntil: true,
    identity: false,
    documentNumber: false,
  },
  SMS_CODE_STATUSES: TAURON_VERIFICATION_CODE_STATUSES,
  theme: {
    ...theme,
    palette: {
      ...theme.palette,
      primary: '#e2007a',
      primaryLight: '#ff1b96',
      primaryLightest: '#ff54b0',
      primaryDark: '#a9005b',
      iconColor: '#e2007a',
      iconHoverColor: '#a9005b',
    },
    linkColor: '#e2007a',
    linkHoverColor: '#a9005b',
  },
};
