import React from 'react';

import { useStatisticsFile } from '../../../../hooks';

// components
import { FormattedMessage } from 'react-intl';
import { LoadingActionButton } from '../../../../../../components';
import { Layout as LayoutModule } from 'billon-ui';

const { PageHeader } = LayoutModule;

const StatisticsHeader = () => {
  const {
    mutate: downloadStatisticReport,
    isLoading: isDownloadingStatisticReport,
  } = useStatisticsFile();

  const handleDownloadStatisticReport = () => {
    downloadStatisticReport();
  };

  return (
    <PageHeader
      classic
      after={
        <LoadingActionButton
          onClick={handleDownloadStatisticReport}
          inActive={isDownloadingStatisticReport}
        >
          <FormattedMessage
            id="Download statistics"
            defaultMessage="Download statistics"
          />
        </LoadingActionButton>
      }
    >
      <FormattedMessage id="Statistics" defaultMessage="Statistics" />
    </PageHeader>
  );
};

export default StatisticsHeader;
