import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const PublishedDocsCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  .dropdown {
    height: 100%;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  margin-bottom: 30px;
`;

export const DropDownDiv = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-end;
  margin-bottom: 40px;
  margin-right: 40px;
  white-space: nowrap;
`;

export const CardTitle = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 30px 0;
`;
