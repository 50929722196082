import { deleteRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../api-routes';

export const useDeleteIdentity = (options) =>
  useMutationWithLogout(async () => {
    const { data: createData } = await deleteRequest({
      url: apiRoutes.SINGLE_RECIPIENT.replace('{id}', options?.id),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    return createData;
  }, options);
