import { parseSharedSecret } from './parseSharedSecret';

export const parseRecipientData = ({
  documentType,
  sharedSecretType,
  sharedSecretValue,
  sharedSecret,
  payerNumbers,
  ppeNumbers,
  payerNumber,
  ppeNumber,
  ...rest
}) => ({
  documentType: documentType?.value,
  sharedSecret: parseSharedSecret(sharedSecretType, sharedSecretValue),
  payerNumbers: !!payerNumber ? [payerNumber] : undefined,
  ppeNumbers: !!ppeNumber ? [ppeNumber] : undefined,
  ...rest,
});
