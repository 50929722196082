import styled from 'styled-components';

export const PB = styled.div`
  color: ${(props) => props.theme.palette.grayLighter};
  font-size: 0.7rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${(props) => props.theme.fontWeightLight};

  &.fixed {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    right: 0;
    left: 0;
    height: 50px;
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 1rem 0;
    z-index: 2;
  }
`;

export const VersionSpan = styled.span`
  margin-left: 8px;
`;
