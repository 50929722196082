import apiRoutes from '../../../api-routes';
import { deleteRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

export const useForgetDocuments = (options) => {
  return useMutationWithLogout(
    async ({ adressess, jobIds }) =>
      await Promise.all(
        adressess.map(async (id) => {
          await deleteRequest({
            url: apiRoutes.SINGLE_DOCUMENT_FORGET.replace('{id}', id),
            headers: {
              Authorization: sessionStorage.getItem('token'),
            },
          });
        }),
        jobIds.map(async (id) => {
          await deleteRequest({
            url: apiRoutes.SINGLE_DOCUMENT.replace('{id}', id),
            headers: {
              Authorization: sessionStorage.getItem('token'),
            },
          });
        }),
      ),

    options,
  );
};
