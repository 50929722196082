import { deleteRequest } from 'js/helpers/apiClient';
import apiRoutes from 'js/api-routes';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

export const useDeleteDocument = (options) =>
  useMutationWithLogout(
    async ({ id }) =>
      await deleteRequest({
        url: apiRoutes.SINGLE_DOCUMENT.replace('{id}', id),
      }),

    options,
  );
