import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Utils } from 'billon-ui';

const { Text } = Utils;

export const BB = styled.div`
  margin-top: 3rem;
`;

export const Title = styled(Text)`
  font-size: 2.5rem;
  font-weight: ${(props) => props.theme.fontWeightBold};

  ${down('sm')} {
    font-size: 1.75rem;
  }

  ${down('md')} {
    font-size: ${(props) => props.theme.fontSizeLg};
  }

  ${down('lg')} {
    font-size: 1.9rem;
  }
`;

export const Description = styled(Text)`
  margin-bottom: 4rem;
  ${down('md')} {
    font-size: ${(props) => props.theme.fontSizeSm};
    margin-bottom: 2rem;
  }
`;

export const LinkWrapper = styled.div`
  margin-bottom: 22px;
`;
