import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomConfirmModal from '../../../../../../ui/CustomConfirmModal';
import { Utils } from 'billon-ui';

const { Text } = Utils;

const ConfirmRodoModal = ({
  onSubmit,
  onClose,
  isOpen,
  isSubmitting,
  documentsNumber,
}) => (
  <CustomConfirmModal
    isSubmitting={isSubmitting}
    onSubmit={onSubmit}
    isOpen={isOpen}
    toggle={onClose}
    title={<FormattedMessage id="Execute GDPR" defaultMessage="Execute GDPR" />}
  >
    <Text>
      <FormattedMessage
        id="Execute GDPR confirm description p1"
        defaultMessage="Execute GDPR confirm description p1"
      />{' '}
      {documentsNumber}{' '}
      {documentsNumber > 1 ? (
        <FormattedMessage
          id="Execute GDPR confirm description p2"
          defaultMessage="Execute GDPR confirm description p2"
        />
      ) : (
        <FormattedMessage
          id="Execute GDPR confirm description p2b"
          defaultMessage="Execute GDPR confirm description p2b"
        />
      )}
    </Text>
  </CustomConfirmModal>
);

export default ConfirmRodoModal;
