import React from 'react';

import * as Styled from './styled';

export const UserInfo = (props) => {
  return (
    <Styled.InfoContainer>
      {!props.iconDisabled && (
        <Styled.InfoIconContainer>
          <Styled.InfoIcon scale={props.scale} name="user-circle" />
        </Styled.InfoIconContainer>
      )}
      <Styled.UserNameContainer>
        <Styled.UserName scale={props.scale}>
          <strong>{props.name}</strong>
        </Styled.UserName>
        <Styled.SessionLeft>{props.children}</Styled.SessionLeft>
      </Styled.UserNameContainer>
    </Styled.InfoContainer>
  );
};

export default UserInfo;
