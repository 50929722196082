import React from 'react';
import { CUSTOMER, IDENTITY_DOCUMENT_TYPE } from '../../constraints';
import UserInfo from '../../ui/UserInfo';
import { Grid } from 'billon-ui';
import { blurSingleFormatter } from '../../formatters';

const { Col } = Grid;

export const formatIdentity = (identity, customer) => {
  const {
    documentNumber,
    firstName,
    lastName,
    phoneNumber,
    email,
    name,
    documentType,
  } = identity || {};

  return (
    <Col margin={0} padding={0}>
      <div>
        {customer === CUSTOMER.TAURON ? name : `${firstName} ${lastName}`}
      </div>
      <div>
        {blurSingleFormatter(
          documentNumber,
          customer === CUSTOMER.DEFAULT &&
            documentType === IDENTITY_DOCUMENT_TYPE.PESEL,
        )}
      </div>
      <div>{phoneNumber}</div>
      <div>{email}</div>
    </Col>
  );
};

export const publishedByFormatter = (value) => {
  if (value) {
    const { firstName, lastName, username } = value;
    return (
      <UserInfo
        scale="small"
        name={`${firstName ? firstName : '---'} ${lastName ? lastName : '---'}`}
      >
        {username}
      </UserInfo>
    );
  }
};
