import React from 'react';

// Components
import ContentContainerGrey from '../../../../ui/ContentContainerGrey';
import { Content } from '../../../../components/Content/Content';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';

const { Text, Card } = Utils;

export const PublicationFormWrapper = ({ pageHeaderId, children }) => {
  return (
    <ContentContainerGrey fluid>
      <Content fluid>
        <Text as="h2" fontWeight={800}>
          <FormattedMessage id={pageHeaderId} defaultMessage={pageHeaderId} />
        </Text>
        <Card>{children}</Card>
      </Content>
    </ContentContainerGrey>
  );
};
