import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Utils, Grid } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

import * as Styled from './styled';

const { Icon } = Utils;
const { Button: ButtonModule } = Utils;
const { Col } = Grid;
const { Button, ButtonLoader } = ButtonModule;

const CustomConfirmModal = ({
  title,
  children,
  isOpen,
  toggle,
  isSubmitting,
  onSubmit,
  onOpened,
  onClosed,
  className,
  backdrop,
  titleUnderline,
}) => (
  <Styled.Modal
    isOpen={isOpen}
    toggle={toggle}
    onOpened={onOpened}
    onClosed={onClosed}
    className={classNames('modal-lg', className)}
    centered
    fade={false}
    backdrop={backdrop}
  >
    <Styled.ModalHeader titleUnderline={titleUnderline}>
      {title && title}
    </Styled.ModalHeader>
    <Styled.CloseIconButton onClick={toggle}>
      <Icon name="times" />
    </Styled.CloseIconButton>
    <Styled.ModalBody>
      <Styled.Description>{children}</Styled.Description>
    </Styled.ModalBody>
    <Styled.ModalFooter>
      <Col md={4}>
        <Button block size="lg" outline onClick={toggle}>
          <FormattedMessage id="Cancel" defaultMessage="Cancel" />
        </Button>
      </Col>
      <Col md={7}>
        {isSubmitting ? (
          <ButtonLoader size="lg" block />
        ) : (
          <Button block size="lg" type="submit" onClick={onSubmit}>
            <FormattedMessage id="Confirm2" defaultMessage="Confirm2" />
          </Button>
        )}
      </Col>
    </Styled.ModalFooter>
  </Styled.Modal>
);

CustomConfirmModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  titleUnderline: PropTypes.bool,
};

CustomConfirmModal.defaultProps = {
  isOpen: false,
  backdrop: 'static',
  titleUnderline: false,
  isSubmitting: false,
};

export default CustomConfirmModal;
