import React from 'react';
import PropTypes from 'prop-types';
import { Utils } from 'billon-ui';

const { BillonLogo } = Utils;

const DMLogo = ({ width, height }) => {
  const styles = {};
  if (width) {
    styles.width = `${width}`;
  }
  if (height) {
    styles.height = `${height}`;
  }

  return <BillonLogo style={styles} />;
};

DMLogo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DMLogo;
