import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { VALIDATION_ERRORS } from '../../../../../../constraints';

export const useSchema = (
  maxRecipientFileSize = 5000,
  areRecipientsObligatory = true,
) => {
  const { formatMessage } = useIntl();

  return yup.object({
    contactDetails: yup
      .mixed()
      .test(
        'file',
        formatMessage({
          id: VALIDATION_ERRORS.REQUIRED,
          defaultMessage: VALIDATION_ERRORS.REQUIRED,
        }),
        (value) =>
          areRecipientsObligatory ? !!value && value.length > 0 : true,
      )
      .test(
        'fileType',
        formatMessage({
          id: VALIDATION_ERRORS.FILE_FORMAT,
          defaultMessage: VALIDATION_ERRORS.FILE_FORMAT,
        }),
        (value) => {
          const splitter = value?.[0]?.path?.split('.');

          if (!splitter || splitter?.length === 0) {
            return true;
          }

          return ['xlsx', 'xls', 'csv'].includes(
            splitter[splitter.length - 1]?.toLowerCase(),
          );
        },
      )
      .test(
        'fileSize',
        formatMessage({
          id: VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE,
          defaultMessage: VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE,
        }),
        (value) => {
          const FILE_SIZE = maxRecipientFileSize * 1024;
          return value?.[0] ? value[0].size <= FILE_SIZE : true;
        },
      ),
  });
};
