import React from 'react';
import { closeWebViewer } from '../../webviewer';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const WebViewer = () => {
  const handleCloseWebViewer = () => {
    closeWebViewer();
  };

  return (
    <>
      <div
        className="webviewer"
        style={{
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
          display: 'none',
        }}
      />
      <Button
        className="webviewer-button-close"
        onClick={handleCloseWebViewer}
        css={{
          zIndex: 1001,
          position: 'absolute',
          bottom: '50px',
          right: '100px',
          display: 'none',
        }}
      >
        Zamknij podgląd
      </Button>
    </>
  )
}
