import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import classNames from 'classnames';
import { Table } from 'billon-ui';

import * as Styled from './styled';

const { TableCell } = Table;

class TableColumn extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    const { onSort, fieldName, sortable, asc } = this.props;

    if (sortable) {
      e.preventDefault();
      if (isFunction(onSort)) {
        onSort(fieldName, !asc);
      }
    }
  }

  render() {
    const { sortable, className, asc, children, sortActive } = this.props;

    let content = children;

    if (sortable) {
      content = (
        <Styled.SortableColumn>
          {children}
          <Styled.SortActions>
            {asc && (
              <Styled.MyIcon
                name="chevron-up"
                active={sortActive && asc}
                onClick={this.toggle}
              />
            )}

            {!asc && (
              <Styled.MyIcon
                name="chevron-down"
                active={sortActive && !asc}
                onClick={this.toggle}
              />
            )}
          </Styled.SortActions>
        </Styled.SortableColumn>
      );
    }

    return (
      <Styled.TH
        className={classNames({
          [className]: !!className,
          sortable,
        })}
        onClick={this.toggle}
      >
        {content}
      </Styled.TH>
    );
  }
}

TableColumn.propTypes = {
  children: PropTypes.any,
  fieldName: PropTypes.string.isRequired,
  sort: PropTypes.func,
  onSort: PropTypes.func,
  sortable: PropTypes.bool,
  className: PropTypes.string,
  asc: PropTypes.bool,
  sortActive: PropTypes.bool,
  renderCell: PropTypes.func,
};

TableColumn.defaultProps = {
  sortable: false,
  className: '',
  asc: true,
  renderCell: (row, props) => <TableCell row={row} {...props} />,
};

export default TableColumn;
