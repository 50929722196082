import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomTooltip from 'js/ui/CustomToolTip';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Icon } = Utils;

const ConsumptionCardHeader = () => {
  return (
    <Styled.CardTitle>
      <FormattedMessage id="Fair Usage" defaultMessage="Fair Usage" />
      {'  '}
      <CustomTooltip
        id="CustomToolTip-fair-usage-1"
        trigger={<Icon name="info-circle" color="grey" />}
      >
        <FormattedMessage
          id="fair usage definition"
          defaultMessage="fair usage definition"
        />
      </CustomTooltip>
    </Styled.CardTitle>
  );
};

export default ConsumptionCardHeader;
