import React, { useLayoutEffect, useState } from 'react';
import BarToolTip from '../BarToolTip';
import base from '../../../../../../../../config/base';
import {
  Bar,
  BarChart,
  Cell,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from 'recharts';

const ConsumptionBarChart = ({ fairUsageData }) => {
  const [size, setSize] = useState([0, 0]);
  const { language } = base;

  const useWindowSize = () => {
    useLayoutEffect(() => {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };

  const formatTicks = (v) => {
    if (v === 'TO-DATE' && language === 'pl') {
      return 'Aktualne';
    } else {
      return v;
    }
  };

  const [width] = useWindowSize();

  return (
    <div
      style={{
        width: width > 1000 ? '98%' : '1700px',
        height: 300,
      }}
    >
      <ResponsiveContainer>
        <BarChart width={1700} height={250} data={fairUsageData}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            tickFormatter={(v) => formatTicks(v)}
          />
          <YAxis padding={{ top: 30 }} />
          <Tooltip content={BarToolTip} />
          <Bar dataKey="memoryUsagePercent" fill="#990054">
            {fairUsageData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  index === fairUsageData.length - 1 ? '#ff9200' : '#990054'
                }
              />
            ))}
            <LabelList
              dataKey="memoryUsagePercent"
              formatter={(v) => parseFloat(v).toFixed(1) + '%'}
              position="top"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ConsumptionBarChart;
