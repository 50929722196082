import { useIntl } from 'react-intl';

import {
  NOTIFICATION_CONTENT_TAGS,
  NOTIFICATION_PREVIEW_TYPES,
} from '../../../constraints';

export const useConfigurableTag = () => {
  const { formatMessage } = useIntl();

  const configurableSmsPartMessage = `{{${formatMessage({
    id: 'Configurable part of the notification',
    defaultMessage: 'Configurable part of the notification',
  })}}}`;

  const configurableEmailPartMessage = `<span style='color:grey !important;'>${configurableSmsPartMessage}</span>`;

  const configurableTag = NOTIFICATION_CONTENT_TAGS.USER_INPUT;

  const getPreviewValue = (previewValue, inputValue, previewType) => {
    if (!inputValue) {
      if (previewType === NOTIFICATION_PREVIEW_TYPES.EMAIL) {
        return previewValue?.replace(
          configurableTag,
          configurableEmailPartMessage,
        );
      }
      if (previewType === NOTIFICATION_PREVIEW_TYPES.SUBJECT) {
        return previewValue?.replace(
          configurableTag,
          configurableSmsPartMessage,
        );
      }
      if (previewType === NOTIFICATION_PREVIEW_TYPES.SMS) {
        return previewValue?.replace(
          configurableTag,
          configurableSmsPartMessage,
        );
      }
    }
    return previewValue;
  };

  return {
    configurableTag,
    configurableSmsPartMessage,
    configurableEmailPartMessage,
    getPreviewValue,
  };
};
