import PropTypes from 'prop-types';
import React from 'react';

import { CustomBackground, DMLogo } from '../../components';
import { getUploadUrl } from 'js/utils';

const Brand = ({ logoUrl }) =>
  logoUrl ? (
    <CustomBackground
      background={getUploadUrl(logoUrl)}
      className="custom-logo"
    />
  ) : (
    <DMLogo />
  );

Brand.propTypes = {
  logoUrl: PropTypes.string,
};

export default Brand;
