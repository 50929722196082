import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { NOTIFICATION_PREVIEW_TYPES } from '../../../../../../constraints';

// hooks
import { useCompileNotificationMessages } from '../../../../../Documents/hooks/useCompileNotificationMessages';
import { useConfigurableTag } from '../../../../hooks/useConfigurableTag';

// Components
import * as Styled from './styled';
import { RenderedNotificationContainer } from '../../../../../Documents/components/RenderedNotificationContainer/RenderedNotificationContainer';
import { RenderedSMS } from '../../../../../Documents/components/RenderedNotificationContainer/RenderedNotificationContainer';
import { Utils, Layout } from 'billon-ui';

const { Content } = Layout;
const { ErrorContent } = Content;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const TemplatePreview = ({
  templateType,
  tenantId,
  tenantName,
  previewType,
  template,
  templateSubject,
  isMinusMarginTop,
  activeTenantId,
  emailTitleEnabled,
}) => {
  const {
    emailPreviewValue,
    smsContentPreviewValue,
    emailSubjectPreviewValue,
    isLoading,
    error,
  } = useCompileNotificationMessages({
    emailContentInput: template,
    smsContentInput: template,
    documentType: templateType,
    tenantId,
    emailSubjectInput: templateSubject,
  });

  const { getPreviewValue } = useConfigurableTag();

  if (isLoading) {
    return (
      <div>
        <PageLoader
          message={
            <FormattedMessage
              id="Templates are loading..."
              defaultMessage="Templates are loading..."
            />
          }
        />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ErrorContent>
          <FormattedMessage
            id="Error while loading templates..."
            defaultMessage="Error while loading templates..."
          />
        </ErrorContent>
      </div>
    );
  }

  if (activeTenantId !== tenantId) {
    return null;
  }

  if (previewType === NOTIFICATION_PREVIEW_TYPES.SMS) {
    return (
      <>
        <Styled.TenantName>{tenantName}</Styled.TenantName>
        <RenderedNotificationContainer isFullHeight customMarginTop="23px">
          {getPreviewValue(smsContentPreviewValue, template, previewType)}
        </RenderedNotificationContainer>
      </>
    );
  }

  return (
    <>
      <Styled.TenantName isMinusMarginTop={isMinusMarginTop}>
        {tenantName}
      </Styled.TenantName>
      {emailTitleEnabled && (
        <Styled.EmailSubject>
          <Styled.EmailSubjectTag>
            <FormattedMessage id="Subject" defaultMessage="Subject" />
          </Styled.EmailSubjectTag>
          {`: ${getPreviewValue(
            emailSubjectPreviewValue,
            templateSubject,
            NOTIFICATION_PREVIEW_TYPES.SUBJECT,
          )}`}
        </Styled.EmailSubject>
      )}
      <RenderedNotificationContainer
        isFullHeight
        customMarginTop={!emailTitleEnabled && '23px'}
        dangerouslySetInnerHTML={{
          __html: getPreviewValue(emailPreviewValue, template, previewType),
        }}
      />
    </>
  );
};

export default TemplatePreview;
