import React, { useState } from 'react';
import { NotificationHistoryModal } from '../../components/NotificationHistory/components';
import NotificationHistory from '../../components/NotificationHistory';

import { useHistory, useRouteMatch } from 'react-router-dom';
import appRoutes from '../../../../app-routes';
import { useSingleDocument } from '../../hooks/useSingleDocument';

const NotificationHistoryPage = () => {
  const history = useHistory();

  const [notificationHistoryModalOpened, setNotificationHistoryModalOpened] =
    useState(true);

  const { params } = useRouteMatch(
    appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY,
  );

  const handleNotificationHistoryModalClose = () => {
    setNotificationHistoryModalOpened(false);
    history.push(`/document/${params?.id}`);
  };

  const { data: document, isLoading } = useSingleDocument({ id: params?.id });

  if (isLoading) {
    return null;
  }

  return (
    <NotificationHistoryModal
      isOpen={notificationHistoryModalOpened}
      toggle={handleNotificationHistoryModalClose}
      onClose={handleNotificationHistoryModalClose}
      closeIconEnabled
    >
      <NotificationHistory
        jobId={params?.id}
        documentBlockchainAddress={document.documentBlockchainAddress}
      />
    </NotificationHistoryModal>
  );
};

export default NotificationHistoryPage;
