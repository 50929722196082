import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useCategoriesOptions } from '../../../../../../hooks/useCategoriesOptions';

// Components
import { SelectField as AsyncSelectField } from '../../../../../../components';
import { FieldLabel } from '../../../../../../components/Form';

const SelectCategoryField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.CATEGORY,
  categoryId,
  onClear,
  isClearable,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { categories, isCategoriesFetching, onChangeCategoryInput } =
    useCategoriesOptions(false, true);

  useEffect(() => {
    if (!isCategoriesFetching && categoryId) {
      setValue(
        name,
        categories.find((c) => c.value === categoryId),
      );
    }
  }, [isCategoriesFetching, categoryId, setValue, categories, name]);

  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Category" defaultMessage="Category" />
      </FieldLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <AsyncSelectField
            input={{
              ...field,
              onInputChange: (inputText) => onChangeCategoryInput(inputText),
              onClear: () => onClear(DOCUMENT_FILTER_FIELD_NAMES.CATEGORY_ID),
            }}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            options={categories}
            isClearable={isClearable}
            isLoading={isCategoriesFetching}
          />
        )}
      />
    </>
  );
};

export default SelectCategoryField;
