import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Warning = ({ warning }) => (
  <label className="warning">
    <FormattedMessage id={warning} defaultMessage={warning} />
  </label>
);

Warning.propTypes = {
  warning: PropTypes.string.isRequired,
};

export default Warning;
