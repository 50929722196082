import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

// hooks
import useFilters from '../../../../../../hooks/useFilters';
import { usePublishDocument } from '../../../../hooks';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';

// Helpers
import { parsePublishRequestData } from '../../../../helpers';

// constants
import { QueryKeys } from '../../../../../../queryKeys';
import {
  DOCUMENT_TYPE,
  mapERRORS,
  TAURON_VERIFICATION_CODE_STATUSES,
  mapDOCUMENT_ACTIONS_ERROR,
  RECIPIENT_ACTIONS,
  MAP_CUSTOM_TAURON_ERRORS,
  UNKNOWN_ERRORS,
  SETTINGS_CONSTANTS,
} from '../../../../../../constraints';
import {
  RECIPIENT_VALIDATION_PURPOSE,
  RECIPIENT_ERROR_CODES,
} from '../../../../../../constants';

// Components
import { DocumentAtrributesForm } from '../DocumentAtrributesForm';
import { ErrorModal } from '../../../../../../components/ErrorModal';
import { ExportableErrorContent } from '../../../../../../components/ExportableErrorContent';
import { NotificationContentForm } from '../NotificationContentForm/NotificationContentForm';
import { RecipientsFileForm } from '../RecipientsFileForm';
import { DocumentPublicationSummary } from '../../../DocumentPublicationSummary';
import { PublicatorForm } from '../PublicatorForm';
import FileErrorSummary from '../RecipientsFileForm/components/FileErrorSummary';
import * as Styled from './styled';
import { Modal as ModalModule } from 'billon-ui';

const { SuccessModal } = ModalModule;

export const OneStepPublication = ({
  onCreateIdentity,
  onCreateCategory,
  publicationMode,
  initialStep,
  areTenantsLoading,
  tenants,
}) => {
  const history = useHistory();

  const { getSettingValueByKey } = useSettingsContext();
  const publicationDelayMinutes = getSettingValueByKey(
    SETTINGS_CONSTANTS.PUBLICATION_DELAY_MINUTES,
  );
  const { isTauron } = useConfigContext();
  const queryClient = useQueryClient();

  const [step, setStep] = useState(initialStep);
  const [formData, setFormData] = useState({});
  const [documentTitle, setDocumentTitle] = useState(null);
  const [isSuccessModalOpened, setSuccessModalOpened] = useState(false);
  const toggleSuccessModal = () => {
    setSuccessModalOpened(!isSuccessModalOpened);
  };
  const { filters, navigateWithNewSearchQuery } = useFilters();
  const documentType = filters.documentTypeList || DOCUMENT_TYPE.PRIVATE;
  const isPrivateDocument = documentType === DOCUMENT_TYPE.PRIVATE;

  const mapErrorBase = isTauron
    ? {
        ...mapERRORS,
        ...TAURON_VERIFICATION_CODE_STATUSES,
        ...MAP_CUSTOM_TAURON_ERRORS,
      }
    : mapERRORS;

  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const handleSubmitStep = (stepData, isStepObligatory = true) => {
    if (isStepObligatory) {
      handleAppendFormData(stepData);
    }

    if (isPrivateDocument && step === 0) {
      setStep(1);
    } else if (isPrivateDocument && step === 1) {
      setStep(3);
    } else if (
      !isPrivateDocument &&
      step === 2 &&
      stepData?.contactDetails?.length === 0
    ) {
      handleAppendFormData({ contactDetails: [] });
      setStep(4);
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = (stepData) => {
    if (step === initialStep) {
      handleReturnDocList();
    } else if (isPrivateDocument && step === 3) {
      setStep(1);
    } else if (
      !isPrivateDocument &&
      step === 4 &&
      (!formData?.contactDetails || formData?.contactDetails?.length === 0)
    ) {
      setStep(2);
    } else {
      if (stepData && step === 1) {
        handleAppendFormData(stepData);
      }
      setStep(step - 1);
    }
  };

  const handleResetRecipientForm = () => {
    reset();
    setStep(2);
  };

  const {
    mutate: publishDocument,
    reset,
    error,
    isLoading,
    isSuccess,
  } = usePublishDocument(documentType, {
    onSuccess: (response) => {
      const { jobId } = response;
      if (publicationDelayMinutes === 0) {
        toggleSuccessModal();
        queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]);
      } else {
        navigateWithNewSearchQuery(
          { filters: { documentTypeList: documentType } },
          `/documents/${jobId}`,
        );
      }
    },
  });

  const handlePublishDocument = (values) => {
    setDocumentTitle(values.title);

    publishDocument({
      data: parsePublishRequestData(
        values,
        publicationMode,
        document?.documentBlockchainAddress,
      ),
    });
  };

  const handleCloseSuccessModal = () => {
    navigateWithNewSearchQuery(
      { filters: { documentTypeList: documentType } },
      '/documents',
    );
  };

  const handleReturnDocList = () => {
    history.goBack();
  };

  if (!isLoading && !isSuccess && error) {
    const statusCode =
      error?.response?.data?.statusCode ||
      error?.statusCode ||
      UNKNOWN_ERRORS.UNKNOWN_PUBLISHING_ERROR;

    if (
      !isPrivateDocument &&
      statusCode !== UNKNOWN_ERRORS.INTERNAL_SERVER_ERROR &&
      RECIPIENT_ERROR_CODES[statusCode]
    ) {
      return (
        <>
          <br />
          <FileErrorSummary
            mode={RECIPIENT_ACTIONS.EXTERNAL_VALIDATION}
            errorData={error.response.data}
            handleResetMutation={handleResetRecipientForm}
            handleExit={handleCloseSuccessModal}
            cancelMessageId="Cancel publication"
            validationPurpose={RECIPIENT_VALIDATION_PURPOSE.PUBLISH}
          />
        </>
      );
    } else {
      const chosenStatusCode =
        statusCode === UNKNOWN_ERRORS.INTERNAL_SERVER_ERROR
          ? UNKNOWN_ERRORS.UNKNOWN_PUBLISHING_ERROR
          : statusCode;
      const deliveredLabel =
        mapErrorBase[chosenStatusCode] || mapErrorBase.UNKNOWN_PUBLISHING_ERROR;

      return (
        <ErrorModal
          isOpen
          toggle={handleCloseSuccessModal}
          onClose={handleCloseSuccessModal}
          closeIconEnabled={false}
        >
          <ExportableErrorContent
            headerTitleId={mapDOCUMENT_ACTIONS_ERROR.PUBLISH}
            statusCode={statusCode}
            jobId={document.jobId}
            title={documentTitle}
            deliveredLabel={deliveredLabel}
          />
        </ErrorModal>
      );
    }
  }

  return (
    <>
      {step === 0 && (
        <>
          <Styled.BackButton onClick={handleBack}>
            <FormattedMessage id="back" defaultMessage="back" />
          </Styled.BackButton>
          <PublicatorForm
            defaultValues={formData}
            onSubmit={handleSubmitStep}
            submitButtonText="Next"
            isLoading={isLoading}
            tenants={tenants}
            areTenantsLoading={areTenantsLoading}
          />
        </>
      )}
      {step === 1 && (
        <>
          {initialStep === 1 && (
            <Styled.BackButton onClick={handleBack}>
              <FormattedMessage id="back" defaultMessage="back" />
            </Styled.BackButton>
          )}
          <DocumentAtrributesForm
            step={step}
            document={formData}
            publicationMode={publicationMode}
            onSubmit={handleSubmitStep}
            onCreateIdentity={onCreateIdentity}
            onCreateCategory={onCreateCategory}
            submitButtonText="Next"
            isLoading={isLoading}
            onBack={initialStep === 0 && handleBack}
          />
        </>
      )}
      {step === 2 && (
        <RecipientsFileForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
          validationPurpose={RECIPIENT_VALIDATION_PURPOSE.PUBLISH}
          mode={RECIPIENT_ACTIONS.INSERT_LIST}
          handleCancelProcess={handleCloseSuccessModal}
        />
      )}
      {step === 3 && (
        <NotificationContentForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
          documentType={documentType}
        />
      )}
      {step === 4 && (
        <DocumentPublicationSummary
          onSubmit={handlePublishDocument}
          onBack={handleBack}
          isLoading={isLoading}
          defaultValues={formData}
          documentType={documentType}
          publicationMode={publicationMode}
        />
      )}
      {isSuccess && (
        <>
          <SuccessModal
            isOpen={isSuccessModalOpened}
            onClose={handleCloseSuccessModal}
          >
            <FormattedMessage
              id="Document publication has been initiated."
              defaultMessage="Document publication has been initiated."
            />
          </SuccessModal>
        </>
      )}
    </>
  );
};
