import download from 'downloadjs';

import apiRoutes from 'js/api-routes';
import { initializeWebViewerFromBlob } from 'js/webviewer';
import { getRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

export const useDownloadDailyReport = (id, documentTitle, isPreview = false) =>
  useMutationWithLogout(
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.SINGLE_DOCUMENT_DOWNLOAD.replace('{id}', id),
        responseType: 'arraybuffer',
      });
      return data;
    },
    {
      onSuccess: (response) => {
        const blob = new Uint8Array(response);

        if (isPreview) {
          initializeWebViewerFromBlob(blob);
          return;
        }

        download(blob, documentTitle + '.pdf');
      },
    },
  );
