import React from 'react';
import { Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const { Card, Button: ButtonModule } = Utils;
const { BackButton } = ButtonModule;

const HelpCard = ({ supportEmail, onBack }) => {
  return (
    <Card>
      <Styled.Title>
        <FormattedMessage
          id="Have a question?"
          defaultMessage="Have a question?"
        />
      </Styled.Title>
      <Styled.Description>
        <FormattedMessage
          id="Write to us and we will help you find a solution."
          defaultMessage="Write to us and we will help you find a solution."
        />
      </Styled.Description>
      <Styled.LinkWrapper>
        <a
          href={`mailto:${supportEmail}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {supportEmail}
        </a>
      </Styled.LinkWrapper>

      <div>
        <BackButton secondary onClick={onBack}>
          <FormattedMessage id="back" defaultMessage="back" />
        </BackButton>
      </div>
    </Card>
  );
};

export default HelpCard;
