import base from '../../base';
import { CUSTOMER } from '../../../constraints';

const config = {
  ...base,
  backendUrl: 'https://apidiplomapublisher.bc.sygnity.pl',
  enablePrivate: true,
  enableSigning: false,
  customer: CUSTOMER.DIPLOMA,
};

export default config;
