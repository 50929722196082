import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSchema } from './useSchema';
import { useDownloadSingleDocument } from '../../../../hooks';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';
import { SETTINGS_CONSTANTS } from '../../../../../../constraints';

// components
import { FormattedMessage } from 'react-intl';
import { StretchedDropzonePlaceholder } from '../../../../../../components/DropzonePlaceholder/DropzonePlaceholder';
import { ControlledUploadField } from '../../../../../../components/ControlledUploadField/ControlledUploadField';
import * as Styled from './styled';
import { Grid, Utils } from 'billon-ui';

const { Button: ButtonModule, Text } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

export const DocumentSigningForm = ({
  onSuccess,
  documentTitle,
  jobId,
  isLoading,
  onExit,
}) => {
  const { getSettingValueByKey } = useSettingsContext();

  const { mutate: downloadFile, isLoading: isDownloadingFile } =
    useDownloadSingleDocument(jobId, documentTitle);

  const maxFileSize = getSettingValueByKey(
    SETTINGS_CONSTANTS.MAX_PDF_FILE_SIZE,
  );

  const schema = useSchema({ maxFileSize });

  const formMethods = useForm({
    defaultValues: {
      file: '',
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit((values) => onSuccess(values))}>
        <Text as="h2" fontSize="32px">
          <FormattedMessage id="Sign document" defaultMessage="Sign document" />
        </Text>
        <Text as="p" fontSize="15px" fontWeight={700}>
          <FormattedMessage
            id="We have prepared a document for you, download it, sign it and then add it here for publishing"
            defaultMessage="We have prepared a document for you, download it, sign it and then add it here for publishing"
          />
        </Text>
        <br />

        <Row css={{ width: '100%' }} margin={'0'}>
          <Col xl={6} margin={'0 0 20px 0'}>
            <Text as="h4" fontSize="16px" fontWeight={700}>
              1.{' '}
              <FormattedMessage
                id="Download document to sign"
                defaultMessage="Download document to sign"
              />
            </Text>
            <StretchedDropzonePlaceholder>
              <Text>{documentTitle}</Text>

              <Styled.CenterRow>
                <Col sm={6}>
                  {isDownloadingFile ? (
                    <ButtonLoader size="lg" block />
                  ) : (
                    <Button onClick={downloadFile} outline type="button" block>
                      <FormattedMessage
                        id="Download"
                        defaultMessage="Download"
                      />
                    </Button>
                  )}
                </Col>
              </Styled.CenterRow>
            </StretchedDropzonePlaceholder>
          </Col>
          <Col xl={6} margin={'0 0 20px 0'}>
            <Text as="h4" fontSize="16px" fontWeight={700}>
              2.{' '}
              <FormattedMessage
                id="Add signed document"
                defaultMessage="Add signed document"
              />
            </Text>

            <ControlledUploadField name="file" maxSize={maxFileSize} />
          </Col>
        </Row>

        <Styled.SubmitButtonRow>
          <Col xl={3} padding="0 16px 16px 16px">
            <Button type="button" size="lg" block outline onClick={onExit}>
              <FormattedMessage id="Close" defaultMessage="Close" />
            </Button>
          </Col>
          <Col xl={3} padding="0 16px 16px 16px">
            {!isLoading ? (
              <Button type="submit" size="lg" block>
                <FormattedMessage
                  id="Publish document"
                  defaultMessage="Publish document"
                />
              </Button>
            ) : (
              <ButtonLoader size="lg" block />
            )}
          </Col>
        </Styled.SubmitButtonRow>
      </Styled.Form>
    </FormProvider>
  );
};
