import React from 'react';

// components
import { Layout as LayoutModule } from 'billon-ui';
import { RecipientTable } from '../../components';

const { Content: ContentModule } = LayoutModule;
const { ContentContainer } = ContentModule;

const HomePage = () => {
  return (
    <ContentContainer fluid>
      <RecipientTable />
    </ContentContainer>
  );
};

export default HomePage;
