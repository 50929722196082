import React from 'react';
import { PageLayout } from '../../components';
import Analytics from '../../components/Analytics';

const AnalyticsPage = () => {
  return (
    <PageLayout>
      <Analytics />
    </PageLayout>
  );
};

export default AnalyticsPage;
