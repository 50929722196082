import { useIntl } from 'react-intl';
import {
  DOCUMENT_STATUS,
  MAP_DOCUMENT_STATUSES,
} from '../../../../../constraints';

export const useReportStatuses = () => {
  const { formatMessage } = useIntl();

  const statusOptions = [
    DOCUMENT_STATUS.CURRENT,
    DOCUMENT_STATUS.DEPRECATED,
    DOCUMENT_STATUS.NOT_EXIST,
    DOCUMENT_STATUS.PREPARED_TO_SIGN,
    DOCUMENT_STATUS.UPLOADING,
  ].map((key) => {
    const msgId = MAP_DOCUMENT_STATUSES[key];
    return {
      label: formatMessage({
        id: msgId,
        defaultMessage: msgId,
      }),
      value: key,
    };
  });

  return { statusOptions };
};
