import { parseDateRange } from '../../../helpers/parseDateRange';
import { parseTextField } from './formFieldParsers/parseTextField';
import { parseGdprSuspension } from './formFieldParsers/parseGdprSuspension';
import { parseStatus } from './formFieldParsers/parseStatus';
import { parseDocumenType } from './formFieldParsers/parseDocumenType';

export const parseFilters = ({
  name,
  firstName,
  lastName,
  documentNumber,
  documentType,
  id,
  email,
  phoneNumber,
  createdBy,
  createdAtDateRange,
  status,
  isGdprSuspended,
}) => ({
  name: parseTextField(name),
  firstName: parseTextField(firstName),
  lastName: parseTextField(lastName),
  documentType: parseDocumenType(documentType),
  documentNumber: parseTextField(documentNumber),
  id: parseTextField(id),
  email: parseTextField(email),
  phoneNumber: parseTextField(phoneNumber),
  createdBy,
  createdAtDateRange: parseDateRange(createdAtDateRange),
  status: parseStatus(status),
  isGdprSuspended: parseGdprSuspension(status, isGdprSuspended),
});
