import React from 'react';
// Components
import { DocumentTable, DeleteDocumentModal } from '../../components';
import ContentContainerGrey from '../../../../ui/ContentContainerGrey';

const HomePage = () => {
  const [initialValues, setInitialValues] = React.useState({});

  const [isPublishDocumentModalOpen, setPublishDocumentModalOpen] =
    React.useState(false);

  const [isDeleteDocumentModalOpen, setDeleteDocumentModalOpen] =
    React.useState(false);

  const onOpenPublishDocumentModal = (initialValues) => {
    setInitialValues(initialValues);
    togglePublishDocumentModal();
  };

  const togglePublishDocumentModal = () => {
    setPublishDocumentModalOpen(!isPublishDocumentModalOpen);
  };

  const onOpenDeleteDocumentModal = (initialValues) => {
    toggleConfirmDeleteDocumentsModal();
    setInitialValues(initialValues);
  };

  const toggleConfirmDeleteDocumentsModal = () => {
    setDeleteDocumentModalOpen(!isDeleteDocumentModalOpen);
  };

  return (
    <ContentContainerGrey fluid>
      <DocumentTable
        handleCreate={onOpenPublishDocumentModal}
        handleDelete={onOpenDeleteDocumentModal}
      />
      <DeleteDocumentModal
        isOpen={isDeleteDocumentModalOpen}
        toggle={toggleConfirmDeleteDocumentsModal}
        document={initialValues}
      />
    </ContentContainerGrey>
  );
};

export default HomePage;
