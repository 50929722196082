import { QueryKeys } from '../../../../queryKeys';
import { getRequest } from '../../../../helpers/apiClient';
import apiRoutes from '../../../../api-routes';
import { useQueryWithLogout } from '../../../../hooks/useQueryWithLogout';

export const usePublicNotificationTemplateGroups = (params, options) => {
  return useQueryWithLogout(
    [QueryKeys.PUBLIC_NOTIFICATION_TEMPLATES, params],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.PUBLIC_NOTIFICATION_TEMPLATES,
        params,
      });

      return data;
    },
    options,
  );
};
