import styled from 'styled-components';

export const Green = styled.span`
  color: ${(props) => props.theme.palette.billonGreen};
`;

export const Red = styled.span`
  color: ${(props) => props.theme.palette.error};
`;

export const Orange = styled.span`
  color: ${(props) => props.theme.palette.billonOrange};
`;
