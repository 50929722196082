import {
  DOCUMENT_TYPE,
  SIGNING_ORDER,
  NOTIFICATION_TEMPLATE_TYPE,
} from '../../../constraints';

export const getNotificationTemplateType = (
  documentType,
  enableSigning,
  signingOrder,
) => {
  if (documentType === DOCUMENT_TYPE.PUBLIC) {
    return NOTIFICATION_TEMPLATE_TYPE.PUBLIC_PUBLISHED;
  }
  if (!enableSigning || signingOrder === SIGNING_ORDER.NO_SIGNATURE) {
    return NOTIFICATION_TEMPLATE_TYPE.PRIVATE_PUBLISHED;
  }
  if (
    [SIGNING_ORDER.PUBLISHER_ONLY, SIGNING_ORDER.NO_SIGNATURE].includes(
      signingOrder,
    )
  ) {
    return NOTIFICATION_TEMPLATE_TYPE.PRIVATE_PUBLISHED;
  }
  if (
    [SIGNING_ORDER.RECEIVER_ONLY, SIGNING_ORDER.SENDER_FIRST].includes(
      signingOrder,
    )
  ) {
    return NOTIFICATION_TEMPLATE_TYPE.AGREEMENT_READY_TO_SIGN;
  }
};
