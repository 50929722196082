export const parseUpdateRequestData = ({
  title,
  identity,
  validSince,
  validUntil,
  category,
  tenant,
  versionName,
  publicationMode,
  previousDocumentBlockchainAddress,
  retainUntil,
  publicDocumentType,
  emailSubjectInput,
  emailContentInput,
  smsContentInput,
  ...rest
}) => ({
  publicationMode,
  previousDocumentBlockchainAddress,
  title,
  identity,
  validSince,
  validUntil,
  category,
  tenant,
  versionName,
  retainUntil,
  publicDocumentType,
  emailSubjectInput,
  emailContentInput,
  smsContentInput,
});
