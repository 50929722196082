import React, { useState, useMemo, useEffect } from 'react';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Icon } = Utils;

export const Accordion = ({
  isNested,
  header,
  isTriggered,
  isFormSubmitted,
  children,
}) => {
  const [open, setOpen] = useState(isNested);

  const toggle = () => {
    setOpen(!open);
  };

  const iconName = useMemo(() => (open ? 'angle-up' : 'angle-down'), [open]);

  useEffect(() => {
    if (isTriggered) {
      setOpen(true);
    }
  }, [isTriggered, isFormSubmitted]);

  return (
    <>
      {!isNested && header && (
        <Styled.HeaderWrapper onClick={toggle} open={open}>
          {header} <Icon name={iconName} />
        </Styled.HeaderWrapper>
      )}
      {open && <Styled.ContentWrapper>{children}</Styled.ContentWrapper>}
    </>
  );
};

export default Accordion;
