import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormProvider, useFormContext } from 'react-hook-form';

// constants
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../../../../../constraints';

// Components
import { ControlledTextField } from '../../../../../../../../components/ControlledTextField';
import { Form as FormModule, Utils } from 'billon-ui';
const { Text } = Utils;
const { FormGroup } = FormModule;

const TitleContent = ({ name = 'titleContentInput', disabled = true }) => {
  const formMethods = useFormContext();

  return (
    <FormProvider {...formMethods}>
      <FormGroup>
        <Text as="h6" fontSize="12px" fontWeight="600">
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CONTENT_SUBJECT}
            defaultMessage={
              NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CONTENT_SUBJECT
            }
          />
        </Text>
        <ControlledTextField name={name} maxLength={null} disabled={disabled} />
      </FormGroup>
    </FormProvider>
  );
};

export default TitleContent;
