import React from 'react';
import { useIntl } from 'react-intl';
import * as Styled from './styled';
import { useSettingsContext } from '../../modules/Settings/hooks/useSettings';
import { SETTINGS_CONSTANTS } from '../../constraints';

const SourceSystemsExporter = ({ onClick, children }) => {
  const { formatMessage } = useIntl();

  const { getSettingValueByKey } = useSettingsContext();

  const fileName = `${formatMessage({ id: 'SourceSystems' })}.csv`;

  const sourceSystems = getSettingValueByKey(
    SETTINGS_CONSTANTS.RECIPIENT_SOURCE_SYSTEM_NAMES,
  );

  let sourceSystemsArray = sourceSystems?.split(',');
  let sourceSystemsRows =
    sourceSystemsArray?.map((value, index) => [index + 1, value]) || [];

  let rows = [
    ['#', formatMessage({ id: 'Source systems available' })],
    ...sourceSystemsRows,
  ];

  return (
    <Styled.CustomCSVLink data={rows} filename={fileName} onClick={onClick}>
      {children}
    </Styled.CustomCSVLink>
  );
};

export default SourceSystemsExporter;
