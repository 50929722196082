import React from 'react';
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';

// Utils
import {
  momentFormatter,
  boundLengthFormatterLong,
  momentFormatterWithoutTime,
} from '../../../../../../formatters';
import { chooseDocumentFormatter } from '../../formatters/chooseDocumentFormatter';
import { forgettingStatusFormatter } from '../../formatters/forgettingStatusFormatter';
import {
  documentListIdentityFormatter,
  documentListIdentityNameFormatter,
} from '../../../../../Documents/formatters/documentListIdentityFormatter';
import {
  DOCUMENT_ORDER_BY,
  ORDER_METHOD,
  RECIPIENT_STATUSES,
} from '../../../../../../constraints';

import appRoutes from '../../../../../../app-routes';
import { QueryKeys } from '../../../../../../queryKeys';

// Hooks
import { useDocumentList } from '../../../../../Documents/hooks';
import useFilters from '../../../../../../hooks/useFilters';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRefresh } from '../../../../../../hooks/useRefresh';

// Components
import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';
import { PreviewDownloadButton } from '../../../../../Documents/components/PreviewDownloadButton';
import HeaderWithToolTip from '../../../../../../components/HeaderWithToolTip';
import * as Styled from './styled';
import RecipientNotFound from '../../../RecipientNotFound';
import { RefreshButton } from '../../../../../../components/RefreshButton/RefreshButton';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination } = Utils;
const { PageHeader } = LayoutModule;
const { Loader } = LoaderModule;

const RecipientDocuments = ({
  header,
  onAppendDocuments,
  unRegisterDocuments,
  children,
  recipient,
  defaultFilters,
}) => {
  const { isDiploma, isTauron, enableSigning } = useConfigContext();
  const { formatMessage } = useIntl();

  const { filters, navigateWithSearchQuery } = useFilters();
  const history = useHistory();

  const fullFilters = merge({}, defaultFilters, filters);

  const { data, isFetching: isDocumentDataFetching } =
    useDocumentList(fullFilters);

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const onSort = (column, isDescending) => {
    navigateWithSearchQuery({
      sort: {
        sortBy: DOCUMENT_ORDER_BY[column],
        order: isDescending ? ORDER_METHOD.ASC : ORDER_METHOD.DESC,
      },
    });
  };

  const isAscendingOrder = (column) => {
    if (filters?.sort?.sortBy === column) {
      return filters?.sort?.order === ORDER_METHOD.ASC;
    } else {
      return true;
    }
  };

  const suspensionTitle = formatMessage({ id: 'Execute GDPR' });

  const backBtnMessageId = isDiploma ? 'Students' : 'Recipients';
  const backButtonLabel = formatMessage({ id: backBtnMessageId });

  const isNoDocRecipientRedirected = false; // false - no success alert is displayed when recipient should've been deleted

  const { isRefreshDisabled, handleRefresh } = useRefresh(
    QueryKeys.DOCUMENT_LIST,
  );

  const handleOnBack = () => {
    history.push(appRoutes.GDPR);
  };

  if (
    isNoDocRecipientRedirected &&
    data?.count === 0 &&
    recipient.status !== RECIPIENT_STATUSES.SHELL
  ) {
    return (
      <RecipientNotFound
        onBackClick={handleOnBack}
        backButtonLabel={backButtonLabel}
      />
    );
  }

  return (
    <>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={children}
          >
            <HeaderWithToolTip
              title={suspensionTitle}
              tooltipId="execute-gdpr-header"
            >
              <FormattedMessage
                id="Execute GDPR definition"
                defaultMessage="Execute GDPR definition"
              />
            </HeaderWithToolTip>
            <RefreshButton
              isRefreshDisabled={isRefreshDisabled}
              handleRefresh={handleRefresh}
            />
          </PageHeader>
        )}
      </FormattedMessage>

      <br />
      <Styled.TableCard>
        {isDocumentDataFetching ? (
          <Loader width={36} />
        ) : (
          <>
            <Table tableData={data.rows} sortMethod={onSort} responsive>
              <TableColumn
                fieldName="publicationDate"
                formatter={momentFormatter}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.publicationDate)}
              >
                <FormattedMessage
                  id="Publication date"
                  defaultMessage="Publication date"
                />
              </TableColumn>
              <TableColumn
                fieldName="title"
                sortable
                formatter={boundLengthFormatterLong}
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.title)}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma number' : 'Title'}
                  defaultMessage={isDiploma ? 'Diploma number' : 'Title'}
                />
              </TableColumn>
              <TableColumn
                fieldName="validSince"
                formatter={momentFormatterWithoutTime}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.validSince)}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma issuance date' : 'Valid since'}
                  defaultMessage={
                    isDiploma ? 'Diploma issuance date' : 'Valid since'
                  }
                />
              </TableColumn>
              {!isDiploma && (
                <TableColumn
                  fieldName="validUntil"
                  formatter={momentFormatterWithoutTime}
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.validUntil)}
                >
                  <FormattedMessage
                    id="Valid until"
                    defaultMessage="Valid until"
                  />
                </TableColumn>
              )}
              <TableColumn
                fieldName="category"
                formatter={(value) => value?.name}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.category)}
              >
                <FormattedMessage id="Category" defaultMessage="Category" />
              </TableColumn>
              <TableColumn
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.status)}
                fieldName="status"
                formatter={forgettingStatusFormatter(enableSigning)}
              >
                <FormattedMessage
                  id="Publication status"
                  defaultMessage="Publication status"
                />
              </TableColumn>
              {isDiploma && (
                <TableColumn
                  fieldName="identity"
                  formatter={(value) => value?.documentNumber}
                >
                  <FormattedMessage
                    id="Index number"
                    defaultMessage="Index number"
                  />
                </TableColumn>
              )}
              {!isTauron ? (
                <TableColumn
                  fieldName="identity_surname"
                  formatter={documentListIdentityFormatter}
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.identity_surname)}
                >
                  <FormattedMessage
                    id="Published for"
                    defaultMessage="Published for"
                  />
                </TableColumn>
              ) : (
                <TableColumn
                  fieldName="identity_name"
                  formatter={documentListIdentityNameFormatter}
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.identity_name)}
                >
                  <FormattedMessage
                    id="Published for"
                    defaultMessage="Published for"
                  />
                </TableColumn>
              )}
              <TableColumn
                className="text-center fixed-width-140"
                fieldName="documentBlockchainAddress"
                formatter={(v, r) =>
                  chooseDocumentFormatter(
                    v,
                    r,
                    onAppendDocuments,
                    unRegisterDocuments,
                  )
                }
              >
                <FormattedMessage
                  id="Execute GDPR"
                  defaultMessage="Execute GDPR"
                />
              </TableColumn>
              <TableColumn
                className="text-center fixed-width"
                fieldName="Preview"
                formatter={(value, record) => (
                  <PreviewDownloadButton record={record} isPreview />
                )}
              >
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </TableColumn>
              <TableColumn
                className="text-center fixed-width"
                fieldName="Download"
                formatter={(value, record) => (
                  <PreviewDownloadButton record={record} />
                )}
              >
                <FormattedMessage id="Download" defaultMessage="Download" />
              </TableColumn>
            </Table>
            <Pagination
              onClick={handlePageChange}
              numberOfPages={Math.ceil(
                data.count / fullFilters.pagination.limit,
              )}
              currentPage={Number(fullFilters.pagination.page)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
            />
          </>
        )}
      </Styled.TableCard>
      <br />
      <br />
    </>
  );
};

export default RecipientDocuments;
