import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_TYPE } from '../../../../../../constraints';
import { QueryKeys } from '../../../../../../queryKeys';

// hooks
import { useDocumentList } from '../../../../../Documents/hooks';
import { useForgetRecipient } from '../../../../hooks/useForgetRecipient';

// components
import * as Styled from '../styled';
import { LoadingActionButton } from '../../../../../../components';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';
import { SuccessModal } from '../../../../../../components/SuccessModal';
import { ErrorModal } from '../../../../../../components/ErrorModal';
import CustomToolTip from '../../../../../../ui/CustomToolTip';
import ConfirmForgettingModal from './ConfirmForgettingModal';
import { Layout as LayoutModule } from 'billon-ui';

const { Content: ContentModule } = LayoutModule;
const { ErrorContent } = ContentModule;

const RecipientForgettinhActions = ({ recipient, handleRedirect }) => {
  const queryClient = useQueryClient();

  const recipientFilters = {
    documentTypeList: DOCUMENT_TYPE.PRIVATE,
    identity: {
      documentNumber: recipient.documentNumber,
    },
  };

  const { data: recipientDocumentCount, isFetching: areDocumentsFetching } =
    useDocumentList({ filters: recipientFilters }, { enabled: true });

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleSuccess = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_IDENTITY, recipient.id]);
    setIsConfirmModalOpen(false);
    setIsSuccessModalOpen(true);
  };

  const handleError = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_IDENTITY, recipient.id]);
    setIsConfirmModalOpen(false);
    setIsErrorModalOpen(true);
  };

  const { mutate: forgetRecipient, isLoading: isForgettingRecipient } =
    useForgetRecipient({
      onSuccess: handleSuccess,
      onError: handleError,
    });

  const inActive = recipientDocumentCount?.count >= 1 || areDocumentsFetching;

  const handleSuspendDataProcessing = () => {
    if (!inActive) {
      setIsConfirmModalOpen(true);
    }
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleSuccessModalClose = () => {
    handleRedirect();
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  const handleConfirm = () => {
    forgetRecipient(recipient.id);
  };

  const ForgetRecipientButton = () => (
    <LoadingActionButton
      onClick={handleSuspendDataProcessing}
      outline
      inActive={inActive}
    >
      <FormattedMessage
        id="Forget recipient"
        defaultMessage="Forget recipient"
      />
    </LoadingActionButton>
  );

  return (
    <>
      <Styled.SectionColumn>
        <Styled.Row padding={0}>
          <BtnCol padding={0}>
            {inActive ? (
              <CustomToolTip
                id="forget-recipient-btn-tooltip"
                trigger={<ForgetRecipientButton />}
                delay={false}
                placement="bottom-start"
              >
                <FormattedMessage
                  id="Forget recipient tooltip"
                  defaultMessage="Forget recipient tooltip"
                />
              </CustomToolTip>
            ) : (
              <ForgetRecipientButton />
            )}
          </BtnCol>
        </Styled.Row>
      </Styled.SectionColumn>
      <ConfirmForgettingModal
        isOpen={isConfirmModalOpen}
        onSubmit={handleConfirm}
        isSubmitting={isForgettingRecipient}
        onClose={handleConfirmModalClose}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        toggle={handleSuccessModalClose}
        onClose={handleSuccessModalClose}
        closeIconEnabled={true}
      >
        <FormattedMessage
          id="Identity has already been permanently removed"
          defaultMessage="Identity has already been permanently removed"
        />
      </SuccessModal>
      <ErrorModal
        isOpen={isErrorModalOpen}
        toggle={handleErrorModalClose}
        onClose={handleErrorModalClose}
        closeIconEnabled={true}
      >
        <ErrorContent>
          <FormattedMessage
            id="Forgetting error"
            defaultMessage="Forgetting error"
          />
        </ErrorContent>
      </ErrorModal>
    </>
  );
};

export default RecipientForgettinhActions;
