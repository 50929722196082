import { useIntl } from 'react-intl';
import {
  SHARED_SECRET_TYPE,
  MAP_SHARED_SECRET_TYPE_OPTIONS,
} from '../../../../../constraints';
import { useConfigContext } from '../../../../Config/hooks/useConfig';

export const useSharedSecretOptions = () => {
  const { formatMessage } = useIntl();
  const { recipientsConfig } = useConfigContext();
  const { isSharedSecretOptional } = recipientsConfig || {};

  const sharedSecretTypeBaseKeys = [
    SHARED_SECRET_TYPE.PESEL,
    SHARED_SECRET_TYPE.NIP,
    SHARED_SECRET_TYPE.POSTAL_CODE,
  ];

  const sharedSecretTypeKeys = isSharedSecretOptional
    ? [SHARED_SECRET_TYPE.NONE, ...sharedSecretTypeBaseKeys]
    : sharedSecretTypeBaseKeys;

  const sharedSecretOptions = sharedSecretTypeKeys.map((key) => {
    const msgId = MAP_SHARED_SECRET_TYPE_OPTIONS[key];
    return {
      label: formatMessage({
        id: msgId,
        defaultMessage: msgId,
      }),
      value: key,
    };
  });

  const defaultSharedSecretTypeKey = isSharedSecretOptional
    ? SHARED_SECRET_TYPE.NONE
    : SHARED_SECRET_TYPE.PESEL;
  const defaultSharedSecretOption = {
    label: formatMessage({
      id: MAP_SHARED_SECRET_TYPE_OPTIONS[defaultSharedSecretTypeKey],
      defaultMessage:
        MAP_SHARED_SECRET_TYPE_OPTIONS[defaultSharedSecretTypeKey],
    }),
    value: defaultSharedSecretTypeKey,
  };

  return { sharedSecretOptions, defaultSharedSecretOption };
};
