import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';
import { parseCustomFilters } from '../helpers/parseCustomFilters';
import { removeUndefinedValues } from '../../../helpers/removeUndefinedValues';

export const useCustomReport = (filters, options) =>
  useQueryWithLogout(
    [QueryKeys.CUSTOM_REPORT, filters],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.CUSTOM_REPORT,
        params: parseCustomFilters(removeUndefinedValues(filters)),
      });

      return data;
    },
    options,
  );
