import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// constants
import {
  UserRoles,
  USER_STATUSES,
  UPDATE_IDENTITY_STATUSES,
  RECIPIENT_STATUSES,
} from '../../../../constraints';

// hooks
import { useConfigContext } from '../../../Config/hooks/useConfig';

// Components
import { ProtectedComponent } from '../../../Auth/components';
import CustomToolTip from '../../../../ui/CustomToolTip';
import { CopiedInfo } from '../../../../components';
import AccountType from '../../../../components/AccountType';
import IdBadge from '../../../../components/IdBadge';
import DeleteSection from './components/DeleteSection';
import { DetailsSection } from './components';
import { Grid, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button, BackButton } = ButtonModule;
const { Row, Col } = Grid;

const RecipientDetails = ({
  recipient,
  onBackClick,
  backButtonLabel,
  onEdit,
}) => {
  const { isTauron } = useConfigContext();

  const { isGdprSuspended, status } = recipient || {};

  if (!recipient) {
    onBackClick();
  }

  const recipientFullName = isTauron
    ? recipient.name
    : `${recipient.firstName} ${recipient.lastName}`;

  const isRecipientPublishing = status === RECIPIENT_STATUSES.PUBLISHING;

  const isEditSuspended =
    recipient?.updateInfo?.status === UPDATE_IDENTITY_STATUSES.IN_PROGRESS ||
    isRecipientPublishing ||
    false;

  const EditIdentityButton = () => (
    <ProtectedComponent
      roles={[UserRoles.AGENT, UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
      render={false}
    >
      <Button outline onClick={onEdit} block disabled={isEditSuspended}>
        <FormattedMessage id="Edit" defaultMessage="Edit" />
      </Button>
    </ProtectedComponent>
  );

  return (
    <>
      <Row margin={'0 0 20px 0 '}>
        <BackButton secondary onClick={onBackClick}>
          {backButtonLabel}
        </BackButton>
      </Row>
      <Row margin={0} md={12}>
        <Col md={5}>
          <Row margin={'0 0 20px 0 '}>
            <IdBadge scale="big" name={recipientFullName} iconDisabled={false}>
              <Col padding={'0 0 0 0'} md={12}>
                {recipient.isRobot && <AccountType />}

                <Col margin={'20px 0 20px 0'} padding={0}>
                  <CopiedInfo
                    id={recipient.id}
                    label="Internal ID"
                    value={recipient.id}
                    emphasisedHeader
                    valuePlacedBetween
                  />
                </Col>

                {onEdit &&
                  !isGdprSuspended &&
                  (isRecipientPublishing ? (
                    <CustomToolTip
                      id="edit-recipient-btn-tooltip-suspended"
                      trigger={<EditIdentityButton />}
                      delay={false}
                      placement="bottom-start"
                    >
                      <FormattedMessage
                        id="Identity during publication"
                        defaultMessage="Identity during publication"
                      />
                    </CustomToolTip>
                  ) : (
                    <EditIdentityButton />
                  ))}

                {recipient.status === USER_STATUSES.SHELL && (
                  <ProtectedComponent
                    roles={[
                      UserRoles.AGENT,
                      UserRoles.BUSINESS_ADMIN,
                      UserRoles.ADMIN,
                    ]}
                    render={false}
                  >
                    <DeleteSection
                      id={recipient.id}
                      onBackClick={onBackClick}
                    />
                  </ProtectedComponent>
                )}
              </Col>
            </IdBadge>
          </Row>
        </Col>

        <Col md={7}>
          <DetailsSection recipient={recipient} />
        </Col>
      </Row>
    </>
  );
};

RecipientDetails.propTypes = {
  recipient: PropTypes.object.isRequired,
  onBackClick: PropTypes.func,
  backButtonLabel: PropTypes.string,
  handleCreate: PropTypes.func,
};

export default RecipientDetails;
