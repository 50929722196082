import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { momentInTimezoneFormatter } from '../../../../../../formatters';
import { ACTIVE_DELIVERY } from '../../../../../../constraints';

// Components
import 'bs-stepper/dist/css/bs-stepper.min.css';
import * as Styled from './styled';
import { ReactComponent as SuccessIcon } from '../../../../../../ui/Icons/success-circle.svg';
import { ReactComponent as MessageIcon } from '../../../../../../ui/Icons/message.svg';
import { Grid } from 'billon-ui';

const { Col, Row } = Grid;

export const Stepper = ({ activeDelivery }) => {
  const { formatMessage } = useIntl();

  const { type, events, eventDate } = activeDelivery || {};

  const eventsByTypes = (events || []).reduce((obj, value) => {
    obj[value.type] = value;
    return obj;
  }, {});

  const stepADate = useMemo(() => {
    if (type === ACTIVE_DELIVERY.CONFIRMATION_OF_RECEIPT && eventDate)
      return eventDate;
    return null;
  }, [type, eventDate]);

  const stepASubName = useMemo(() => {
    if (
      eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_EMAIL]
        ?.eventDate ||
      eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_SMS]?.eventDate
    )
      return (
        <Styled.CenteredThinWrapper>
          {eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_SMS]
            ?.eventDate && (
            <Col padding={0}>
              <b>
                <FormattedMessage
                  id="SMS notification"
                  defaultMessage="SMS notification"
                />
                {': '}
              </b>
              {momentInTimezoneFormatter(
                eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_SMS]
                  ?.eventDate,
              )}
            </Col>
          )}
          {eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_EMAIL]
            ?.eventDate && (
            <Col padding={0}>
              <b>
                <FormattedMessage
                  id="Email notification"
                  defaultMessage="Email notification"
                />
                {': '}
              </b>
              {momentInTimezoneFormatter(
                eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_EMAIL]
                  ?.eventDate,
              )}
            </Col>
          )}
        </Styled.CenteredThinWrapper>
      );
    return <FormattedMessage id="Waiting3" defaultMessage="Waiting3" />;
  }, [eventsByTypes]);

  const isStepAActive = useMemo(() => {
    return (
      eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_EMAIL]
        ?.eventDate ||
      eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_SMS]?.eventDate
    );
  }, [eventsByTypes]);

  const stepA = {
    name: formatMessage({
      id: 'Confirmation of sending notifications',
    }),
    subName: stepASubName,
    date: momentInTimezoneFormatter(stepADate),
    active: isStepAActive,
    failed: false,
  };

  const stepBSubName = useMemo(() => {
    if (eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_RECEIPT]) {
      return <b />;
    }
    return <FormattedMessage id="Waiting3" defaultMessage="Waiting3" />;
  }, [eventsByTypes]);

  const stepBDate = useMemo(() => {
    if (eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_RECEIPT]?.eventDate) {
      return eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_RECEIPT]?.eventDate;
    }
    return null;
  }, [eventsByTypes]);

  const isStepBActive = useMemo(() => {
    return !!eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_RECEIPT];
  }, [eventsByTypes]);

  const stepB = {
    name: formatMessage({
      id: 'Confirmation of receipt',
    }),
    subName: stepBSubName,
    date: momentInTimezoneFormatter(stepBDate),
    active: isStepBActive,
    failed: false,
  };

  const stepCSubName = useMemo(() => {
    if (
      type === ACTIVE_DELIVERY.CONFIRMATION_OF_ACKNOWLEDGMENT &&
      !eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_ACKNOWLEDGMENT]
    )
      return <FormattedMessage id="Waiting3" defaultMessage="Waiting3" />;
    return <b />;
  }, [eventsByTypes, type]);

  const stepCDate = useMemo(() => {
    return (
      eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_ACKNOWLEDGMENT]
        ?.eventDate ?? null
    );
  }, [eventsByTypes]);

  const isStepCActive = useMemo(() => {
    return eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_ACKNOWLEDGMENT];
  }, [eventsByTypes]);

  const stepC = {
    name: formatMessage({
      id: 'Confirmation of acknowledgment',
    }),
    subName: stepCSubName,
    date: momentInTimezoneFormatter(stepCDate),
    active: isStepCActive,
    failed: false,
  };

  const steps = useMemo(() => {
    if (
      type === ACTIVE_DELIVERY.CONFIRMATION_OF_ACKNOWLEDGMENT ||
      eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_ACKNOWLEDGMENT]
    ) {
      return [stepA, stepB, stepC];
    }
    if (
      type === ACTIVE_DELIVERY.CONFIRMATION_OF_RECEIPT ||
      eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_RECEIPT]
    ) {
      return [stepA, stepB];
    }
    return [stepA];
  }, [type, stepA, stepB, stepC, eventsByTypes]);

  if (type === ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY && events?.length > 0) {
    return (
      <div>
        <h3>
          <FormattedMessage
            id="e-Delivery Status"
            defaultMessage="e-Delivery Status"
          />
        </h3>
        <Col md={5} margin={0} padding={0}>
          <Styled.SuccessBadgeWrapper>
            <Styled.IconWrapper>
              <SuccessIcon />
            </Styled.IconWrapper>
            <Styled.BadgeHeader>
              <b>
                <FormattedMessage
                  id="Confirmation of sending notifications"
                  defaultMessage="Confirmation of sending notifications"
                />
              </b>
            </Styled.BadgeHeader>

            <Row margin="0 0 0 32px">
              {eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_SMS]
                ?.eventDate && (
                <Col>
                  <b>
                    <FormattedMessage
                      id="SMS notification"
                      defaultMessage="SMS notification"
                    />
                    {': '}
                  </b>
                  {momentInTimezoneFormatter(
                    eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_SMS]
                      ?.eventDate,
                  )}
                </Col>
              )}
              {eventsByTypes[ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_EMAIL]
                ?.eventDate && (
                <Col>
                  <b>
                    <FormattedMessage
                      id="Email notification"
                      defaultMessage="Email notification"
                    />
                    {': '}
                  </b>
                  {momentInTimezoneFormatter(
                    eventsByTypes[
                      ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY_EMAIL
                    ]?.eventDate,
                  )}
                </Col>
              )}
            </Row>
          </Styled.SuccessBadgeWrapper>
        </Col>
      </div>
    );
  }

  if (
    type === ACTIVE_DELIVERY.CONFIRMATION_OF_DELIVERY &&
    events?.length === 0
  ) {
    return (
      <div>
        <h3>
          <FormattedMessage
            id="e-Delivery Status"
            defaultMessage="e-Delivery Status"
          />
        </h3>
        <Col md={5} margin={0} padding={0}>
          <Styled.WaitingBadgeWrapper>
            <Styled.IconWrapper isProcessing>
              <MessageIcon />
            </Styled.IconWrapper>
            <Styled.BadgeHeader>
              <b>
                <FormattedMessage
                  id="Confirmation of sending notifications"
                  defaultMessage="Confirmation of sending notifications"
                />
              </b>
            </Styled.BadgeHeader>

            <Row margin="0 0 0 32px">
              <Col>
                <FormattedMessage id="Waiting3" defaultMessage="Waiting3" />
              </Col>
            </Row>
          </Styled.WaitingBadgeWrapper>
        </Col>
      </div>
    );
  }

  return (
    <div>
      <h3>
        <FormattedMessage
          id="e-Delivery Status"
          defaultMessage="e-Delivery Status"
        />
      </h3>
      <Styled.StepperRowWrapper>
        <Col xl={steps?.length > 2 ? 10 : 8}>
          <div id="stepper1" class="bs-stepper">
            <div class="bs-stepper-header">
              {steps.map(
                ({ name, date, active, failed, extraWarning, subName }, id) => (
                  <>
                    <Styled.Step class="step">
                      <Styled.CenterWrapper>
                        <span class="step-trigger.disabled">
                          <Styled.StepFailedColorWrapper
                            failed={failed}
                            active={active}
                          >
                            <span class="bs-stepper-circle">{id + 1}</span>
                          </Styled.StepFailedColorWrapper>
                        </span>
                      </Styled.CenterWrapper>
                      <Styled.NameWrapper active={active}>
                        <Styled.CenterWrapper>
                          <Styled.SplitWrapper>{name}</Styled.SplitWrapper>
                        </Styled.CenterWrapper>
                      </Styled.NameWrapper>
                      <Styled.CenterWrapper>{subName}</Styled.CenterWrapper>
                      {active && date && (
                        <Styled.CenterWrapper>
                          <b>
                            <FormattedMessage id="Date" defaultMessage="Date" />
                            {':'}
                          </b>
                          &nbsp;
                          {date}
                        </Styled.CenterWrapper>
                      )}
                      {extraWarning && (
                        <Styled.CenterWrapper>
                          <Styled.WarningColorWrapper>
                            {extraWarning}
                          </Styled.WarningColorWrapper>
                        </Styled.CenterWrapper>
                      )}
                    </Styled.Step>
                    {id + 1 < steps?.length && (
                      <Styled.LineWrapper className="line"></Styled.LineWrapper>
                    )}
                  </>
                ),
              )}
            </div>
          </div>
        </Col>
      </Styled.StepperRowWrapper>
    </div>
  );
};
