import apiRoutes from '../../../api-routes';
import { patchRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

export const useUpdateUser = (options) => {
  return useMutationWithLogout(async ({ id, data }) => {
    const { data: createData } = await patchRequest({
      url: apiRoutes.SINGLE_USER.replace('{id}', id),
      data,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });
    return createData;
  }, options);
};
