import styled from 'styled-components';

export const SectionHeaderContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 11px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const TextColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
`;

export const SectionHeaderLabel = styled.span`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export const ExpirationLabel = styled.span`
  margin-left: 10px;
`;

export const ColumnSectionText = styled.p`
  font-size: 13px;
`;

export const Underlined = styled.span`
  text-decoration: underline;
`;
