import React from 'react';
import { FormattedMessage } from 'react-intl';

// hooks
import { useConfigContext } from '../../../../../../../Config/hooks/useConfig';

// Components
import { ControlledTextAreaField } from '../../../../../../../../components/ControlledTextAreaField';
import { RenderedNotificationContainer } from '../../../../../RenderedNotificationContainer/RenderedNotificationContainer';
import Modal from '../../../../../../../../ui/Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import * as Styled from './styled';
import { SubtitleToolTip } from '..';
import { Form as FormModule, Grid, Utils } from 'billon-ui';

const { FormGroup } = FormModule;
const { Text } = Utils;
const { Col, Row } = Grid;

const EmailNotificationModal = ({
  isOpen,
  onClose,
  emailPreviewValue,
  isEditable,
  fieldName = 'emailUserInput',
}) => {
  const { notificationTheme } = useConfigContext();
  const { inputColumnSize, templateColumnSize } = notificationTheme || {};

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
    >
      <ModalHeader>
        <FormattedMessage
          id="Email notification message"
          defaultMessage="Email notification message"
        />
      </ModalHeader>

      <Styled.ModalBodyWrapper>
        <Row>
          {isEditable && (
            <Col md={inputColumnSize}>
              <Text as="h5" fontWeight="600">
                <FormattedMessage
                  id="Edit message"
                  defaultMessage="Edit message"
                />{' '}
                <SubtitleToolTip />
              </Text>
              <FormGroup>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Enter message"
                    defaultMessage="Enter message"
                  />
                </Text>
                <ControlledTextAreaField name={fieldName} maxLength={null} />
              </FormGroup>
            </Col>
          )}

          <Col md={!isEditable ? 12 : templateColumnSize}>
            <Text as="h5" fontWeight="600">
              <FormattedMessage id="Preview" defaultMessage="Preview" />
            </Text>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </Text>
              <RenderedNotificationContainer
                dangerouslySetInnerHTML={{
                  __html: emailPreviewValue,
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Styled.ModalBodyWrapper>
      <ModalFooter onBack={onClose} onNext={onClose} nextAlignLeft={true} />
    </Modal>
  );
};

export default EmailNotificationModal;
