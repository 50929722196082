import React from 'react';
import { Utils } from 'billon-ui';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CUSTOMER } from '../../../../constraints';
import * as Styled from './styled';
import appRoutes from '../../../../app-routes';
import { Link } from 'react-router-dom';

const { Card } = Utils;

const LoginCard = ({ customer, children }) => {
  return (
    <Card>
      <Styled.Title>
        {
          {
            [CUSTOMER.DIPLOMA]: (
              <FormattedMessage
                id="Panel for publishing diploma on blockchain"
                defailtMessage="Panel for publishing diploma on blockchain"
              />
            ),
            [CUSTOMER.DEFAULT]: (
              <>
                <FormattedMessage
                  id="Panel for publishing documents"
                  defailtMessage="Panel for publishing documents"
                />
              </>
            ),
            [CUSTOMER.TAURON]: (
              <>
                <FormattedMessage
                  id="Panel for publishing documents"
                  defailtMessage="Corporate publication panel"
                />
              </>
            ),
          }[customer]
        }
      </Styled.Title>

      {[CUSTOMER.DEFAULT, CUSTOMER.TAURON].includes(customer) && (
        <Styled.SubTitle>
          <FormattedMessage id="on blockchain" defailtMessage="on blockchain" />
        </Styled.SubTitle>
      )}

      {[CUSTOMER.DIPLOMA].includes(customer) && (
        <Styled.Description>
          <FormattedMessage
            id="Please sign in to publish diploma"
            defailtMessage="Please sign in to publish diploma"
          />
        </Styled.Description>
      )}

      {children}

      {customer === CUSTOMER.TAURON ? (
        <Styled.SupportLabel>
          <FormattedMessage
            id="Forgot your password"
            defaultMessage="Forgot your password"
          />
          {'? '}
          <Link to={appRoutes.SUPPORT}>
            <FormattedMessage
              id="Contact the support team"
              defaultMessage="Contact the support team"
            />
          </Link>
        </Styled.SupportLabel>
      ) : (
        [CUSTOMER.DEFAULT, CUSTOMER.DIPLOMA].includes(customer) && (
          <Styled.SupportLabel>
            <Link to={appRoutes.RECOVER_PASSWORD}>
              <FormattedMessage
                id="Forgot your password"
                defaultMessage="Forgot your password"
              />
              {'?'}
            </Link>
          </Styled.SupportLabel>
        )
      )}
    </Card>
  );
};

LoginCard.propTypes = {
  customer: PropTypes.string.isRequired,
};

export default LoginCard;
