import styled from 'styled-components';
import { Utils, Grid } from 'billon-ui';

const { Text } = Utils;

export const Col = styled(Grid.Col)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  min-width: 13rem;
`;

export const Header = styled.h2`
  font-size: 20px;
  color: #011627;
  font-weight: 700;
  margin: 0 40px 0 0;
  padding-left: 32px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  padding-left: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding-left: 0;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-shrink: 0;
  flex-basis: 330px;
  min-height: 100px;
  background: #ffffff;
  border: 2px solid;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.primary : 'lightGrey'};
  color: ${({ theme, isSelected, disabled }) =>
    isSelected
      ? theme.palette.primary
      : !disabled
      ? theme.bodyColor
      : 'lightGrey'};
  border-radius: 15px;
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  padding: 16px;
  margin-right: 20px;
  font-size: 12px;
  text-align: left;
  background-color: '#f9fbfc';
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    min-height: 50px;
    flex-basis: 100px;
    margin-bottom: 20px;
  }
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    border: 2px solid
      ${({ disabled, theme }) =>
        !disabled ? theme.palette.primary : 'lightGrey'};
    color: ${({ disabled, theme }) =>
      !disabled ? theme.palette.primary : 'lightGrey'};
  }
`;

export const HighLevelFilterButton = styled(FilterButton)`
  min-height: 50px;
  max-height: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    flex-basis: 100px;
    margin-bottom: 10px;
  }
`;

export const CustomText = styled(Text)`
  margin: 0;
  font-size: 12px;
  white-space: break-spaces;
`;

export const Title = styled(CustomText)`
  font-weight: 600;
`;
