import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import classNames from 'classnames';

import { Utils } from 'billon-ui';

import { ReactComponent as CloseIcon } from 'js/ui/Icons/close.svg';

import {
  InputLabel,
  RegularInputWrapper,
  BtnGroup,
  InputBtn,
  ClearBtn,
} from './styled';

const { Icon } = Utils;

const RegularInput = ({
  className,
  active,
  dirty,
  touched,
  error,
  warning,
  icon,
  label,
  children,
  iconLeft,
  onClick,
  floatLabel,
  labelSize,
  labelWeight,
  readyToClose,
  onClear,
  onWrite,
}) => (
  <RegularInputWrapper
    className={classNames({
      'float-label': !!floatLabel,
      [className]: className,
      focused: active || dirty,
      error: touched && error,
      warning: touched && warning,
      'icon-left': iconLeft,
      'with-icon': icon,
    })}
    labelSize={labelSize}
    labelWeight={labelWeight}
    readyToClose={readyToClose}
  >
    {label && <InputLabel>{label}</InputLabel>}
    <div>
      {children}
      <BtnGroup>
        <ClearBtn type="button" onClick={onClear} readyToClose={readyToClose}>
          <CloseIcon />
        </ClearBtn>

        {icon && (
          <InputBtn
            type="button"
            onClick={onClick}
            className={classNames({
              clickable: isFunction(onClick),
              [icon]: true,
            })}
            tabIndex={-1}
          >
            <Icon name={icon} />
          </InputBtn>
        )}
      </BtnGroup>
    </div>
  </RegularInputWrapper>
);

RegularInput.propTypes = {
  active: PropTypes.bool,
  touched: PropTypes.bool,
  dirty: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  warning: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.string,
  iconLeft: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  floatLabel: PropTypes.bool,
  labelSize: PropTypes.string,
  labelWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RegularInput.defaultProps = {
  iconLeft: false,
};

export default RegularInput;
