import styled from 'styled-components';

export const NavButton = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 16px;
  color: ${({ theme, disabled }) => !disabled && theme.linkColor};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.65)};
  padding-left: 0;
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  font-weight: 600;
  text-decoration: ${({ isSelected }) => (isSelected ? 'underline' : 'none')};
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: ${({ disabled }) => !disabled && 'underline'};
    opacity: 1;
  }
`;
