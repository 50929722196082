import styled from 'styled-components';

export const CheckedBox = styled.div`
  position: absolute;
  opacity: 0;
  top: 1rem;
  right: 1rem;
  transition: ${(props) => props.theme.transitionBase};

  svg[data-icon] {
    font-size: 1.5rem;
    color: ${(props) => props.theme.palette.primary};
  }
`;

export const FancyHeader = styled.h3`
  font-size: ${(props) => props.theme.fontSizeSm};
  white-space: pre-line;
  font-weight: ${(props) => props.theme.fontWeightMedium};
`;

export const FancyRadio = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
  margin-bottom: 1.5rem;

  &.checked .fancy-box {
    border-color: ${(props) => props.theme.palette.primary};
  }

  &.checked .checked-box {
    opacity: 1;
  }
`;

export const FancyBox = styled.div`
  cursor: pointer;
  font-weight: ${(props) => props.theme.fontWeightMedium};
  font-size: ${(props) => props.theme.fontSizeSm};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.palette.grayLight};
  background-color: transparent;
  min-height: 10.5rem;
  height: auto;
  width: 100%;
  display: block;
  padding: 1rem;
  transition: ${(props) => props.theme.transitionBase};

  &:hover,
  &:focus,
  &:active {
    border-color: ${(props) => props.theme.palette.primary};
    box-shadow: ${(props) => props.theme.boxShadow} !important;
  }

  ul {
    margin: 0;
    padding-left: 1rem;
    font-weight: ${(props) => props.theme.fontWeightLight};
    font-size: ${(props) => props.theme.fontSizeSm};
  }
`;

export const FancyControlInput = styled.input`
  display: none;
`;

export const Error = styled.label`
  color: ${(props) => props.theme.palette.error};
  font-size: 0.75rem !important;
  margin-bottom: 0;

  &:not(.expand-error) {
    position: absolute;
  }
`;
