import { QueryKeys } from '../../../queryKeys';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';
import { getRequest } from '../../../helpers/apiClient';
import { removeUndefinedValues } from '../../../helpers/removeUndefinedValues';
import { merge } from 'lodash';

export const useCategoryList = (filters, options) => {
  const defaultFilters = {
    filters: { accountingNumberOfDocuments: true },
  };

  return useQueryWithLogout(
    [QueryKeys.CATEGORY_LIST, filters],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.CATEGORY,
        params: removeUndefinedValues(merge(defaultFilters, filters)),
      });
      return data;
    },
    options,
  );
};
