import { RECIPIENT_STATUSES } from '../../../../constraints';

export const parseGdprSuspension = (status, isGdprSuspended) => {
  if (!!isGdprSuspended) {
    const { value } = status || {};
    if (!!value && value !== RECIPIENT_STATUSES.SUSPENDED) {
      return false;
    }
    return true;
  }
  const { value } = status || {};
  if (!!value) return value === RECIPIENT_STATUSES.SUSPENDED;
};
