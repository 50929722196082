import { DOCUMENT_RECIPIENTS_FILTERS_OPTIONS } from '../../../constraints';

const parseTextField = (v) => {
  if (!v || v?.length === 0) return;
  else return v.toLowerCase();
};

export const parseDocumentRecipientsFilters = ({
  field,
  contactDetails,
  recipientId,
  groups,
}) => ({
  contactDetails:
    field.value === DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.CONTACT_DETAILS
      ? parseTextField(contactDetails)
      : undefined,
  recipientId:
    field.value === DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.RECIPIENT_ID
      ? parseTextField(recipientId)
      : undefined,
  groups:
    groups && field.value === DOCUMENT_RECIPIENTS_FILTERS_OPTIONS.GROUPS
      ? groups.value
      : undefined,
});
