import React from 'react';
import { PASSWORD_FORM_MODES } from '../../../../constraints';

// Components
import TwoStepPasswordChange from '../../components/TwoStepPasswordChange';
import { CenteredContent } from '../../../../components/UniCardContent/UniCardContent';

const ChangePasswordPage = () => {
  return (
    <CenteredContent>
      <TwoStepPasswordChange mode={PASSWORD_FORM_MODES.PASSWORD_CHANGE} />
    </CenteredContent>
  );
};

export default ChangePasswordPage;
