import { useMemo } from 'react';
import { merge } from 'lodash';

// hooks
import useFilters from '../../../../../../../hooks/useFilters';
import { useSigningStatusOptions } from '../../../../../../Documents/components/FilterForm/hooks/useSigningStatusOptions';
import { useDocumentStatusOptions } from '../../../../../../Documents/components/FilterForm/hooks/useDocumentStatusOptions';
import { useDocumentTypeOptions } from './useDocumentTypeOptions';

// utils
import { DOCUMENTS_OPTIONS } from '../../../../../../../constraints';
import { activeDeliveryOptions } from '../../../../../../../components/SelectActiveDeliveryField/SelectActiveDeliveryField';

export const useDocumentFormDefaultValues = (defaultFilters) => {
  const { filters } = useFilters();
  const { statusOptions: signingStatusOptions } = useSigningStatusOptions();
  const { statusOptions: documentStatusOptions } = useDocumentStatusOptions();
  const { documentTypeOptions } = useDocumentTypeOptions();

  const {
    categoryId,
    identity,
    documentStatusList,
    signingStatus,
    activeDeliveryType,
    publicationDateRange,
    validSinceDateRange,
    validUntilDateRange,
    tenant,
    blockchainAddress,
    publishedBy,
    title,
    documentTypeList,
  } = useMemo(
    () => merge({}, defaultFilters, filters)?.filters || {},
    [defaultFilters, filters],
  );

  const formInitialValues = {
    blockchainAddress: '',
    publicationDateRange: '',
    documentStatusList: documentStatusList,
    documentTypeList: DOCUMENTS_OPTIONS.ALL,
    signingStatus: null,
    activeDeliveryType: null,
    publishedBy: '',
    title: '',
    validSinceDateRange: '',
    validUntilDateRange: '',
    category: '',
    identity: null,
    tenant: null,
  };

  const { documentNumber, name } = identity || {};
  const { id: tenantId } = tenant || {};

  const asyncFieldsValues = {
    identityDocumentNumber: documentNumber,
    identityName: name,
    tenantId,
    categoryId,
  };

  const computedDefaultValues = useMemo(
    () =>
      merge({}, formInitialValues, {
        ...defaultFilters.filters,
        documentStatusList:
          documentStatusList &&
          documentStatusOptions.filter(
            (option) => option.value === documentStatusList,
          ),
        signingStatus:
          signingStatus &&
          signingStatusOptions.filter(
            (option) => option.value === signingStatus,
          ),
        activeDeliveryType:
          activeDeliveryType &&
          activeDeliveryOptions.filter(
            (option) => option.value === activeDeliveryType,
          ),
        publicationDateRange: [
          publicationDateRange?.from,
          publicationDateRange?.to,
        ],
        validSinceDateRange: [
          validSinceDateRange?.from,
          validSinceDateRange?.to,
        ],
        validUntilDateRange: [
          validUntilDateRange?.from,
          validUntilDateRange?.to,
        ],
        blockchainAddress,
        publishedBy,
        title,
        documentTypeList:
          documentTypeList &&
          documentTypeOptions.filter(
            (option) => option.value === documentTypeList || !documentTypeList,
          ),
      }),
    [
      defaultFilters,
      documentStatusList,
      documentStatusOptions,
      signingStatusOptions,
      signingStatus,
      activeDeliveryType,
      formInitialValues,
      publicationDateRange,
      validSinceDateRange,
      validUntilDateRange,
      blockchainAddress,
      publishedBy,
      title,
    ],
  );

  return {
    formInitialValues,
    computedDefaultValues,
    asyncFieldsValues,
  };
};
