import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  NOTIFICATION_HISTORY_STATUSES_LABELS,
  NOTIFICATION_HISTORY_STATUSES,
} from '../../../../../constraints';
import { Green, Orange, Red } from '../../../../../components/StatusColorSpans';

export const notificationStatusFormatter = (value) => {
  const statusMessageId =
    NOTIFICATION_HISTORY_STATUSES_LABELS[value] || 'Error';

  const greenStatuses = [
    NOTIFICATION_HISTORY_STATUSES.DELIVERED,
    NOTIFICATION_HISTORY_STATUSES.NEW,
    NOTIFICATION_HISTORY_STATUSES.UPDATED,
  ];
  const orangeStatuses = [
    NOTIFICATION_HISTORY_STATUSES.IN_PROGRESS,
    NOTIFICATION_HISTORY_STATUSES.PREPARED,
  ];
  const redStatuses = [
    NOTIFICATION_HISTORY_STATUSES.ERROR,
    NOTIFICATION_HISTORY_STATUSES.UNDELIVERED,
  ];

  const Message = () => (
    <FormattedMessage id={statusMessageId} defaultMessage={statusMessageId} />
  );

  if (greenStatuses.includes(value)) {
    return (
      <Green>
        <Message />
      </Green>
    );
  } else if (orangeStatuses.includes(value)) {
    return (
      <Orange>
        <Message />
      </Orange>
    );
  } else if (redStatuses.includes(value)) {
    return (
      <Red>
        <Message />
      </Red>
    );
  } else return <Message />;
};
