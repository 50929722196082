/* global sessionStorage */
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import {
  requestDecodeToken,
  requestRefreshToken,
  receiveTokenSuccess,
  receiveTokenFailure,
  receiveForbidden,
  requestAuthentication,
  receiveAuthenticationSuccess,
  receiveAuthenticationFailure,
  requestAuthorizationByToken,
  receiveAuthorizationByTokenSuccess,
  requestLogout,
  receiveLogoutSuccess,
} from './actions';

import { requestSmsCode } from 'js/modules/Settings/actions';

import { Utils } from 'billon-ui';

import apiRoutes from 'js/api-routes';

import { decodeJwtToken } from 'js/modules/Login/hooks';

const { request } = Utils.helpers;

export const forbiddenEpic = (actions$) =>
  actions$.ofType(receiveForbidden.getType()).map((action) =>
    requestRefreshToken({
      toRetryAction: action.payload?.toRetryAction || {},
    }),
  );

export const authenticationEpic = (actions$) =>
  actions$.ofType(requestAuthentication.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta || {};
    return ajax({
      url: apiRoutes.LOGIN,
      body: JSON.stringify(action.payload.values),
      headers: {
        [request.HEADER_CONTENT_TYPE]: request.CONTENT_TYPE_APPLICATION_JSON,
      },
      method: request.METHOD_POST,
    })
      .map(({ response }) => {
        const { token } = response;

        sessionStorage.setItem('token', token);
        if (resolve) {
          resolve(response);
        }
        return receiveAuthenticationSuccess();
      })
      .takeUntil(actions$.ofType(requestAuthentication.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveAuthenticationFailure(err.response));
      });
  });

export const logoutEpic = (actions$) =>
  actions$.ofType(requestLogout.getType()).map(() => {
    sessionStorage.removeItem('token');
    return receiveLogoutSuccess();
  });

export const decodeToken = (actions$) =>
  actions$.ofType(requestDecodeToken.getType()).map(() => {
    const user = decodeJwtToken();
    if (user) {
      return receiveTokenSuccess({ user: { ...user, login: user.sub } });
    } else {
      return receiveTokenFailure();
    }
  });

export const refreshToken = (actions$) =>
  actions$.ofType(requestRefreshToken.getType()).mergeMap((action) => {
    const toRetryAction = action.payload.toRetryAction;

    if (!toRetryAction) {
      receiveTokenFailure();
    }

    return ajax({
      url: apiRoutes.REFRESH_TOKEN,
      headers: {
        [request.HEADER_CONTENT_TYPE]: request.CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: request.METHOD_GET,
    })
      .mergeMap(({ response }) => {
        const { token } = response;
        sessionStorage.setItem('token', token);
        return [
          requestDecodeToken(),
          toRetryAction &
            { type: toRetryAction.getType(), payload: toRetryAction.payload },
        ];
      })
      .catch((err) => {
        return Observable.of(receiveTokenFailure(err.response));
      });
  });

export const tokenFailureEpic = (actions$) =>
  actions$.ofType(receiveTokenFailure.getType()).map(requestLogout);

export const authenticationSuccessEpic = (actions$) =>
  actions$.ofType(receiveAuthenticationSuccess.getType()).map(requestSmsCode);

export const authorizationByTokenEpic = (actions$) =>
  actions$.ofType(requestAuthorizationByToken.getType()).map(() => {
    return receiveAuthorizationByTokenSuccess();
  });

export default [
  authenticationEpic,
  authenticationSuccessEpic,
  authorizationByTokenEpic,
  logoutEpic,
  forbiddenEpic,
  decodeToken,
  tokenFailureEpic,
  refreshToken,
];
