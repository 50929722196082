import { useMemo } from 'react';

import { NOTIFICATION_CATEGORY_EDIT_TYPES } from '../../../../../../../constraints';

import { useNotificationGroupOptions } from '../../../../../hooks/privateNotifications/useNotificationGroupOptions';

export const useNotificationCategoryRow = ({
  groupId,
  emailTitle,
  groupName,
}) => {
  const {
    notificationGroupOptions,
    firstNotificationGroupOption,
    areNotificationGroupsLoading,
  } = useNotificationGroupOptions();

  const emailTitleFieldName = `value-${groupId}-${NOTIFICATION_CATEGORY_EDIT_TYPES.EMAIL_TITLE}`;
  const groupNameFieldName = `value-${groupId}-${NOTIFICATION_CATEGORY_EDIT_TYPES.GROUP_NAME}`;

  const emailTitleValue = emailTitle;
  const groupNameValue = useMemo(() => {
    if (areNotificationGroupsLoading) {
      return;
    }
    if (!!groupId) {
      const currentOption =
        groupId &&
        notificationGroupOptions?.find(({ value }) => groupId === value);

      if (currentOption?.length > 0) {
        return currentOption[0];
      }
      return currentOption;
    }
  }, [
    groupName,
    groupId,
    notificationGroupOptions,
    areNotificationGroupsLoading,
  ]);

  const computedStatusOption = useMemo(
    () => ({
      [emailTitleFieldName]: emailTitleValue,
      [groupNameFieldName]: groupNameValue,
    }),
    [],
  );

  return {
    computedStatusOption,
    emailTitleValue,
    groupNameValue,
    firstNotificationGroupOption,
    emailTitleFieldName,
    groupNameFieldName,
    notificationGroupOptions,
    areNotificationGroupsLoading,
  };
};
