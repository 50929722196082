import React, { useState } from 'react';
import { QueryKeys } from '../../../queryKeys';
import { useRefresh } from '../../../hooks/useRefresh';

// Components
import { FormattedMessage } from 'react-intl';
import { Layout } from 'billon-ui';
import { Topbar } from '../components/TopBar/TopBar';
import { CategoriesContainer } from '../components/CategoriesContainer';
import { CreateForm } from '../components/CreateForm/CreateForm';
import { RefreshButton } from '../../../components/RefreshButton/RefreshButton';
import * as Styled from './styled';

const { Content: ContentModule } = Layout;
const { ContentContainer, Content } = ContentModule;

const HomePage = () => {
  const { isRefreshDisabled, handleRefresh } = useRefresh(
    QueryKeys.CATEGORY_LIST,
  );

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const [parentCategory, setParentCategory] = useState();
  const handleCreate = (category) => {
    setParentCategory(category);
    toggleCreateCategoryModal();
  };

  const toggleCreateCategoryModal = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const createMessageId = parentCategory
    ? 'Adding subcategory'
    : 'Adding category';

  return (
    <ContentContainer fluid>
      <Content fluid>
        <Topbar onCreate={handleCreate} />
        <Styled.TitleRefreshWrapper>
          <Styled.Title>
            <FormattedMessage id="Categories" defaultMessage="Categories" />
          </Styled.Title>
          <RefreshButton
            isRefreshDisabled={isRefreshDisabled}
            handleRefresh={handleRefresh}
          />
        </Styled.TitleRefreshWrapper>
        <CategoriesContainer onCreate={handleCreate} />
        <Styled.CreateModal
          isOpen={createModalOpen}
          onSuccess={toggleCreateCategoryModal}
          onCancel={toggleCreateCategoryModal}
          initialValues={{ isActive: true }}
          createMessage={
            <FormattedMessage
              id={createMessageId}
              defaultMessage={createMessageId}
            />
          }
        >
          <CreateForm
            parentCategory={parentCategory}
            toggle={toggleCreateCategoryModal}
          />
        </Styled.CreateModal>
      </Content>
    </ContentContainer>
  );
};

export default HomePage;
