import React from 'react';
import { StatusTooltip } from '../../../../../../components';
import StatusWithIcon from '../../../../../../ui/StatusWithIcon';
import { generateRecordBasedId } from '../../../../../../utils';

const NotificationRecipientToolTip = ({ label, content, record }) => {
  return (
    <StatusWithIcon label={label}>
      <StatusTooltip
        label={content}
        id={generateRecordBasedId(record)}
        delay={false}
      />
    </StatusWithIcon>
  );
};

export default NotificationRecipientToolTip;
