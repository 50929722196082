import React, { useState, useMemo } from 'react';

// hooks
import { useHistory } from 'react-router-dom';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useResendDocumentNotification } from '../../hooks/useResendDocumentNotification';

// constants
import {
  mapDOCUMENT_ACTIONS_ERROR,
  RESEND_NOTIFICATION_MODES,
  SIGNING_ORDER,
  NOTIFICATION_TEMPLATE_TYPE,
  SIGNING_STATUS,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
} from '../../../../constraints';

// helpers
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';
import { parseResendNotificationData } from '../../helpers/parseResendNotificationData';

// Components
import { FormattedMessage } from 'react-intl';
import { ExportableErrorContent } from '../../../../components/ExportableErrorContent';
import { EnterNotificationContent } from './components';
import { ErrorModal } from '../../../../components/ErrorModal';
import { SuccessModal } from '../../../../components/SuccessModal';

const ResendPrivateNotification = ({ document, resendMode, handleClose }) => {
  const history = useHistory();
  const { enableSigning } = useConfigContext();

  const {
    tenant,
    category,
    documentType,
    signingOrder,
    status,
    signingStatus,
    documentBlockchainAddress,
  } = document || {};
  const tenantId = tenant?.id;
  const categoryId = category?.id;
  const defaultValues = { ...document, tenantId, categoryId };

  const notificationTemplateType = useMemo(() => {
    if (!enableSigning) {
      return documentType === DOCUMENT_TYPE.PUBLIC
        ? NOTIFICATION_TEMPLATE_TYPE.PUBLIC_PUBLISHED
        : NOTIFICATION_TEMPLATE_TYPE.PRIVATE_PUBLISHED;
    }

    if (
      [
        SIGNING_STATUS.WAITING_FOR_RECEIVER,
        SIGNING_STATUS.PREPARED_TO_SIGN,
      ].includes(signingStatus)
    ) {
      return NOTIFICATION_TEMPLATE_TYPE.AGREEMENT_READY_TO_SIGN;
    }

    if (
      (status === DOCUMENT_STATUS.CURRENT ||
        signingStatus === SIGNING_STATUS.PUBLISHING_OK) &&
      [SIGNING_ORDER.RECEIVER_ONLY, SIGNING_ORDER.SENDER_FIRST].includes(
        signingOrder,
      )
    ) {
      return NOTIFICATION_TEMPLATE_TYPE.AGREEMENT_PUBLISHED;
    }

    if (
      (status === DOCUMENT_STATUS.CURRENT ||
        signingStatus === SIGNING_STATUS.PUBLISHING_OK) &&
      [SIGNING_ORDER.NO_SIGNATURE, SIGNING_ORDER.PUBLISHER_ONLY].includes(
        signingOrder,
      )
    ) {
      return NOTIFICATION_TEMPLATE_TYPE.PRIVATE_PUBLISHED;
    }

    return null;
  }, [enableSigning, signingOrder, documentType, status, signingStatus]);

  const [formData, setFormData] = useState({});
  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const onDistinguishableBack = () => {
    history.goBack();
  };

  const onResendSuccess = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SUCCESS}`,
    );
  };

  const onResendFailure = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.FAILURE}`,
    );
  };

  const {
    mutate: resendNotification,
    error,
    isLoading: isProcessing,
    isError,
  } = useResendDocumentNotification({
    id: documentBlockchainAddress,
    documentType: documentType,
    onSuccess: onResendSuccess,
    onFailure: onResendFailure,
  });

  const onSubmitForm = (subFormData) => {
    handleAppendFormData(subFormData);
    resendNotification({
      data: removeUndefinedValues(
        parseResendNotificationData({ ...formData, ...subFormData }),
      ),
    });
  };

  const statusCode = error?.response?.data?.statusCode;

  return (
    <>
      <EnterNotificationContent
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.EDIT_TYPE_VIEW}
        isLoading={isProcessing}
        onClose={handleClose}
        onBack={onDistinguishableBack}
        onNext={onSubmitForm}
        documentBlockchainAddress={documentBlockchainAddress}
        documentType={notificationTemplateType}
        defaultValues={defaultValues}
      />
      <SuccessModal
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SUCCESS}
        onClose={handleClose}
        toggle={handleClose}
        closeIconEnabled={true}
      >
        <FormattedMessage
          id="Notifications already resent"
          defaultMessage="Notifications already resent"
        />
      </SuccessModal>
      <ErrorModal
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.FAILURE || isError}
        toggle={handleClose}
        onClose={handleClose}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={mapDOCUMENT_ACTIONS_ERROR.RENOTIFY}
          statusCode={statusCode}
          jobId={document.jobId}
          title={document.title}
        />
      </ErrorModal>
    </>
  );
};

export default ResendPrivateNotification;
