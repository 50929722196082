import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// constants
import { CUSTOMER, UserRoles, USER_STATUSES } from '../../../../constraints';

// Components
import { DetailsSection, PasswordManager } from './components';
import { ProtectedComponent } from '../../../../modules/Auth/components';
import { CopiedInfo } from '../../../../components';
import AccountType from '../../../../components/AccountType';
import IdBadge from '../../../../components/IdBadge';
import { Grid, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button, BackButton } = ButtonModule;
const { Row, Col } = Grid;

const UserDetails = ({
  user,
  onBackClick,
  customer,
  backButtonLabel,
  handleCreate,
  handleDelete,
}) => {
  if (!user) {
    onBackClick();
  }

  const isPasswordManagerDisplayed =
    [
      USER_STATUSES.SHELL,
      USER_STATUSES.PENDING,
      USER_STATUSES.REGISTERED,
      USER_STATUSES.PASSWORD_EXPIRED,
    ].includes(user.status) || customer === CUSTOMER.DEFAULT;

  const userFullName = `${user.firstName} ${user.lastName}`;

  return (
    <>
      <Row margin={'0 0 20px 0 '}>
        <BackButton secondary onClick={onBackClick}>
          {backButtonLabel}
        </BackButton>
      </Row>
      <Row margin={0} md={12}>
        <Col md={5}>
          <Row margin={'0 0 20px 0 '}>
            <IdBadge scale="big" name={userFullName} iconDisabled={false}>
              <Col padding={'0 0 0 0'} md={12}>
                {user.isRobot && <AccountType />}

                <Col margin={'20px 0 20px 0'} padding={0}>
                  <CopiedInfo
                    id={user.id}
                    label="ID"
                    value={user.id}
                    emphasisedHeader
                    valuePlacedBetween
                  />
                </Col>

                <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
                  <Button outline onClick={handleCreate} block>
                    <FormattedMessage id="Edit" defaultMessage="Edit" />
                  </Button>
                  {isPasswordManagerDisplayed && (
                    <PasswordManager scale="big" user={user} />
                  )}
                </ProtectedComponent>
              </Col>
            </IdBadge>
          </Row>
        </Col>

        <Col md={7}>
          <DetailsSection user={user} customer={customer} />
        </Col>
      </Row>
    </>
  );
};

UserDetails.propTypes = {
  user: PropTypes.object.isRequired,
  customer: PropTypes.object,
  onBackClick: PropTypes.func,
  backButtonLabel: PropTypes.string,
  handleCreate: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default UserDetails;
