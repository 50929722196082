import React from 'react';
import * as Styled from './styled';

const LoadingActionButton = ({
  isPlaceholder,
  isLoading,
  onClick,
  outline,
  children,
  inActive,
  dangerColor,
  type,
}) => (
  <>
    {isLoading ? (
      <Styled.FullHeightButtonLoader block />
    ) : (
      <Styled.FullHeightBtn
        block
        onClick={onClick}
        outline={outline}
        inActive={inActive}
        dangerColor={dangerColor}
        type={type}
      >
        {children}
      </Styled.FullHeightBtn>
    )}
  </>
);

export default LoadingActionButton;
