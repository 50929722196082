import styled from 'styled-components';
import { Grid } from 'billon-ui';

export const Row = styled(Grid.Row)`
  justify-content: flex-end;
`;

export const Col = styled(Grid.Col)`
  padding: 0.5rem;
`;
