export default {
  SELECT_DOCUMENT_TYPE: '/select-document-type',
  PUBLISH_DOCUMENT: '/document/publish',
  CATEGORIES: '/categories',
  DAILY_REPORTS_BLANK: `/dailyReports`,
  DAILY_REPORT_DETAILS: `/dailyReport/:id`,
  DOCUMENTS: '/documents',
  DOCUMENTS_DETAILS: '/document/:id',
  DOCUMENTS_DETAILS_NOTIFICATION_HISTORY: '/document/:id/notificationHistory',
  DOCUMENTS_DETAILS_RESEND_NOTIFICATION:
    '/document/:id/resendNotification/:mode',
  DOCUMENTS_DETAILS_UPDATE: '/document/:id/update/:mode',
  DOCUMENT_PUBLICATION_PREVIEW: '/documents/:id',
  LOGIN: '/login',
  ME: '/me',
  USERS: '/users',
  USERS_BLANK: '/users',
  USER_DETAILS: '/user/:id',
  PASSWORD_CHANGE_BLANK: '/passwordChange',
  PASSWORD_CHANGE: '/passwordChange/:token?',
  PASSWORD_SET: '/setPassword/:token?',
  RECIPTIENT_DETAILS: '/recipient/:id',
  RECIPIENT_MANAGER: '/recipients/manage/:mode',
  RECIPIENT_CREATOR: '/recipients/create',
  RECIPIENT_EDITOR: '/recipient/:id/edit',
  RECIPTIENTS: '/recipients',
  PASSWORD_OBSOLETE_SET: '/setObsoletePassword',
  TEMPORATY_PASSWORD_SET: '/users/:id/setTemporaryPassword',
  ADMIN_PASSWORD_CHANGE: '/user/:id/passwordChange',
  SUPPORT: '/support',
  RECOVER_PASSWORD: '/recoverPassword',
  RECOVER_PASSWORD_SUCCESS: '/recoverPassword-success',
  FORGOTTEN_PASSWORD: '/forgottenPassword/:token?',
  // Reports module
  REPORT: '/report',
  REPORTS: '/reports',
  REPORT_DELIVERY: '/report-delivery',
  REPORT_STATISTICS: '/report-statistics',
  REPORT_ANALYTICS: '/report-analytics',
  // GDPR module
  GDPR: '/gdpr',
  GDPR_RECIPIENT: '/gdpr/recipient/:id',
  // Notifications module
  NOTIFICATIONS: '/notifications',
  NOTIFICATION_TEMPLATES: '/notifications/templates',
  NOTIFICATION_TEMPLATE_CREATOR: '/notifications/templates/create',
  NOTIFICATION_TEMPLATE_MANAGE: '/notifications/templates/manage',
  NOTIFICATION_TEMPLATE_MANAGE_NEW: '/notifications/templates/manage/new',
  NOTIFICATION_TEMPLATE_MANAGE_EDIT: '/notifications/templates/manage/edit',
  NOTIFICATION_TEMPLATE_MANAGE_RESTORE:
    '/notifications/templates/manage/restore',
  NOTIFICATION_CATEGORIES: '/notifications/categories',
};
