import env from './env';
import * as configs from './config/index';
import localConfig from './config/local';

// eslint-disable-next-line import/no-mutable-exports
let config = configs[env];

if (env === 'dev') {
  config = {
    ...config,
    ...localConfig,
  };
}

export default config;
