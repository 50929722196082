import { useIntl } from 'react-intl';

export const useIsActiveOptions = () => {
  const { formatMessage } = useIntl();

  const isActiveOptions = [
    {
      label: formatMessage({
        id: 'Active2',
        defaultMessage: 'Active2',
      }),
      value: true,
    },
    {
      label: formatMessage({
        id: 'Inactive',
        defaultMessage: 'Inactive',
      }),
      value: false,
    },
  ];

  return { isActiveOptions };
};
