import React, { useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useLocation, matchPath } from 'react-router-dom';

// constants
import appRoutes from '../../../../app-routes';
import { QueryKeys } from '../../../../queryKeys';
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../constraints';

// hooks
import useFilters from '../../../../hooks/useFilters';
import { usePrivateSingleNotificationGroupContent } from '../../hooks/privateNotifications/usePrivateSingleNotificationGroupContent';

// Components
import Modal from '../../components/Modal';
import { ErrorModal } from '../../../../components/ErrorModal';
import { SuccessModal } from '../../../../components/SuccessModal';
import { CreateContentForm } from '../../components/Templates/components/NotificationManager/components';
import { Layout, Utils } from 'billon-ui';
const { Content: ContentModule } = Layout;
const { ErrorContent } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const CreateContentPage = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const { filters, navigateWithNewSearchQuery } = useFilters();

  const isUpdateMode = matchPath(pathname, {
    path: appRoutes.NOTIFICATION_TEMPLATE_MANAGE_EDIT,
  });

  const { contentId } = filters?.filters || {};
  const { data: contentData, isFetching: isContentLoading } =
    usePrivateSingleNotificationGroupContent(contentId, {
      enabled: !!contentId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  const modalTitle = useMemo(() => {
    if (isUpdateMode) {
      return formatMessage({
        id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_EDIT_MODAL,
        defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_EDIT_MODAL,
      });
    }
    if (!!contentId) {
      return formatMessage({
        id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_RESTORE_MODAL,
        defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_RESTORE_MODAL,
      });
    }
    return formatMessage({
      id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_NEW_MODAL,
      defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_NEW_MODAL,
    });
  }, [isUpdateMode, contentId]);
  const modalSuccess = useMemo(() => {
    if (isUpdateMode) {
      return formatMessage({
        id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_EDIT_SUCCESS,
        defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_EDIT_SUCCESS,
      });
    }
    if (!!contentId) {
      return formatMessage({
        id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_RESTORE_SUCCESS,
        defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_RESTORE_SUCCESS,
      });
    }
    return formatMessage({
      id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_NEW_SUCCESS,
      defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_NEW_SUCCESS,
    });
  }, [isUpdateMode, contentId]);
  const modalError = useMemo(() => {
    if (isUpdateMode) {
      return formatMessage({
        id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_EDIT_ERROR,
        defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_EDIT_ERROR,
      });
    }
    if (!!contentId) {
      return formatMessage({
        id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_RESTORE_ERROR,
        defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_RESTORE_ERROR,
      });
    }
    return formatMessage({
      id: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_NEW_ERROR,
      defaultMessage: NOTIFICATION_MODULE_MENU_LABEL.CONTENT_NEW_ERROR,
    });
  }, [isUpdateMode, contentId]);

  const [createModalOpened, setCreateModalOpened] = useState(true);
  const [errorModalOpened, setErrorModalOpened] = useState(false);
  const [errorContent, setErrorContent] = useState(null);
  const [successModalOpened, setSuccessModalOpened] = useState(false);

  const handleCreateModalClose = () => {
    setCreateModalOpened(false);
    const { contentId, ...rest } = filters?.filters;
    navigateWithNewSearchQuery(
      { filters: { ...rest } },
      appRoutes.NOTIFICATION_TEMPLATE_MANAGE,
    );
  };
  const handleSuccess = (groupContentId) => {
    setSuccessModalOpened(true);
    if (!!groupContentId) {
      queryClient.invalidateQueries([
        QueryKeys.PRIVATE_SINGLE_NOTIFICATION_GROUP_CONTENT,
        groupContentId,
      ]);
    }
  };
  const handleError = () => {
    setErrorModalOpened(true);
    queryClient.invalidateQueries([
      QueryKeys.PRIVATE_NOTIFICATION_GROUP_CONTENTS,
      filters?.filters?.groupId,
    ]);
  };
  const handleErrorModalClose = () => {
    setErrorModalOpened(false);
    setErrorContent(null);
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpened(false);
    queryClient.invalidateQueries([
      QueryKeys.PRIVATE_NOTIFICATION_GROUP_CONTENTS,
      filters?.filters?.groupId,
    ]);
    handleCreateModalClose();
  };

  if (isContentLoading) {
    return (
      <Modal isOpen={true} title={modalTitle}>
        <PageLoader
          message={
            <FormattedMessage
              id="Contents are loading..."
              defaultMessage="Contents are loading..."
            />
          }
        />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        isOpen={createModalOpened}
        toggle={handleCreateModalClose}
        onClose={handleCreateModalClose}
        title={modalTitle}
      >
        <CreateContentForm
          submitButtonText={'Save'}
          handleSuccess={handleSuccess}
          handleError={handleError}
          groupId={filters?.filters?.groupId}
          contentData={contentData}
          isUpdateMode={isUpdateMode}
        />
      </Modal>
      <ErrorModal
        isOpen={errorModalOpened}
        toggle={handleErrorModalClose}
        onClose={handleErrorModalClose}
        closeIconEnabled={false}
      >
        <ErrorContent>
          {modalError}
          {errorContent}
        </ErrorContent>
      </ErrorModal>
      <SuccessModal
        isOpen={successModalOpened}
        toggle={handleSuccessModalClose}
        onClose={handleSuccessModalClose}
        closeIconEnabled={false}
      >
        {modalSuccess}
      </SuccessModal>
    </>
  );
};

export default CreateContentPage;
