import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule, Utils } from 'billon-ui';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';
import {
  DOCUMENT_STATUS,
  DOCUMENT_PUBLICATION_STATUS,
  SETTINGS_CONSTANTS,
} from '../../../../../../constraints';

const { CheckboxField } = FormModule;
const { Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

const GDPRCheckBoxField = ({ record }) => {
  const { control } = useFormContext();

  const {
    forgettingStatus,
    documentBlockchainAddress,
    jobId,
    documentPublicationStatus,
  } = record;

  const { getSettingValueByKey } = useSettingsContext();
  const isPrivateOneStepPublication = getSettingValueByKey(
    SETTINGS_CONSTANTS.PRIVATE_ONE_STEP_PUBLISH,
  );

  const isLoaderReturned = useMemo(
    () => forgettingStatus === DOCUMENT_STATUS.FORGETTING_IN_PROGRESS,
    [forgettingStatus],
  );

  const isNothingReturned = useMemo(() => {
    const isTwoStepPreparedToSign =
      !isPrivateOneStepPublication &&
      documentPublicationStatus ===
        DOCUMENT_PUBLICATION_STATUS.PREPARED_TO_SIGN;

    const isPublishingError = [
      DOCUMENT_PUBLICATION_STATUS.PUBLISHING_ERROR,
      DOCUMENT_PUBLICATION_STATUS.NOT_EXIST,
    ].includes(documentPublicationStatus);

    const isDiscardingStatusMatched =
      isPublishingError || isTwoStepPreparedToSign;

    return !documentBlockchainAddress && isDiscardingStatusMatched;
  }, [
    documentPublicationStatus,
    documentBlockchainAddress,
    isPrivateOneStepPublication,
  ]);

  const controlName = useMemo(() => {
    const inputName = !documentBlockchainAddress
      ? 'documentsToDelete'
      : 'rodoDocuments';

    const property = !documentBlockchainAddress
      ? jobId
      : documentBlockchainAddress;

    return `${inputName}[${property}]`;
  }, [documentBlockchainAddress, jobId]);

  if (isLoaderReturned) {
    return <Loader width={50} />;
  } else if (isNothingReturned) {
    return null;
  } else
    return (
      <Controller
        name={controlName}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <br />
              <CheckboxField
                input={{
                  ...field,
                  checked: field.value,
                }}
                meta={{
                  touched: fieldState.invalid,
                  error: fieldState.error,
                }}
              />
            </>
          );
        }}
      />
    );
};

export default GDPRCheckBoxField;
