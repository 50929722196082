import React from 'react';
import { FormattedMessage } from 'react-intl';

import CustomToolTip from '../../../../../../../../ui/CustomToolTip';
import * as Styled from './styled';

const EditButton = ({ handleActive, name }) => {
  return (
    <CustomToolTip
      id={'edit-' + name}
      minWidth="8px"
      trigger={
        <Styled.ActionButton>
          <Styled.EditIcon onClick={handleActive} />
        </Styled.ActionButton>
      }
    >
      <FormattedMessage id="Edit" defaultMessage="Edit" />
    </CustomToolTip>
  );
};

export default EditButton;
