import apiRoutes from '../../../api-routes';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';
import { postRequest } from '../../../helpers/apiClient';

const transformFromFileParams = (params) => {
  const { file } = params;
  const formData = new FormData();

  formData.append('file', file[0]);

  return formData;
};

export const useUpdateIdentityFile = (options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: updateData } = await postRequest({
      url: apiRoutes.RECIPIENT_FILE,
      data: transformFromFileParams(data),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });
    return updateData;
  }, options);
