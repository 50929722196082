import styled from 'styled-components';
import { Layout, Utils } from 'billon-ui';
import { down } from 'styled-breakpoints';

const { Content: ContentModule } = Layout;
const { Card: BillonCard } = Utils;
const { ContentContainer } = ContentModule;

export const ContentContainerGrey = styled(ContentContainer)`
  background: rgb(250, 250, 250);
  max-width: 100%;
  padding: 80px;
  height: calc(100vh - 71px);

  ${down('sm')} {
    padding: 1rem;
  }
`;

export const CardContent = styled.div`
  margin-bottom: 4rem;
`;

export const Card = styled(BillonCard)`
  ${down('sm')} {
    margin-bottom: 2rem;
  }
`;

export const Icon = styled.img`
  height: 70px;
  margin-bottom: 40px;
`;
