import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { NOTIFICATION_MODULE_MENU_LABEL } from '../../../../constraints';

// components
import { CardHeader } from '../CardHeader/CardHeader';
import { SearchCategoryName, CategoriesContainer } from './components';
import { Grid } from 'billon-ui';

const { Row, Col } = Grid;

const Categories = () => (
  <>
    <Row margin="0 0 2rem 0">
      <CardHeader>
        <FormattedMessage
          id={NOTIFICATION_MODULE_MENU_LABEL.CATEGORIES}
          defaultMessage={NOTIFICATION_MODULE_MENU_LABEL.CATEGORIES}
        />
      </CardHeader>
    </Row>
    <SearchCategoryName />
    <Row margin="1rem  0 1rem 0">
      <Col padding={0}>
        <CategoriesContainer />
      </Col>
    </Row>
  </>
);

export default Categories;
