import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import appRoutes from '../../../../../../app-routes';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const PageMenu = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const options = [
    { urlKey: appRoutes.REPORT_ANALYTICS, labelId: 'Analytics' },
    { urlKey: appRoutes.REPORT_STATISTICS, labelId: 'Statistics' },
    { urlKey: appRoutes.REPORT_DELIVERY, labelId: 'Delivery Reports' },
  ];

  const handleChangeReportType = (urlKey) => () => {
    history.push(urlKey);
  };

  return (
    <Styled.FiltersWrapper>
      {options.map(({ urlKey, labelId }) => {
        const isSelected = pathname === urlKey;
        return (
          <Styled.FilterButton
            isSelected={isSelected}
            onClick={handleChangeReportType(urlKey)}
          >
            <Styled.Title>
              <FormattedMessage id={labelId} defaultMessage={labelId} />
            </Styled.Title>
            {!isSelected && (
              <Styled.SeeLabel>
                <FormattedMessage id="See" defaultMessage="See" />
              </Styled.SeeLabel>
            )}
          </Styled.FilterButton>
        );
      })}
    </Styled.FiltersWrapper>
  );
};

export default PageMenu;
