import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../../../constraints';

// hooks
import { useDocumentTypeOptions } from '../../hooks/useDocumentTypeOptions';

// Components
import { ControlledSelectField } from '../../../../../../../../components/ControlledSelectField/ControlledSelectField';
import { FieldLabel } from '../../../../../../../../components/Form';

const SelectDocumentTypeField = ({
  onClear,
  isClearable,
  name = DOCUMENT_FILTER_FIELD_NAMES.DOCUMENT_TYPE_LIST,
}) => {
  const { documentTypeOptions } = useDocumentTypeOptions();
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Document type" defaultMessage="Document type" />
      </FieldLabel>
      <ControlledSelectField
        name={name}
        options={documentTypeOptions}
        onClear={onClear}
        isClearable={isClearable}
      />
    </>
  );
};

export default SelectDocumentTypeField;
