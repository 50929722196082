import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { DOCUMENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// Components
import { ControlledCalendarField } from '../../../../../../components/ControlledCalendarField/ControlledCalendarField';
import { FieldLabel } from '../../../../../../components/Form';

const CalendarValidSinceField = ({
  name = DOCUMENT_FILTER_FIELD_NAMES.VALID_SINCE_DATE_RANGE,
  onClear,
}) => {
  const { isDiploma } = useConfigContext();

  return (
    <>
      <FieldLabel>
        {isDiploma ? (
          <FormattedMessage
            id="Date of issue of the diploma from-to"
            defaultMessage="Date of issue of the diploma from-to"
          />
        ) : (
          <FormattedMessage id="Valid since" defaultMessage="Valid since" />
        )}
      </FieldLabel>
      <ControlledCalendarField
        name={name}
        onClear={onClear}
        enableTime={false}
        showMonths={2}
        range
      />
    </>
  );
};

export default CalendarValidSinceField;
