import styled from 'styled-components';

export const CopyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;

  &:hover,
  &:focus {
    outline: none;
  }
`;

export const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${({ theme }) => theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${({ theme }) => theme.transitionBase};
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const CopyLabel = styled.div`
  word-wrap: break-word;
  font-weight: 600;
`;

export const CopyLabelInline = styled.span`
  word-wrap: break-word;
  font-weight: 600;
`;
