import styled from 'styled-components';

export const HeaderWrapper = styled.span`
  font-weight: ${({ open, theme }) =>
    open && theme.fontWeightSemiBold} !important;
  :hover {
    font-weight: ${(props) => props.theme.fontWeightSemiBold} !important;
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 16px;
`;
