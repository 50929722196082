import React from 'react';
import { PageLayout } from '../../components';
import { DailyReportTable } from '../../../DailyReports/components';

const DeliveryReportsPage = () => {
  return (
    <PageLayout>
      <DailyReportTable customHeaderId="Delivery reports" />
    </PageLayout>
  );
};

export default DeliveryReportsPage;
