import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import {
  NOTIFICATION_MODULE_MENU_LABEL,
  NOTIFICATION_PREVIEW_TYPES,
} from '../../../../../../../../constraints';

// formatters
import { validSinceValidUntilFormatter } from '../../../../../../helpers/formatters';

// Components
import { ControlledTextAreaField } from '../../../../../../../../components/ControlledTextAreaField';
import { Form as FormModule, Utils } from 'billon-ui';
import * as Styled from './styled';
const { Text } = Utils;
const { FormGroup } = FormModule;

const NotificationContent = ({
  validSince,
  validUntil,
  name = 'contentInput',
  disabled = true,
  editType,
  notificationContentAreaRows,
}) => {
  const numberOfRows =
    editType === NOTIFICATION_PREVIEW_TYPES.EMAIL
      ? notificationContentAreaRows
      : 4;

  return (
    <>
      <FormGroup>
        <Text as="h6" fontSize="12px" fontWeight="600">
          <FormattedMessage
            id={NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CONTENT_PREVIEW}
            defaultMessage={
              NOTIFICATION_MODULE_MENU_LABEL.TEMPLATE_CONTENT_PREVIEW
            }
          />
        </Text>
        <ControlledTextAreaField
          name={name}
          maxLength={null}
          disabled={disabled}
          rows={numberOfRows}
        />
        <Styled.ValidSinceValidUntil>
          {validSinceValidUntilFormatter(validSince, validUntil)}
        </Styled.ValidSinceValidUntil>
      </FormGroup>
    </>
  );
};

export default NotificationContent;
