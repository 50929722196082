import React from 'react';
import * as Styled from './styled';
import { Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const SummaryFileDownload = ({ file, onClick }) => {
  const handleClick = (v) => {
    if (onClick) {
      onClick(v);
    } else if (file) {
      const url = URL.createObjectURL(file);
      window.open(url, '_blank').focus();
    }
  };

  return (
    <Styled.SummaryContainer>
      <Button secondary type="button" onClick={handleClick}>
        <FormattedMessage id="Download" defaultMessage="Download" />
      </Button>
    </Styled.SummaryContainer>
  );
};
