import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// constants
import {
  RECIPIENT_FILTER_FIELD_NAMES,
  MAP_RECIPIENT_PLACEHOLDERS,
  MAP_RECIPIENT_FIELD_NAMES,
} from '../../../../../../constraints';

// components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';

const PayerNumbersField = () => {
  const { recipientsConfig } = useConfigContext();
  const { isPayerNumberOptional } = recipientsConfig || {};
  const { watch } = useFormContext();
  const payerNumbers = watch(RECIPIENT_FILTER_FIELD_NAMES.PAYER_NUMBERS);

  return (
    <ControlledTextField
      name={RECIPIENT_FILTER_FIELD_NAMES.PAYER_NUMBER}
      label={
        <>
          <FormattedMessage
            id={MAP_RECIPIENT_FIELD_NAMES.PAYER_NUMBERS}
            defaultMessage={MAP_RECIPIENT_FIELD_NAMES.PAYER_NUMBERS}
          />
          {payerNumbers?.length > 0 && (
            <>
              <br />
              <i>{payerNumbers?.join(', ')}</i>
            </>
          )}
        </>
      }
      placeholder={MAP_RECIPIENT_PLACEHOLDERS.PAYER_NUMBERS}
      required={!isPayerNumberOptional}
    />
  );
};

export default PayerNumbersField;
