import { Utils, Grid } from 'billon-ui';
import styled from 'styled-components';
import React from 'react';
import { ReactComponent as SearchIcon } from '../../../../../../ui/Icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../../../../ui/Icons/close.svg';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const AbsoluteDivForExitIcon = styled.div`
  position: absolute;
  right: 4%;
  top: 10%;
  cursor: pointer;
`;

export const FilterCard = styled(Utils.Card)`
  position: relative;
  box-shadow: none;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

export const Text = ({ children }) => (
  <Utils.Text as="p" fontSize={'12px'} fontWeight={700} margin={'0 0 5px 0'}>
    {children}
  </Utils.Text>
);

export const FilterBtnCol = styled(Grid.Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  fill: grey;
  padding-left: 0;
  padding-right: 0;
`;

export const Close = styled(CloseIcon)`
  fill: ${(props) => props.theme.palette.grayLighter};
`;
export const Search = styled(SearchIcon)`
  fill: ${(props) => props.theme.palette.grayLighter};
`;
