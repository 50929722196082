import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

// Hooks
import { useAuthenticatedContext } from '../../../../../../modules/Auth/hooks/useAuthenticated';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import useFilters from '../../../../../../hooks/useFilters';
import { useSchema } from './useSchema';
import { useSettingsContext } from '../../../../../Settings/hooks/useSettings';

// constants
import {
  PUBLICATION_FIELD_NAMES,
  DOCUMENT_TYPE,
  SETTINGS_CONSTANTS,
  PUBLICATION_MODES,
  SIGNING_ORDER,
} from '../../../../../../constraints';

// Helpers
import { getPublicationModeProperties } from '../../../../helpers';
import { computeDefaultValues } from './helpers';
import { validateValidUntilWithRetainUntil } from '../../../../helpers';

// components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { ControlledUploadField } from '../../../../../../components/ControlledUploadField/ControlledUploadField';
import { ControlledCalendarField } from '../../../../../../components/ControlledCalendarField/ControlledCalendarField';
import { SelectCategoryField } from '../../../../../../components/SelectCategoryField/SelectCategoryField';
import { SelectIdentityField } from '../../../../../../components/SelectIdentityField/SelectIdentityField';
import { SelectRetentionField } from '../../../../../../components/SelectRetentionField/SelectRetentionField';
import { SelectSigningOrderField } from '../../../../../../components/SelectSigningOrderField/SelectSigningOrderField';
import { SelectSigningTypeField } from '../../../../../../components/SelectSigningTypeField/SelectSigningTypeField';
import { SelectActiveDeliveryField } from '../../../../../../components/SelectActiveDeliveryField/SelectActiveDeliveryField';
import { SelectPublicDocumentTypeField } from '../../../../../../components/SelectPublicDocumentTypeField/SelectPublicDocumentTypeField';
import ValidSinceToolTip from './ValidSinceToolTip';
import ValidUntilToolTip from './ValidUntilToolTip';
import * as Styled from './styled';
import { Form as FormModule, Grid, Utils } from 'billon-ui';

const { FormGroup } = FormModule;
const { Button: ButtonModule, Text } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

export const DocumentAtrributesForm = ({
  step = 2,
  document,
  publicationMode,
  onSubmit,
  submitButtonText,
  onBack,
  isLoading,
  initialStep = 1,
}) => {
  const { filters } = useFilters();
  const { formatMessage } = useIntl();

  const isPublicDocument = [
    document?.documentType,
    filters?.documentTypeList,
  ].includes(DOCUMENT_TYPE.PUBLIC);

  const { getSettingValueByKey } = useSettingsContext();
  const maxFileSize = getSettingValueByKey(
    SETTINGS_CONSTANTS.MAX_PDF_FILE_SIZE,
  );
  const isPrivateOneStepPublication = getSettingValueByKey(
    SETTINGS_CONSTANTS.PRIVATE_ONE_STEP_PUBLISH,
  );

  const {
    isDiploma,
    publicationFormConfig,
    enableSigning,
    enableActiveDelivery,
    notificationsConfig,
  } = useConfigContext();

  const { enableRetention, displayTime, isValidUntilRequired } =
    publicationFormConfig;
  const { enablePublicDocumentType } = notificationsConfig;
  const isPublicDocumentTypeEnabled =
    enablePublicDocumentType && isPublicDocument;

  const schema = useSchema({
    isPublicDocument,
    isValidUntilRequired,
    isRetentionRequired: enableRetention,
    maxFileSize,
  });
  const { user } = useAuthenticatedContext();

  const formMethods = useForm({
    defaultValues: computeDefaultValues(
      document,
      user,
      isPublicDocumentTypeEnabled,
    ),
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const { handleSubmit, watch, setValue, setError, clearErrors } = formMethods;

  const { title, disabledFormFields } =
    getPublicationModeProperties(publicationMode);

  const isDisabledField = (keyName) => {
    return disabledFormFields.includes(keyName);
  };

  const handleBack = () => {
    if (initialStep === 0) {
      const values = watch();
      onBack(values);
    } else {
      onBack();
    }
  };

  const retentionDateExceededMessage = formatMessage({
    id: 'Retention date exceeded',
    defaultMessage: 'Retention date exceeded',
  });

  const validSinceWatcher = watch('validSince');
  const validUntilWatcher = watch('validUntil');
  const validSinceWatcherDate = new Date(validSinceWatcher);
  const retainUntilWatcher = watch('retainUntil');

  useEffect(() => {
    if (new Date(validUntilWatcher) < new Date(validSinceWatcher)) {
      setValue('validUntil', undefined);
    }

    const isValidSinceSet = !!validSinceWatcher;
    const isValidUntilSet = !!validUntilWatcher;
    const isRetainUntilSet = !!retainUntilWatcher;

    const isValidateValidUntilWithRetainUntil =
      validateValidUntilWithRetainUntil(validUntilWatcher, retainUntilWatcher);

    if (!isValidSinceSet && isValidUntilSet) {
      setError('validUntil', {
        type: 'custom',
        message: formatMessage({ id: 'Set the Valid Since date first' }),
      });
    }

    if (
      (validSinceWatcher || !validUntilWatcher) &&
      isValidateValidUntilWithRetainUntil
    ) {
      clearErrors('validUntil');
    }

    if (
      enableRetention &&
      isValidUntilSet &&
      isRetainUntilSet &&
      !isValidateValidUntilWithRetainUntil
    ) {
      setError('retainUntil', undefined);
      setError('validUntil', {
        type: 'custom',
        message: retentionDateExceededMessage,
      });
    } else {
      clearErrors('retainUntil');
    }
  }, [
    validSinceWatcher,
    validUntilWatcher,
    retainUntilWatcher,
    enableRetention,
    retentionDateExceededMessage,
    formatMessage,
    clearErrors,
    setError,
    setValue,
  ]);

  const signingOrderWatcher = watch('signingOrder');

  const validSinceMaxDate = useMemo(() => {
    if (isDiploma) {
      return new Date();
    }
    if (!enableRetention) {
      return retainUntilWatcher?.rawDate;
    }
    return undefined;
  }, [retainUntilWatcher, enableRetention, isDiploma]);

  const validUntilMaxDate = useMemo(
    () => (!enableRetention ? retainUntilWatcher?.rawDate : undefined),

    [retainUntilWatcher, enableRetention],
  );

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        {title && (
          <>
            <Text as="h5" fontWeight={800}>
              <FormattedMessage id={title} defaultMessage={title} />
            </Text>
            <br />
          </>
        )}
        <Row>
          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              <FormattedMessage
                id="Published by"
                defaultMessage="Published by"
              />
            </Text>
            <ControlledTextField
              name={PUBLICATION_FIELD_NAMES.PUBLISHED_BY}
              disabled={isDisabledField(PUBLICATION_FIELD_NAMES.PUBLISHED_BY)}
            />
            <Text as="h6" fontSize="12px" fontWeight="600">
              {isDiploma ? (
                <FormattedMessage
                  id="Diploma number"
                  defaultMessage="Diploma number"
                />
              ) : (
                <FormattedMessage id="Title" defaultMessage="Title" />
              )}
            </Text>
            <ControlledTextField
              name={PUBLICATION_FIELD_NAMES.TITLE}
              disabled={isDisabledField(PUBLICATION_FIELD_NAMES.TITLE)}
            />
            <SelectCategoryField
              createCategoryEnabled={step === 1}
              disabled={step !== 1}
              name="category"
            />
            {!isDiploma && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Document version"
                    defaultMessage="Document version"
                  />
                </Text>
                <ControlledTextField
                  name="versionName"
                  disabled={isDisabledField('versionName')}
                />
              </>
            )}
            {!isPublicDocument &&
              enableSigning &&
              !isPrivateOneStepPublication && (
                <>
                  <SelectSigningOrderField
                    disabled={
                      ![PUBLICATION_MODES.NEW].includes(publicationMode)
                    }
                  />
                  {[
                    SIGNING_ORDER.RECEIVER_ONLY,
                    SIGNING_ORDER.SENDER_FIRST,
                  ].includes(signingOrderWatcher?.value) && (
                    <SelectSigningTypeField
                      disabled={
                        ![PUBLICATION_MODES.NEW].includes(publicationMode)
                      }
                    />
                  )}
                </>
              )}
          </Col>

          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              {isDiploma ? (
                <FormattedMessage
                  id="Date of issue of the diploma"
                  defaultMessage="Date of issue of the diploma"
                />
              ) : (
                <>
                  <FormattedMessage
                    id="Valid since"
                    defaultMessage="Valid since"
                  />{' '}
                  <ValidSinceToolTip />
                </>
              )}
            </Text>
            <FormGroup>
              <ControlledCalendarField
                name="validSince"
                disabled={isDisabledField('validSince')}
                displayTime={displayTime}
                enableTime={false}
                maxDate={validSinceMaxDate}
              />
            </FormGroup>
            {!isDiploma && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Valid until"
                    defaultMessage="Valid until"
                  />{' '}
                  <ValidUntilToolTip />
                </Text>
                <FormGroup>
                  <ControlledCalendarField
                    name="validUntil"
                    disabled={isDisabledField('validUntil')}
                    minDate={validSinceWatcherDate}
                    maxDate={validUntilMaxDate}
                    displayTime={displayTime}
                    enableTime={false}
                  />
                </FormGroup>
              </>
            )}
            {enablePublicDocumentType && isPublicDocument && (
              <SelectPublicDocumentTypeField
                disabled={![PUBLICATION_MODES.NEW].includes(publicationMode)}
              />
            )}
            {!isPublicDocument && (
              <SelectIdentityField
                createIdentityEnabled={step === 1}
                disabled={step !== 1}
              />
            )}
            {enableRetention && (
              <SelectRetentionField
                disabled={![PUBLICATION_MODES.NEW].includes(publicationMode)}
              />
            )}
            {enableActiveDelivery && !isPublicDocument && (
              <SelectActiveDeliveryField
                disabled={![PUBLICATION_MODES.NEW].includes(publicationMode)}
              />
            )}
          </Col>

          <Col xl={4}>
            <>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Document" defaultMessage="Document" />
              </Text>
              <FormGroup>
                <ControlledUploadField
                  name="documentFile"
                  disabled={isDisabledField('documentFile')}
                  maxSize={maxFileSize}
                />
              </FormGroup>
            </>
          </Col>
        </Row>

        <Styled.CenterRow>
          <Col md={12} xl={3}>
            {onBack && (
              <Styled.BackButton secondary onClick={handleBack}>
                <FormattedMessage id="back" defaultMessage="back" />
              </Styled.BackButton>
            )}
          </Col>
          <Col xl={6}>
            {!isLoading ? (
              <Button type="submit" size="lg" block>
                <FormattedMessage
                  id={submitButtonText}
                  defaultMessage={submitButtonText}
                />
              </Button>
            ) : (
              <ButtonLoader size="lg" block />
            )}
          </Col>
        </Styled.CenterRow>
      </Styled.Form>
    </FormProvider>
  );
};
