import { useIntl } from 'react-intl';
import {
  SIGNING_STATUS,
  MAP_DOCUMENT_STATUSES,
  MAP_SIGNING_STATUSES,
} from '../../../../../constraints';

export const useSigningStatusOptions = () => {
  const { formatMessage } = useIntl();

  const MAP_SIGNING_STATUSES_BASE = {
    ...MAP_DOCUMENT_STATUSES,
    ...MAP_SIGNING_STATUSES,
  };

  const statusKeys = [
    SIGNING_STATUS.PREPARED_TO_SIGN,
    SIGNING_STATUS.SIGNING,
    SIGNING_STATUS.WAITING_FOR_RECEIVER,
    SIGNING_STATUS.UPLOADING,
    SIGNING_STATUS.REJECTED,
    SIGNING_STATUS.PUBLISHING_OK,
    SIGNING_STATUS.PUBLISHING_ERROR,
  ];

  const statusOptions = statusKeys.map((key) => {
    const msgId = MAP_SIGNING_STATUSES_BASE[key];
    return {
      label: formatMessage({
        id: msgId,
        defaultMessage: msgId,
      }),
      value: key,
    };
  });

  return { statusOptions };
};
