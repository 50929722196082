import { useQueryClient } from 'react-query';
import { useState } from 'react';

export const useRefresh = (key) => {
  const queryClient = useQueryClient();
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);

  const handleRefresh = () => {
    if (!isRefreshDisabled) {
      setIsRefreshDisabled(true);

      setTimeout(() => setIsRefreshDisabled(false), 5000);

      queryClient.invalidateQueries([key]);
    }
  };

  return {
    isRefreshDisabled,
    handleRefresh,
  };
};
