import apiRoutes from '../../../api-routes';
import { patchRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

const transformFromFileParams = (params) => {
  const { contactDetails } = params;
  const formData = new FormData();
  if(contactDetails) {
    formData.append('contactDetails', contactDetails[0]);
  }

  return formData;
};

export const usePatchRecipientsFile = (options) =>
  useMutationWithLogout(async ({ id, data }) => {
    const { data: publishedDocumentData } = await patchRequest({
      url: apiRoutes.DOCUMENT_UPDATE_RECIPIENTS.replace('{id}', id) ,
      data: transformFromFileParams(data),
      headers: {
        Authorization: sessionStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
    });

    return publishedDocumentData;
  }, options);
