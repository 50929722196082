import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const ModalBodyWrapper = styled.div`
  padding-bottom: 30px;
`;

export const EditPanel = styled.div`
  border: 1px solid #c9d4de;
  border-radius: 8px;
  padding: 4rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const EditButton = styled(Button)`
  padding-left: 4rem;
  padding-right: 4rem;
`;
