import React, { useEffect } from 'react';
import {
  REPORT_TYPE,
  MAP_REPORT_TYPE,
  DOCUMENTS_OPTIONS,
} from '../../../../../../../../constraints';
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Dropdown as DropdownModule } from 'billon-ui';
import { ControlledCalendarField } from '../../../../../../../../components/ControlledCalendarField/ControlledCalendarField';
import { useForm, FormProvider } from 'react-hook-form';

const { FormGroup, Form } = FormModule;

const { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } =
  DropdownModule;

const ReportHistoryHeader = ({
  reportType,
  onChangeReportType,
  historyDocumentType,
  onChangeHistoryDocumentType,
  dateRange,
  onChangeDateRange,
}) => {
  const reportTypeOptions = [
    REPORT_TYPE.DAILY,
    REPORT_TYPE.MONTHLY,
    REPORT_TYPE.YEARLY,
  ];

  const historyDocumentTypeOptions = [
    { documentType: DOCUMENTS_OPTIONS.ALL, messageId: 'All' },
    { documentType: DOCUMENTS_OPTIONS.PRIVATE, messageId: 'Private' },
    { documentType: DOCUMENTS_OPTIONS.PUBLIC, messageId: 'Public' },
  ];

  const selectReportType = (option) => () => {
    onChangeReportType(option);
  };

  const selectHistoryDocumentType = (option) => () => {
    onChangeHistoryDocumentType(option);
  };

  const formMethods = useForm({
    defaultValues: { dateRange: [dateRange?.from, dateRange?.to] },
  });

  const { watch } = formMethods;

  const dateRangeWatcher = watch('dateRange');

  useEffect(() => {
    onChangeDateRange(dateRangeWatcher);
  }, [dateRangeWatcher]);

  return (
    <>
      <Styled.HistoryHeader>
        <Styled.CardTitle>
          <FormattedMessage id="History" defaultMessage="History" />
        </Styled.CardTitle>
        <Styled.SelectHistoryType>
          <UncontrolledDropdown>
            <Styled.DropDownDiv>
              <FormattedMessage
                id="Document type"
                defaultMessage="Document type"
              />
              <DropdownToggle />
            </Styled.DropDownDiv>
            <DropdownMenu>
              {historyDocumentTypeOptions.map(({ documentType, messageId }) => (
                <DropdownItem onClick={selectHistoryDocumentType(documentType)}>
                  <FormattedMessage id={messageId} defaultMessage={messageId} />
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Styled.SelectHistoryType>
      </Styled.HistoryHeader>

      <Styled.SelectHistoryType>
        {reportTypeOptions.map((option) => {
          const optionMessageId = MAP_REPORT_TYPE[option];
          return (
            <Styled.Button
              color="link"
              active={reportType === option}
              onClick={selectReportType(option)}
            >
              <FormattedMessage
                id={optionMessageId}
                defaultMessage={optionMessageId}
              />
            </Styled.Button>
          );
        })}

        <FormProvider {...formMethods}>
          <Form>
            <FormGroup>
              <ControlledCalendarField
                name="dateRange"
                displayTime={false}
                enableTime={false}
                range
                showMonths={2}
              />
            </FormGroup>
          </Form>
        </FormProvider>
      </Styled.SelectHistoryType>
    </>
  );
};

export default ReportHistoryHeader;
