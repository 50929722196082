import React, { useState, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useTenantList } from '../../../Tenants/hooks/useTenantList';

// constants
import {
  NOTIFICATION_TEMPLATES,
  MAP_NOTIFICATION_TEMPLATES,
} from '../../../../constraints';

// Components
import * as Styled from './styled';
import NotificationTemplePreview from './NotificationTemplePreview';
import { Modal as ModalModule, Grid, Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Modal } = ModalModule;
const { Row, Col } = Grid;

export const NotificationTemplatesModal = () => {
  const { formatMessage } = useIntl();
  const { isDiploma, enableSigning, enablePrivate, enablePublic } =
    useConfigContext();
  const { data: tenantRows, isLoading } = useTenantList();
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const notificationTemplates = useMemo(() => {
    if (enableSigning) {
      return [
        ...(enablePublic
          ? [
              {
                key: NOTIFICATION_TEMPLATES.PUBLIC_PUBLISHED,
                labelKey: NOTIFICATION_TEMPLATES.PUBLIC_PUBLISHED,
              },
            ]
          : []),
        {
          key: NOTIFICATION_TEMPLATES.PRIVATE_PUBLISHED,
          labelKey: NOTIFICATION_TEMPLATES.PRIVATE_PUBLISHED,
        },
        {
          key: NOTIFICATION_TEMPLATES.AGREEMENT_READY_TO_SIGN,
          labelKey: NOTIFICATION_TEMPLATES.AGREEMENT_READY_TO_SIGN,
        },
        {
          key: NOTIFICATION_TEMPLATES.AGREEMENT_PUBLISHED,
          labelKey: NOTIFICATION_TEMPLATES.AGREEMENT_PUBLISHED,
        },
        {
          key: NOTIFICATION_TEMPLATES.AGREEMENT_REJECTED,
          labelKey: NOTIFICATION_TEMPLATES.AGREEMENT_REJECTED,
        },
      ];
    }

    if (isDiploma) {
      return [
        {
          key: NOTIFICATION_TEMPLATES.PRIVATE_PUBLISHED,
          labelKey: NOTIFICATION_TEMPLATES.MVP_PRIVATE_PUBLISHED,
        },
      ];
    }

    return [
      ...(enablePublic
        ? [
            {
              key: NOTIFICATION_TEMPLATES.PUBLIC_PUBLISHED,
              labelKey: NOTIFICATION_TEMPLATES.PUBLIC_PUBLISHED,
            },
          ]
        : []),
      ...(enablePrivate
        ? [
            {
              key: NOTIFICATION_TEMPLATES.PRIVATE_PUBLISHED,
              labelKey: NOTIFICATION_TEMPLATES.MVP_PRIVATE_PUBLISHED,
            },
          ]
        : []),
    ];
  }, [isDiploma, enableSigning, enablePrivate, enablePublic]);

  const TemplateGroupRenderer = ({ notifyTemplateGroup, tenantId }) =>
    notifyTemplateGroup.map(({ key, labelKey }) => {
      const labelMessageId = MAP_NOTIFICATION_TEMPLATES[labelKey];
      return (
        <Styled.TemplateWrapper>
          <Row>
            <Col>
              {!isDiploma && (
                <Styled.TemplateHeader>
                  <FormattedMessage
                    id={labelMessageId}
                    defaultMessage={labelMessageId}
                  />
                </Styled.TemplateHeader>
              )}
              <NotificationTemplePreview
                notificationTemplateType={key}
                tenantId={tenantId}
              />
            </Col>
          </Row>
        </Styled.TemplateWrapper>
      );
    });

  if (isLoading) {
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        onClose={toggleModal}
        title={formatMessage({ id: 'Notification messages' })}
      >
        <PageLoader
          message={
            <FormattedMessage
              id="Templates are loading..."
              defaultMessage="Templates are loading..."
            />
          }
        />
      </Modal>
    );
  }

  return (
    <>
      <Styled.ActionButton onClick={toggleModal} color={'back-secondary'}>
        <FormattedMessage
          id="Show notification template"
          defaultMessage="Show notification template"
        />
      </Styled.ActionButton>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        onClose={toggleModal}
        title={formatMessage({ id: 'Notification messages' })}
      >
        {tenantRows?.length > 0 ? (
          tenantRows.map(({ id: tenantId, name: tenantName }) => {
            return (
              <div>
                {!!tenantName && (
                  <Styled.TemplateTenantHeader>
                    {tenantName}
                  </Styled.TemplateTenantHeader>
                )}
                <TemplateGroupRenderer
                  notifyTemplateGroup={notificationTemplates}
                  tenantId={tenantId}
                />
              </div>
            );
          })
        ) : (
          <TemplateGroupRenderer notifyTemplateGroup={notificationTemplates} />
        )}
      </Modal>
    </>
  );
};
