import React, { useState } from 'react';
// Utils
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';
import * as formatters from '../../formatters';
import { QueryKeys } from '../../../../queryKeys';
import {
  ORDER_METHOD,
  USER_ORDER_BY,
  UserRoles,
} from '../../../../constraints';

// Components
import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';
import { ProtectedComponent } from '../../../../modules/Auth/components';
import UserTableDetails from '../UserTableDetails';
import IsActiveSwitch from '../IsActiveSwitch';
import { FilterForm } from '../FilterForm';
import * as Styled from './styled';
import { RefreshButton } from '../../../../components/RefreshButton/RefreshButton';
import InternalServerError from '../../../../components/InternalServerError/InternalServerError';

// Hooks
import useFilters from '../../../../hooks/useFilters';
import { useUsersList } from '../../hooks/useUsersList';
import { useQueryClient } from 'react-query';
import { useRefresh } from '../../../../hooks/useRefresh';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination, Button: ButtonModule } = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;

const UsersTable = ({ handleCreate, handleDelete }) => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    sort: {
      order: ORDER_METHOD.DESC,
      sortBy: USER_ORDER_BY.createdAt,
    },
  };

  const { isRefreshDisabled, handleRefresh } = useRefresh(QueryKeys.USERS_LIST);

  const [areFiltersOpen, setAreFiltersOpen] = useState();
  const toggleFiltersForm = () => setAreFiltersOpen(!areFiltersOpen);

  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge(defaultFilters, filters);

  const { data, isLoading, isError } = useUsersList(fullFilters);
  const queryClient = useQueryClient();

  const onSwitchSuccess = () =>
    queryClient.invalidateQueries([QueryKeys.USERS_LIST, fullFilters]);

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const switchFormat = (value, row) => (
    <IsActiveSwitch value={value} row={row} onSuccess={onSwitchSuccess} />
  );

  const numberOfPages = Math.ceil(data?.count / fullFilters.pagination.limit);

  const onSort = (column, isDescending) => {
    navigateWithSearchQuery({
      sort: {
        sortBy: USER_ORDER_BY[column],
        order: isDescending ? ORDER_METHOD.ASC : ORDER_METHOD.DESC,
      },
    });
  };

  const isAscendingOrder = (column) => {
    if (filters?.sort?.sortBy === column) {
      return filters?.sort?.order === ORDER_METHOD.ASC;
    } else {
      return true;
    }
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  if (isError) {
    return (
      <Content fluid>
        <InternalServerError />
      </Content>
    );
  }

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={
              <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
                <Styled.AfterWrapper>
                  <Button
                    onClick={handleCreate}
                    fontWeight={500}
                    padding="0.5rem 3.5rem;"
                  >
                    <FormattedMessage
                      id="Add administrator"
                      defaultMessage="Add administrator"
                    />
                  </Button>
                </Styled.AfterWrapper>
              </ProtectedComponent>
            }
          >
            <FormattedMessage
              id="Administrators"
              defaultMessage="Administrators"
            />
            <RefreshButton
              isRefreshDisabled={isRefreshDisabled}
              handleRefresh={handleRefresh}
            />
            <Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              onClick={toggleFiltersForm}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
            </Button>
          </PageHeader>
        )}
      </FormattedMessage>

      {areFiltersOpen && (
        <FilterForm initialValues={filters} closeFilters={toggleFiltersForm} />
      )}

      <Styled.TableCard>
        <Table
          tableData={data?.rows || []}
          sortMethod={onSort}
          responsive
          detailsComponent={(record) => (
            <UserTableDetails
              record={record}
              handleCreate={(initialValues, isPreparedToSign = false) =>
                handleCreate(initialValues, () => {}, isPreparedToSign)
              }
              handleDelete={(id) => handleDelete(id, () => {})}
            />
          )}
        >
          <TableColumn
            fieldName="createdAt"
            formatter={formatters.createdAtFormatter}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.createdAt)}
          >
            <FormattedMessage id="Created at" defaultMessage="Created at" />
          </TableColumn>
          <TableColumn
            fieldName="username"
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.username)}
          >
            <FormattedMessage id="Login" defaultMessage="Login" />
          </TableColumn>
          <TableColumn
            fieldName="firstName"
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.firstName)}
          >
            <FormattedMessage id="First name" defaultMessage="First name" />
          </TableColumn>
          <TableColumn
            fieldName="lastName"
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.lastName)}
          >
            <FormattedMessage id="Last name" defaultMessage="Last name" />
          </TableColumn>
          <TableColumn
            fieldName="email"
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.email)}
          >
            <FormattedMessage id="Email" defaultMessage="Email" />
          </TableColumn>
          <TableColumn
            fieldName="role"
            formatter={formatters.roleFormatter}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.role)}
          >
            <FormattedMessage id="Account type" defaultMessage="Account type" />
          </TableColumn>
          <TableColumn
            fieldName="createdBy"
            formatter={formatters.createdByFormatter}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.createdBy)}
          >
            <FormattedMessage id="Created by" defaultMessage="Created by" />
          </TableColumn>
          <TableColumn
            fieldName="status"
            formatter={formatters.userStatusWithToolTipFormatter}
            sortable
            asc={isAscendingOrder(USER_ORDER_BY.status)}
          >
            <FormattedMessage id="Status" defaultMessage="Status" />
          </TableColumn>
          <TableColumn
            className="fixed-width"
            fieldName="isActive"
            formatter={switchFormat}
          >
            <FormattedMessage id="Active2" defaultMessage="Active" />
          </TableColumn>
        </Table>
        <Pagination
          onClick={handlePageChange}
          numberOfPages={numberOfPages}
          currentPage={fullFilters.pagination.page}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      </Styled.TableCard>
    </Content>
  );
};

export default UsersTable;
