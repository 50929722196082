import styled from 'styled-components';

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 15px;
  margin-bottom: 40px;
`;
