import React, { useState } from 'react';

// constants
import {
  DOCUMENT_TYPE,
  SETTINGS_CONSTANTS,
  CUSTOMER,
} from '../../../../constraints';
import { QueryKeys } from '../../../../queryKeys';
import appRoutes from '../../../../app-routes';

// hooks
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSettingsContext } from '../../../Settings/hooks/useSettings';
import { useSingleDocument } from '../../hooks/useSingleDocument';
import { useConfigContext } from '../../../Config/hooks/useConfig';

// components
import { FormattedMessage } from 'react-intl';
import { ExportableErrorContent } from '../../../../components/ExportableErrorContent';
import { SuccessModal as CustomSuccessModal } from '../../../../components/SuccessModal';
import { ErrorModal } from '../../../../components/ErrorModal';
import UpdateModal from '../../components/UpdateModal';
import UpdateForm from '../../components/UpdateForm';
import UpdateFormAsync from '../../components/UpdateForm/UpdateFormAsync';
import { Layout as LayoutModule, Utils } from 'billon-ui';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const DocumentUpdatePage = () => {
  const history = useHistory();
  const { params } = useRouteMatch(appRoutes.DOCUMENTS_DETAILS_UPDATE);
  const queryClient = useQueryClient();
  const { customer } = useConfigContext();
  const { getSettingValueByKey } = useSettingsContext();

  const publisherName = getSettingValueByKey(SETTINGS_CONSTANTS.PUBLISHER_NAME);

  const { data: document, isLoading } = useSingleDocument({ id: params?.id });

  const handleSuccess = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_DOCUMENT, params?.id]);
  };

  const [updateModalOpened, setUpdateModalOpened] = useState(true);
  const [currentUpdateMode] = useState(params?.mode);

  const [updateErrorModalOpened, setUpdateErrorModalOpened] = useState(false);
  const [actionErrorStatusCode, setActionErrorStatusCode] = useState(null);
  const [actionErrorLabel, setActionErrorLabel] = useState(null);

  const [updateSuccessModalOpened, setUpdateSuccessModalOpened] =
    useState(false);
  const [actionSuccessLabel, setActionSuccessLabel] = useState(null);

  const handUpdateModalClose = () => {
    setUpdateModalOpened(false);
    history.push(`/document/${document.jobId}`);
  };

  const handleUpdateFailure = (errorLabel, statusCode) => {
    setActionErrorStatusCode(statusCode);
    setActionErrorLabel(errorLabel);
    setUpdateModalOpened(false);
    handleUpdateFailureModalOpen();
  };

  const handleUpdateFailureModalOpen = () => {
    setUpdateErrorModalOpened(true);
  };

  const handleUpdateFailureModalClose = () => {
    setUpdateErrorModalOpened(false);
    handUpdateModalClose();
  };

  const handleUpdateSuccessModalOpen = () => {
    setUpdateSuccessModalOpened(true);
  };

  const handleUpdateSuccessModalClose = () => {
    setUpdateSuccessModalOpened(false);
    handUpdateModalClose();
  };

  const handleUpdateModalSuccess = (label) => {
    handleSuccess();
    setActionSuccessLabel(label);
    setUpdateModalOpened(false);
    handleUpdateSuccessModalOpen();
  };

  const isUpdateAsync =
    document?.documentType !== DOCUMENT_TYPE.PRIVATE ||
    customer === CUSTOMER.TAURON;

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <>
      <UpdateModal
        isOpen={updateModalOpened}
        toggle={handUpdateModalClose}
        onClose={handUpdateModalClose}
        mode={currentUpdateMode}
      >
        {isUpdateAsync ? (
          <UpdateFormAsync
            initialValues={document}
            mode={currentUpdateMode}
            onClose={handUpdateModalClose}
            onSuccess={handleUpdateModalSuccess}
            onError={handleUpdateFailure}
          />
        ) : (
          <UpdateForm
            initialValues={document}
            mode={currentUpdateMode}
            publisherName={publisherName}
            onClose={handUpdateModalClose}
            onSuccess={handleUpdateModalSuccess}
            onError={handleUpdateFailure}
          />
        )}
      </UpdateModal>

      <ErrorModal
        isOpen={updateErrorModalOpened}
        toggle={handleUpdateFailureModalClose}
        onClose={handleUpdateFailureModalClose}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={actionErrorLabel}
          statusCode={actionErrorStatusCode}
          jobId={document.jobId}
          title={document.title}
        />
      </ErrorModal>

      <CustomSuccessModal
        isOpen={updateSuccessModalOpened}
        toggle={handleUpdateSuccessModalClose}
        onClose={handleUpdateSuccessModalClose}
        closeIconEnabled={false}
      >
        {actionSuccessLabel && (
          <FormattedMessage
            id={actionSuccessLabel}
            defaultMessage={actionSuccessLabel}
          />
        )}
      </CustomSuccessModal>
    </>
  );
};

export default DocumentUpdatePage;
