import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// Components
import * as Styled from './styled';
import { Form as FormModule } from 'billon-ui';

const { FormControl } = FormModule;

const SelectField = (
  {
    input,
    className,
    meta: { touched, active, dirty, error, warning, submitting },
    options,
    disabled,
    multi,
    searchable,
    clean,
    label,
    placeholder,
    floatLabel,
    isLoading,
    isClearable,
    menuShouldBlockScroll,
  },
  ...rest
) => {
  const select = (
    <Styled.CustomizedSelect
      value={input.value}
      onChange={(value, rest) => {
        const { action } = rest || {};

        if (input?.onClear && input?.name && action === 'clear') {
          input.onClear(input?.name);
        }

        return input.onChange(value, rest);
      }}
      options={options}
      isDisabled={disabled || submitting}
      isMulti={multi}
      isSearchable={searchable}
      placeholder={placeholder}
      className="billon-select"
      classNamePrefix="billon-select"
      onInputChange={(value, rest) => input.onInputChange(value, rest)}
      isLoading={isLoading}
      isClearable={isClearable}
      components={{
        IndicatorSeparator: () => null,
      }}
      isOptionDisabled={(option) => option?.disabled}
      noOptionsMessage={() => (
        <FormattedMessage
          id={'No data to display'}
          defaultMessage={'No data to display'}
        />
      )}
      loadingMessage={() => (
        <FormattedMessage id={'Loading'} defaultMessage={'Loading'} />
      )}
      filterOption={null}
      menuShouldBlockScroll={menuShouldBlockScroll}
      menuPortalTarget={document.body}
      styles={{
        ...Styled.colourStyles,
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );

  if (clean) {
    return select;
  }

  return (
    <FormControl
      active={active}
      touched={touched}
      dirty={dirty || !!input.value}
      error={error}
      warning={warning}
      className={className}
      label={label}
      floatLabel={floatLabel}
      {...rest}
    >
      {select}
    </FormControl>
  );
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  floatLabel: PropTypes.bool,
  clean: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
};

SelectField.defaultProps = {
  multi: false,
  type: 'select',
  searchable: true,
  clean: false,
  floatLabel: false,
  placeholder: '',
};

export default SelectField;
