import styled, { css } from 'styled-components';
import { Modal as M } from 'reactstrap';
import { down } from 'styled-breakpoints';
import { Modal as ModalModule } from 'billon-ui';

const { ModalHeader: BillonModalHeader, ModalBody: BillonModalBody } =
  ModalModule;

export const Modal = styled(M)`
  position: relative;
  width: auto;
  margin: 1.75rem auto;

  pointer-events: none;
  max-width: 1200px;

  ${down('lg')} {
    margin: 0 auto;
    width: 90%;
  }

  &.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);

    ${down('lg')} {
      align-items: flex-start;
      min-height: 100%;
    }

    &::before {
      display: block;
      height: calc(100vh - 3.5rem);
      content: '';
    }
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    color: ${(props) => props.theme.bodyColor};
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: ${(props) => props.theme.borderRadius};
    box-shadow: ${(props) => props.theme.boxShadow};

    outline: 0;
  }
`;

export const ModalHeader = styled(BillonModalHeader)`
  .modal-title {
    text-align: left;
    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    margin-top: 0.5rem;
    font-size: 1.8rem;
    font-weight: ${(props) => props.theme.fontWeightBold};

    ${({ titleUnderline }) =>
      titleUnderline &&
      css`
        &:after {
          content: '';
          width: 5rem;
          display: block;
          height: 0.275rem;
          background: ${(props) => props.theme.palette.primary};
        }
      `};
  }
`;

export const CloseIconButton = styled.button`
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.grayLight};
  padding: 0;
  transition: ${(props) => props.theme.transitionBase};
  position: absolute;
  right: 25px;
  top: 14px;

  &:hover {
    color: ${(props) => props.theme.bodyColor};
  }
`;

export const ModalBody = styled(BillonModalBody)`
  margin-right: ${(props) => (props.closePadding ? '16px' : '0px')};
`;
