import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

export const useNotificationContent = (params, jobId, options) =>
  useQueryWithLogout(
    [QueryKeys.NOTIFICATION_CONTENT, { ...params, jobId }],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.NOTIFICATION_CONTENT.replace('{id}', jobId),
        ...params,
      });
      return data;
    },
    options,
  );
