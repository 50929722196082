import { removeUndefinedValues } from './removeUndefinedValues';

export const parseFilters = (filters) => {
  let output = filters && removeUndefinedValues(filters);

  if (filters?.pagination?.page > 0) {
    output.pagination.page = filters.pagination.page - 1;
  }

  return output;
};
