import { createReducer } from 'redux-act';
import {
  requestSettings,
  receiveSettingsSuccess,
  receiveSettingsFailure,
  receiveFailed,
  requestSmsCode,
  receiveSmsCodeSuccess,
  receiveSmsCodeFailure,
  requestUnauthorizedSmsCodeByToken,
  receiveUnauthorizedSmsCodeSuccess,
  receiveUnauthorizedSmsCodeFailure,
  requestMe,
  receiveMeSuccess,
  receiveMeFailure,
  requestValidatePassword,
  receiveValidatePasswordSuccess,
  receiveValidatePasswordFailure,
  requestModifyPasswordByToken,
  receiveModifyPasswordSuccess,
  receiveModifyPasswordFailure,
} from './actions';

export default createReducer(
  {
    [requestSmsCode]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [receiveSmsCodeSuccess]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [receiveSmsCodeFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [requestSettings]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [receiveSettingsSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      settings: payload,
      didFetched: true,
    }),

    [receiveSettingsFailure]: (state) => ({
      ...state,
      isFetching: false,
      didFetched: true,
      failed: true,
    }),

    [receiveFailed]: (state) => ({
      ...state,
      isFetching: false,
      didFetched: true,
      failed: true,
    }),

    [requestMe]: (state) => ({
      ...state,
      isFetchingMe: true,
    }),
    [receiveMeSuccess]: (state, payload) => ({
      ...state,
      isFetchingMe: false,
      me: payload,
      didFetchedMe: true,
    }),
    [receiveMeFailure]: (state) => ({
      ...state,
      isFetchingMe: false,
    }),

    [requestValidatePassword]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [receiveValidatePasswordSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      backendValidationErrors: {},
      didFetched: true,
    }),

    [receiveValidatePasswordFailure]: (state, payload) => ({
      ...state,
      isFetching: false,
      didFetched: true,
      backendValidationErrors: payload,
    }),

    [requestUnauthorizedSmsCodeByToken]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [receiveUnauthorizedSmsCodeSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      password: payload,
    }),
    [receiveUnauthorizedSmsCodeFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [requestModifyPasswordByToken]: (state) => ({
      ...state,
      isFetching: true,
      isInvalid: false,
    }),
    [receiveModifyPasswordSuccess]: (state) => ({
      ...state,
      isFetching: false,
      isInvalid: false,
    }),
    [receiveModifyPasswordFailure]: (state) => ({
      ...state,
      isFetching: false,
      isInvalid: true,
    }),
  },
  {
    settings: {},
    me: {},
    isFetching: false,
    didFetched: false,
    isFetchingMe: false,
    didFetchedMe: false,
    backendValidationErrors: {},
    failed: false,
    password: null,
    isInvalid: false,
  },
);
