import { Utils } from 'billon-ui';
import styled from 'styled-components';

const { Button, ButtonLoader } = Utils.Button;

export const LineHeaderRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 15px;
`;

export const LineHeaderRowTitle = styled.div`
  flex-grow: 0;
`;

export const LineHeaderRowLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${(props) => props.theme.palette.grayLighter};
`;

export const FullHeightBtn = styled(Button)`
  height: 100%;
  overflow: hidden;
`;

export const FullHeightButtonLoader = styled(ButtonLoader)`
  height: 100%;
`;
