import { useMemo } from 'react';

//hooks
import { NOTIFICATION_CONTENT_TAGS } from '../../../constraints';

export const useCompileNotificationsWithUserInputs = ({
  emailSubjectWithContent,
  emailWithContent,
  smsWithContent,
  emailSubjectUserInput,
  emailUserInput,
  smsUserInput,
}) => {
  const emailSubjectRenderValue = useMemo(() => {
    if (!!emailSubjectUserInput && emailSubjectUserInput !== '') {
      return emailSubjectWithContent?.replace(
        NOTIFICATION_CONTENT_TAGS.USER_INPUT,
        emailSubjectUserInput,
      );
    }
    return emailSubjectWithContent;
  }, [emailSubjectWithContent, emailSubjectUserInput]);

  const emailRenderValue = useMemo(() => {
    if (!!emailUserInput && emailUserInput !== '') {
      return emailWithContent?.replace(
        NOTIFICATION_CONTENT_TAGS.USER_INPUT,
        emailUserInput,
      );
    }
    return emailWithContent;
  }, [emailWithContent, emailUserInput]);

  const smsRenderValue = useMemo(() => {
    if (!!smsUserInput && smsUserInput !== '') {
      return smsWithContent?.replace(
        NOTIFICATION_CONTENT_TAGS.USER_INPUT,
        smsUserInput,
      );
    }
    return smsWithContent;
  }, [smsWithContent, smsUserInput]);

  return {
    emailSubjectRenderValue,
    emailRenderValue,
    smsRenderValue,
  };
};
