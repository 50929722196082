import styled from 'styled-components';
import { Grid, Utils } from 'billon-ui';
import { down } from 'styled-breakpoints';

const { Text } = Utils;
const { Row } = Grid;

export const LoginContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: ${(props) => props.theme.palette.grayLightest};
  display: flex;
  justify-content: center;

  .flag-select {
    position: absolute;
    right: 3rem;
    top: 1.54rem;

    @media (max-width: 992px) {
      top: 0.5rem;
    }
  }
`;

export const LoginContent = styled(Row)`
  align-items: center;
  width: 100%;
  margin: 0;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  ${down('md')} {
    margin-bottom: 20px;
  }
`;

export const Title = styled(Text)`
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  text-align: right;
  font-size: 3rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
  line-height: 1.2;

  ${down('sm')} {
    font-size: 2.25rem;
    line-height: 1.1;
  }

  @media (max-width: 360px) {
    font-size: 2rem;
    line-height: 1.1;
  }
`;

export const Small = styled.small`
  font-size: 0.5rem;
  font-weight: ${(props) => props.theme.fontWeightMedium};
  margin-bottom: 0.25rem;
`;
