import styled from 'styled-components';

import { Utils } from 'billon-ui';

const { Text } = Utils;

export const SummaryContainer = styled.div`
  border: 1px dashed #c9d4de;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const IconButtonWrapper = styled.div`
  padding: 0.5rem;
`;

export const EmailSubject = styled(Text)`
  margin-bottom: 8px;
`;

export const EmailSubjectTag = styled.span`
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  font-size: ${(props) => props.theme.fontSizeSm};
`;
