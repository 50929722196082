import styled from 'styled-components';
import { Grid } from 'billon-ui';

const { Col } = Grid;

export const MyCol = styled(Col)`
  margin: 10rem 0;
`;

export const Description = styled.h6`
  height: 8rem;
`;

export const Title = styled.h2`
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: 3rem;
  height: 5rem;
`;
