import React from 'react';
import { Grid, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../../../ui/Modal';
import * as Styled from './styled';
import { FilterForm } from '..';
import { useAuthenticatedContext } from '../../../../../Auth/hooks/useAuthenticated';
import { UserRoles } from '../../../../../../constraints';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Col, Row } = Grid;

const ModalHeaderPanel = ({ isFilterDisabled }) => (
  <Row>
    <Styled.TitleContainer md={5}>
      <Styled.ModalTitle>
        <FormattedMessage
          id="Notification history"
          defaultMessage="Notification history"
        />
      </Styled.ModalTitle>
    </Styled.TitleContainer>
    {!isFilterDisabled && (
      <Styled.FilterCol md={7}>
        <FilterForm />
      </Styled.FilterCol>
    )}
  </Row>
);

const NotificationHistoryModal = (props) => {
  const { role } = useAuthenticatedContext();
  const isFilterDisabled = role === UserRoles.DPI;

  return (
    <Modal {...props} closePadding>
      <ModalHeaderPanel isFilterDisabled={isFilterDisabled} />
      <Styled.ModalBody>{props.children}</Styled.ModalBody>
      <Styled.ModalFooter>
        <Col md={3}></Col>
        <Col md={6}>
          <Button size="lg" block onClick={props.onClose}>
            <FormattedMessage id="Close" defaultMessage="Close" />
          </Button>
        </Col>
        <Col md={3}></Col>
      </Styled.ModalFooter>
    </Modal>
  );
};

export default NotificationHistoryModal;
