import React from 'react';
import * as Styled from './styled';
import { Utils } from 'billon-ui';
import StatusBasedBadge from '../../../StatusBasedBadge';

const { Text } = Utils;

const BadgedTitle = ({
  title,
  errata,
  publicationMode,
  status,
  signingStatus,
  signingOrder,
  nextPublicationMode,
}) => {
  return (
    <Styled.HeaderRow>
      <Text as={'h1'} fontSize={'20px'} margin={'0 0 0 0'} fontWeight={700}>
        {title}
      </Text>
      <StatusBasedBadge
        publicationMode={publicationMode}
        status={status}
        signingStatus={signingStatus}
        signingOrder={signingOrder}
        nextPublicationMode={nextPublicationMode}
      />
    </Styled.HeaderRow>
  );
};

export default BadgedTitle;
