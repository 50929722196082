import styled from 'styled-components';
import { Grid } from 'billon-ui';

const { Row } = Grid;

export const LayoutWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: ${(props) => props.theme.palette.grayLightest};
  display: flex;
  justify-content: center;

  .flag-select {
    position: absolute;
    right: 3rem;
    top: 1.54rem;

    @media (max-width: 992px) {
      top: 0.5rem;
    }
  }
`;

export const ContentRow = styled(Row)`
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
`;
