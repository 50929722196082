import React from 'react';
import { FormattedMessage } from 'react-intl';

// constants
import { RECIPIENT_FILTER_FIELD_NAMES } from '../../../../../../constraints';

// Components
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { FieldLabel } from '../../../../../../components/Form';

const TextPhoneNumberField = ({
  name = RECIPIENT_FILTER_FIELD_NAMES.PHONE_NUMBER,
}) => {
  return (
    <>
      <FieldLabel>
        <FormattedMessage id="Phone number" defaultMessage="Phone number" />
      </FieldLabel>
      <ControlledTextField name={name} />
    </>
  );
};

export default TextPhoneNumberField;
