import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  DOCUMENT_RECIPIENTS_GROUPS,
  ORDER_METHOD,
  CUSTOMER,
} from '../../../../../../constraints';
import useFilters from '../../../../../../hooks/useFilters';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSchema } from './useSchema';
import { merge } from 'lodash';
import { useDocumentRecipients } from '../../../../../../modules/Documents/hooks/useDocumentRecipients';
import { useConfigContext } from '../../../../../../modules/Config/hooks/useConfig';

// components
import { SubmittingFooter } from '../SubmittingFooter/SubmittingFooter';
import { FormattedMessage } from 'react-intl';
import { Layout, Grid, Utils, Form as FormModule } from 'billon-ui';
import RecipientsTable from '../RecipientsTable';
import RecipientsFilterForm from '../RecipientsFilterForm';
import Modal from '../../../../../../ui/Modal';
import * as Styled from './styled';

const { CheckboxField } = FormModule;
const { Col, Row } = Grid;
const { Loader: LoaderModule } = Utils;
const { Content: ContentModule } = Layout;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;

const ModalHeaderPanel = ({ groupArray }) => (
  <Row>
    <Styled.TitleContainer md={6}>
      <Styled.ModalTitle>
        <FormattedMessage
          id="Select a group of recipients"
          defaultMessage="Select a group of recipients"
        />
      </Styled.ModalTitle>
    </Styled.TitleContainer>
    <Col md={6}>
      <RecipientsFilterForm
        isSearchByGroupEnabled={false}
        initialValues={{ groups: groupArray }}
      />
    </Col>
  </Row>
);

const SelectRecipientsGroup = ({
  jobId,
  resendMode,
  isOpen,
  isLoading,
  onClose,
  onBack,
  onNext,
  documentBlockchainAddress,
}) => {
  const defaultFilters = {
    pagination: {
      limit: 5,
      page: 1,
    },
    sort: {
      order: ORDER_METHOD.DESC,
    },
  };

  const { customer } = useConfigContext();
  const { formatMessage } = useIntl();

  const { navigateWithSearchQuery, filters } = useFilters();
  const schema = useSchema();

  const groupsOptions = [
    {
      label: formatMessage({
        id: 'New recipients',
      }),
      value: DOCUMENT_RECIPIENTS_GROUPS.NEW,
      checked: true,
    },
    {
      label: formatMessage({
        id: 'Incorrectly notified',
      }),
      value: DOCUMENT_RECIPIENTS_GROUPS.ERROR,
      checked: true,
    },
    {
      label: formatMessage({
        id: 'Delivered',
      }),
      value: DOCUMENT_RECIPIENTS_GROUPS.SUCCESS,
      checked: false,
    },
  ];

  const transformGroupArrayIntoFields = (filterArray, groupsOptions) => {
    if (filterArray) {
      return groupsOptions.map((option, i) =>
        filterArray.includes(option.value)
          ? { ...option, checked: true }
          : { ...option, checked: false },
      );
    } else {
      return groupsOptions;
    }
  };

  const formMethods = useForm({
    defaultValues: {
      groups: transformGroupArrayIntoFields(
        filters?.filters?.groups,
        groupsOptions,
      ),
    },
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = (values) => {
    if (minimumRequestDataLength) {
      onNext(values);
    }
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = formMethods;

  const groups = groupsOptions.map((option, i) => watch(`groups[${i}]`));

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const groupArray = groups
        .filter(({ checked }) => checked)
        .map(({ value }) => value);

      return navigateWithSearchQuery({
        filters: {
          groups: groupArray,
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [watch, groups]);

  useEffect(() => {
    if (isOpen && filters?.filters?.groups === undefined) {
      const groupArray = groupsOptions
        .filter(({ checked }) => checked)
        .map(({ value }) => value);

      navigateWithSearchQuery({
        filters: {
          groups: groupArray,
        },
      });
    }
  }, [isOpen]);

  const fullFilters = merge({}, defaultFilters, filters);

  const { data: requestData, isLoading: isRequesting } = useDocumentRecipients(
    fullFilters,
    {
      id: documentBlockchainAddress,
    },
  );

  let minimumRequestDataLength = requestData?.count > 0;

  const noDataMessageId =
    customer === CUSTOMER.DIPLOMA
      ? 'No students to notify'
      : 'No recipients to notify';

  const GroupsControllers = () =>
    groupsOptions.map((option, i) => (
      <Styled.CheckBoxWrapper>
        <Controller
          name={`groups[${i}].checked`}
          control={control}
          render={({ field, fieldState }) => (
            <CheckboxField
              input={{
                ...field,
                checked: field.value,
              }}
              meta={{
                touched: fieldState.invalid,
                error: fieldState.error,
              }}
              label={option.label}
            />
          )}
        />
      </Styled.CheckBoxWrapper>
    ));

  if (isLoading) {
    return (
      <Modal
        isOpen={true}
        closeIconEnabled={true}
        onClose={onClose}
        toggle={onClose}
      >
        <Content fluid>
          <PageLoader />
        </Content>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
      closePadding
    >
      <ModalHeaderPanel
        groupArray={groups
          .filter(({ checked }) => checked)
          .map(({ value }) => value)}
      />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(() => {})}>
          <GroupsControllers />
          {errors['groups'] && (
            <Styled.WarningMessage>
              <FormattedMessage
                id="Select at least one group to continue"
                defaultMessage="Select at least one group to continue"
              />
            </Styled.WarningMessage>
          )}
          {!minimumRequestDataLength && (
            <Styled.WarningMessage>
              <FormattedMessage
                id={noDataMessageId}
                defaultMessage={noDataMessageId}
              />
            </Styled.WarningMessage>
          )}

          <RecipientsTable
            jobId={jobId}
            documentBlockchainAddress={documentBlockchainAddress}
            deliveredData={{ data: requestData, isLoading: isRequesting }}
            customFooter={
              <SubmittingFooter
                onBack={onBack}
                onClose={onClose}
                onNext={handleSubmit(handleFormSubmit)}
              />
            }
            customErrorFooter={
              <SubmittingFooter
                isError
                onBack={onBack}
                onClose={onClose}
                onNext={handleSubmit(handleFormSubmit)}
              />
            }
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SelectRecipientsGroup;
