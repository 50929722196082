import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Utils } from 'billon-ui';
import * as Styled from './styled';
import SearchBarField from '../../../../ui/SearchBarField';
import { ProtectedComponent } from '../../../Auth/components';
import { UserRoles } from '../../../../constraints';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const Topbar = ({ onCreate }) => {
  const { formatMessage } = useIntl();

  const CreateCategoryBtn = () => (
    <Styled.Col md={2}>
      <Button fontSize="12px" onClick={() => onCreate()} block>
        <FormattedMessage id="Add Category" defaultMessage="Add Category" />
      </Button>
    </Styled.Col>
  );

  return (
    <Styled.Wrapper>
      <Styled.Col md={8}>
        <Styled.Text>
          <FormattedMessage
            id="Search for Category"
            defaultMessage="Search for Category"
          />
        </Styled.Text>
        <SearchBarField
          name="name"
          className="search-bar"
          classic
          placeholder={formatMessage({
            id: 'Category name',
            defaultMessage: 'Category name',
          })}
        />
      </Styled.Col>
      <ProtectedComponent
        roles={[UserRoles.BUSINESS_ADMIN, UserRoles.ADMIN]}
        render={false}
      >
        <CreateCategoryBtn />
      </ProtectedComponent>
    </Styled.Wrapper>
  );
};
