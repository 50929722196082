import React from 'react';
import { Utils } from 'billon-ui';
import { useDownloadDailyReport } from '../../hooks';
import * as Styled from './styled';

const { Icon, Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

const PreviewDailyReportButton = ({ record, isPreview = false }) => {
  const { mutate: downloadFile, isLoading } = useDownloadDailyReport(
    record.jobId,
    record.title,
    isPreview,
  );

  if (isLoading) {
    return (
      <Styled.LoadingButton color="link" className="ignore-row-click">
        <Loader width={36} />
      </Styled.LoadingButton>
    );
  }

  return (
    <Styled.PrevButton
      color="link"
      className="ignore-row-click"
      onClick={downloadFile}
    >
      <Icon billon={!isPreview} name={isPreview ? 'eye' : 'pdf'} />
    </Styled.PrevButton>
  );
};

export default PreviewDailyReportButton;
