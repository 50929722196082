import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconInCircle } from '../../IconInCircle';
import * as Styled from './styled';

const FormHeader = ({ closeFilters, headerMessageId = 'Filter by' }) => (
  <>
    <Styled.AbsoluteDivForExitIcon
      onClick={closeFilters}
      headerMessageId="Filter by"
    >
      <IconInCircle
        iconName="rejected_no_fill"
        size="31px"
        color="#cad5dd"
        hoverColor="#ACBBC7"
      />
    </Styled.AbsoluteDivForExitIcon>
    <Styled.Header>
      <FormattedMessage id={headerMessageId} defaultMessage={headerMessageId} />
    </Styled.Header>
  </>
);

export default FormHeader;
