import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule } from 'billon-ui';
const { FormGroup, PasswordField } = FormModule;

const ControlledPasswordField = ({ name, labelMessageId }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <PasswordField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                <FormattedMessage
                  id={labelMessageId}
                  defaultMessage={labelMessageId}
                />
              }
            />
          );
        }}
      />
    </FormGroup>
  );
};

export default ControlledPasswordField;
