import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// hooks
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { usePatchPassword } from '../../hooks/usePatchPassword';
import useSmsCodeRequest from '../../../../modules/Settings/hooks/useSmsCodeRequest';

// components
import PasswordForm from '../PasswordForm';
import SingleCustomCard from '../SingleCustomCard';
import { SendSmsCodeFailureModal } from '../SendSmsCodeFailureModal';
import TypeVerificationCode from '../TypeVerificationCode';
import SetPasswordSuccess from '../SetPasswordSuccess';

const TwoStepPasswordChange = ({ mode }) => {
  const history = useHistory();
  const { usersConfig } = useConfigContext();
  const { enableTwoStepPassword } = usersConfig || {};

  const [step, setStep] = useState(1);
  const [passwords, setPasswords] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [sendSmsCodeFailure, setSmsCodeFailure] = useState(false);

  const handleOnBack = () => {
    setStep(step - 1);
  };
  const handleOnClose = () => {
    history.push('/');
  };

  const handlePatchPasswordSuccess = () => {
    setStep(3);
  };
  const handlePatchPasswordFailure = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };
  const { mutate: patchPassword, isLoading: isPatchingPassword } =
    usePatchPassword({
      onSuccess: handlePatchPasswordSuccess,
      onError: handlePatchPasswordFailure,
    });

  const handleOnSendSmsCodeSuccess = () => {
    setStep(step + 1);
  };
  const handleOnSendSmsCodeFailure = () => {
    setSmsCodeFailure(true);
  };
  const handleSmsCodeFailureModalClose = () => {
    setSmsCodeFailure(false);
  };
  const { mutate: sendSmsCode, isLoading: isSendingSmsCode } =
    useSmsCodeRequest({
      onSuccess: handleOnSendSmsCodeSuccess,
      onError: handleOnSendSmsCodeFailure,
    });

  const handleOnValidatePasswordSuccess = (passwords) => {
    setPasswords(passwords);
    if (enableTwoStepPassword) {
      sendSmsCode();
    } else {
      patchPassword({
        passwordData: {
          oldPassword: passwords.oldPassword,
          password: passwords.password,
        },
      });
    }
  };

  const formConfig = {
    mode,
    handleSuccess: handleOnValidatePasswordSuccess,
    passwordLabelId: 'New password',
    repeatPasswordLabelId: 'Repeat new password',
    submitMessageId: 'Change password',
  };

  const isLoading = useMemo(
    () => isPatchingPassword || isSendingSmsCode,
    [isPatchingPassword, isSendingSmsCode],
  );

  return (
    <>
      {
        {
          1: (
            <SingleCustomCard titleId="Password change">
              <PasswordForm
                formConfig={formConfig}
                deliveredReasons={reasons}
                deliveredErrorHandler={handlePatchPasswordFailure}
                deliveredLoading={isLoading}
              />
              <SendSmsCodeFailureModal
                isOpen={sendSmsCodeFailure}
                toggle={handleSmsCodeFailureModalClose}
                onClose={handleSmsCodeFailureModalClose}
                closeIconEnabled={false}
              />
            </SingleCustomCard>
          ),
          2: (
            <TypeVerificationCode
              passwords={passwords}
              handleOnSubmitCodeSuccess={handleOnSendSmsCodeSuccess}
              handleOnBack={handleOnBack}
            />
          ),
          3: (
            <SetPasswordSuccess
              onClose={handleOnClose}
              messageId={'Password successfully changed'}
            />
          ),
        }[step]
      }
    </>
  );
};

export default TwoStepPasswordChange;
