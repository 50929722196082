import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Layout, Grid, Utils, Table as TableModule } from 'billon-ui';
import Modal from '../../../../../../ui/Modal';
import * as Styled from './styled';
import RecipientsFilterForm from '../RecipientsFilterForm';
import RecipientsTable from '../RecipientsTable';
import { chooseRecipientFormatter } from '../../formatters/chooseRecipientFormatter';
import { removeUndefinedValues } from '../../../../../../helpers/removeUndefinedValues';
import { SubmittingFooter } from '../SubmittingFooter/SubmittingFooter';

const { Col, Row } = Grid;
const { Loader: LoaderModule } = Utils;
const { Content: ContentModule } = Layout;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;
const { TableColumn } = TableModule;

const ModalHeaderPanel = () => (
  <Row>
    <Styled.TitleContainer md={6}>
      <Styled.ModalTitle>
        <FormattedMessage
          id="Search from list"
          defaultMessage="Search from list"
        />
      </Styled.ModalTitle>
    </Styled.TitleContainer>
    <Col md={6}>
      <RecipientsFilterForm isSearchByGroupEnabled />
    </Col>
  </Row>
);

const SearchFromList = ({
  isOpen,
  isLoading,
  onClose,
  onBack,
  onNext,
  jobId,
  documentBlockchainAddress,
  onAppendRecipients,
  unRegisterRecipient,
  defaultValues,
  latelyRemovedRecipient,
  areStoredRecipientsEmpty,
}) => {
  const formMethods = useForm({
    defaultValues: { defaultValues, recipients: {} },
  });

  const handleFormSubmit = () => {};

  const { handleSubmit, control, watch, getValues, setValue } = formMethods;

  useEffect(() => {
    if (isEmpty(defaultValues)) {
      const keys = Object.keys(removeUndefinedValues(getValues('recipients')));
      keys.forEach((uniqueId) =>
        setValue(`recipients[${uniqueId}]`, undefined),
      );
    }
  }, [defaultValues]);

  useEffect(() => {
    if (latelyRemovedRecipient?.uniqueId) {
      setValue(`recipients[${latelyRemovedRecipient.uniqueId}]`, undefined);
    }
  }, [latelyRemovedRecipient]);

  if (isLoading) {
    return (
      <Modal
        isOpen={true}
        closeIconEnabled={true}
        onClose={onClose}
        toggle={onClose}
      >
        <Content fluid>
          <PageLoader />
        </Content>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
      closePadding
    >
      <ModalHeaderPanel />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <RecipientsTable
            jobId={jobId}
            documentBlockchainAddress={documentBlockchainAddress}
            customFooter={
              <SubmittingFooter
                onBack={onBack}
                onClose={onClose}
                onNext={onNext}
                disabled={areStoredRecipientsEmpty}
              />
            }
            customErrorFooter={
              <SubmittingFooter
                isError
                onBack={onBack}
                onClose={onClose}
                onNext={onNext}
              />
            }
          >
            <TableColumn
              fieldName="choose"
              formatter={(value, record) =>
                chooseRecipientFormatter(
                  value,
                  record,
                  control,
                  onAppendRecipients,
                  unRegisterRecipient,
                  watch,
                )
              }
              className="fixed-width-200"
            >
              <FormattedMessage id="Choose" defaultMessage="Choose" />
            </TableColumn>
          </RecipientsTable>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SearchFromList;
