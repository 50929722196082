import React from 'react';

// hooks
import { usePrivateSingleNotificationGroupContent } from '../../../../../../hooks/privateNotifications/usePrivateSingleNotificationGroupContent';

// Components
import RowHistoryFields from './RowHistoryFields';
import { Utils } from 'billon-ui';
const { Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

const ContentHistoryRow = ({ contentId }) => {
  const { data: contentData, isFetching: isContentLoading } =
    usePrivateSingleNotificationGroupContent(contentId, {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  if (isContentLoading) {
    return <Loader width={50} />;
  }

  return <RowHistoryFields contentData={contentData} />;
};

export default ContentHistoryRow;
