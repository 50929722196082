import styled from 'styled-components';
import { Grid } from 'billon-ui';

const { Row: R, Col } = Grid;

export const LeftColumn = styled(Col)`
  align-self: center;
`;

export const RightColumn = styled(Col)`
  padding-top: 22px;
`;

export const Row = styled(R)``;

export const Text = styled.h2`
  font-size: 20px;
  color: ${(props) => props.theme.bodyColor} !important;
  font-weight: ${(props) => props.theme.fontWeightBold} !important;
`;
