import { useMemo } from 'react';
import { merge } from 'lodash';
import { useIntl } from 'react-intl';

// hooks
import useFilters from '../../../../../hooks/useFilters';
import { useRoleOptions } from './useRoleOptions';
import { useStatusOptions } from './useStatusOptions';
import { useIsActiveOptions } from './useIsActiveOptions';

export const useDocumentFormDefaultValues = (defaultFilters) => {
  const { formatMessage } = useIntl();
  const { filters } = useFilters();
  const { roleOptions } = useRoleOptions();
  const { statusOptions } = useStatusOptions();
  const { isActiveOptions } = useIsActiveOptions();

  const formInitialValues = {
    createdAt: '',
    createdBy: '',
    firstName: '',
    id: '',
    isActive: null,
    lastName: '',
    role: null,
    username: '',
    status: null,
    email: '',
    phoneNumber: '',
  };

  const { createdAt, isActive, role, status } = useMemo(
    () => merge({}, defaultFilters, filters)?.filters || {},
    [defaultFilters, filters],
  );

  const computedDefaultValues = useMemo(
    () =>
      merge({}, formInitialValues, {
        ...defaultFilters.filters,
        isActive:
          `${isActive}` !== 'null' &&
          isActiveOptions.filter(
            (option) => `${option.value}` === `${isActive}`,
          ),
        role: role && roleOptions.filter((option) => option.value === role),
        createdAt: [createdAt?.from, createdAt?.to],
        status:
          status && statusOptions.filter((option) => option.value === status),
      }),
    [],
  );

  return {
    formInitialValues,
    computedDefaultValues,
  };
};
