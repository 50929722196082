import { useMemo } from 'react';

// hooks
import { useNotificationContents } from '../../../../../hooks/useNotificationContents';
import { useCompileNotificationMessages } from '../../../../../hooks/useCompileNotificationMessages';
import { useNotificationUserInputs } from '../../../../../hooks';
import { useCompileNotificationsWithUserInputs } from '../../../../../hooks/useCompileNotificationsWithUserInputs';

// constants
import { NOTIFICATION_CONTENT_TAGS } from '../../../../../../../constraints';

export const useNotificationContentForm = ({
  documentBlockchainAddress,
  categoryId,
  categoryNotificationTemplate,
  tenantId,
  publicDocumentType,
  notificationTemplateType,
  emailSubjectUserInputTagField,
  emailContenttUserInputTagField,
  smsContenttUserInputTagField,
}) => {
  const {
    inputs: contents,
    isLoading: areContentsLoading,
    isError: isContentsError,
  } = useNotificationContents({
    categoryNotificationTemplate,
    categoryId,
    publicDocumentType,
  });

  const {
    data: defaultInputs,
    isLoading: areDefaultInputsLoading,
    isError: isDefaultInputsError,
  } = useNotificationUserInputs(documentBlockchainAddress, {
    enabled: !!documentBlockchainAddress,
  });

  const {
    emailSubjectContent,
    emailContent,
    smsContent,
    isEmailSubjectUserInputPresent,
    isEmailUserInputPresent,
    isSmsUserInputPresent,
  } = useMemo(() => {
    if (!!contents) {
      const { subjectInput, emailInput, smsInput } = contents || {};
      const { emailUserInput, smsUserInput } = defaultInputs || {};
      return {
        emailSubjectContent: subjectInput,
        emailContent: emailInput,
        smsContent: smsInput,
        isEmailSubjectUserInputPresent: subjectInput?.includes(
          NOTIFICATION_CONTENT_TAGS.USER_INPUT,
        ),
        isEmailUserInputPresent:
          !emailUserInput &&
          emailInput?.includes(NOTIFICATION_CONTENT_TAGS.USER_INPUT),
        isSmsUserInputPresent:
          !smsUserInput &&
          smsInput?.includes(NOTIFICATION_CONTENT_TAGS.USER_INPUT),
      };
    }
  }, [defaultInputs, contents]);

  const {
    emailSubjectPreviewValue: emailSubjectWithContent,
    emailPreviewValue: emailWithContent,
    smsContentPreviewValue: smsWithContent,
    isEmailUserInputPresent: isTemplateEmailUserInputPresent,
    isEmailSubjectUserInputPresent: isTemplateEmailSubjectUserInputPresent,
    isSmsUserInputPresent: isTemplateSmsUserInputPresent,
    isLoading: isCompilingMessages,
    error: compilingMessageError,
  } = useCompileNotificationMessages({
    emailSubjectInput: emailSubjectContent,
    emailContentInput: emailContent,
    smsContentInput: smsContent,
    documentType: notificationTemplateType,
    tenantId,
  });

  const isLoading = useMemo(
    () => areContentsLoading || areDefaultInputsLoading || isCompilingMessages,
    [areContentsLoading, areDefaultInputsLoading, isCompilingMessages],
  );

  const isError = useMemo(
    () => isContentsError || isDefaultInputsError || !!compilingMessageError,
    [isContentsError, isDefaultInputsError, compilingMessageError],
  );

  const { emailSubjectRenderValue, emailRenderValue, smsRenderValue } =
    useCompileNotificationsWithUserInputs({
      emailSubjectWithContent,
      emailWithContent,
      smsWithContent,
      emailSubjectUserInput: emailSubjectUserInputTagField,
      emailUserInput: emailContenttUserInputTagField,
      smsUserInput: smsContenttUserInputTagField,
    });

  const {
    isEmailSubjectUserInputTagFieldEnabled,
    isEmailContenttUserInputTagFieldEnabled,
    isSmsContentUserInputTagFieldEnabled,
  } = useMemo(() => {
    if (!documentBlockchainAddress) {
      if (!!contents) {
        return {
          isEmailSubjectUserInputTagFieldEnabled:
            isEmailSubjectUserInputPresent,
          isEmailContenttUserInputTagFieldEnabled: isEmailUserInputPresent,
          isSmsContentUserInputTagFieldEnabled: isSmsUserInputPresent,
        };
      } else {
        return {
          isEmailSubjectUserInputTagFieldEnabled:
            isTemplateEmailSubjectUserInputPresent,
          isEmailContenttUserInputTagFieldEnabled:
            isTemplateEmailUserInputPresent,
          isSmsContentUserInputTagFieldEnabled: isTemplateSmsUserInputPresent,
        };
      }
    }
    return {
      isEmailSubjectUserInputTagFieldEnabled: false,
      isEmailContenttUserInputTagFieldEnabled: false,
      isSmsContentUserInputTagFieldEnabled: false,
    };
  }, [
    documentBlockchainAddress,
    contents,
    isEmailSubjectUserInputPresent,
    isEmailUserInputPresent,
    isSmsUserInputPresent,
    isTemplateEmailSubjectUserInputPresent,
    isTemplateEmailUserInputPresent,
    isTemplateSmsUserInputPresent,
  ]);

  return {
    isLoading,
    isError,
    contents,
    defaultInputs,
    isEmailSubjectUserInputTagFieldEnabled,
    isEmailContenttUserInputTagFieldEnabled,
    isSmsContentUserInputTagFieldEnabled,
    emailSubjectRenderValue,
    emailRenderValue,
    smsRenderValue,
  };
};
