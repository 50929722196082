import styled from 'styled-components';

export const Tooltip = styled.div`
  background: #ffffff;
  padding: 4px;
  box-shadow: 2px 2px 9px 2px #cecece;
  p {
    padding: 0px;
    margin: 0px;
  }
`;
