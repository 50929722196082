import styled from 'styled-components';

export const HistoryTableWrapper = styled.div`
  table {
    width: 100%;
    tbody tr td:first-of-type {
      white-space: nowrap;
    }
  }
`;
