import download from 'downloadjs';

import apiRoutes from '../../../api-routes';
import { initializeWebViewerFromBlob } from '../../../webviewer';
import { getRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from '../../../hooks/useMutationWithLogout';

export const useDownloadRecipientsList = (
  id,
  documentTitle,
  isPreview = false,
) =>
  useMutationWithLogout(
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.DOCUMENT_DOWNLOAD_RECIPIENTS_LIST.replace('{id}', id),
        responseType: 'arraybuffer',
      });
      return data;
    },
    {
      onSuccess: (response) => {
        const blob = new Uint8Array(response);

        if (isPreview) {
          initializeWebViewerFromBlob(blob);
          return;
        }

        download(blob, documentTitle + '-recipients.xlsx');
      },
    },
  );
