import React, { useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DOCUMENTS_OPTIONS,
  MAP_DOCUMENTS_OPTIONS,
} from '../../../../../../../../constraints';
import BarToolTip from '../BarToolTip';
import base from '../../../../../../../../config/base';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const PublicationBarChart = ({ publishedDocumentType, yearlyReportData }) => {
  const { theme } = base;
  const [size, setSize] = useState([0, 0]);
  const selectedDocTypeMessageId = MAP_DOCUMENTS_OPTIONS[publishedDocumentType];

  const useWindowSize = () => {
    useLayoutEffect(() => {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };

  const [width] = useWindowSize();

  return (
    <>
      <FormattedMessage id={selectedDocTypeMessageId} />
      <div
        style={{
          width: width > 1400 ? '98%' : 600,
          height: 300,
        }}
      >
        <ResponsiveContainer>
          <BarChart width={600} height={440} data={yearlyReportData}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis dataKey="name" interval={0} />
            <YAxis allowDecimals={false} angle={1} />
            <Tooltip
              content={
                <BarToolTip publishedDocumentType={publishedDocumentType} />
              }
            />
            {[DOCUMENTS_OPTIONS.ALL, DOCUMENTS_OPTIONS.PUBLIC].includes(
              publishedDocumentType,
            ) && (
              <Bar
                dataKey="publicDocumentPublished"
                fill={theme.palette.billonGreen}
              />
            )}
            {[DOCUMENTS_OPTIONS.ALL, DOCUMENTS_OPTIONS.PRIVATE].includes(
              publishedDocumentType,
            ) && (
              <Bar
                dataKey="privateDocumentPublished"
                fill={theme.palette.billonBlue}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PublicationBarChart;
