import { NOTIFICATION_HISTORY_FILTERS_OPTIONS } from '../../../constraints';

const parseTextField = (v) => {
  if (!v || v?.length === 0) return;
  else return v.toLowerCase();
};

export const parseNotificationHistoryFilters = ({
  field,
  contactDetails,
  clientId,
  notificationStatusList,
}) => ({
  contactDetails:
    field.value === NOTIFICATION_HISTORY_FILTERS_OPTIONS.CONTACT_DETAILS
      ? parseTextField(contactDetails)
      : undefined,
  clientId:
    field.value === NOTIFICATION_HISTORY_FILTERS_OPTIONS.CLIENT_ID
      ? parseTextField(clientId)
      : undefined,
  notificationStatusList:
    notificationStatusList &&
    field.value === NOTIFICATION_HISTORY_FILTERS_OPTIONS.STATUS
      ? notificationStatusList.value
      : undefined,
});
