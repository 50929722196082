import styled from 'styled-components';

export const Form = styled.form`
  min-height: 435px;
  position: relative;
`;

export const BottomWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
