import { useMemo } from 'react';

//hooks
import { useNotificationTemplates } from '../../Notifications/hooks/useNotificationTemplates';
import { DOCUMENT_TYPE, NOTIFICATION_CONTENT_TAGS } from '../../../constraints';

export const useCompileNotificationMessages = ({
  emailContentInput,
  smsContentInput,
  documentType = DOCUMENT_TYPE.PUBLIC,
  tenantId,
  emailSubjectInput,
  enableTechnicalPreviewBlocking,
}) => {
  const {
    data: templates,
    isLoading,
    error,
  } = useNotificationTemplates({
    type: documentType,
    tenantId,
  });

  const emailSubjectPreviewValue = useMemo(() => {
    if (error || isLoading || !templates) {
      return '';
    }
    if (!!emailSubjectInput) {
      return templates?.emailSubject?.replace(
        NOTIFICATION_CONTENT_TAGS.USER_INPUT,
        emailSubjectInput,
      );
    }
    return templates?.emailSubject;
  }, [error, isLoading, templates, emailSubjectInput]);

  const emailPreviewValue = useMemo(() => {
    if (error || isLoading || !templates) {
      return '';
    }

    let compiledTemplate = templates?.emailTemplate;

    (templates.attachments || []).forEach((attachment) => {
      if (attachment?.name && attachment?.source) {
        compiledTemplate = compiledTemplate.replace(
          `cid:${attachment.name}`,
          `data:image/jpeg;charset=utf-8;base64,${attachment.source}`,
        );
      }
    });

    if (!emailContentInput || !emailContentInput.length) {
      return compiledTemplate;
    }

    return compiledTemplate?.replace(
      NOTIFICATION_CONTENT_TAGS.USER_INPUT,
      emailContentInput,
    );
  }, [error, isLoading, templates, emailContentInput]);

  const smsContentPreviewValue = useMemo(() => {
    if (error || isLoading || !templates) {
      return '';
    }

    if (!smsContentInput || !smsContentInput?.length) {
      return templates?.smsTemplate;
    }

    return templates?.smsTemplate?.replace(
      NOTIFICATION_CONTENT_TAGS.USER_INPUT,
      smsContentInput,
    );
  }, [error, isLoading, templates, smsContentInput]);

  const isEmailUserInputPresent =
    !enableTechnicalPreviewBlocking &&
    templates?.emailTemplate?.includes(NOTIFICATION_CONTENT_TAGS.USER_INPUT);

  const isEmailSubjectUserInputPresent =
    !enableTechnicalPreviewBlocking &&
    templates?.emailSubject?.includes(NOTIFICATION_CONTENT_TAGS.USER_INPUT);

  const isSmsUserInputPresent =
    !enableTechnicalPreviewBlocking &&
    templates?.smsTemplate?.includes(NOTIFICATION_CONTENT_TAGS.USER_INPUT);

  return {
    templates,
    isLoading,
    error,
    emailSubjectPreviewValue,
    emailPreviewValue,
    smsContentPreviewValue,
    isEmailUserInputPresent,
    isEmailSubjectUserInputPresent,
    isSmsUserInputPresent,
  };
};
