import React from 'react';

// hooks
import { useConfigContext } from '../../../../../Config/hooks/useConfig';

// constants
import {
  RECIPIENT_ACTIONS,
  RECIPIENT_UPLOAD_ERROR_VALUES,
  RECIPIENT_FILE_ERRORS_TYPE,
  RECIPIENT_UPLOAD_FILE_ERRORS,
  RECIPIENT_UPLOAD_FILE_ERRORS_DIPLOMA,
  MAP_RECIPIENT_UPLOAD_ERRORS,
} from '../../../../../../constraints';
import {
  RECIPIENT_ERROR_CODES,
  RECIPIENT_FILE_FIELDS,
  MAP_RECIPIENT_FILE_FIELDS,
} from '../../../../../../constants';

// Components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Styled from '../styled';
import { Grid, Utils } from 'billon-ui';

const { Text } = Utils;
const { Col } = Grid;

const ReasonsForFailuresColumn = ({
  statusCode,
  mode,
  validationErrors = [],
  errors = [],
  isNeighbourDisplayed,
}) => {
  const { isDiploma } = useConfigContext();

  const ReasonsForFailureColSize = isNeighbourDisplayed ? 6 : 12;

  const ERROR_BASE = isDiploma
    ? RECIPIENT_UPLOAD_FILE_ERRORS_DIPLOMA
    : RECIPIENT_UPLOAD_FILE_ERRORS;

  const FileReasons = () => (
    <>
      {statusCode === RECIPIENT_ERROR_CODES.FILE_ERROR &&
        errors?.map((singleError) => (
          <div>
            {singleError.type === RECIPIENT_FILE_ERRORS_TYPE.MISSING && (
              <Text fontWeight={800} color="red" margin={0}>
                {ERROR_BASE[
                  `${RECIPIENT_FILE_ERRORS_TYPE.MISSING}_${singleError.columnName}`
                ] ? (
                  <>
                    {' '}
                    <FormattedMessage
                      id={
                        ERROR_BASE[
                          `${RECIPIENT_FILE_ERRORS_TYPE.MISSING}_${singleError.columnName}`
                        ]
                      }
                      defaultMessage={
                        ERROR_BASE[
                          `${RECIPIENT_FILE_ERRORS_TYPE.MISSING}_${singleError.columnName}`
                        ]
                      }
                    />
                    {', '}
                    <FormattedMessage
                      id="accepted column names"
                      defaultMessage="accepted column names"
                    />
                    {': "'}
                    {singleError.acceptedValues.join('", "')}
                    {'"'}
                  </>
                ) : (
                  <>
                    <FormattedMessage
                      id={ERROR_BASE.UNKNOWN}
                      defaultMessage={ERROR_BASE.UNKNOWN}
                    />
                    {'. '}
                  </>
                )}
              </Text>
            )}
            {singleError.type === RECIPIENT_FILE_ERRORS_TYPE.DUPLICATED && (
              <Text fontWeight={800} color="red" margin={0}>
                {ERROR_BASE[
                  `${RECIPIENT_FILE_ERRORS_TYPE.DUPLICATED}_${singleError.columnName}`
                ] ? (
                  <>
                    {' '}
                    <FormattedMessage
                      id={
                        ERROR_BASE[
                          `${RECIPIENT_FILE_ERRORS_TYPE.DUPLICATED}_${singleError.columnName}`
                        ]
                      }
                      defaultMessage={
                        ERROR_BASE[
                          `${RECIPIENT_FILE_ERRORS_TYPE.DUPLICATED}_${singleError.columnName}`
                        ]
                      }
                    />
                    .
                  </>
                ) : (
                  <>
                    <FormattedMessage
                      id={ERROR_BASE.UNKNOWN}
                      defaultMessage={ERROR_BASE.UNKNOWN}
                    />
                    .
                  </>
                )}
              </Text>
            )}
          </div>
        ))}
    </>
  );

  const NonUniqueReasons = () => (
    <>
      {statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR &&
        validationErrors?.map((singleError) => {
          const { lines, errorInfo, invalidData } = singleError || {};
          const invalidID =
            invalidData[MAP_RECIPIENT_FILE_FIELDS.DOCUMENT_NUMBER];

          if (
            errorInfo[RECIPIENT_FILE_FIELDS.DOCUMENT_NUMBER] ===
              RECIPIENT_UPLOAD_ERROR_VALUES.NON_UNIQUE &&
            lines
          )
            return (
              <div>
                <Text margin={0}>
                  <Styled.Bold>
                    <FormattedMessage id="In rows" defaultMessage="In rows" />{' '}
                    <FormattedMessage id="number" defaultMessage="number" />
                    {' ( '}
                    {lines.join(', ')} {'): '}
                  </Styled.Bold>
                  <Styled.ErrorRow>
                    {isDiploma ? (
                      <FormattedMessage
                        id="Students have the same indices"
                        defaultMessage="Students have the same indices"
                      />
                    ) : (
                      <FormattedMessage
                        id="Recipients have the same Document Number"
                        defaultMessage="Recipients have the same Document Number"
                      />
                    )}{' '}
                    {invalidID && (
                      <Styled.LightBold>{invalidID}</Styled.LightBold>
                    )}
                    {', '}
                    <FormattedMessage
                      id="please remove duplicates"
                      defaultMessage="please remove duplicates"
                    />
                    .
                  </Styled.ErrorRow>
                </Text>
              </div>
            );
          if (errorInfo['EMAIL'] === RECIPIENT_UPLOAD_ERROR_VALUES.NON_UNIQUE)
            return (
              <div>
                <Text margin={0}>
                  <Styled.Bold>
                    <FormattedMessage id="In rows" defaultMessage="In rows" />{' '}
                    <FormattedMessage id="number" defaultMessage="number" />
                    {' ( '}
                    {lines.join(', ')} {'): '}
                  </Styled.Bold>
                  <Styled.ErrorRow>
                    {isDiploma ? (
                      <FormattedMessage
                        id="There is more than one student with a given email address"
                        defaultMessage="There is more than one student with a given email address"
                      />
                    ) : (
                      <FormattedMessage
                        id="There is more than one recipient with a given email address"
                        defaultMessage="There is more than one recipient with a given email address"
                      />
                    )}
                    {', '}
                    <FormattedMessage
                      id="please remove duplicates in order to send notification"
                      defaultMessage="please remove duplicates in order to send notification"
                    />
                    .
                  </Styled.ErrorRow>
                </Text>
              </div>
            );
          return null;
        })}
    </>
  );

  const ValidationReasons = () => (
    <>
      {statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR &&
        validationErrors?.map((singleError) => {
          const { line, lines, errorInfo } = singleError || {};

          const errorsToDisplay = Object.entries(errorInfo).filter(
            ([column, errorType]) =>
              errorType !== RECIPIENT_UPLOAD_ERROR_VALUES.NEEDS_UPDATE,
          );

          return lines?.length > 0 || errorsToDisplay.length === 0 ? null : (
            <>
              <Text margin={0}>
                <Styled.Bold>
                  <FormattedMessage id="In row" defaultMessage="In row" />{' '}
                  <FormattedMessage id="number" defaultMessage="number" />
                  {` [${line}]: `}
                </Styled.Bold>
                {errorsToDisplay.map(([column, errorType]) => {
                  const keyToMap =
                    MAP_RECIPIENT_UPLOAD_ERRORS[`${errorType}_${column}`];
                  return (
                    <Styled.ErrorRow>
                      {keyToMap ? (
                        <FormattedMessage
                          id={keyToMap}
                          defaultMessage={keyToMap}
                        />
                      ) : (
                        <>
                          <FormattedMessage
                            id="Unknown reason for column"
                            defaultMessage="Unknown reason for column"
                          />
                          {` - ${column} - ${errorType}`}
                        </>
                      )}
                      .
                    </Styled.ErrorRow>
                  );
                })}
              </Text>
            </>
          );
        })}
    </>
  );

  const ServerReasons = () => (
    <>
      {statusCode === RECIPIENT_ERROR_CODES.INTERNAL_SERVER_ERROR && (
        <div>
          <Text margin={0}>
            <Styled.Bold>
              <FormattedMessage
                id="Unexpected error."
                defaultMessage="Unexpected error."
              />
            </Styled.Bold>
          </Text>
          <Link to="/support">
            <FormattedMessage
              id="Contact the support team"
              defaultMessage="Contact the support team"
            />
          </Link>
        </div>
      )}
    </>
  );

  const ReasonsForFailures = () => (
    <>
      <FileReasons />
      <NonUniqueReasons />
      <ValidationReasons />
      <ServerReasons />
    </>
  );

  return (
    <Col md={ReasonsForFailureColSize}>
      <Styled.TextDanger fontWeight={800}>
        <FormattedMessage id="Reasons" defaultMessage="Reasons" />
        {':'}
      </Styled.TextDanger>

      {[
        RECIPIENT_ACTIONS.INSERT_LIST,
        RECIPIENT_ACTIONS.EXTERNAL_VALIDATION,
      ].includes(mode) ? (
        <Styled.EditPanel>
          <ReasonsForFailures />
        </Styled.EditPanel>
      ) : (
        <ReasonsForFailures />
      )}
    </Col>
  );
};

export default ReasonsForFailuresColumn;
