import styled from 'styled-components';
import { Utils } from 'billon-ui';
import Flatpickr from 'react-flatpickr';

const { Button: ButtonModule, Text } = Utils;

export const SelectHistoryType = styled.div`
  display: flex;
`;

export const CardTitle = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 30px 0;
`;

export const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .dropdown {
    height: 100%;
    margin-left: 20px;
    margin-top: 10px;
  }
  .form-group {
    width: 180px;
    .form-control {
      font-size: 0.65rem;
    }
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const Button = styled(ButtonModule.Button)`
  &.btn-link {
    height: 50%;
    margin-top: 13px;
    margin-bottom: 30px;
    color: ${({ active }) => (active ? '#00aeff' : '#011627')};
    font-weight: ${({ active }) => active && 700};
    font-size: 12px;
    border-radius: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    &:not(:first-of-type) {
      border-left: 2px solid #cad5dd;
    }
  }
`;

export const HistoryTableWrapper = styled.div`
  table {
    width: 100%;
    tbody tr td:first-of-type {
      white-space: nowrap;
    }
  }
`;

export const DropDownDiv = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-end;
  margin-bottom: 40px;
  margin-right: 40px;
  white-space: nowrap;
`;

export const CalendarInput = styled(Flatpickr)`
  position: relative;
  z-index: 2;
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  transition: ${(props) => props.theme.transitionBase};
  font-size: ${(props) => props.theme.fontSizeSm};
  box-shadow: none;
  padding: 0.6rem 1rem;
  height: 42px;
  -webkit-appearance: none;

  &::placeholder {
    color: ${(props) => props.theme.palette.grayLight};
    font-weight: ${(props) => props.theme.fontWeightBase};
  }

  &:not([disabled]) {
    background-color: transparent;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    border-color: ${(props) => props.theme.palette.borderHoverColor};
    background-color: transparent;
    outline: 0;

    + i {
      color: ${(props) => props.theme.palette.gray};
    }

    + button:not(.btn) {
      color: ${(props) => props.theme.palette.iconHoverColor};
    }
  }

  &[disabled] {
    cursor: ${(props) => props.theme.disabledCursor};
    background-color: ${(props) => props.theme.disabledBackground};
  }
`;
