import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styled';
import { Form as FormModule } from 'billon-ui';

const { FormControl, TextField: TF } = FormModule;

const TextField = ({
  input,
  maxLength,
  type = 'text',
  clean,
  placeholder,
  disabled,
  meta: { touched, active, dirty, error, warning, submitting },
  ...rest
}) => {
  const inputField = (
    <Styled.Input
      {...input}
      type={type}
      className="form-control"
      maxLength={maxLength}
      disabled={submitting || disabled}
      placeholder={placeholder}
    />
  );

  if (clean) {
    return inputField;
  }

  return (
    <Styled.TrimmedField>
      <FormControl
        active={active}
        touched={touched}
        dirty={dirty || !!input.value}
        error={error}
        warning={warning}
        {...rest}
      >
        {inputField}
      </FormControl>
    </Styled.TrimmedField>
  );
};

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  maxLength: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  clean: PropTypes.bool,
};

TextField.defaultProps = {
  clean: false,
  disabled: false,
};

export default TextField;
