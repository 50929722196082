import { QueryKeys } from '../../../../queryKeys';
import { getRequest } from '../../../../helpers/apiClient';
import apiRoutes from '../../../../api-routes';
import { useQueryWithLogout } from '../../../../hooks/useQueryWithLogout';

export const usePrivateNotificationGroupContents = (
  params,
  groupId,
  options,
) => {
  return useQueryWithLogout(
    [QueryKeys.PRIVATE_NOTIFICATION_GROUP_CONTENTS, groupId],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.PRIVATE_NOTIFICATION_GROUP_CONTENTS.replace(
          '{groupId}',
          groupId,
        ),
        params,
      });

      return data;
    },
    options,
  );
};
