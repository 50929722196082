import { createAction } from 'redux-act';

export const receiveForbidden = createAction('User action forbidden');
export const receiveAllowed = createAction('User action allowed');

export const requestDecodeToken = createAction('Decode user info request');
export const requestRefreshToken = createAction('Refresh user token request');

export const receiveTokenSuccess = createAction(
  'Decode user info or refresh token success',
);
export const receiveTokenFailure = createAction(
  'Decode user info or refresh token failure',
);

export const requestAuthentication = createAction(
  'Request authentication',
  ({ url, values, meta }, ...rest) => ({
    url,
    values,
    meta,
    ...rest,
  }),
);
export const receiveAuthenticationSuccess = createAction(
  'Authentication success',
);
export const receiveAuthenticationFailure = createAction(
  'Authentication failed',
);
export const requestLogout = createAction('Request logout');
export const receiveLogoutSuccess = createAction('Logout success');

export const testReceive = createAction('Request testReceive');

export const requestAuthorizationByToken = createAction(
  'Request authorization by token',
  ({ token, url, values, meta }, ...rest) => ({
    token,
    url,
    values,
    meta,
    ...rest,
  }),
);
export const receiveAuthorizationByTokenSuccess = createAction(
  'Authorization by token success',
);
export const receiveAuthorizationByTokenFailure = createAction(
  'Authorization by token failed',
);
