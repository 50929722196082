import React from 'react';
import * as Styled from './styled';

const LineHeaderRow = (props) => (
  <Styled.LineHeaderRowContainer {...props}>
    <Styled.LineHeaderRowTitle>{props.children}</Styled.LineHeaderRowTitle>
    <Styled.LineHeaderRowLine />
  </Styled.LineHeaderRowContainer>
);

export default LineHeaderRow;
