import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactInputVerificationCode from 'react-input-verification-code';
import { Controller } from 'react-hook-form';
import * as Styled from './styled';

const SmsCodeInput = ({ setSmsCodeValue, control, errors }) => {
  return (
    <Controller
      name="smsCode"
      control={control}
      render={({ field, fieldState }) => {
        const fieldError = errors?.[field.name];
        const isInvalid = fieldError ? true : false;
        const errMessage = fieldError?.message;

        return (
          <>
            <Styled.VerificationCodeLabel>
              <FormattedMessage
                id="Enter the code from SMS"
                defaultMessage="Enter the code from SMS"
              />
            </Styled.VerificationCodeLabel>
            <Styled.StyledReactInputVerificationCode isInvalid={isInvalid}>
              <ReactInputVerificationCode
                placeholder={null}
                length={6}
                onChange={setSmsCodeValue}
              />
            </Styled.StyledReactInputVerificationCode>

            <Styled.VerificationCodeErrorLabel isInvalid={isInvalid}>
              {errMessage}
            </Styled.VerificationCodeErrorLabel>
          </>
        );
      }}
    />
  );
};

export default SmsCodeInput;
