import { parseDateRange } from '../../../helpers/parseDateRange';
import { parseDocumentTypeList } from './parseDocumentTypeList';
import { parseIdentity } from '../../Documents/helpers/parseIdentity';

export const parseAnalitycsFilters = ({
  blockchainAddress,
  publicationDateRange,
  documentStatusList,
  signingStatus,
  publishedBy,
  title,
  validSinceDateRange,
  validUntilDateRange,
  category,
  identity,
  activeDeliveryType,
  documentTypeList,
}) => ({
  blockchainAddress: blockchainAddress || undefined,
  categoryId: category?.value || undefined,
  identity: parseIdentity(identity),
  publicationDateRange: parseDateRange(publicationDateRange),
  documentStatusList: documentStatusList?.value || undefined,
  signingStatus: signingStatus?.value || undefined,
  publishedBy: publishedBy ? publishedBy.toLowerCase() : undefined,
  title: title ? title.toLowerCase() : undefined,
  validSinceDateRange: parseDateRange(validSinceDateRange),
  validUntilDateRange: parseDateRange(validUntilDateRange),
  activeDeliveryType: activeDeliveryType?.value || undefined,
  documentTypeList: parseDocumentTypeList(documentTypeList),
});
