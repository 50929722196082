import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const AccountType = () => (
  <Styled.Label>
    <FormattedMessage id="Account type2" defaultMessage="Account type2" />
    {': '}
    <Styled.LightWeight>{'Bot'}</Styled.LightWeight>
  </Styled.Label>
);

export default AccountType;
